/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AsyncSelect from 'react-select/lib/Async';
import Loader from '../../common/Loader';
import Utils from '../../../app/common/utils';
import SweetAlertPage from '../../../app/common/SweetAlertPage';
import groupService from '../../../app/groupService/groupService';


const styles = theme => ({
    heading: {
        fontSize: '21px',
        fontWeight: '500',
        marginTop: '0',
        marginBottom: '0',
        fontFamily: 'Montserrat, sans-serif',
    },
    dialogPaper: {
        minWidth: '700px',
        // maxWidth: '700px',
        minHeight: '250px',
        // maxHeight: '500px'
    },
    formAddBtn: {
        width: '90%',
        borderRadius: '10px',
        fontSize: '20px',
        textTransform: 'uppercase',
        backgroundColor: '#4d9fa0 ',
        color: '#fff',
        height: '45px',
        marginBottom: '15px',
        marginTop: "11px",
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    formControl: {
        margin: '0px 5px',
        padding: '0px 10px',
        minWidth: 120,
        color: '#373737 !important',
        borderRight: '1px solid #e6e7e8'
    },
    formRoot: {
        // display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        // marginLeft: '25%',
        border: '1px solid #ccc',
        boxShadow: '2px 2px 6px 0px  rgba(0,0,0,0.3)',
        borderRadius: '4px',
        marginBottom: '20px',
        marginTop: '8%',
        padding: '25px',
        textAlign: 'center'
    },
    input: {
        display: 'none',
    },

});
// const statusOption = ["pending", "settled", "partial_settled"];

const CustomOption = props => {
    const { data, innerRef, innerProps } = props;
    return data && data.is_primary ? (
        <div ref={innerRef} {...innerProps} className='custom_option'>
            <i className="fa fa-check" style={{ color: "#60c1d8" }} aria-hidden="true"></i> &nbsp; {data.label ? data.label : ""}
        </div>
    ) : (
            <div ref={innerRef} {...innerProps} className='custom_option' >
                {data.label ? data.label : ""}
            </div>

        );
};

class CreateGroupModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,

            errorFields: {},
            showLoader: false,

            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },

            // new variables
            primaryid: [],
            userid: [],
            payloadData: {
                "group_data": {
                    "credit_limit": "",
                    "group_name": "",
                    "primary_user_id": "",
                    "primary_user_mobile": ""
                },
                "user_data": []
            },
            defaultOption: { primaryUserData: [], normalUserData: [] }
        }
    }


    componentDidMount() {
        if (this.state.defaultOption && this.state.defaultOption.primaryUserData && this.state.defaultOption.primaryUserData.length === 0) {
            this.getOptions("allData", "a", (data) => {
                this.setState({ defaultOption: data });
            });
        }
    }

    UNSAFE_componentWillReceiveProps() {
        if (this.props !== this.state) {
            this.setState({ open: this.props.open });
        }
    }

    handleInputChange(event) {
        let payloadDataVal = this.state.payloadData;
        var floatIds = ["credit_limit"]; // this values need to be float
        var errors = this.state.errorFields;
        var id = event.target.id;
        if (!id && id === undefined) {
            id = event.target.name;
        }
        var val = event.target.value;
        if (floatIds.indexOf(id) > -1) {
            if (val === "" || !isNaN(val)) {
                // if (val === "" || val.match(/^(\d+\.?\d{0,9}|\.\d{1,9})$/)) {
                let amount = val && val !== "" ? Number(val) : 0;
                if (amount <= 1000000000) {
                    payloadDataVal["group_data"][id] = (val && val !== "" ? Number(val) : "");
                }
            }
        } else {
            if (val.length <= 50) {
                payloadDataVal["group_data"][id] = val;
            }
        }
        if (errors.hasOwnProperty(id)) {
            delete errors[id];
        }
        this.setState({
            payloadData: payloadDataVal,
            errorFields: errors
        })
    }

    handleDialogCancel(event) {
        this.props.onAddModalCancel();
    }

    getOptions = async (type, inputValue, callback) => {
        try {
            if (!inputValue) {
                callback([]);
            }
            let data = {};
            data["searchVal"] = inputValue;
            data["role"] = "ca";
            let resp = await groupService.searchUserList(data);

            if (resp.data.status === 1 && resp.data.result) {
                var respData = this.formatDataForDropDown(resp.data.result.data, "fullname", "id", type);
                callback(respData);
            } else {
                callback([]);
            }
        } catch (err) {
            console.error(err);
            callback([]);
        }
    }

    formatDataForDropDown(data, labelKey, valuekey, type) {

        var optionsData = [];
        if (type === "allData") {
            optionsData = { primaryUserData: [], normalUserData: [] };
        }
        if (data) {
            for (var i = 0; i < data.length; i++) {
                // optionsData.push({ label: data[i][labelKey] + " (" + data[i][valuekey] + " )", value: data[i][valuekey] });
                if (type === "allData") {

                    if (data[i].hasOwnProperty('is_primary') && data[i]['is_primary'] && data[i]['is_primary'] !== 'false') {
                        optionsData.primaryUserData.push({
                            rawdata: data[i],
                            is_primary: Utils.checkIfPrimaryKey(data[i]),
                            label: data[i]["fullname"] + ",  " + data[i]["business_name"] + " \n  (" + data[i]["locality"] + " , " + data[i][valuekey] + " )",
                            value: data[i][valuekey]
                        });
                    } else {
                        optionsData.normalUserData.push({
                            rawdata: data[i],
                            is_primary: Utils.checkIfPrimaryKey(data[i]),
                            label: data[i]["fullname"] + ",  " + data[i]["business_name"] + " \n  (" + data[i]["locality"] + " , " + data[i][valuekey] + " )",
                            value: data[i][valuekey]
                        });
                    }
                } else if (type === "primaryid" && data[i].hasOwnProperty('is_primary') && data[i]['is_primary'] && data[i]['is_primary'] !== 'false') {
                    optionsData.push({
                        rawdata: data[i],
                        is_primary: Utils.checkIfPrimaryKey(data[i]),
                        label: data[i]["fullname"] + ",  " + data[i]["business_name"] + " \n  (" + data[i]["locality"] + " , " + data[i][valuekey] + " )",
                        value: data[i][valuekey]
                    });
                } else if (type !== "primaryid" && (!data[i]['is_primary'] || data[i]['is_primary'] === 'false')) {
                    optionsData.push({
                        rawdata: data[i],
                        is_primary: Utils.checkIfPrimaryKey(data[i]),
                        label: data[i]["fullname"] + ",  " + data[i]["business_name"] + " \n  (" + data[i]["locality"] + " , " + data[i][valuekey] + " )",
                        value: data[i][valuekey]
                    });
                }
            }
        }
        return optionsData;
    }

    getSearchAreaTextPrimaryId = (id, event) => {
        try {
            console.log(event)
            let payloadDataVal = this.state.payloadData;
            let errorFields = this.state.errorFields;
            this.setState({ [id]: event !== null ? event : "" }, () => {
                let sData = {};
                if (event !== null) {
                    sData = event["rawdata"];
                    payloadDataVal["group_data"]["primary_user_id"] = event["rawdata"]["id"];
                    payloadDataVal["group_data"]["primary_user_mobile"] = event["rawdata"]["mobile"];
                } else {
                    payloadDataVal["group_data"]["primary_user_id"] = "";
                    payloadDataVal["group_data"]["primary_user_mobile"] = "";
                }
                errorFields[id] = false;
                this.setState({ payloadData: payloadDataVal, errorFields: errorFields });
            });
        } catch (err) {
            console.log(err);
        }
    }


    getSearchAreaText = (id, event) => {
        try {
            console.log(event);
            let payloadDataVal = this.state.payloadData;
            let errorFields = this.state.errorFields;
            this.setState({ [id]: event !== null ? event : "" }, () => {
                payloadDataVal["user_data"] = [];
                for (let i = 0; i < event.length; i++) {
                    if (event !== null) {
                        payloadDataVal["user_data"].push(event[i]["value"]);
                    } else {
                        payloadDataVal["user_data"] = [];
                    }
                }
                console.log(payloadDataVal)
                errorFields[id] = false;
                this.setState({ payloadDataVal: payloadDataVal, errorFields: errorFields });
            });
        } catch (err) {
            console.log(err);
        }
    }

    checkForValidPayload(data) {
        let isvalid = true;
        let sweetAlertDataVal = this.state.sweetAlertData;
        sweetAlertDataVal["title"] = "Error";
        sweetAlertDataVal["type"] = "error";
        sweetAlertDataVal["text"] = "";
        let errorFields = {};
        if (data["group_data"] && data["user_data"]) {
            if (!data["group_data"]["group_name"] || (data["group_data"]["group_name"] && data["group_data"]["group_name"].trim() === "")) {
                isvalid = false;
                errorFields["group_name"] = true;
            }
            if (!data["group_data"]["credit_limit"] || data["group_data"]["credit_limit"] === "" || data["group_data"]["credit_limit"] === 0) {
                isvalid = false;
                errorFields["credit_limit"] = true;
            }
            if (!data["group_data"]["primary_user_id"] || data["group_data"]["primary_user_id"] === "") {
                isvalid = false;
                errorFields["primaryid"] = true;
            }

            if (data["user_data"] && data["user_data"].length === 0) {
                isvalid = false;
                errorFields["userid"] = true;
            }

            if (this.state.primaryid && this.state.primaryid["rawdata"] && this.state.primaryid["rawdata"]["is_connected_to_group"]) {
                isvalid = false;
                errorFields["primaryid"] = true;
                sweetAlertDataVal["text"] = "Primary user already exists in another group. so please select another primary user."
            } else if (this.state.userid && this.state.userid.length > 0) {
                let userName = "";
                for (let i = 0; i < this.state.userid.length; i++) {
                    if (this.state.userid[i]["rawdata"] && this.state.userid[i]["rawdata"]["is_connected_to_group"]) {
                        userName = this.state.userid[i]["label"] + (i !== 0 ? (", " + userName) : "")
                    }
                }
                if (userName !== "") {
                    isvalid = false;
                    errorFields["userid"] = true;
                    sweetAlertDataVal["text"] = userName + " . This user already exists in another group. So please select other user/users."
                }
            }
        } else {
            errorFields = {};
            isvalid = false;
        }
        if (sweetAlertDataVal["text"] !== "") {
            this.setState({ sweetAlertData: sweetAlertDataVal, showSweetAlert: true })
        }
        this.setState({ errorFields: errorFields });
        return isvalid;
    }

    async handelCreateGroup() {
        try {
            if (this.checkForValidPayload(this.state.payloadData)) {
                this.setState({ showLoader: true });
                let payload = this.state.payloadData;
                payload["user_data"].push(this.state.payloadData["group_data"]["primary_user_id"]);
                let resp = await groupService.createGroup(payload);
                console.log(resp);
                let sweetAlertDataVal = this.state.sweetAlertData;
                if (resp.data.status === 1 && resp.data.result) {
                    // 
                    sweetAlertDataVal["type"] = "success",
                        sweetAlertDataVal["title"] = "Success",
                        sweetAlertDataVal["text"] = "Group created successfully";
                } else {
                    sweetAlertDataVal["type"] = "error",
                        sweetAlertDataVal["title"] = "Error",
                        sweetAlertDataVal["text"] = "Oops an error occured while creating the group";
                }
                this.setState({ sweetAlertData: sweetAlertDataVal, showSweetAlert: true, showLoader: false })
            }
        } catch (err) {
            console.log(err);
            sweetAlertDataVal["type"] = "error",
                sweetAlertDataVal["title"] = "Error",
                sweetAlertDataVal["text"] = "Oops an error occured while creating the group";
            this.setState({ sweetAlertData: sweetAlertDataVal, showSweetAlert: true, showLoader: false })
        }
    }

    handelSweetAlertClosed() {
        this.setState({ showSweetAlert: false }, () => {
            if (this.state.sweetAlertData["type"] === "success") {
                this.props.onGroupCreated();
            }
        });
    }

    render() {
        const { classes } = this.props;
        const { showLoader, primaryid, userid, payloadData, errorFields, showSweetAlert,
            sweetAlertData } = this.state;

        return (<div>
            <Dialog style={{ zIndex: '1', display: showSweetAlert ? "none" : "unset" }}
                open={this.state.open}
                classes={{ paper: classes.dialogPaper }}
                disableBackdropClick={true}
                onClose={this.handleDialogCancel.bind(this)}
                aria-labelledby="form-dialog-title"                >
                {!showLoader ? <div>
                    <DialogTitle
                        style={{ background: '#05073a', textAlign: 'center', height: '50px' }}
                        id="form-dialog-title">
                        <p style={{ color: '#fff', marginTop: '-10px', fontFamily: 'Lato', fontSize: '18px' }}>
                            Add a new Group</p>
                    </DialogTitle>
                    <DialogContent >
                        <div style={{ display: "flex" }} >

                            <TextField
                                margin="dense"
                                id="group_name"
                                label="Group name"
                                type="text"
                                error={errorFields["group_name"] ? true : false}
                                style={{ width: '49%' }}
                                value={payloadData.group_data.group_name}
                                onChange={this.handleInputChange.bind(this)}
                                fullWidth />
                            &nbsp;
                            &nbsp;
                            <TextField
                                margin="dense"
                                id="credit_limit"
                                label="Credit Limit"
                                type="text"
                                error={errorFields["credit_limit"] ? true : false}
                                style={{ width: '49%' }}
                                value={payloadData.group_data.credit_limit}
                                onChange={this.handleInputChange.bind(this)}
                                fullWidth />

                        </div>
                        <div style={{ display: "flex" }}>

                            <div style={{ borderBottom: errorFields["primaryid"] ? "2px solid red" : "1px solid gray", width: "98%" }}>

                                <AsyncSelect
                                    cacheOptions
                                    value={primaryid}
                                    name={"primaryid"}
                                    onChange={this.getSearchAreaTextPrimaryId.bind(this, "primaryid")}
                                    isSearchable={true}
                                    isClearable={true}
                                    menuPortalTarget={document.body}
                                    placeholder={`Select primary user`}
                                    defaultOptions={this.state.defaultOption.primaryUserData}
                                    components={{ Option: CustomOption }}
                                    loadOptions={this.getOptions.bind(this, "primaryid")}
                                />

                            </div>
                        </div>

                        <div style={{ display: "flex", paddingTop: "10px" }}>
                            <div style={{ borderBottom: errorFields["userid"] ? "2px solid red" : "1px solid gray", width: "98%" }}>
                                <AsyncSelect
                                    isMulti
                                    value={userid}
                                    // menuIsOpen={true}
                                    name={"userid"}
                                    onChange={this.getSearchAreaText.bind(this, "userid")}
                                    isSearchable={true}
                                    isClearable={true}
                                    placeholder={`Select user`}
                                    menuPortalTarget={document.body}
                                    defaultOptions={this.state.defaultOption.normalUserData}
                                    components={{ Option: CustomOption }}
                                    loadOptions={this.getOptions.bind(this, "userid")}
                                />
                            </div>
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button className={classes.formCancelBtn} onClick={this.handelCreateGroup.bind(this)} color="primary">Create</Button>
                        <Button className={classes.formCancelBtn} onClick={this.handleDialogCancel.bind(this)} color="primary">Cancel</Button>
                    </DialogActions>
                </div> :
                    <Loader primaryText="Please wait.." />}
            </Dialog>

            {showSweetAlert &&
                <SweetAlertPage
                    show={true}
                    type={sweetAlertData.type}
                    title={sweetAlertData.title}
                    text={sweetAlertData.text}
                    sweetAlertClose={() => this.handelSweetAlertClosed()}
                />}
        </div>
        );
    }
}

CreateGroupModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateGroupModal);