/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import AsyncSelect from 'react-select/lib/Async';
import buyerService from '../../../app/buyerService/buyerService';
import supplierService from '../../../app/supplierService/supplierService';
import brokerService from '../../../app/brokerService/brokerService';
// import { Storage } from 'aws-amplify';
import Storage from '@aws-amplify/storage';
import commodityService from '../../../app/commodityService/commodityService';
import orderService from '../../../app/orderService/orderService';
import Loader from '../../common/Loader';
import Utils from '../../../app/common/utils';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
// import { Auth } from 'aws-amplify';
import Auth from '@aws-amplify/auth';
import SweetAlertPage from '../../../app/common/SweetAlertPage';
import commonService from '../../../app/commonService/commonService';
import { getBucketName } from '../../../config/appConfig';

const styles = theme => ({
  heading: {
    fontSize: '21px',
    fontWeight: '500',
    marginTop: '0',
    marginBottom: '0',
    fontFamily: 'Montserrat, sans-serif',
  },
  dialogPaper: {
    minWidth: '700px',
    // maxWidth: '700px',
    minHeight: '400px',
    // maxHeight: '500px'
  },
  formAddBtn: {
    width: '90%',
    borderRadius: '10px',
    fontSize: '20px',
    textTransform: 'uppercase',
    backgroundColor: '#4d9fa0 ',
    color: '#fff',
    height: '45px',
    marginBottom: '15px',
    marginTop: "11px",
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  formRoot: {
    // display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    // marginLeft: '25%',
    border: '1px solid #ccc',
    boxShadow: '2px 2px 6px 0px  rgba(0,0,0,0.3)',
    borderRadius: '4px',
    marginBottom: '20px',
    marginTop: '8%',
    padding: '25px',
    textAlign: 'center'
  },
  input: {
    display: 'none',
  },

});
// const statusOption = ["pending", "settled", "partial_settled"];

const CustomOption = props => {
  const { data, innerRef, innerProps } = props;
  return data && data.is_primary ? (
    <div ref={innerRef} {...innerProps} className='custom_option'>
      <i className="fa fa-check" style={{ color: "#60c1d8" }} aria-hidden="true"></i> &nbsp; {data.label ? data.label : ""}
    </div>
  ) : (
      <div ref={innerRef} {...innerProps} className='custom_option' >
        {data.label ? data.label : ""}
      </div>

    );
};

class AddOrderModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      addOrderPayload: {
        "buyerid": "",
        "supplierid": "",
        "brokerid": "",
        "commodity": "",
        "rate": "",
        "qnt": "",
        "unit": "",
        "type": "bilti",
        "bijak_amt": "",
        "supporting_images": [],
        "actual_dispatch_date": new Date(),
        "transport_info": "",
        "author_name": "",
        // "author_mobile": "",
        "creator_role": "",
        "status": "pending",
        "remark": "",
        "other_info": "",
        "buyer_mobile": "",
        "supplier_mobile": "",
        "commission_rate": "",
        "commission_unit": "",
        "target_location": "",
        "source_location": "",
        "rate_unit": "",

        "broker_mobile": "",
        "bijak_total_amount": "",
        "invoice_no": "",
        "old_system_order_id": "",
        "pkt": "",
        "brokerage": "",
        "tags": [],
        "order_type": ""
      },

      buyerid: "",
      supplierid: "",
      brokerid: "",
      tempVar: {},
      errorFields: {},
      attachmentArray: [],
      commodityList: { options: [], optionN_E: {}, optionE_N: {} },
      showLoader: false,
      subId: "",

      showSweetAlert: false,
      sweetAlertData: {
        "type": "",
        "title": "",
        "text": ""
      },
      showFormError: false,

      tagsOption: [],
      orderTypeOptions: []

    }
    this.getCommodityNames();
    this.handelAutoCompleteChange = this.handelAutoCompleteChange.bind(this);
  }


  componentDidMount() {
    this.getTagsData();
    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => this.setState({ subId: user.attributes.sub }))
      .catch(err => console.log(err));

    if (this.props.userdata && this.props.userdata.role === "ca") {
      this.state.addOrderPayload['buyerid'] = this.props.userdata.id;
      this.state.addOrderPayload['buyer_mobile'] = this.props.userdata.mobile;

    } else if (this.props.userdata && this.props.userdata.role === "la") {
      this.state.addOrderPayload['supplierid'] = this.props.userdata.id;
      this.state.addOrderPayload['supplier_mobile'] = this.props.userdata.mobile;
    } else if (this.props.userdata && this.props.userdata.role === "broker") {
      this.state.addOrderPayload['brokerid'] = this.props.userdata.id;
    }
  }

  UNSAFE_componentWillReceiveProps() {
    if (this.props !== this.state) {
      this.setState({ open: this.props.open });
    }
  }

  async getTagsData() {
    try {
      let tagsData = [];
      let orderTypeOptionsVal = [];
      let resp = await commonService.getTagsData("orders");
      if (resp.data.status === 1 && resp.data.order_type) {
        orderTypeOptionsVal = resp.data.order_type;
      }
      if (resp.data.status === 1 && resp.data.result) {
        tagsData = resp.data.result;
      } else {
        tagsData = [];
      }
      this.setState({ tagsOption: tagsData, orderTypeOptions: orderTypeOptionsVal });
    } catch (err) {
      console.log(err);
    }
  }


  async getCommodityNames() {
    try {
      let resp = await commodityService.getCommodityTable();
      console.error(resp)
      if (resp.data.status === 1 && resp.data.result) {
        this.setState({ commodityList: Utils.getCommodityNamesArrayKeysMap(resp.data.result.data) });
      } else {
        this.setState({ commodityList: { options: [], optionN_E: {}, optionE_N: {} } });
      }
    } catch (err) {
      console.error(err)
      this.setState({ commodityList: { options: [], optionN_E: {}, optionE_N: {} } });
    }
  }

  getCommodityNamesArray(data) {
    try {
      var listData = [];
      if (data) {
        for (var i = 0; i < data.length; i++) {
          if (data[i]["name"]) {
            listData.push(data[i]["name"])
          }
        }
      }
      console.log('listdata', listData);
      return listData;
    } catch (err) {
      console.log(err);
      return [];
    }
  }



  handleInputChange(event) {
    var floatIds = ["rate", "qnt", "bijak_amt", "commission_rate", "bijak_total_amount", "pkt", "brokerage"]; // this values need to be float
    var errors = this.state.errorFields;
    var id = event.target.id;
    if (!id && id === undefined) {
      id = event.target.name;
    }
    var val = event.target.value;
    var addOrderPayloadVal = this.state.addOrderPayload;
    if (floatIds.indexOf(id) > -1) {
      // if (val === "" || !isNaN(val)) {
      if (val === "" || val.match(/^(\d+\.?\d{0,9}|\.\d{1,9})$/)) {
        addOrderPayloadVal[id] = val;
      }
    } else {
      addOrderPayloadVal[id] = val;
    }
    if (errors.hasOwnProperty(id)) {
      delete errors[id];
    }
    this.setState({
      addOrderPayload: addOrderPayloadVal,
      errorFields: errors,
      showFormError: false
    })
  }

  handelAutoCompleteChange = (event, values) => {

    var errors = this.state.errorFields;
    var id = "commodity";
    var addOrderPayloadVal = this.state.addOrderPayload;

    let commoditylist = [];
    // let data = this.state.dataObj;
    if (values && values.length > 0) {
      for (var i = 0; i < values.length; i++) {
        commoditylist.push(this.state.commodityList["optionE_N"][values[i]]);
      }
    }

    if (values && values !== null) {
      addOrderPayloadVal[id] = commoditylist.toString();
    } else {
      addOrderPayloadVal[id] = "";
    }

    if (errors.hasOwnProperty(id)) {
      delete errors[id];
    }
    this.setState({
      addOrderPayload: addOrderPayloadVal,
      errorFields: errors
    })
  }

  handleDialogCancel(event) {
    this.props.onAddModalCancel();
  }

  getOptions = async (type, inputValue, callback) => {
    try {
      if (!inputValue || inputValue.length <= 4) {
        callback([]);
      } else {
        let resp = {};
        let data = {};
        data["searchVal"] = inputValue;
        if (type === "buyerid") {

          data['role'] = 'ca';
          resp = await buyerService.serchUser(data);
        }

        if (type === "supplierid") {
          data['role'] = 'la';
          resp = await supplierService.serchUser(data);
        }

        if (type === "brokerid") {
          data['role'] = 'broker';
          resp = await brokerService.serchUser(data);
        }

        if (resp.data.status === 1 && resp.data.result) {
          var respData = [];
          if (type === "brokerid") {
            respData = this.formatDataForDropDown(resp.data.result.data, "fullname", "id");
            this.setTempArray(resp.data.result.data, "id");
          } else {
            respData = this.formatDataForDropDown(resp.data.result.data, "fullname", "mobile");
            this.setTempArray(resp.data.result.data, "mobile");
          }

          callback(respData);
        } else {
          callback([]);
        }
      }
    } catch (err) {
      console.error(err);
      callback([]);
    }
  }

  setTempArray(data, type) {
    var tempVarVal = this.state.tempVar;
    for (var i = 0; i < data.length; i++) {
      tempVarVal[data[i][type]] = data[i];
    }
    this.setState({ tempVar: tempVarVal });
  }

  formatDataForDropDown(data, labelKey, valuekey) {

    var optionsData = [];
    if (data) {
      for (var i = 0; i < data.length; i++) {
        // optionsData.push({ label: data[i][labelKey] + " (" + data[i][valuekey] + " )", value: data[i][valuekey] });
        optionsData.push({
          is_primary: Utils.checkIfPrimaryKey(data[i]),
          label: data[i]["fullname"] + ",  " + data[i]["business_name"] + " \n  (" + data[i]["locality"] + " , " + data[i][valuekey] + " )",
          value: data[i][valuekey]
        });
      }
    }
    return optionsData;
  }

  formateDateForApi(data) {
    if (data && data !== "") {
      var dateVal = new Date(data);
      dateVal = dateVal.getFullYear() + "-" + ((dateVal.getMonth() + 1) < 10 ? "0" + (dateVal.getMonth() + 1) : dateVal.getMonth() + 1) + "-" + (dateVal.getDate() < 10 ? "0" + dateVal.getDate() : dateVal.getDate());
      return dateVal;
    } else {
      return "";
    }
  }

  getCommodityMappedName(data) {
    if (data) {
      return this.state.commodityList["optionE_N"][data]
    }
    return data;
  }

  async addOrder(event) {
    try {
      var payloadData = { "data": [] };
      var payload = this.state.addOrderPayload;
      // console.log()
      if (this.checkForInvalidFields(payload)) {
        this.setState({ showLoader: true });
        payload["supporting_images"] = this.prepareSupportingUrlArray(this.state.attachmentArray);
        payload["actual_dispatch_date"] = this.formateDateForApi(payload["actual_dispatch_date"]);
        payload["commodity"] = this.getCommodityMappedName(payload["commodity"]);
        payloadData["data"].push(this.removeBlankNonMandatoryFields(payload));

        // let resp = { data: { status: 1, message: "custom Mas" ,result:[]} }
        var resp = await orderService.addNewOrder(this.state.subId, payloadData);
        let sweetAlrtData = this.state.sweetAlertData;
        this.setState({ showLoader: false });
        if (resp.data.status === 1 && resp.data.result) {
          // alert("Successfully added this order ");
          // this.props.onOrderDataAdded();
          sweetAlrtData["type"] = "success";
          sweetAlrtData["title"] = "Success";
          sweetAlrtData["text"] = "Order added successfully";
        } else {
          // alert("There was an error while adding this order");
          // alert(resp && resp.data && resp.data.message ? resp.data.message : "There was an error while adding this order");
          sweetAlrtData["type"] = "error";
          sweetAlrtData["title"] = "Error";
          sweetAlrtData["text"] = resp && resp.data && resp.data.message ? resp.data.message : "There was an error while adding this order";
        }
        this.setState({
          showSweetAlert: true,
          sweetAlertData: sweetAlrtData
        });
      } else {
        // alert("please fill the mandatory fields highlighted");
        this.setState({ showFormError: true });
      }
    } catch (err) {
      console.log(err);
    }
  }

  removeBlankNonMandatoryFields(data) {
    var formateddata = {};
    var floatIds = ["rate", "qnt", "bijak_amt", "commission_rate"];
    for (var key in data) {
      if (data[key] !== "") {
        formateddata[key] = data[key];
      }
      if (formateddata[key] && floatIds.indexOf(key) > -1) {
        formateddata[key] = parseFloat(data[key]);
      }
      if (key === "cashback_value" && data[key] === "") {
        formateddata[key] = 0;
      }
      if (key === "cashback_allotted_to" && data[key] === "") {
        formateddata[key] = null;
      }
    }
    return formateddata;
  }

  prepareSupportingUrlArray(data) {
    var urlArray = [];
    for (var i = 0; i < data.length; i++) {
      urlArray.push(data[i]["image_url"]);
    }
    return urlArray;

  }

  checkForInvalidFields(data) {
    var isValid = true;
    var error = {};
    var nonMandatoryFields = ["transport_info", "type", "author_name", "brokerid",
      "remark", "other_info", "commission_rate", "commission_unit", "rate", "qnt",
      "unit", "rate_unit", "broker_mobile", "bijak_total_amount",
      "invoice_no", "old_system_order_id", "pkt", "brokerage", "order_type"]
    for (var key in data) {
      if (nonMandatoryFields.indexOf(key) === -1 && data[key] === "") {
        error[key] = true;
        isValid = false;
      }
    }
    this.setState({ errorFields: error });
    return isValid;
  }

  onStepChangeEventOccurs = (stepType) => {
    let { attachmentArray, step } = this.state;
    attachmentArray = [];
    step = stepType;
    this.setState({ attachmentArray, step });
  }

  deleteItem(key) {
    let { attachmentArray } = this.state;
    for (var i = 0; i < attachmentArray.length; i++) {
      var indFile = attachmentArray[i];
      if (indFile.key === key) {
        attachmentArray.splice(i, 1);
        this.setState({ attachmentArray });
      }
    }
  }


  fileChangedHandler = (event) => {
    let { selectedFileName, isFileLoading, file } = this.state;
    file = event.target.files[0];
    if (Utils.checkIfValidImageFile(file.name)) {
      selectedFileName = file ? file.name : null;
      isFileLoading = !file ? false : true;
      this.setState({ selectedFileName, isFileLoading, file })
      let updatedFileName = Utils.getImageName(file.name);
      Storage.configure({
        level: 'public',
        AWSS3: {
          bucket: getBucketName(),//Your bucket name;
          region: 'ap-south-1'//Specify the region your bucket was created in;
        }
      });

      Storage.put("order/" + updatedFileName, file, {
        // key: "UBIL-Register-Online.png"
        contentType: 'image/png'
      }).then(result => {
        let data = result
        let attachmentObj = {
          bucket: getBucketName(),
          filename: updatedFileName,
          key: result.key
        }
        let { attachmentArray } = this.state;

        Storage.get("order/" + updatedFileName)
          .then(result => {
            attachmentObj["image_url"] = result.split("?")[0];
            attachmentArray.push(attachmentObj)
            this.setState({
              isFileUpload: false,
              attachmentArray
            });
          })
          .catch(err => console.log(err));
      }
      ).catch(err => {
        this.setState({
          isFileUpload: false
        })
        // let data = err
        console.log(err)
      });
    } else {
      let sweetAlrtData = this.state.sweetAlertData;
      sweetAlrtData["type"] = "warning";
      sweetAlrtData["title"] = "Info";
      sweetAlrtData["text"] = "Please select a valid image file.";
      this.setState({
        showSweetAlert: true,
        sweetAlertData: sweetAlrtData
      });
    }
  }


  handelDateChange(dateval) {
    var addOrderPayloadVal = this.state.addOrderPayload;
    addOrderPayloadVal["actual_dispatch_date"] = dateval;
    this.setState({ addOrderPayload: addOrderPayloadVal })
  }

  handelSweetAlertClosed() {
    this.setState({ showSweetAlert: false }, () => {
      if (this.state.sweetAlertData.type !== 'warning') {
        this.props.onOrderDataAdded();
      }
    })
  }

  handelTagsChanges = (event, values) => {
    let addOrderPayloadVal = this.state.addOrderPayload;
    addOrderPayloadVal["tags"] = values;
    this.setState({ addOrderPayload: addOrderPayloadVal });
  }

  handelOptionTypeChanges = (event, values) => {
    let addOrderPayloadVal = this.state.addOrderPayload;
    addOrderPayloadVal["order_type"] = values;
    this.setState({ addOrderPayload: addOrderPayloadVal });
  }

  handelCommoditySelect = (event, values) => {
    let addOrderPayloadVal = this.state.addOrderPayload;
    addOrderPayloadVal["commodity"] = values;
    this.setState({ addOrderPayload: addOrderPayloadVal });
  }

  render() {
    const { classes } = this.props;
    const { showLoader, addOrderPayload, supplierid, buyerid,
      commodityList, tempVar, errorFields, showSweetAlert, sweetAlertData, showFormError,
      tagsOption, orderTypeOptions } = this.state;
    console.log('orderTypeOptions', orderTypeOptions);
    return (<div>
      <Dialog style={{ zIndex: '1' }}
        open={this.state.open}
        classes={{ paper: classes.dialogPaper }}
        // disableBackdropClick={true}
        onClose={this.handleDialogCancel.bind(this)}
        aria-labelledby="form-dialog-title"                >
        {!showLoader ? <div>
          <DialogTitle
            style={{ background: '#05073a', textAlign: 'center', height: '50px' }}
            id="form-dialog-title">
            <p style={{ color: '#fff', marginTop: '-10px', fontFamily: 'Lato', fontSize: '18px' }}>
              Add Order</p>
          </DialogTitle>
          <DialogContent>

            <div style={{ display: "flex" }}>
              <Grid container style={{ width: "49%" }} >
                <div style={{ width: "100%", textAlign: "center", lineHeight: "54px" }}>
                  Order Date
                        </div>
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ width: "49%" }} >
                <Grid container style={{ width: "49%" }} >
                  <KeyboardDatePicker
                    margin="normal"
                    id="actual_dispatch_date"
                    format="dd-MMM-yyyy"
                    style={{ width: '100%' }}
                    value={addOrderPayload["actual_dispatch_date"]}
                    maxDate={new Date()}
                    onChange={(dateval) => {
                      this.handelDateChange(dateval);
                    }}

                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </div>

            <div style={{ display: "flex" }}>
              {this.props.userdata && this.props.userdata.role === "ca" ?

                <TextField
                  margin="dense"
                  id="buyerid"
                  label="Buyer"
                  disabled={true}
                  // error={errorFields["amount"] ? true : false}
                  type="text"
                  style={{ width: '49%' }}
                  value={this.props.userdata.fullname}
                  // onChange={this.handleInputChange.bind(this)}
                  fullWidth />
                : <div style={{ borderBottom: errorFields["buyerid"] ? "2px solid red" : "1px solid gray", width: "49%" }}>
                  <AsyncSelect
                    cacheOptions
                    value={buyerid}
                    id={"reactSelectCustom"}
                    name={"buyerid"}
                    // onChange={( item )=>{ this.setState({ buyerid : item  })}}
                    onChange={(item) => {
                      this.setState({ buyerid: item }, function () {
                        var data = addOrderPayload;
                        if (errorFields["buyerid"]) {
                          delete errorFields["buyerid"];
                        }
                        if (item && item !== null) {
                          data["buyerid"] = tempVar[item["value"]]["id"];
                          data["buyer_mobile"] = tempVar[item["value"]]["mobile"];
                          data["target_location"] = tempVar[item["value"]]["locality"]
                        } else {
                          data["buyerid"] = "";
                          data["buyer_mobile"] = "";
                        }
                        this.setState({ addOrderPayload: data, errorFields: errorFields })
                      })
                    }}
                    isSearchable={true}
                    isClearable={true}
                    placeholder={`Select buyer..`}
                    defaultOptions={[]}
                    components={{ Option: CustomOption }}
                    loadOptions={this.getOptions.bind(this, "buyerid")}
                  />
                </div>}
                            &nbsp;
                            &nbsp;
                        {this.props.userdata && this.props.userdata.role === "la" ? <TextField
                margin="dense"
                id="supplierid"
                label="Supplier"
                disabled={true}
                // error={errorFields["amount"] ? true : false}
                type="text"
                style={{ width: '49%' }}
                value={this.props.userdata.fullname}
                // onChange={this.handleInputChange.bind(this)}
                fullWidth /> : <div style={{ borderBottom: errorFields["supplierid"] ? "2px solid red" : "1px solid gray", width: "49%" }}>

                  <AsyncSelect
                    cacheOptions
                    value={supplierid}
                    id={"reactSelectCustom"}
                    name={"supplierid"}
                    onChange={(item) => {
                      this.setState({ supplierid: item }, function () {
                        if (errorFields["supplierid"]) {
                          delete errorFields["supplierid"];
                        }
                        var data = addOrderPayload;
                        if (item && item !== null) {
                          data["supplierid"] = tempVar[item["value"]]["id"];
                          data["supplier_mobile"] = tempVar[item["value"]]["mobile"];
                          data["source_location"] = tempVar[item["value"]]["locality"]
                        } else {
                          data["supplierid"] = "";
                          data["supplier_mobile"] = "";
                        }
                        this.setState({ addOrderPayload: data, errorFields: errorFields })
                      })
                    }}
                    isSearchable={true}
                    isClearable={true}
                    placeholder={`Select supplier..`}
                    defaultOptions={[]}
                    components={{ Option: CustomOption }}
                    loadOptions={this.getOptions.bind(this, "supplierid")}
                  />
                </div>}
            </div>

            <div style={{ display: "flex" }}>


              <TextField
                select
                id="creator_role"
                name="creator_role"
                label="Creater Role"
                error={errorFields["creator_role"] ? true : false}
                type="text"
                style={{ width: '49%', marginTop: '5px' }}
                value={addOrderPayload.creator_role}
                onChange={this.handleInputChange.bind(this)}>
                {["la", "ca"].map((key, i) => (
                  <MenuItem key={i} value={key} selected={true}>
                    {key}
                  </MenuItem>
                ))}
              </TextField>
                            &nbsp;
                            &nbsp;
                            {/* <Autocomplete
                                // multiple
                                id="commodity"
                                error={errorFields["commodity"] ? true : undefined}
                                name="commodity"
                                label="Commodity"
                                options={commodityList["options"]}
                                getOptionLabel={e => e}
                                value={this.getCommodityArray(addOrderPayload.commodity)}
                                onChange={this.handelAutoCompleteChange}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                style={{ width: '49%', marginTop: '1%', borderBottom: (errorFields["commodity"] ? "1px solid red" : "unset") }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Commodity"
                                        placeholder="Search"
                                        fullWidth
                                    />
                                )}
                            /> */}

              {/* <TextField
                                select
                                id="commodity"
                                error={errorFields["commodity"] ? true : false}
                                name="commodity"
                                label="Commodity"
                                type="text"
                                style={{ width: '49%', marginTop: '1%' }}
                                value={addOrderPayload.commodity && addOrderPayload.commodity !== null ? [addOrderPayload.commodity] : []}
                                onChange={this.handleInputChange.bind(this)}>
                                {commodityList["options"].sort().map((key, i) => (
                                    <MenuItem key={i} value={key} selected={true}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </TextField> */}

              <Autocomplete
                id="commodity"
                error={errorFields["commodity"] ? true : false}
                options={commodityList["options"].sort()}
                value={addOrderPayload.commodity && addOrderPayload.commodity !== null ? addOrderPayload.commodity : ""}
                getOptionLabel={e => e}
                onChange={this.handelCommoditySelect}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip label={option} {...getTagProps({ index })} />
                  ))
                }
                style={{ width: "49%" }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Select Commodity"
                    placeholder="Commodity"
                    fullWidth
                  />
                )}
              />
            </div>
            <div style={{ display: "flex" }} >

              <TextField
                margin="dense"
                id="source_location"
                label="Source Location"
                type="text"
                error={errorFields["source_location"] ? true : false}
                style={{ width: '49%' }}
                value={addOrderPayload.source_location}
                onChange={this.handleInputChange.bind(this)}
                fullWidth />
                            &nbsp;
                            &nbsp;
<TextField
                margin="dense"
                id="target_location"
                label="Target Location"
                error={errorFields["target_location"] ? true : false}
                type="text"
                style={{ width: '49%' }}
                value={addOrderPayload.target_location}
                onChange={this.handleInputChange.bind(this)}
                fullWidth />
            </div>

            <div style={{ display: "flex", marginTop: 4 }} >

              <TextField
                margin="dense"
                id="bijak_amt"
                label="Bijak Amount"
                type="text"
                error={errorFields["bijak_amt"] ? true : false}
                style={{ width: '49%' }}
                value={addOrderPayload.bijak_amt}
                onChange={this.handleInputChange.bind(this)}
                fullWidth />

                            &nbsp;
                        &nbsp;
<TextField
                select
                id="type"
                name="type"
                label="Type"
                error={errorFields["type"] ? true : false}
                type="text"
                style={{ width: '49%', marginTop: '5px' }}
                value={addOrderPayload.type}
                onChange={this.handleInputChange.bind(this)}>
                {["bilti", "commission"].map((key, i) => (
                  <MenuItem key={i} value={key} selected={true}>
                    {key}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div style={{ display: "flex" }} >

              <TextField
                margin="dense"
                id="rate"
                label="Rate"
                type="text"
                error={errorFields["rate"] ? true : false}
                style={{ width: '49%' }}
                value={addOrderPayload.rate}
                onChange={this.handleInputChange.bind(this)}
                fullWidth />
                            &nbsp;
                          &nbsp;

                          <TextField
                select
                id="rate_unit"
                name="rate_unit"
                label="Rate Unit"
                error={errorFields["rate_unit"] ? true : false}
                type="text"
                style={{ width: '49%', marginTop: '5px' }}
                value={addOrderPayload.rate_unit}
                onChange={this.handleInputChange.bind(this)}>
                {["Rs/Kg", "Rs/Quintal", "Rs/Ton", "Rs/Packet", "Rs/Crate", "Rs/Box", "Rs/Pc"].map((key, i) => (
                  <MenuItem key={i} value={key} selected={true}>
                    {key}
                  </MenuItem>
                ))}
              </TextField>

            </div>



            <div style={{ display: "flex" }} >
              <TextField
                margin="dense"
                id="qnt"
                label="Quantity"
                error={errorFields["qnt"] ? true : false}
                type="text"
                style={{ width: '49%' }}
                value={addOrderPayload.qnt}
                onChange={this.handleInputChange.bind(this)}
                fullWidth />

                            &nbsp;
                              &nbsp;
                            <TextField
                select
                id="unit"
                error={errorFields["unit"] ? true : false}
                name="unit"
                label="Unit"
                type="text"
                style={{ width: '49%', marginTop: '5px' }}
                value={addOrderPayload.unit}
                onChange={this.handleInputChange.bind(this)}>
                {["kg", "quintal", "ton", "packet", "crate", "box", "pc"].map((key, i) => (
                  <MenuItem key={i} value={key} selected={true}>
                    {key}
                  </MenuItem>
                ))}
              </TextField>



            </div>


            <div style={{ display: "flex" }} >
              <TextField
                margin="dense"
                id="transport_info"
                label="Transport info"
                error={errorFields["transport_info"] ? true : false}
                type="text"
                style={{ width: '49%' }}
                value={addOrderPayload.transport_info}
                onChange={this.handleInputChange.bind(this)}
                fullWidth />
                            &nbsp;
                  &nbsp;

                            <TextField
                margin="dense"
                id="remark"
                error={errorFields["remark"] ? true : false}
                label="Remarks"
                type="text"
                style={{ width: '49%' }}
                value={addOrderPayload.remark}
                onChange={this.handleInputChange.bind(this)}
                fullWidth />
            </div>

            {/*--------------- newly Added---------------- */}
            <div style={{ display: "flex" }} >

              <TextField
                margin="dense"
                id="broker_mobile"
                error={errorFields["broker_mobile"] ? true : false}
                label="Broker Mobile"
                type="text"
                style={{ width: '49%' }}
                value={addOrderPayload.broker_mobile}
                onChange={this.handleInputChange.bind(this)}
                fullWidth />
                            &nbsp;
                        &nbsp;
                        <TextField
                margin="dense"
                id="bijak_total_amount"
                label="Commerce Value"
                error={errorFields["bijak_total_amount"] ? true : false}
                type="text"
                style={{ width: '49%' }}
                value={addOrderPayload.bijak_total_amount}
                onChange={this.handleInputChange.bind(this)}
                fullWidth />
            </div>

            <div style={{ display: "flex" }} >

              <TextField
                margin="dense"
                id="invoice_no"
                error={errorFields["invoice_no"] ? true : false}
                label="Invoice No."
                type="text"
                style={{ width: '49%' }}
                value={addOrderPayload.invoice_no}
                onChange={this.handleInputChange.bind(this)}
                fullWidth />
                            &nbsp;
                        &nbsp;
                        <TextField
                margin="dense"
                id="old_system_order_id"
                label="Old System Order id"
                error={errorFields["old_system_order_id"] ? true : false}
                type="text"
                style={{ width: '49%' }}
                value={addOrderPayload.old_system_order_id}
                onChange={this.handleInputChange.bind(this)}
                fullWidth />
            </div>

            <div style={{ display: "flex" }} >

              <TextField
                margin="dense"
                id="pkt"
                error={errorFields["pkt"] ? true : false}
                label="Pkt"
                type="text"
                style={{ width: '49%' }}
                value={addOrderPayload.pkt}
                onChange={this.handleInputChange.bind(this)}
                fullWidth />
                            &nbsp;
                            &nbsp;
                            <TextField
                margin="dense"
                id="brokerage"
                label="brokerage"
                error={errorFields["brokerage"] ? true : false}
                type="text"
                style={{ width: '49%' }}
                value={addOrderPayload.brokerage}
                onChange={this.handleInputChange.bind(this)}
                fullWidth />
            </div>


            {/*--------------- newly Added ends---------------- */}

            <div style={{ display: "flex", paddingTop: "5px" }} >
              <Autocomplete
                multiple
                id="fixed-demo"
                options={tagsOption}
                value={addOrderPayload.tags && addOrderPayload.tags !== null ? addOrderPayload.tags : ""}
                getOptionLabel={e => e}
                onChange={this.handelTagsChanges}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip label={option} {...getTagProps({ index })} />
                  ))
                }
                style={{ width: "98%" }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Select Tags"
                    placeholder="Search"
                    fullWidth
                  />
                )}
              />
            </div>
            <div style={{ display: "flex", paddingTop: "5px" }} >
              <Autocomplete
                id="fixed-demo2"
                options={orderTypeOptions}
                value={addOrderPayload.order_type && addOrderPayload.order_type !== null ? addOrderPayload.order_type : ""}
                getOptionLabel={e => e}
                onChange={this.handelOptionTypeChanges}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip label={option} {...getTagProps({ index })} />
                  ))
                }
                style={{ width: "98%" }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Select order type"
                    placeholder="Search"
                    fullWidth
                  />
                )}
              />
            </div>

            {this.state.attachmentArray && this.state.attachmentArray.length !== 0 &&
              <div style={{ fontFamily: "lato", padding: "10px" }}>
                Uploaded Images
                        </div>}
            <div style={{ display: "flex" }}>
              {(this.state.attachmentArray && this.state.attachmentArray.length !== 0) && this.state.attachmentArray.map((keyObj, i) => (
                // <div key={"imhs_" + i} style={{ width: "150px", marginLeft: "5px", boxShadow: " 0px 0px 10px 0px rgba(0,0,0,0.75)" }} >
                //     <img src={key} alt={key} height="150px" />
                // </div>
                <div key={"imhs_" + i} className="transaction-supporting-image">
                  <img src={keyObj["image_url"]} style={{ cursor: "zoom-in" }} onClick={() => window.open(keyObj["image_url"], "_blank")} alt={keyObj["image_url"]} height="150px" width="150px" />
                  <div className="transaction-delete-icon" onClick={this.deleteItem.bind(this, keyObj.key)}>
                    <i className="fa fa-trash fa-lg"></i>
                  </div>
                </div>
              ))}
            </div>

            <div >

              <Grid container direction="row" alignItems="stretch">
                <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'left', margin: "11px 0px 5px 0px", marginBottom: 5 }}>
                  <input
                    className={classes.input}
                    id="flat-button2-file"
                    type="file"
                    onClick={(event) => {
                      event.target.value = null
                    }}
                    accept="image/*"
                    onChange={this.fileChangedHandler.bind(this)}
                  />
                  <label htmlFor="flat-button2-file">
                    <Button component="span" style={{ border: '1px solid #d5d2d2', padding: '5px 10px', fontSize: 12, backgroundColor: '#dbdbdb' }}  >
                      Choose supporting images
                            </Button>
                  </label>
                </Grid>
              </Grid>
            </div>
            {showFormError &&
              <div style={{
                fontFamily: 'Montserrat, sans-serif',
                fontSize: "12px",
                color: "red",
                textAlign: "right"
              }}
              > Please fill the mandatory fields highlighted above.</div>}
          </DialogContent>
          <DialogActions>
            <Button className={classes.formCancelBtn} onClick={this.addOrder.bind(this)} color="primary">Add</Button>
            <Button className={classes.formCancelBtn} onClick={this.handleDialogCancel.bind(this)} color="primary">Cancel</Button>
          </DialogActions>
        </div> :
          <Loader primaryText="Please wait.." />}
      </Dialog>

      {showSweetAlert &&
        <SweetAlertPage
          show={true}
          type={sweetAlertData.type}
          title={sweetAlertData.title}
          text={sweetAlertData.text}
          sweetAlertClose={() => this.handelSweetAlertClosed()}
        />}
    </div>
    );
  }
}

AddOrderModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddOrderModal);