import React from 'react';
import PropTypes from 'prop-types';
// import '../../assets/css/app.css';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../common/Loader';
import Paper from '@material-ui/core/Paper';
import FilterListComponent from "./components/FilterListComponent";
import buyerService from './../../app/buyerService/buyerService';
import supplierService from './../../app/supplierService/supplierService';
// import OrderListTable from "./components/OrderListTable";
import DateRangeSelector from "./components/DateRangeSelector";
import paymentDetailsService from '../../app/paymentDetailsService/paymentDetailsService';
import PaymentDetailsTable from './components/PaymentDetailsTable';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import PaymentFilterOptionModal from '../common/PaymentFilterOptionModal';
import { getAccessAccordingToRole } from '../../config/appConfig';
import AddTransactionModal from '../payment/components/AddTransactionModal';
import BulkPaymentPaymentDetailsTable from './components/BulkPaymentPaymentDetailsTable';
import Utils from '../../app/common/utils';


const styles = theme => ({
    root: {
        width: '100%',
        overflow: 'auto',
        fontFamily: 'Lato !important',
        maxWidth: '1200px',
        minHeight: '80vh',
    },
    card: {
        maxWidth: '100%',
        marginTop: '15px',
        height: '97%',
    },
    heading: {
        marginTop: '15px',
        fontSize: '20px',
        alignTtems: 'center',
        display: '-webkit-inline-box'
    }
});

const groupedTypeFilter = {
    "direct_payment": "Direct Payments",
    "direct_payment_payout": "Direct Payment Payouts",
    "dashboard_credit": "Dashboard Credit",
    "credit_requested_via_app": "User requested Credit via App",
    "to_bijak": "ToBijak",
    "other_payments": "Other payments"
};


class PaymentDetailsContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            buyersList: [],
            brokersList: [],
            suppliersList: [],
            allTransactionsData: [],
            showLoader: false,
            datePayloads: { "startDate": "", "endDate": "" },
            params: {
                limit: 1000, // total amount of data 
                offset: 0 // data from which data needs to be get
            },
            totalDataCount: 0,

            showPaymentFilterOption: false,
            filterDataArray: [],
            transactionTypeArray: [],
            additionalFilter: {},


            showAddTransactionModal: false,
            paymentMetaInfo: undefined,
            isTableDataLoading: false,
            filterOptions: {},

            sortKeys: {},
            sortParams: {},
            sortKeysOrder: [],
            resetFilter: false,
            selectedTab: "all",
            groupedSubType: "",
            currentRef: this,
            notificationData: "",
            lastUpdatedKeys: {
                'transaction_type': '',
                'payment_mode': '',
                'amount': '',
                'status': '',
                'id': ''
            }
        }
        this.ismounted = true;
    }

    componentDidMount() {
        this.getBuyersList();
        // this.getBrokersList();
        this.getSuppliersList();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (window.notificationdata && window.notificationdata !== "" &&
            window.notificationdata.hasOwnProperty("type") && window.notificationdata["type"] === 'payment' &&
            prevState.notificationData !== window.notificationdata) {
            prevState.currentRef.updateDataOnNotificationRcvd(window.notificationdata);
            return null;
        } else {
            return null;
        }
    }

    updateDataOnNotificationRcvd(notfData) {
        try {
            if (notfData && notfData['type'] && notfData['type'] === 'payment') {
                if (!this.checkIfLastUpdatedKeysAreSame(notfData['raw_data']['transaction_type'],
                    notfData['raw_data']['payment_mode'], notfData['raw_data']['amount'],
                    notfData['raw_data']['status'], notfData['id'])) {
                    this.setState({
                        lastUpdatedKeys: {
                            'transaction_type': notfData['raw_data']['transaction_type'],
                            'payment_mode': notfData['raw_data']['payment_mode'],
                            'amount': notfData['raw_data']['amount'],
                            'status': notfData['raw_data']['status'],
                            'id': notfData['id']
                        }
                    }, () => {
                        let allTransactionsData = this.state.allTransactionsData;
                        if (allTransactionsData && allTransactionsData.length > 0) {
                            if (Utils.ifDataHasId(this.state.allTransactionsData, notfData['id'])) {
                                let index = Utils.getIndexOfTheID(this.state.allTransactionsData, notfData['id']);
                                allTransactionsData[index]['transaction_type'] = notfData['raw_data']['transaction_type'];
                                allTransactionsData[index]['payment_mode'] = notfData['raw_data']['payment_mode'];
                                allTransactionsData[index]['amount'] = notfData['raw_data']['amount'];
                                allTransactionsData[index]['status'] = notfData['raw_data']['status'];
                                this.setState({ 'allTransactionsData': allTransactionsData }, () => {
                                    window.notificationdata = "";
                                })
                            }
                        }
                    });
                }
            } else {
                window.notificationdata = "";
            }
        } catch (err) {
            console.log(err);
        }
    }

    checkIfLastUpdatedKeysAreSame(transaction_type, payment_mode, amount, status, id) {
        let lastUpdatedKeysVal = this.state.lastUpdatedKeys;
        if (lastUpdatedKeysVal['transaction_type'] === transaction_type &&
            lastUpdatedKeysVal['payment_mode'] === payment_mode &&
            lastUpdatedKeysVal['status'] === status &&
            lastUpdatedKeysVal['amount'] === amount &&
            lastUpdatedKeysVal['id'] === id) {
            return true;
        } else {
            return false;;
        }
    }

    async getBuyersList() {
        try {
            let param = { role: 'ca', "limit": 1000, "offset": 0 };
            let resp = await buyerService.serchUser(param);
            if (this.ismounted) {
                if (resp.data.status === 1 && resp.data.result) {
                    this.setState({ buyersList: this.formatDataForDropDown(resp.data.result.data, "fullname", "mobile") });
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    // async getBrokersList() {
    //     try {
    //         let resp = await brokerService.getBrokerList();
    //         if (this.ismounted) {
    //             if (resp.data.status === 1 && resp.data.result) {
    //                 this.setState({ brokersList: this.formatDataForDropDown(resp.data.result.data, "fullname", "id") });
    //             }
    //         }
    //     } catch (err) {
    //         console.error(err);
    //     }
    // }

    async getSuppliersList() {
        try {
            let param = { role: 'la', "limit": 1000, "offset": 0 };
            let resp = await supplierService.serchUser(param);
            if (this.ismounted) {
                if (resp.data.status === 1 && resp.data.result) {
                    this.setState({ suppliersList: this.formatDataForDropDown(resp.data.result.data, "fullname", "mobile") });
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    formatDataForDropDown(data, labelKey, valuekey) {

        var optionsData = [];
        if (data) {
            for (var i = 0; i < data.length; i++) {
                // optionsData.push({ label: data[i][labelKey] + " (" + data[i][valuekey] + " )", value: data[i][valuekey] });
                optionsData.push({ 
                    is_primary : Utils.checkIfPrimaryKey( data[i] ),
                    label: data[i]["fullname"] + ",  " + data[i]["business_name"] + " \n  (" + data[i]["locality"] + " , " + data[i][valuekey] + " )", 
                    value: data[i][valuekey] });
            }
        }
        return optionsData;
    }

    async getPaymentDetailsData(params) {
        if (!params.hasOwnProperty("limit")) {
            params["limit"] = this.state.params["limit"];
        }
        if (!params.hasOwnProperty("offset")) {
            params["offset"] = this.state.params["offset"];
        }

        if (this.state.filterDataArray.length > 0) {
            params["filter_status"] = this.state.filterDataArray.toString();
        } else {
            if (params.hasOwnProperty("filter_status")) {
                delete params["filter_status"];
            }
        }



        if (this.state.transactionTypeArray.length > 0) {
            params["filter_transaction_type"] = this.state.transactionTypeArray.toString();
        } else {
            if (params.hasOwnProperty("filter_transaction_type")) {
                delete params["filter_transaction_type"];
            }
        }


        this.setState({ params: params });
        // { "startDate": "", "endDate": "" }
        if (this.state.datePayloads["startDate"] !== "") {
            params["startDate"] = this.state.datePayloads["startDate"];
        }
        if (this.state.datePayloads["endDate"] !== "") {
            params["endDate"] = this.state.datePayloads["endDate"];
        }

        if (Object.keys(this.state.sortParams).length > 0 && this.state.sortParams["sortkey"] !== "") {
            params["sortkey"] = this.state.sortParams["sortkey"];
            params["sortorder"] = this.state.sortParams["sortorder"];
        } else {
            delete params["sortkey"];
            delete params["sortorder"];
        }
        // params["supplierid"] = "9953368723";
        let updatedParams = { ...params, ...this.state.additionalFilter };
        if (this.state.groupedSubType !== "") {
            updatedParams["group_type"] = this.state.groupedSubType;
        } else {
            if (updatedParams.hasOwnProperty("group_type")) {
                delete updatedParams["group_type"];
            }
        }

        try {
            let resp = await paymentDetailsService.getPaymentDetails(updatedParams);
            console.log( resp )
            if (resp.data.status === 1 && resp.data.result) {
                var respData = resp.data.result;
                this.setState({
                    allTransactionsData: this.state.allTransactionsData.concat(respData["allTransactions"]),
                    totalDataCount: respData.totalCount && respData.totalCount[0] && respData.totalCount[0]["count"] ? parseInt(respData.totalCount[0]["count"], 10) : 0,
                    paymentMetaInfo: respData["metainfo"],
                    showLoader: false,
                    isTableDataLoading: false
                });
            } else {
                this.setState({
                    allTransactionsData: [],
                    totalDataCount: 0,
                    paymentMetaInfo: [],
                    showLoader: false,
                    isTableDataLoading: false
                });
            }

        } catch (err) {
            console.error(err);
            if (this.ismounted) { this.setState({ allTransactionsData: [], totalDataCount: 0, showLoader: false, isTableDataLoading: false }); }
        }
    }

    componentWillUnmount() {
        this.ismounted = false;
    }

    // this function brings default selected date from the DateRangeSelection and call the Api 
    //when first Landed on this page
    onDefaultDateFromDateRangeShown(data) {
        this.setState({ datePayloads: data, showLoader: true }, function () {
            this.handelGetData(this.state.params);
        });
    }

    onDateChaged(data) {
        this.setState({ datePayloads: data, showLoader: true }, function () {
            this.getPaymentDetailsData(this.state.params);
        });
    }

    handelRefreshData() {
        if (!this.state.showLoader) {
            this.setState({
                filterDataArray: [],
                transactionTypeArray: [],
                additionalFilter: {},
                sortParams: {},
                sortKeysOrder: [],
                sortKeys: {},
                params: { limit: 1000, offset: 0 },
                resetFilter: true,
                selectedTab: "all",
                groupedSubType: ""
            }, () => {
                this.setState({
                    resetFilter: false
                }, () =>
                    this.handelGetData(this.state.params)
                )
            })
        }
    }

    onTransactionDataAdded(event) {
        this.setState({ showAddTransactionModal: false }, function () {
            this.handelGetData(this.state.params);
        })
    }

    resetOffsetAndGetData() {
        let paramsval = this.state.params;
        paramsval["offset"] = paramsval["offset"] + 1000;
        this.setState({ params: paramsval, isTableDataLoading: true }, function () {
            this.getPaymentDetailsData(paramsval);
        })
    }

    handelGetData(param) {
        param["offset"] = 0;
        param["limit"] = 1000;
        this.setState({
            allTransactionsData: [],
            resetPageNumber: true,
            showLoader: true,
            filterOptions: param,
            // sortParams: {},
            // sortKeysOrder: [],
            // sortKeys: {}
        }, () =>
            this.getPaymentDetailsData(param)
        )
    }

    onTableSorted(sortParamsData) {
        let param = this.state.filterOptions;
        param["offset"] = 0;
        param["limit"] = 1000;
        this.setState({
            sortParams: sortParamsData["params"],
            sortKeysOrder: sortParamsData["order"],
            sortKeys: sortParamsData["sortKeys"],
            allTransactionsData: [],
            showLoader: true,
            resetPageNumber: true,
            filterOptions: param
        }, () =>
            this.getPaymentDetailsData(this.state.filterOptions)
        );
    }

    handelTabChange(type, subtype) {
        let param = this.state.filterOptions;
        param["offset"] = 0;
        param["limit"] = 1000;
        this.setState({
            allTransactionsData: [],
            selectedTab: type,
            groupedSubType: subtype,
            resetPageNumber: true,
            showLoader: true,
            filterOptions: param
        }, () =>
            this.getPaymentDetailsData(param)
        )
    }

    render() {
        const { classes } = this.props;
        const { allTransactionsData, paymentMetaInfo, showPaymentFilterOption, filterDataArray,
            transactionTypeArray, additionalFilter, showAddTransactionModal, selectedTab, groupedSubType } = this.state;
        // Bulk Payout Btn
        let bulkPayoutBtn = null;
        const userRole = sessionStorage.getItem("userRole").split(",");
        let userRoleSuperAdmin = userRole.includes("SuperAdmin");
        let userRolePayoutChecker = userRole.includes("PayoutChecker");
        if(userRoleSuperAdmin || userRolePayoutChecker) {
            bulkPayoutBtn = <span style={{ paddingLeft: "15px" }} >
                {selectedTab === "bulkpayout" &&
                    <i className="fa fa-caret-left translabelIcon" style={{ marginRight: "-0.75px" }} aria-hidden="true"></i>}
                <span
                    onClick={() => this.handelTabChange("bulkpayout", "")}
                    className=" translabeltag  labeltag"
                    style={{ cursor: "pointer", color: "#fff", padding: "14px", background: selectedTab === "bulkpayout" ? "#60c1d8" : "#1d6b7d" }}>
                    Bulk Payout
                </span>
            </span>
        }else {
            bulkPayoutBtn = null;
        }
        return (
            <div className={classes.root}>
                <Paper className={classes.card} >
                    <div style={{ display: "flex" }}>
                        <i onClick={(event) => this.handelRefreshData()} style={{ padding: "18px", fontSize: "18px", color: "#50a1cf", cursor: "pointer" }} data-toggle="tooltip" data-html="true" title="Refresh" className="fa fa-refresh" aria-hidden="true"></i>
                        {/* ------------------------------ Tab Button------------------------------------------------ */}
                        <div style={{ paddingTop: "20px", textAlign: "center", display: "flex", width: "50%" }}>
                            <span>
                                {selectedTab === "all" &&
                                    <i className="fa fa-caret-left translabelIcon" style={{ marginRight: "-0.75px" }} aria-hidden="true"></i>}
                                <span
                                    onClick={() => this.handelTabChange("all", "")}
                                    className=" translabeltag  labeltag"
                                    style={{ cursor: "pointer", color: "#fff", padding: "14px", background: selectedTab === "all" ? "#60c1d8" : "#1d6b7d" }}>
                                    Ledger
                                </span>
                            </span>
                            <span style={{ paddingLeft: "15px" }} >
                                {selectedTab === "grouped" &&
                                    <i className="fa fa-caret-left translabelIcon" style={{ marginRight: "-0.75px" }} aria-hidden="true"></i>}
                                <span
                                    onClick={() => this.handelTabChange("grouped", "direct_payment")}
                                    className=" translabeltag  labeltag"
                                    style={{ cursor: "pointer", color: "#fff", padding: "14px", background: selectedTab === "grouped" ? "#60c1d8" : "#1d6b7d" }}>
                                    Actions
                                </span>
                            </span>
                            {bulkPayoutBtn}
                        </div>
                        {/* ------------------------------------------------------------------------------ */}
                        <DateRangeSelector
                            onDateChanged={this.onDateChaged.bind(this)}
                            onDefaultDateFromDateRangeShown={this.onDefaultDateFromDateRangeShown.bind(this)} />

                        <div style={{ padding: "15px 15px 15px 0px" }}>
                            <Badge
                                className={classes.margin} style={{ height: '25px' }}
                                badgeContent={filterDataArray.length + transactionTypeArray.length + Object.keys(additionalFilter).length} color="primary">
                                <Button
                                    component="span"
                                    style={{ padding: '5px 10px', fontSize: 12, color: '#b1b1b1', margin: '0px 5px' }}
                                    onClick={() => this.setState({ showPaymentFilterOption: true })}>
                                    Filter
                                </Button>
                            </Badge>
                        </div>
                    </div>
                    {/* ----------------------------- Grouped Transaction Filter button--------------------------------------------- */}
                    {selectedTab === "grouped" &&
                        <div style={{ padding: "15px", paddingBottom: "10px", textAlign: "center", display: "flex", width: "100%" }}>
                            {Object.keys(groupedTypeFilter).map((bkey, bindex) =>
                                <span key={bindex + "_" + bkey} style={{ marginRight: "5px" }}>
                                    {(groupedSubType === bkey) &&
                                        <i className="fa fa-caret-left translabelIcon" style={{ marginRight: "-0.75px", color: "#e72e89" }} aria-hidden="true"></i>}
                                    <span
                                        onClick={() => this.handelTabChange("grouped", bkey)}
                                        className=" translabeltag  labeltag"
                                        style={{ cursor: "pointer", color: "#fff", padding: "6px", background: groupedSubType === bkey ? "#e72e89" : "#1d6b7d" }}>
                                        {groupedTypeFilter[bkey]}
                                    </span>
                                </span>)}
                        </div>}
                    {/* ----------------------------- Grouped Transaction Filter button--------------------------------------------- */}
                    <div>

                    </div>

                    {!this.state.resetFilter && <FilterListComponent
                        buyersList={this.state.buyersList}
                        // brokersList={this.state.brokersList}
                        showLoader={this.state.showLoader}
                        suppliersList={this.state.suppliersList}
                        getPaymentDetailsData={this.handelGetData.bind(this)} />}




                    {this.state.showLoader && <Loader />}
                    {selectedTab !== "bulkpayout" ?
                        <PaymentDetailsTable
                            allTransactionsData={allTransactionsData}
                            paymentMetaInfo={paymentMetaInfo}
                            OnPaymentUpdated={() => this.handelGetData(this.state.filterOptions)}
                            sortKeys={this.state.sortKeys}
                            sortKeysOrder={this.state.sortKeysOrder}
                            onSortingChanged={(sortParams) => this.onTableSorted(sortParams)}
                            resetOffsetAndGetData={() => this.resetOffsetAndGetData()}
                            currentOffset={this.state.params["offset"]}
                            resetPageNumber={this.state.resetPageNumber}
                            showLoader={this.state.showLoader}
                            setPageNumber={() => this.setState({ resetPageNumber: false })}
                            totalDataCount={this.state.totalDataCount}
                            isTableDataLoading={this.state.isTableDataLoading}
                        />
                        :
                        <BulkPaymentPaymentDetailsTable
                            allTransactionsData={allTransactionsData}
                            paymentMetaInfo={paymentMetaInfo}
                            OnPaymentUpdated={() => this.handelGetData(this.state.filterOptions)}
                            sortKeys={this.state.sortKeys}
                            sortKeysOrder={this.state.sortKeysOrder}
                            onSortingChanged={(sortParams) => this.onTableSorted(sortParams)}
                            resetOffsetAndGetData={() => this.resetOffsetAndGetData()}
                            currentOffset={this.state.params["offset"]}
                            resetPageNumber={this.state.resetPageNumber}
                            showLoader={this.state.showLoader}
                            setPageNumber={() => this.setState({ resetPageNumber: false })}
                            totalDataCount={this.state.totalDataCount}
                            isTableDataLoading={this.state.isTableDataLoading}
                        />}
                    {showPaymentFilterOption &&
                        <PaymentFilterOptionModal
                            openModal={showPaymentFilterOption}
                            filterDataArr={filterDataArray}
                            transactionTypeArray={transactionTypeArray}
                            additionalFilter={additionalFilter}
                            onEditModalCancel={(event) => this.setState({ showPaymentFilterOption: false })}
                            onFilterAdded={(data) => this.setState({
                                filterDataArray: data["paymentType"],
                                transactionTypeArray: data["transactionType"],
                                additionalFilter: data["additionalFilter"],
                                showPaymentFilterOption: false
                            })} />}

                    {selectedTab !== "bulkpayout" && <div className="updateBtndef">
                        {getAccessAccordingToRole("addPayment") &&
                            <div
                                className="updateBtnFixed"
                                style={{ display: 'flex' }}
                                onClick={(event) => this.setState({ showAddTransactionModal: true })}>
                                <i className="fa fa-plus-circle add-icon" aria-hidden="true"></i>
                                <p>Add Payment</p></div>}
                    </div>}

                    {showAddTransactionModal &&
                        <AddTransactionModal
                            open={showAddTransactionModal}
                            onTransactionAdded={(event) => this.onTransactionDataAdded(event)}
                            onEditModalCancel={(event) => this.setState({ showAddTransactionModal: false })}
                        />}

                </Paper>
            </div>
        );
    }
}
PaymentDetailsContainer.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(PaymentDetailsContainer);