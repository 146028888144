/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import AsyncSelect from 'react-select/lib/Async';
import Storage from '@aws-amplify/storage';
import Loader from '../../common/Loader';
import Utils from '../../../app/common/utils';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
// import { Auth } from 'aws-amplify';
import Auth from '@aws-amplify/auth';
import SweetAlertPage from '../../../app/common/SweetAlertPage';
import commonService from '../../../app/commonService/commonService';
import { getBucketName } from '../../../config/appConfig';
import groupService from '../../../app/groupService/groupService';
import FormControl from '@material-ui/core/FormControl';


const styles = theme => ({
    heading: {
        fontSize: '21px',
        fontWeight: '500',
        marginTop: '0',
        marginBottom: '0',
        fontFamily: 'Montserrat, sans-serif',
    },
    dialogPaper: {
        minWidth: '700px',
        // maxWidth: '700px',
        minHeight: '250px',
        // maxHeight: '500px'
    },
    formAddBtn: {
        width: '90%',
        borderRadius: '10px',
        fontSize: '20px',
        textTransform: 'uppercase',
        backgroundColor: '#4d9fa0 ',
        color: '#fff',
        height: '45px',
        marginBottom: '15px',
        marginTop: "11px",
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    formControl: {
        margin: '0px 5px',
        padding: '0px 10px',
        minWidth: 120,
        color: '#373737 !important',
        borderRight: '1px solid #e6e7e8'
    },
    formRoot: {
        // display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        // marginLeft: '25%',
        border: '1px solid #ccc',
        boxShadow: '2px 2px 6px 0px  rgba(0,0,0,0.3)',
        borderRadius: '4px',
        marginBottom: '20px',
        marginTop: '8%',
        padding: '25px',
        textAlign: 'center'
    },
    input: {
        display: 'none',
    },

});
// const statusOption = ["pending", "settled", "partial_settled"];

const CustomOption = props => {
    const { data, innerRef, innerProps } = props;
    return data && data.is_primary ? (
        <div ref={innerRef} {...innerProps} className='custom_option'>
            <i className="fa fa-check" style={{ color: "#60c1d8" }} aria-hidden="true"></i> &nbsp; {data.label ? data.label : ""}
        </div>
    ) : (
            <div ref={innerRef} {...innerProps} className='custom_option' >
                {data.label ? data.label : ""}
            </div>

        );
};

class AttachUserToGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,

            errorFields: {},

            showLoader: false,
            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },
            showFormError: false,

            tagsOptions: [],


            // new variables
            groupid: [],
            payloadData: {
                "group_data": {
                    "credit_limit": "",
                    "group_name": "",
                    "primary_user_id": "",
                    "primary_user_mobile": ""
                },
                "user_data": []
            },
            defaultOption: [],
            dataForAttachDettach: this.props.dataForAttachDettach
        }
    }

    componentDidMount() {
        this.getOptions("groupid", "a", (data) => {
            this.setState({ defaultOption: data });
        });
    }

    UNSAFE_componentWillReceiveProps() {
        if (this.props !== this.state) {
            this.setState({ open: this.props.open, dataForAttachDettach: this.props.dataForAttachDettach });
        }
    }

    handleDialogCancel(event) {
        this.props.onAddModalCancel();
    }

    getOptions = async (type, inputValue, callback) => {
        try {
            if (!inputValue) {
                callback([]);
            }
            let data = {};
            data["searchVal"] = inputValue;
            let resp = await groupService.searchGroupList(data);
            if (resp.data.status === 1 && resp.data.result) {
                var respData = this.formatDataForDropDown(resp.data.result.data, "group_name", "id");
                callback(respData);
            } else {
                callback([]);
            }
        } catch (err) {
            console.error(err);
            callback([]);
        }
    }

    formatDataForDropDown(data, labelKey, valuekey) {
        var optionsData = [];
        if (data) {
            for (var i = 0; i < data.length; i++) {
                optionsData.push({
                    rawdata: data[i],
                    label: data[i][labelKey] + " \n  ( id : " + data[i][valuekey] + " )",
                    value: data[i][valuekey]
                });
            }
        }
        return optionsData;
    }

    getSearchAreaTextPrimaryId = (id, event) => {
        try {
            console.log(event)
            let payloadDataVal = this.state.payloadData;
            let errorFields = this.state.errorFields;
            this.setState({ [id]: event !== null ? event : "" }, () => {
                let sData = {};
                if (event !== null) {
                    sData = event["rawdata"];
                    payloadDataVal["group_data"]["primary_user_id"] = event["rawdata"]["id"];
                    payloadDataVal["group_data"]["primary_user_mobile"] = event["rawdata"]["mobile"];
                } else {
                    payloadDataVal["group_data"]["primary_user_id"] = "";
                    payloadDataVal["group_data"]["primary_user_mobile"] = "";
                }
                errorFields[id] = false;
                this.setState({ payloadData: payloadDataVal, errorFields: errorFields });
            });
        } catch (err) {
            console.log(err);
        }
    }


    getSearchAreaText = (id, event) => {
        try {
            console.log(event);
            let payloadDataVal = this.state.payloadData;
            let errorFields = this.state.errorFields;
            this.setState({ [id]: event !== null ? event : "" }, () => {
                if (event !== null) {
                    payloadDataVal["user_data"] = [];
                    for (let i = 0; i < event.length; i++) {
                        if (event !== null) {
                            payloadDataVal["user_data"].push(event[i]["value"]);
                        } else {
                            payloadDataVal["user_data"] = [];
                        }
                    }
                    console.log(payloadDataVal)
                    errorFields[id] = false;
                    this.setState({ payloadDataVal: payloadDataVal, errorFields: errorFields });
                }
            });
        } catch (err) {
            console.log(err);
        }
    }


    async handelConfirmBtnClicked() {
        let sweetAlrtData = this.state.sweetAlertData;
        this.setState({ showLoader: true });
        try {
            if (this.state.groupid && this.state.groupid["value"]) {
                let payload = {
                    "connect_group_id": this.state.groupid["value"],
                    "is_connected_to_group": true
                };
                let idOfUser = this.state.dataForAttachDettach["id"];
                // if( this.state.dataForAttachDettach && this.state.dataForAttachDettach[""]){

                // }
                let resp = await groupService.attacheDetacheUserApi(idOfUser, payload);
                console.log(resp);
                if (resp.data.status === 1) {
                    sweetAlrtData["type"] = "success";
                    sweetAlrtData["title"] = "Success";
                    sweetAlrtData["text"] = "User account successfully attached";
                    this.setState({
                        showSweetAlert: true,
                        sweetAlertData: sweetAlrtData
                    }, () => {
                        let respResult = resp.data.result;
                        let dataForAttachDettachVal = this.state.dataForAttachDettach;
                        for (let key in respResult[0]) {
                            dataForAttachDettachVal[key] = respResult[0][key]
                        }
                        // handeling for new user 
                        for (let gkey in this.state.groupid["rawdata"]) {
                            dataForAttachDettachVal[gkey] = this.state.groupid["rawdata"][gkey];
                        }
                        if(!dataForAttachDettachVal["connect_group_id"] ){
                            dataForAttachDettachVal["connect_group_id"] = this.state.groupid["rawdata"]["id"];
                        }
                        dataForAttachDettachVal["is_connected_to_group"] = true;
                        this.setState({ dataForAttachDettach: dataForAttachDettachVal });
                    });
                } else {
                    sweetAlrtData["type"] = "error";
                    sweetAlrtData["title"] = "Error";
                    sweetAlrtData["text"] = resp && resp.data && resp.data.message ? resp.data.message : "Oops an error occured";
                    this.setState({
                        showLoader: false,
                        showSweetAlert: true,
                        sweetAlertData: sweetAlrtData
                    });
                }
            } else {
                sweetAlrtData["type"] = "error";
                sweetAlrtData["title"] = "Error";
                sweetAlrtData["text"] = "Please select a group";
                this.setState({
                    showLoader: false,
                    showSweetAlert: true,
                    sweetAlertData: sweetAlrtData
                });
            }
        } catch (err) {
            console.log(err);
            sweetAlrtData["type"] = "error";
            sweetAlrtData["title"] = "Error";
            sweetAlrtData["text"] = err
            this.setState({
                showLoader: false,
                showSweetAlert: true,
                sweetAlertData: sweetAlrtData
            });
        }
    }

    handelSweetAlertClosed() {
        this.setState({ showSweetAlert: false }, () => {
            if (this.state.sweetAlertData["type"] === "success") {
                this.props.onDataAttachedSuccess(this.state.dataForAttachDettach);
            }
        });
    }

    render() {
        const { classes } = this.props;
        const { showLoader, groupid, errorFields, showSweetAlert, sweetAlertData } = this.state;

        return (<div>
            <Dialog style={{ zIndex: '1', display: showSweetAlert ? "none" : "unset" }}
                open={this.state.open}
                classes={{ paper: classes.dialogPaper }}
                disableBackdropClick={true}
                onClose={this.handleDialogCancel.bind(this)}
                aria-labelledby="form-dialog-title"                >
                {!showLoader ? <div>
                    <DialogTitle
                        style={{ background: '#05073a', textAlign: 'center', height: '50px' }}
                        id="form-dialog-title">
                        <p style={{ color: '#fff', marginTop: '-10px', fontFamily: 'Lato', fontSize: '18px' }}>
                            Add user to group</p>
                    </DialogTitle>
                    <DialogContent >

                        <div style={{ display: "flex", paddingTop: "10px" }}>

                            <div style={{ borderBottom: errorFields["userid"] ? "2px solid red" : "1px solid gray", width: "98%" }}>
                                <div style={{ padding: "15px 0px" }}>Select a group to attach to the user </div>
                                <AsyncSelect
                                    value={groupid}
                                    name={"groupid"}
                                    onChange={this.getSearchAreaText.bind(this, "groupid")}
                                    isSearchable={true}
                                    isClearable={true}
                                    placeholder={`Select group`}
                                    menuPortalTarget={document.body}
                                    defaultOptions={this.state.defaultOption}
                                    components={{ Option: CustomOption }}
                                    loadOptions={this.getOptions.bind(this, "groupid")}
                                />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button className={classes.formCancelBtn} onClick={this.handelConfirmBtnClicked.bind(this)} color="primary">Submit</Button>
                        <Button className={classes.formCancelBtn} onClick={this.handleDialogCancel.bind(this)} color="primary">Cancel</Button>
                    </DialogActions>
                </div> :
                    <Loader primaryText="Please wait.." />}
            </Dialog>

            {showSweetAlert &&
                <SweetAlertPage
                    show={true}
                    type={sweetAlertData.type}
                    title={sweetAlertData.title}
                    text={sweetAlertData.text}
                    sweetAlertClose={() => this.handelSweetAlertClosed()}
                />}
        </div>
        );
    }
}

AttachUserToGroup.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AttachUserToGroup);