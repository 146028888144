import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import Loader from '../../common/Loader';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import AddDsModal from '../common/AddDsModal';
import Utils from '../../../app/common/utils';
import commodityService from '../../../app/commodityService/commodityService';
import orderService from '../../../app/orderService/orderService';
import Fab from '@material-ui/core/Fab';
import BusinessInfoDialog from '../../common/BusinessInfoDialog';
import { getAccessAccordingToRole } from '../../../config/appConfig';
import SweetAlertPage from '../../../app/common/SweetAlertPage';
import CONSTANTS from '../../../utils/constants';
// Hira
import EditIcon from '@material-ui/icons/Edit';
// Hira

var moment = require('moment');

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      head: {
        color: '#2e3247',
        fontWeight: 600,
        fontSize: '13px !important',
        fontFamily: 'lato !important',
        textTransform: 'uppercase',
        lineHeight: "1em"

      },
      body: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: 500,
        fontSize: '13px !important',
        fontFamily: 'lato !important',
      },
      root: {
        padding: '10px'
      }
    },
    MuiTablePagination: {
      toolbar: {
        paddingRight: '250px'
      }
    },
  }
});

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    verticalAlign: "middle",
    marginRight: theme.spacing.unit,
    fontSize: 10,
  },
  tableCell: {
    paddingLeft: '4px',
    paddingRight: '1px',
    maxWidth: '200px',
    fontSize: '13px !important'
  },
  titleText: { width: '50%', textAlign: 'left', paddingLeft: '15px', paddingTop: '7px', fontFamily: 'lato !important', },
  defaultTemplate: { height: '30vh', paddingTop: '10vh', },
  defaultSpan: { display: 'grid', fontSize: '25px' },
  defaultIcon: { fontSize: '65px', color: "#384952" },
  editIcon: { fontSize: '20px', color: "#1e459c", paddingLeft: 3, cursor: 'pointer', marginRight: '2px', float: 'left' },
  infoIcon: { color: '#d46262', fontSize: '18px', cursor: 'pointer' },
  cellDiv: {
    maxWidth: '180px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  root: {
    width: '100%',
    minHeight: '80vh'
  },
  lightTooltip: {
    fontSize: '15px',
    maxWidth: 'none',
  },
  info: {
    fontSize: '14px',
    marginLeft: '5px',
    color: '#fd0671',
    cursor: 'pointer'
  },
  textEllpses: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "110px",
    lineHeight: "18px",
    display: "block"
  },
  textEllipseForAppid: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    direction: 'rtl',
    // textAlign: 'left',
    maxWidth: '75px',
    textOverflow: 'ellipsis'
  },
  button: {
    boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)"
  }
});


class DemandListTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      // tableHeadData Space added - Hira
      tableHeadData: ['buyer Name/ Business Name', 'Commodity name', 'Quantity', 'Variety', 'Min/Fixed Price \u20B9', 'Max price \u20B9', 'Contact info', 'Status', 'Actions'],
      tableHeadDataKeys: ['commodity_name', 'commodity_quantity_value', 'variety', 'min_price', 'max_price', 'contact_name', 'status', 'actions'],
      tableBodyData: this.props.tableData,
      totalDataCount: this.props.totalDataCount || 0,
      currentOffset: this.props.currentOffset || 0,
      rawTableBodyData: [],
      searchedText: '',
      editableData: {},
      showServerDialog: false,
      showOptionModal: false,
      anchorEl: null,
      showUserModal: false,
      userData: {},
      // userId: null,
      payload: null,
      showAddModal: false,
      infoData: null,
      open: false,

      rowsPerPage: 50,
      page: 0,

      showSupportingInvoiceModal: false,
      invoiceModalData: [],

      showAddDsModal: false,
      addDsData: null,

      showEditDataModal: false,
      commodityList: { options: [], optionN_E: {}, optionE_N: {} },
      showUploader: false,

      showPayoutModal: false,
      // showAddTransactionModal - Hira
      showAddTransactionModal: false,
      // showAddTransactionModal - Hira
      payoutData: undefined,

      showUserInfo: false,
      userInfoData: undefined,
      isLimitUpdate: false,
      userId: undefined,

      showSweetAlert: false,
      sweetAlertData: {
        'type': '',
        'title': '',
        'text': ''
      },

      sortKeys: this.props.sortKeys,
      sortKeysOrder: this.props.sortKeysOrder,
      sortingEnabled: ['id', 'unsettled_amount_pltf', 'createdtime', 'bijak_amt'],
      showPaymentsOfOrderModal: false,
      orderInfo: undefined,
      fromDemandList: false
    }
    this.getCommodityNames();
  }

  async getCommodityNames() {
    try {
      let resp = await commodityService.getCommodityTable();
      if (resp.data.status === 1 && resp.data.result) {
        this.setState({ commodityList: Utils.getCommodityNamesArrayKeysMap(resp.data.result.data) });
      } else {
        this.setState({ commodityList: { options: [], optionN_E: {}, optionE_N: {} } });
      }
    } catch (err) {
      // console.error(err)
      this.setState({ commodityList: { options: [], optionN_E: {}, optionE_N: {} } });
    }
  }

  getCommodityNamesArray(data) {
    try {
      var listData = [];
      if (data) {
        for (var i = 0; i < data.length; i++) {
          if (data[i]['name']) {
            listData.push(data[i]['name'])
          }
        }
      }
      return listData;
    } catch (err) {
      // console.log(err);
      return [];
    }
  }


  UNSAFE_componentWillReceiveProps(nextprops) {

    if (this.state.tableBodyData !== nextprops.tableData) {
      this.setState({ tableBodyData: nextprops.tableData });
    }
    if (this.state.totalDataCount !== nextprops.totalDataCount) {
      this.setState({ totalDataCount: nextprops.totalDataCount });
    }
    if (this.state.currentOffset !== nextprops.currentOffset) {
      this.setState({ currentOffset: nextprops.currentOffset });
    }
    if (this.state.isTableDataLoading !== nextprops.isTableDataLoading) {
      this.setState({ isTableDataLoading: nextprops.isTableDataLoading });
    }
    if (this.state.sortKeys !== nextprops.sortKeys) {
      this.setState({ sortKeys: nextprops.sortKeys });
    }
    if (this.state.sortKeysOrder !== nextprops.sortKeysOrder) {
      this.setState({ sortKeysOrder: nextprops.sortKeysOrder });
    }
    if (nextprops.resetPageNumber) {
      this.setState({ page: 0 }, () =>
        this.props.setPageNumber());
    }

  }


  getTableCellClass(classes, index) {
    return classes.tableCell;
  }

  getInfoSTring(obj) {
    return obj.source_location ? obj.source_location : "- , " + obj.target_location ? obj.target_location : "-";
  }

  getBackgroundColor(obj) {
    if (obj.role === "ca") {
      return "#f94141";
    } else if (obj.role === 'la') {
      return "#82af82";
    } else if (obj.role === 'broker') {
      return "#7070fd";
    } else {
      return "#e5e8ec";
    }
  }

  onInfoClick = (info, event) => {
    this.setState({
      infoData: info, showAddModal: true, open: true
    })
  }

  onShowSupportinInvoiceModal = (info, event) => {
    this.setState({
      invoiceModalData: info["supporting_images"], showSupportingInvoiceModal: true
    })
  }

  onModalCancel(event) {
    this.setState({ showAddDsModal: false, addDsData: null, open: false, edit: false, repost: false, expire: false })
  }

  changeLimitSucces(event) {
    if (event) {
      let obj = this.state.userInfoData;
      obj['bijak_credit_limit'] = event;
      this.setState({ userInfoData: obj, isLimitUpdate: true });
    } else {
      this.setState({ isLimitUpdate: true });
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    if (this.state.tableBodyData.length === (newPage * this.state.rowsPerPage)) {
      this.props.resetOffsetAndGetData();
    }
  };

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  formatDateAndTime = (dateval) => {
    var fdate = moment.utc(new Date(dateval)).format('DD-MMM-YYYY HH:mm A')
    return <div style={{ width: "95px", display: "inline-block" }}> {fdate.split(" ")[0] + " \n" + fdate.split(" ")[1] + " " + fdate.split(" ")[2]}</div>
    // return <div style={{ width: "95px", display: "inline-block" }}> {fdate.split(" ")[0]}</div>
  }

  handleClickOpen() {
    this.setState({ addDsData: {}, showAddDsModal: true, repost: false, edit: false, expire: false })
  }

  onDemandPostCreated() {
    this.setState({ addDsData: {}, showAddDsModal: false, repost: false, edit: false, expire: false }, function () {
      this.props.onDemandPostCreated();
    });
  }

  getEmptyOtherDetails = () => {
    return {
      [CONSTANTS.COMMON_CONSTANTS.EN]: {
        specifics: [],
        description: ''
      },
      [CONSTANTS.COMMON_CONSTANTS.HI]: {
        specifics: [],
        description: ''
      }
    }
  }

  //repost and edit option
  handleRepostOrEdit(data, action = 'edit') {
    const addDsData = { ...data };
    const { en_json_data, hi_json_data } = addDsData.extra_data;
    if (en_json_data && hi_json_data) {
      const other_details = {
        en: en_json_data,
        hi: hi_json_data
      };

      addDsData.other_details = other_details;
    }
    else {
      addDsData.other_details = this.getEmptyOtherDetails();
    }

    // add buyer details
    addDsData['buyerid'] = {
      is_primary: false,
      label: `${addDsData.user_data.first_name}, ${addDsData.user_data.business_name} (${addDsData.user_data.location_area}, ${addDsData.user_data.username})`,
      value: addDsData.user_data.username,
      id: addDsData.user_data.user_id
    }
    this.setState({ addDsData: addDsData, showAddDsModal: true, [action]: true });
  }

  onUserInfoModalCancel(event) {
    this.setState({ showUserInfo: false, isInfo: false });
  }

  handleUserInfoClose(event) {
    this.setState({ showUserInfo: false, isInfo: false });
  }

  onUserInfoClicked = (info, type, event) => {
    let id = "";
    if (type === "supplier_name") {
      id = info["supplier_mobile"];
    } else {
      id = info["buyer_mobile"];
    }
    this.setState({ userId: info['username'], showUserInfo: true, userInfoData: JSON.parse(JSON.stringify(info)), isInfo: true });
  }

  handelSweetAlertClosed() {
    this.setState({ showSweetAlert: false }, () => {
      if (this.state.sweetAlertData.type !== "error") {
        this.props.onOrderAdded();
      }
    });
  }

  renderEditAndForceExpire = (row) => {
    const { classes, showLoader } = this.props;
    return (
      <>
        <EditIcon
          variant="extended"
          size="small"
          style={{ width: "30px", background: "rgb(16, 138, 208)", padding: "2px 6px", borderRadius: "10px", height: "28px", cursor: "pointer", color: "#fff", marginRight: 10 }}
          variant="contained"
          color="default"
          className={classes.button}
          onClick={e => this.handleRepostOrEdit(row, 'edit')}>
        </EditIcon>
        <Fab
          variant="extended"
          size="small"
          style={{ background: "#cc5500", color: "#fff", padding: "4px 10px", fontSize: '12px', height: 'min-content', cursor: 'pointer', textTransform: "none" }}
          variant="contained"
          color="default"
          className={classes.button}
          onClick={e => this.handleRepostOrEdit(row, 'expire')}>
          Force expire
      </Fab>
      </>
    )
  }

  render() {
    const { classes, showLoader, unitsMap } = this.props;
    const { rowsPerPage, page, showAddDsModal, repost, edit, expire,
      totalDataCount, commodityList, isTableDataLoading, showSweetAlert,
      sweetAlertData, tableHeadDataKeys, sortKeys, sortingEnabled, addDsData } = this.state;
    const leftAlignedIndexs = [0, 1, 2, 3, 4, 5, 6];
    const centerAlignedIndexs = [7, 8];
    const minWidthIndexs = [7, 8];
    return (
      <MuiThemeProvider theme={theme}>
        {!showLoader && <Paper className={classes.root} >
          {this.state.tableBodyData ? <div> <div style={{ maxHeight: "65vh", overflowY: "scroll" }}>
            <Table className='table-body' stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow style={{ borderBottom: "2px solid #858792" }} >
                  {this.state.tableHeadData.map((option, i) => (
                    <TableCell
                      key={option}
                      onClick={() => this.addKeysToSortList(tableHeadDataKeys[i])}
                      className={this.getTableCellClass(classes, i)}
                      style={{
                        minWidth: (minWidthIndexs.indexOf(i) > -1 || i === (this.state.tableHeadData.length - 1)) ? (i !== 1 ? "100px" : "80px") : '120px',
                        textAlign: leftAlignedIndexs.indexOf(i) > -1 ? "left" : centerAlignedIndexs.indexOf(i) > -1 ? "center" : "",
                        cursor: sortingEnabled.indexOf(tableHeadDataKeys[i]) > -1 ? "pointer" : "unset"
                      }}>{option}
                      {sortingEnabled.indexOf(tableHeadDataKeys[i]) > -1 &&
                        <i className={Utils.getArrowIconType(sortKeys, tableHeadDataKeys[i])} aria-hidden="true" style={{ color: sortKeys.hasOwnProperty(tableHeadDataKeys[i]) ? '#e72e89' : 'unset' }}></i>}
                    </TableCell>
                  ))}
                  {/* <TableCell key="star" className={this.getTableCellClass(classes, 4)} style={{ minWidth: '50px', color: "goldenrod", textAlign: 'left' }}> Quantity </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {!isTableDataLoading && this.state.tableBodyData &&

                  (rowsPerPage > 0
                    ? this.state.tableBodyData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : this.state.tableBodyData
                  ).map((row, i) => {
                    return (

                      <TableRow key={'table_' + i} id={"dsfeed_demand_" + row.id} style={{ background: i % 2 !== 0 ? "#e8e8e8" : "#fff" }}>

                        <TableCell component="td" scope="row" className={this.getTableCellClass(classes, 0)}>
                          <div className=" name-span" style={{ display: "grid", textAlign: "left", textTransform: "capitalize", cursor: "pointer" }}
                            onClick={this.onUserInfoClicked.bind(this, row.user_data, "buyer_name")}>
                            <span className="text-ellpses">{row.user_data.first_name ? row.user_data.first_name : ""} </span>
                            <span className="text-ellpses" style={{ fontSize: "12px" }}>{row.user_data.business_name ? row.user_data.business_name : " "}</span>
                          </div>
                        </TableCell>


                        <TableCell component="td" scope="row" className={this.getTableCellClass(classes, 0)}>
                          <div style={{ display: "grid", textAlign: "left", textTransform: "capitalize", }}>
                            <span className="text-ellpses">{row ? row.commodity_name : ""} </span>
                            {/* <span className="text-ellpses" style={{ fontSize: "12px" }}>{row.supplier_businessname ? row.supplier_businessname : " "}</span> */}
                          </div>
                        </TableCell>

                        <TableCell component="td" scope="row" className={this.getTableCellClass(classes, 3)} style={{ textAlign: "left" }}>
                          <div className="value-unit">
                            {row ? <>{row.commodity_quantity_value}<span> {row.quantity_unit}</span></> : ""}
                          </div>
                        </TableCell>

                        <TableCell component="td" scope="row" className={this.getTableCellClass(classes, 4)}>
                          <div style={{ textTransform: "capitalize", }}>
                            <span className="text-ellpses">{row ? row.variety : ""} </span>
                            {/* <span className="text-ellpses" style={{ fontSize: "12px" }}>{row.supplier_businessname ? row.supplier_businessname : " "}</span> */}
                          </div>
                        </TableCell>

                        <TableCell component="td" scope="row" className={this.getTableCellClass(classes, 6)}>
                          <div className="value-unit">
                            {row ? <>{row.min_price}<span>/{unitsMap?.get(row.rate_range_id)}</span></> : ""}
                          </div>
                        </TableCell>

                        <TableCell component="td" scope="row" className={this.getTableCellClass(classes, 5)}>
                          <div className="value-unit">
                            {row.max_price ? <>{row.max_price}<span>/{unitsMap?.get(row.rate_range_id)}</span></> : <span className="n-a">N/A</span>}
                          </div>
                        </TableCell>

                        <TableCell component="td" scope="row" className={this.getTableCellClass(classes, 0)}>
                          <div style={{ textTransform: "capitalize", }}>
                            <span className="text-ellpses">{row ? row.contact_name : ""} </span><br />
                            <span className="text-ellpses">{row ? row.contact_phonenumber : ""} </span>
                            {/* <span className="text-ellpses" style={{ fontSize: "12px" }}>{row.supplier_businessname ? row.supplier_businessname : " "}</span> */}
                          </div>
                        </TableCell>

                        <TableCell component="td" scope="row" style={{ textAlign: "center" }}>
                          {row.is_expired ?
                            <span style={{
                              color: "#fff",
                              background: "red",
                              padding: "4px 12px",
                              width: 'fit-content',
                              borderRadius: "13px"
                            }}>Expired</span>
                            :
                            <span style={{
                              color: "#fff",
                              background: "green",
                              padding: "4px 12px",
                              width: 'fit-content',
                              borderRadius: "13px"
                            }}>Active</span>
                          }
                        </TableCell>
                        <TableCell component="td" scope="row" style={{ minWidth: 200 }}>
                          <div style={{ display: "flex", alignItems: "center", textAlign: "center", justifyContent: "center" }}>
                            {row.is_expired &&
                              <Fab
                                variant="extended"
                                size="small"
                                style={{ background: "#108ad0", color: "#fff", padding: "4px 10px", fontSize: '12px', height: 'min-content', cursor: 'pointer', textTransform: "none" }}
                                variant="contained"
                                color="default"
                                className={classes.button}
                                onClick={e => this.handleRepostOrEdit(row, 'repost')}>
                                Repost
                            </Fab>
                            }
                            {!row.is_expired &&
                              this.renderEditAndForceExpire(row)
                            }
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {isTableDataLoading && <div><Loader /> </div>}
            {this.state.tableBodyData.length > 0 ? "" : <div className={classes.defaultTemplate}>
              {this.state.searchedText.length > 0 ? <span className={classes.defaultSpan}>
                <i className={classes.defaultIcon + " fa fa-frown-o"} aria-hidden="true"></i>
                {"Your search does not match any list"} </span> : <span className={classes.defaultSpan}>
                  <i className={classes.defaultIcon + " fa fa-frown-o"} aria-hidden="true"></i>{"No Data Available"}</span>}
            </div>}

          </div>
            {this.state.tableBodyData.length > 0 && <Table>
              <TableFooter style={{ borderTop: "2px solid #858792", background: "#fafafa" }}>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    // rowsPerPageOptions={[1,2,3]}
                    colSpan={6}
                    count={totalDataCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onChangePage={this.handleChangePage.bind(this)}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                  />
                </TableRow>
              </TableFooter>
            </Table>}
          </div> :
            <div style={{ paddingTop: "14%" }} >
              <span className={classes.defaultSpan}>
                <i className={classes.defaultIcon + " fa fa-search-plus"} aria-hidden="true"></i>{"Search from above to check specific Orders"}</span>
            </div>
          }

          {/* <div className="fixedLeftBtnContainer">
                        <div className="fixedLeftBtn" style={{ display: 'flex', left: "16%", background: "#4da443" }}
                            onClick={this.handleUploaderClick.bind(this)}
                        >
                            <i className="fa fa-cloud-upload add-icon" aria-hidden="true"></i>
                            <p style={{
                                fontSize: "14px",
                                fontFamily: "lato",
                                fontWeight: 600
                            }}>Upload file</p></div>
                    </div> */}
          {<div className="updateBtndef">
            <div className="updateBtnFixed" style={{ display: 'flex' }} onClick={this.handleClickOpen.bind(this)}>
              <i className="fa fa-plus-circle add-icon" aria-hidden="true"></i>
              <p style={{
                fontSize: "14px",
                fontFamily: "lato",
                fontWeight: 600
              }}>Add Demand</p></div>
          </div>}

          {/* {getAccessAccordingToRole("allowDownload") &&  <div className="updateBtndef" style={{ right: "160px" }} data-toggle="tooltip" data-html="true" title="Download">
                        <div className="updateBtnFixed" style={{ display: 'flex', background: "#e72e89", borderRadius: "6px" }} onClick={this.handelDownloadClicked.bind(this)}>
                            <i className="fa fa-cloud-download add-icon" style={{ marginRight: 0, color: "white" }} aria-hidden="true"></i>
                        </div>
                    </div>} */}
          {showAddDsModal &&
            <AddDsModal
              open={showAddDsModal}
              commodityList={commodityList}
              addDsData={addDsData}
              repost={repost}
              edit={edit}
              expire={expire}
              onDemandPostCreated={(event) => this.onDemandPostCreated(event)}
              onAddModalCancel={this.onModalCancel.bind(this)}
            />}
          {this.state.showUserInfo ?
            <BusinessInfoDialog
              openModal={this.state.showUserInfo}
              onEditModalClosed={this.handleUserInfoClose.bind(this)}
              data={this.state.userInfoData}
              isInfo={true}
              userId={this.state.userId}
              onLimitUpdate={this.changeLimitSucces.bind(this)}
              onEditModalCancel={this.onUserInfoModalCancel.bind(this)} /> : ""}

          {showSweetAlert &&
            <SweetAlertPage
              show={true}
              type={sweetAlertData.type}
              title={sweetAlertData.title}
              text={sweetAlertData.text}
              sweetAlertClose={() => this.handelSweetAlertClosed()}
            />}

        </Paper>}
      </MuiThemeProvider>
    );
  }
}

DemandListTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DemandListTable);