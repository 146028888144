import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import groupService from '../../../app/groupService/groupService';
import SweetAlertPage from '../../../app/common/SweetAlertPage';
import EditGroupModal from './EditGroupModal';
import AddUserInGroupModal from './AddUserInGroupModal';
import Tooltip from '@material-ui/core/Tooltip';
import ConfirmDialogWithLoader from '../common/ConfirmDialogWithLoader';


const styles = theme => ({
    root: {
        // height: "20%",
        width: '100%',
        overflow: 'auto',
        fontFamily: 'Lato !important',
        padding: "1%",
        maxWidth: '1200px',
        // padding: "15px 20%",
        // maxHeight: '40vh',
    },
    card: {
        maxWidth: '100%',
        minHeight: '20%',
        border: "1px solid gray",
        padding: "1%",

        // marginTop: '15px',
        // backgroundColor: "#ececec",
        // height: '25vh',
    },
    gpinfoTitle: {
        width: "35%",
        paddingLeft: "6%",
        textAlign: "left",
        fontFamily: 'Lato !important',
    },
    gpinfoTitle2: {
        width: "40%",
        paddingLeft: "2%",
        textAlign: "left",
        fontFamily: 'Lato !important',
    },
    defaultSpan: { display: 'grid', fontSize: '25px' },
    lightTooltip: {
        fontSize: '15px',
        maxWidth: 'none',
        fontFamily: 'Lato !important',
    },
});

class GroupMetaInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },
            dialogText: "",
            dialogTitle: "",
            showConfirmDialog: false,
            selectedId: "",
            showEditGroupModal: false,
            selectedData: "",
        }
    }



    onDeleteClicked(row) {
        this.setState({
            dialogTitle: "Are you sure to delete this group ?",
            showConfirmLoader: false,
            dialogText:
                <div style={{ display: "block", paddingTop: "17px" }}>
                    <div style={{ fontSize: "16px" }}>
                        {`"${row['group_name']}" group will be deleted. Please confirm. `}
                    </div>
                </div>,
            showConfirmDialog: true,
            selectedId: row["id"]
        });
    }

    handelCancelUpdate(event) {
        this.setState({ showConfirmDialog: false, showConfirmLoader: false, selectedId: "" });
    }

    async handelConfirmBtnClicked() {
        let sweetAlertDataVal = this.state.sweetAlertData;
        this.setState({ showConfirmLoader: true })
        try {
            let id = this.state.selectedId;
            let payload = { active: false };
            let resp = await groupService.updateGroupMetaInfo(id, payload);
            console.log(resp);

            if (resp.data.status === 1) {
                sweetAlertDataVal["text"] = "Group deleted successfully";
                sweetAlertDataVal["title"] = "Success";
                sweetAlertDataVal["type"] = "success";
            } else {
                sweetAlertDataVal["text"] = resp.data && resp.data.result && resp.data.result.err ? resp.data.result.err : "Oops an error occured";
                sweetAlertDataVal["title"] = "Error";
                sweetAlertDataVal["type"] = "error";
            }
            this.setState({
                showConfirmLoader: false,
                sweetAlertData: sweetAlertDataVal,
                showSweetAlert: true
            })
        } catch (err) {
            console.log(err);
        }
    }

    handelSweetAlertClosed() {
        this.setState({ showSweetAlert: false, showConfirmDialog: false }, () => {
            if (this.state.sweetAlertData.type !== "error") {
                this.props.onGroupDeleted();
            }
        });
    }

    onEditGroupModal(row) {
        this.setState({
            showEditGroupModal: true,
            selectedData: row
        });
    }

    onAddModalClicked(row) {
        this.setState({
            showAddUserModal: true,
            selectedData: row
        });
    }

    render() {
        const { classes, selectedUser } = this.props;
        const { showSweetAlert, sweetAlertData } = this.state;
        console.log(selectedUser)
        return (
            <div className={classes.root}>
                <Paper className={classes.card} >
                    <div>
                        <div style={{ display: "flex" }}> <div style={{ width: "91%", fontSize: "17px", fontFamily: "lato", textDecoration: "underline" }}> <strong> Group Details </strong></div>
                            <div style={{ float: "right" }}>
                                <Tooltip title={"Add user to group"} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                    <i className="fa fa-plus-circle" style={{ color: "rgb(98, 204, 66)", cursor: "pointer", fontSize: "18px" }} onClick={() => this.onAddModalClicked(selectedUser)}></i>
                                </Tooltip> &nbsp;&nbsp;&nbsp;
                                <Tooltip title={"Edit group details"} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                    <i className="fa fa-edit" style={{ color: "blue", cursor: "pointer", fontSize: "18px" }} onClick={() => this.onEditGroupModal(selectedUser)}></i>
                                </Tooltip> &nbsp;&nbsp;&nbsp;
                                <Tooltip title={"Delete group"} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                    <i className="fa fa-trash" style={{ color: "red", cursor: "pointer", fontSize: "18px" }} onClick={() => this.onDeleteClicked(selectedUser)}></i>
                                </Tooltip>
                            </div>
                        </div>

                        < div style={{ display: "flex", paddingTop: "10px" }}>
                            <div style={{ width: "50%" }}>
                                <div style={{ display: "flex" }} >
                                    <span className={classes.gpinfoTitle} > Group id </span> : &nbsp; &nbsp;<span> <strong>{selectedUser['id'] ? selectedUser['id'] : ""}</strong> </span>
                                </div>
                                <div style={{ display: "flex" }} >
                                    <span className={classes.gpinfoTitle} > Group name </span> : &nbsp; &nbsp;<span style={{ width: "55%" }}> <div
                                        style={{ textOverflow: "ellipsis", overflow: "hidden", fontWeight: "600", fontFamily: "lato", textAlign: "left", whiteSpace: "nowrap" }}
                                    >{selectedUser['group_name'] ? selectedUser['group_name'] :""}</div> </span>
                                </div>
                                <div style={{ display: "flex" }} >
                                    <span className={classes.gpinfoTitle}> Group credit limit </span> : &nbsp; &nbsp; <span><strong>{selectedUser['group_credit_limit']?selectedUser['group_credit_limit']:""}</strong></span>
                                </div>
                                {/* <div style={{ display: "flex" }} >
                                    <span style={{ width: " 30%" }}> </span> &nbsp; &nbsp; <span> <strong></strong></span>
                                </div> */}
                            </div>
                            <div style={{ width: "50%" }}>
                                {/* <div style={{ display: "flex" }} >
                                    <span style={{ width: " 30%" }}> Primary user Id </span> : &nbsp; &nbsp; <span> <strong>{selectedUser['primary_user_id']}</strong> </span>
                                </div> */}
                                <div style={{ display: "flex" }} >
                                    <span className={classes.gpinfoTitle2}> Primary user name</span> : &nbsp; &nbsp; <span style={{ width: "55%" }}> <div
                                        style={{ textOverflow: "ellipsis", overflow: "hidden", fontWeight: "600", fontFamily: "lato", textAlign: "left", whiteSpace: "nowrap" }}
                                    >{selectedUser['group_fullname']?selectedUser['group_fullname']:""}</div> </span>
                                </div>
                                
                                <div style={{ display: "flex" }} >
                                    <span className={classes.gpinfoTitle2}> Primary user mobile</span> : &nbsp; &nbsp; <span><strong>{selectedUser['group_user_mobile']?selectedUser['group_user_mobile']:""} </strong></span>
                                </div>
                                
                                <div style={{ display: "flex" }} >
                                    <span className={classes.gpinfoTitle2}> Primary user business name</span> : &nbsp; &nbsp; <span style={{ width: "55%" }}> <div
                                        style={{ textOverflow: "ellipsis", overflow: "hidden", fontWeight: "600", fontFamily: "lato", textAlign: "left", whiteSpace: "nowrap" }}
                                    >{selectedUser['group_business_name']? selectedUser['group_business_name'] : ""}</div> </span>
                                </div>
                            </div>
                        </div>
                    </div>


                    {this.state.showConfirmDialog ?
                        <ConfirmDialogWithLoader
                            dialogText={this.state.dialogText}
                            showSweetAlertShown={showSweetAlert}
                            dialogTitle={this.state.dialogTitle}
                            showConfirmLoader={this.state.showConfirmLoader}
                            show={this.state.showConfirmDialog}
                            onConfirmed={() => this.handelConfirmBtnClicked()}
                            onCanceled={() => this.handelCancelUpdate()} /> : ""}

                    {showSweetAlert &&
                        <SweetAlertPage
                            show={true}
                            type={sweetAlertData.type}
                            title={sweetAlertData.title}
                            text={sweetAlertData.text}
                            sweetAlertClose={() => this.handelSweetAlertClosed()}
                        />}

                    {this.state.showEditGroupModal &&
                        <EditGroupModal
                            open={this.state.showEditGroupModal}
                            selectedData={this.state.selectedData}
                            onGroupEdited={(event) => {
                                this.setState({ showEditGroupModal: false, selectedData: undefined }, () => {
                                    this.props.OnGroupDetailsEdited();
                                })
                            }}
                            onAddModalCancel={(event) => this.setState({ showEditGroupModal: false, selectedData: undefined })}
                        />}

                    {this.state.showAddUserModal &&
                        <AddUserInGroupModal
                            open={this.state.showAddUserModal}
                            selectedData={this.state.selectedData}
                            onUserAdded={(event) => {
                                this.setState({ showAddUserModal: false }, () => {
                                    this.props.onUserAttached();
                                })
                            }}
                            onAddModalCancel={(event) => this.setState({ showAddUserModal: false })}
                        />}
                </Paper>
            </div>
        );
    }
}
GroupMetaInfo.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(GroupMetaInfo);