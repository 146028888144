/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Loader from '../../common/Loader';
import SweetAlertPage from '../../../app/common/SweetAlertPage';
import relationshipManagerService from '../../../app/relationshipManagerService/relationshipManagerService';


const styles = theme => ({
    heading: {
        fontSize: '21px',
        fontWeight: '500',
        marginTop: '0',
        marginBottom: '0',
        fontFamily: 'Montserrat, sans-serif',
    },
    dialogPaper: {
        minWidth: '450px',
        // maxWidth: '700px',
        minHeight: '150px',
        // maxHeight: '500px'
    }

});

class EnableRelationManagerModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            showLoader: false,
            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            }
        }
    }


    UNSAFE_componentWillReceiveProps() {
        if (this.props !== this.state) {
            this.setState({ open: this.props.open });
        }
    }

    handleDialogCancel(event) {
        this.props.onEnableModalCancel();
    }



    async handelEnableClicked() {
        try {
            this.setState({ showLoader: true }, () => {
                this.enableRmFromList();
            });
        } catch (err) {
            console.log(err);
        }
    }


    async enableRmFromList() {
        let sweetAlertDataVal = this.state.sweetAlertData;
        try {
            let payload = {
                "active": true
            };
            let idRmUser = this.props.editUserData["id"];
            console.log(idRmUser, payload);
            let resp = await relationshipManagerService.updateRMUser(idRmUser, payload);

            if (resp.data.status === 1 && resp.data.result) {
                sweetAlertDataVal["type"] = "success",
                    sweetAlertDataVal["title"] = "Success",
                    sweetAlertDataVal["text"] = "Relationship manager enabled successfully";
            } else {
                sweetAlertDataVal["type"] = "error",
                    sweetAlertDataVal["title"] = "Error",
                    sweetAlertDataVal["text"] = resp && resp.data && resp.data.message ? resp.data.message : "Oops an error occured while enabling the relationship manager";

            }
            this.setState({ sweetAlertData: sweetAlertDataVal, showSweetAlert: true, showLoader: false })

        } catch (err) {
            console.log(err);
            sweetAlertDataVal["type"] = "error",
                sweetAlertDataVal["title"] = "Error",
                sweetAlertDataVal["text"] = "Oops an error occured while enabling the relationship manager"
            this.setState({ sweetAlertData: sweetAlertDataVal, showSweetAlert: true, showLoader: false })
        }
    }

    handelSweetAlertClosed() {
        this.setState({ showSweetAlert: false }, () => {
            if (this.state.sweetAlertData["type"] === "success") {
                this.props.onRmEnabledSuccess();
            }
        });
    }



    render() {
        const { classes, editUserData } = this.props;
        const { showLoader, showSweetAlert, sweetAlertData } = this.state;

        return (<div>
            <Dialog style={{ zIndex: '1' }}
                open={this.state.open}
                classes={{ paper: classes.dialogPaper }}
                disableBackdropClick={true}
                onClose={this.handleDialogCancel.bind(this)}
                aria-labelledby="form-dialog-title"                >
                {!showLoader ?
                    <div>
                        <DialogTitle
                            style={{ background: '#05073a', textAlign: 'center', height: 'auto', maxHeight: "100px" }}
                            id="form-dialog-title">
                            <p style={{ color: '#fff', marginTop: '-10px', fontFamily: 'Lato', fontSize: '18px' }}>
                                Enable relationship manager </p>
                        </DialogTitle>
                        <DialogContent >
                            <div style={{ fontFamily: 'Lato', fontSize: '16px', paddingTop: "12px" }}>
                                Are you sure to enable this Relationship manager?
                            <div style={{ display: "flex", padding: "10px" }}>
                                    <i className="fa fa-hand-o-right" style={{ margin: "auto 0px" }} aria-hidden="true"></i> &nbsp;
                                    {editUserData["name"] ? <div className="text-ellpses" style={{ whiteSpace: "nowrap" }}>{editUserData["name"]}</div> : ""}
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button className={classes.formCancelBtn} onClick={this.handelEnableClicked.bind(this)} color="primary">Enable</Button>
                            <Button className={classes.formCancelBtn} onClick={this.handleDialogCancel.bind(this)} color="primary">Cancel</Button>
                        </DialogActions>
                    </div> :
                    <Loader primaryText="Please wait.." />}
            </Dialog>

            {showSweetAlert &&
                <SweetAlertPage
                    show={true}
                    type={sweetAlertData.type}
                    title={sweetAlertData.title}
                    text={sweetAlertData.text}
                    sweetAlertClose={() => this.handelSweetAlertClosed()}
                />}
        </div>
        );
    }
}

EnableRelationManagerModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnableRelationManagerModal);