import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Loader from '../../common/Loader';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Fab from '@material-ui/core/Fab';


var moment = require('moment');

const theme = createMuiTheme({
    overrides: {
        MuiTableCell: {
            head: {
                color: '#2e3247',
                fontWeight: 600,
                fontSize: '13px !important',
                fontFamily: 'lato !important',
                textTransform: 'uppercase',
                lineHeight: "1em"

            },
            body: {
                color: 'rgba(0, 0, 0, 0.87)',
                fontWeight: 500,
                fontSize: '14px !important',
                fontFamily: 'lato !important',
            }
        },
        MuiFormLabel: {
            root: {
                color: "White"
            }
        },
        MuiInput: {
            underline: {
                borderBottom: "1px solid rgb(255, 255, 255)"
            }
        },
        MuiIconButton: {
            root: {
                color: "#000"
            }
        },
        MuiInputBase: {
            input: {
                color: "#000"
            }
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                color: "#000"
            }
        }
    }
});

const styles = theme => ({
    dialogPaper: {
        minWidth: '84%',
        maxWidth: '85%',
        // minHeight: '700px',
        // maxHeight: '500px'
    },
    appBar: {
        position: 'relative',
        background: "#05073a",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        fontSize: "20px !important",
        fontFamily: "lato !important",
        fontWeight: 500
    },
    closeBtn: {
        fontSize: "15px !important",
        fontFamily: "lato !important",
        fontWeight: 500
    },
    tableCell: {
        padding: '12px',
        // paddingRight: '4px',
        textAlign: 'center',
        maxWidth: '200px'
    },
    formControl: {
        color: "#fff"
    },
    dataHeader: {
        width: "20%"
    },
    lightTooltip: {
        fontSize: '15px',
        fontWeight: 500,
        maxWidth: 'none',
    },
});


class AdminUserLogsTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableBodyData: this.props.tableBodyData || [],
            tableHeadData: ["Name", "Mobile", ""],
            tableHeadDataKeys: ["name", "mobile", "subid"],
            showLoader: false,

            rowsPerPage: 50,
            page: 0,

            params: {
                limit: 1000, // total amount of data 
                offset: 0 // data from which data needs to be get
            },
            totalDataCount: 0,
            isTableDataLoading: false,

            sortKeys: {},
            sortParams: {},
            sortKeysOrder: [],
            sortingEnabled: ["id", "createdtime", "amount"],
            orderData: this.props.orderData,

            showEventInfo: false,
            eventDialogData: undefined

        }
    }

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.tableBodyData && this.state.tableBodyData.length !== nextprops.tableBodyData.length) {
            this.setState({ tableBodyData: nextprops.tableBodyData });
        }
        if (this.state.totalDataCount !== nextprops.totalDataCount) {
            this.setState({ totalDataCount: nextprops.totalDataCount });
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
        if (this.state.tableBodyData.length === (newPage * this.state.rowsPerPage)) {
            this.props.resetOffsetAndGetData();
        }
    };

    handleChangeRowsPerPage = event => {
        this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
    };

    formatDateAndTime = (dateval) => {
        var fdate = moment.utc(new Date(dateval)).format('DD-MMM-YYYY HH:mm A')
        return <div style={{ width: "95px", display: "inline-block" }}> {fdate.split(" ")[0] + " \n" + fdate.split(" ")[1] + " " + fdate.split(" ")[2]}</div>
        // return <div style={{ width: "95px", display: "inline-block" }}> {fdate.split(" ")[0]}</div>
    }

    // -------------------------------------------------- 
    resetOffsetAndGetData() {
        let paramsval = this.state.params;
        paramsval["offset"] = paramsval["offset"] + 1000;
        this.setState({ params: paramsval, isTableDataLoading: true }, function () {
            this.getPaymentHistory(this.props.orderData["id"], this.state.params);
        })
    }

    handelRefreshModal() {
        let param = {};
        param["offset"] = 0;
        param["limit"] = 1000;
        this.setState({
            groupedTransactionData: {},
            tableBodyData: [],
            page: 0,
            totalDataCount: 0,
            sortKeys: {},
            sortParams: {},
            sortKeysOrder: [],
            showLoader: true
        }, () =>
            this.getPaymentHistory(this.props.orderData["id"], param)
        )
    }

    getArrowType = (columnName) => {
        let iconClass = 'fa fa-arrow-';
        if (this.state.sortKeys.hasOwnProperty(columnName)) {
            iconClass += this.state.sortKeys[columnName] === 'asc' ? 'down' : 'up';
        }else{
            iconClass = iconClass + 'down';
        }
        return iconClass;
    };

    addKeysToSortList = (keys) => {
        if (this.state.sortingEnabled.indexOf(keys) > -1) {
            let srtkys = Object.assign({}, this.state.sortKeys);
            let srtKeyOrders = this.state.sortKeysOrder;
            if (srtKeyOrders.indexOf(keys) === -1) {
                srtKeyOrders.splice(0, 0, keys);
            } else {
                srtKeyOrders.splice(srtKeyOrders.indexOf(keys), 1);
                srtKeyOrders.splice(0, 0, keys);
            }
            if (srtkys.hasOwnProperty(keys)) {
                if (srtkys[keys] === "asc") {
                    srtkys[keys] = "desc";
                } else {
                    srtkys[keys] = "asc";
                }
            } else {
                srtkys[keys] = "asc";
            }
            this.setState({ sortKeys: srtkys, sortKeysOrder: srtKeyOrders });
            let params = {
                sortkey: [],
                sortorder: []
            }
            for (let i = 0; i < srtKeyOrders.length; i++) {
                params["sortkey"].push(srtKeyOrders[i]);
                params["sortorder"].push(srtkys[srtKeyOrders[i]]);
            }
            params["sortkey"] = params["sortkey"].toString();
            params["sortorder"] = params["sortorder"].toString();
            this.setState({
                sortParams: params,
                tableBodyData: [],
                groupedTransactionData: [],
                page: 0,
                showLoader: true
            }, () => {
                this.getPaymentHistory(this.props.orderData["id"], this.state.params);
            })
        }
    }

    openInfoDialog(row) {
        this.setState({
            showEventInfo: true,
            eventDialogData: row
        });
    }

    getIconTypeForId(row) {
        try {
            let type = row.type.toLowerCase() || "";
            if (type === "user") {
                return <i className="fa fa-user" aria-hidden="true" style={{ color: this.getIdColorAccToType(row) }}></i>
            } else if (type === "order") {
                return <i className="fa fa-shopping-cart" aria-hidden="true" style={{ color: this.getIdColorAccToType(row) }}></i>
            } else if (type === "payment") {
                return <i className="fa fa-credit-card" aria-hidden="true" style={{ color: this.getIdColorAccToType(row) }}></i>;
            }
        } catch (err) {
            return "";
        }
    }

    getIdColorAccToType(row) {
        try {
            let type = row.type.toLowerCase() || "";
            if (type === "user") {
                return "rgb(71, 125, 227)";
            } else if (type === "order") {
                return "rgba(78, 36, 0, 1)"; //"#61cb42"; 
            } else if (type === "payment") {
                return "#02a735";
            }
        } catch (err) {
            return "";
        }
    }

    getFormatedPermissionText(prms) {
        if (prms) {
            if (prms.indexOf(",") > -1) {
                let prmsArry = prms.split(",");
                prmsArry = prmsArry.join(", ");
                return prmsArry;
            } else {
                return prms;
            }
        } else {
            return null
        }
    }

    render() {
        const { classes, isTableDataLoading, showLoader } = this.props;
        const { tableBodyData, rowsPerPage, page, totalDataCount } = this.state;
        const leftAlignedIndexs = [];
        const rightAlignedIndexs = [];
        return (
            <div>
                <MuiThemeProvider theme={theme}>
                    {!showLoader && tableBodyData && <div>
                        <div style={{ marginTop: 8, maxHeight: "65vh", overflowY: "scroll" }}>
                            {tableBodyData && tableBodyData.length > 0 &&
                                <Table className='table-body' stickyHeader aria-label="sticky table">
                                    <TableHead style={{}}>
                                        <TableRow style={{ borderBottom: "2px solid #858792" }} >
                                            {this.state.tableHeadData.map((option, i) => (
                                                <TableCell
                                                    key={option}
                                                    // onClick={() => this.addKeysToSortList(tableHeadDataKeys[i])}
                                                    // className={classes.tableCell}
                                                    style={{
                                                        minWidth: '120px', 
                                                        // paddingLeft: i === 0 ? '22px' : '',
                                                        textTransform: "uppercase",
                                                        // cursor: sortingEnabled.indexOf(tableHeadDataKeys[i]) > -1 ? "pointer" : "unset",
                                                        paddingLeft: leftAlignedIndexs.indexOf(i) > -1 ? "20px" : "",
                                                        paddingRight: rightAlignedIndexs.indexOf(i) > -1 ? "20px" : "",
                                                        textAlign: leftAlignedIndexs.indexOf(i) > -1 ? "left" : rightAlignedIndexs.indexOf(i) > -1 ? "right" : "center"
                                                    }}>{option}
                                                    {/* {sortKeys.hasOwnProperty(tableHeadDataKeys[i]) &&
                                                    <i className={this.getArrowType(tableHeadDataKeys[i])} aria-hidden="true"></i>} */}
                                                </TableCell>
                                            ))}
                                            {/* <TableCell className={classes.tableCell} style={{ paddingLeft: '' }}></TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    {!isTableDataLoading && <TableBody>
                                        {
                                            (rowsPerPage > 0
                                                ? tableBodyData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : tableBodyData
                                            ).map((row, i) => {
                                                return (
                                                    <TableRow key={'table_' + i} style={{ background: (i % 2 === 0 ? "#e5e8ec" : "#fff") }}>

                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            className={classes.tableCell}  >
                                                            {row["name"] ? row["name"] : null}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            className={classes.tableCell}  >
                                                            {row["mobile"] ? row["mobile"] : null}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            className={classes.tableCell}  >
                                                            {row["subid"] && <Fab
                                                                variant="extended"
                                                                size="small"
                                                                disabled={!row["subid"]}
                                                                aria-label="PAYOUT"
                                                                onClick={() => this.props.onIdClicked(row["subid"])}
                                                                style={{ textTransform: "none", background: "#0c6523", color: "#ffffff", padding: "0 15px" }}
                                                            >
                                                                View
                                                            </Fab>}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>}
                                </Table>}
                        </div>
                        <div>
                            {isTableDataLoading && <Loader height={"40vh"} />}
                            {this.state.tableBodyData.length > 0 &&
                                <Table>
                                    <TableFooter style={{ borderTop: "2px solid #858792", background: "#fafafa" }}>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[25, 50, 100]}
                                                colSpan={6}
                                                count={totalDataCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: { 'aria-label': 'rows per page' },
                                                    native: true,
                                                }}
                                                onChangePage={this.handleChangePage.bind(this)}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>}
                        </div>
                    </div>}

                    {/* {showEventInfo &&
                        <LogsInfoModal
                            eventDialogData={eventDialogData}
                            onEventModalClosed={() => this.setState({ showEventInfo: false, eventDialogData: undefined })}
                        />} */}
                </MuiThemeProvider>
            </div>);

    }
}

AdminUserLogsTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminUserLogsTable);