/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Label from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AsyncSelect from 'react-select/lib/Async';
import buyerService from '../../../app/buyerService/buyerService';
import supplierService from '../../../app/supplierService/supplierService';
import brokerService from '../../../app/brokerService/brokerService';
// import { Storage } from 'aws-amplify';
import commodityService from '../../../app/commodityService/commodityService';

import Loader from '../../common/Loader';
import Place from '../../common/google_place'
import Utils from '../../../app/common/utils';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
// import { Auth } from 'aws-amplify';
import Auth from '@aws-amplify/auth';
import SweetAlertPage from '../../../app/common/SweetAlertPage';
//
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import dsFeedService from '../../../app/dsFeedService/dsFeedService';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CONSTANTS from '../../../utils/constants';
import CommonUtils from '../../../utils/helper';



const styles = theme => ({
  heading: {
    fontSize: '21px',
    fontWeight: '500',
    marginTop: '0',
    marginBottom: '0',
    fontFamily: 'Montserrat, sans-serif',
  },
  dialogPaper: {
    minWidth: '700px',
    // maxWidth: '700px',
    minHeight: '400px',
    // maxHeight: '500px'
  },
  formAddBtn: {
    width: '90%',
    borderRadius: '10px',
    fontSize: '20px',
    textTransform: 'uppercase',
    backgroundColor: '#4d9fa0 ',
    color: '#fff',
    height: '45px',
    marginBottom: '15px',
    marginTop: "11px",
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  formRoot: {
    // display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    // marginLeft: '25%',
    border: '1px solid #ccc',
    boxShadow: '2px 2px 6px 0px  rgba(0,0,0,0.3)',
    borderRadius: '4px',
    marginBottom: '20px',
    marginTop: '8%',
    padding: '25px',
    textAlign: 'center'
  },
  input: {
    display: 'none',
  },

});
// const statusOption = ["pending", "settled", "partial_settled"];

const CustomOption = props => {
  const { data, innerRef, innerProps } = props;
  return data && data.is_primary ? (
    <div ref={innerRef} {...innerProps} className='custom_option'>
      <i className="fa fa-check" style={{ color: "#60c1d8" }} aria-hidden="true"></i> &nbsp; {data.label ? data.label : ""}
    </div>
  ) : (
      <div ref={innerRef} {...innerProps} className='custom_option' >
        {data.label ? data.label : ""}
      </div>

    );
};

class AddDemandModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      addDsData: {
        buyerid: null,
        buyer_mobile: null,
        max_price: null,
        min_price: null,
        rate_range_id: null,
        variety: null,
        commodity_id: null,
        commodity_name: null,
        contact_name: null,
        contact_phonenumber: CONSTANTS.DSFEED_CONSTANTS.CONTACT_PHONENUMBER,
        contact_organisation: null,
        commodity_quantity_value: null,
        commodity_quantity_id: null,
        is_expired: false,
        district_id: null,
        district: null,
        other_details: this.getEmptyOtherDetails()
      },

      buyerid: "088e99d5-2f87-4757-8e04-2b7a4589853d",
      tempVar: {},
      errorFields: {},
      attachmentArray: [],
      commodityList: { options: [], optionN_E: {}, optionE_N: {} },
      showLoader: false,
      subId: "",

      showSweetAlert: false,
      sweetAlertData: {
        "type": "",
        "title": "",
        "text": ""
      },
      showFormError: false,
      errorMessages: {},
      masterData: {},
      english: true,
      hindi: false,
      fixedRate: true,
      title: 'Create Demand for IB'
    }
  }


  componentDidMount() {
    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => this.setState({ subId: user.attributes.sub }))
      .catch(err => console.error(err));

    if (this.props.userdata && this.props.userdata.role === "ca") {
      this.state.addDsData.buyerid = this.props.userdata.id;
      this.state.addDsData.buyer_mobile = this.props.userdata.mobile;

    }

    this.getMasterData();
    this.setInitialData();
    this.getCommodityNames();
    this.handelAutoCompleteChange = this.handelAutoCompleteChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps() {
    if (this.props !== this.state) {
      this.setState({ open: this.props.open });
    }

    this.setInitialData();
  }

  setInitialData = () => {
    const { repost, expire, edit } = this.props;
    if (this.props.addDsData !== null && (repost || edit || expire)) {
      const addDsData = { ...this.props.addDsData };
      // addDsData.other_details = this.getEmptyOtherDetails();

      // combine district and state
      addDsData.district = `${addDsData.district}, ${addDsData.state}`;

      // remove unwanted data
      Object.keys(addDsData).forEach(key => {
        const exists = this.state.addDsData[key] !== undefined;
        if (!exists) {
          delete addDsData[key];
        }
      })

      // add u_id
      addDsData[CONSTANTS.DSFEED_CONSTANTS.U_ID] = this.props.addDsData[CONSTANTS.DSFEED_CONSTANTS.U_ID];

      // copy buyer details aside
      const buyerid = { ...addDsData.buyerid };
      addDsData.buyerid = buyerid.id;

      let english = false, hindi = false, fixedRate = true;
      // check for hindi and english
      if (!CommonUtils.isEmpty(addDsData.other_details.en.description) || addDsData.other_details.en.specifics.length) {
        english = true;
      }
      if (!CommonUtils.isEmpty(addDsData.other_details.hi.description) || addDsData.other_details.hi.specifics.length) {
        hindi = true;
      }

      // check for fixed rate
      if (!CommonUtils.isEmpty(addDsData.max_price)) {
        fixedRate = false;
      }

      this.setState({ addDsData, buyerid, english, hindi, fixedRate });
    }
  }

  getEmptyOtherDetails = () => {
    return {
      [CONSTANTS.COMMON_CONSTANTS.EN]: {
        specifics: [],
        description: ''
      },
      [CONSTANTS.COMMON_CONSTANTS.HI]: {
        specifics: [],
        description: ''
      }
    }
  }

  async getCommodityNames() {
    try {
      let resp = await commodityService.getCommodityTable();
      // console.error(resp)
      if (resp.data.status === 1 && resp.data.result) {
        this.setState({ commodityList: Utils.getCommodityNamesArrayKeysMap(resp.data.result.data) });
      } else {
        this.setState({ commodityList: { options: [], optionN_E: {}, optionE_N: {} } });
      }
    } catch (err) {
      // console.error(err)
      this.setState({ commodityList: { options: [], optionN_E: {}, optionE_N: {} } });
    }
  }

  async getMasterData() {
    try {
      const params = { lang: CONSTANTS.COMMON_CONSTANTS.EN };
      let resp = await dsFeedService.getMasterData(params);
      // console.log(resp);
      if (resp.data.data) {
        this.setState({ masterData: resp.data.data });
      }
    } catch (err) {
      // console.error(err);
    }
  }

  getCommodityNamesArray(data) {
    try {
      var listData = [];
      if (data) {
        for (var i = 0; i < data.length; i++) {
          if (data[i]["name"]) {
            listData.push(data[i]["name"])
          }
        }
      }
      // console.log('listdata', listData);
      return listData;
    } catch (err) {
      // console.log(err);
      return [];
    }
  }

  getQuantityUnitsArray() {
    let arr = [];
    const { masterData } = this.state;
    if (masterData.quantity_units) {
      arr = [...masterData.quantity_units]
    }
    return arr;
  }

  handleInputChange(event) {
    var floatIds = ["min_price", "max_price", "commodity_quantity_value"]; // this values need to be float
    var { errorFields, errorMessages } = this.state;
    var id = event.target.id;
    if (!id && id === undefined) {
      id = event.target.name;
    }
    var val = event.target.value;
    var addDsDataVal = { ...this.state.addDsData };
    if (floatIds.indexOf(id) > -1) {
      // if (val === "" || !isNaN(val)) {
      if (val === "" || val.match(/^(\d+\.?\d{0,9}|\.\d{1,9})$/)) {
        addDsDataVal[id] = val;
      }
    } else {
      addDsDataVal[id] = val;
    }

    if (id === 'description_en') {
      addDsDataVal.other_details.en.description = val;
    }

    if (id === 'description_hi') {
      addDsDataVal.other_details.hi.description = val;
    }

    if (errorFields.hasOwnProperty(id)) {
      delete errorFields[id];
    }

    errorMessages = {};

    this.setState({
      addDsData: addDsDataVal,
      errorFields,
      showFormError: false,
      errorMessages
    })
  }

  handelAutoCompleteChange = (event, values) => {

    var errors = this.state.errorFields;
    var id = "commodity";
    var addDsDataVal = this.state.addDsData;

    let commoditylist = [];
    // let data = this.state.dataObj;
    if (values && values.length > 0) {
      for (var i = 0; i < values.length; i++) {
        commoditylist.push(this.state.commodityList["optionE_N"][values[i]]);
      }
    }

    if (values && values !== null) {
      addDsDataVal[id] = commoditylist.toString();
    } else {
      addDsDataVal[id] = "";
    }

    if (errors.hasOwnProperty(id)) {
      delete errors[id];
    }
    this.setState({
      addDsData: addDsDataVal,
      errorFields: errors
    })
  }

  handleDialogCancel(event) {
    this.props.onAddModalCancel();
  }

  getOptions = async (type, inputValue, callback) => {
    try {
      if (!inputValue) {
        callback([]);
      }
      let resp = {};
      let data = {};
      data["searchVal"] = inputValue;
      if (type === "buyerid") {

        data['role'] = 'ca';
        resp = await buyerService.serchUser(data);
        // Check is_ib true
        if (resp.data.result.data) {
          const newData = resp.data.result.data.filter(d => d.is_ib);
          resp.data.result.data = newData;
        }
      }

      if (type === "supplierid") {
        data['role'] = 'la';
        resp = await supplierService.serchUser(data);
      }

      if (type === "brokerid") {
        data['role'] = 'broker';
        resp = await brokerService.serchUser(data);
      }

      if (resp.data.status === 1 && resp.data.result) {
        var respData = [];
        if (type === "brokerid") {
          respData = this.formatDataForDropDown(resp.data.result.data, "fullname", "id");
          this.setTempArray(resp.data.result.data, "id");
        } else {
          respData = this.formatDataForDropDown(resp.data.result.data, "fullname", "mobile");
          this.setTempArray(resp.data.result.data, "mobile");
        }

        callback(respData);
      } else {
        callback([]);
      }
    } catch (err) {
      // console.error(err);
      callback([]);
    }
  }

  setTempArray(data, type) {
    var tempVarVal = this.state.tempVar;
    for (var i = 0; i < data.length; i++) {
      tempVarVal[data[i][type]] = data[i];
    }
    this.setState({ tempVar: tempVarVal });
  }

  formatDataForDropDown(data, labelKey, valuekey) {

    var optionsData = [];
    if (data) {
      for (var i = 0; i < data.length; i++) {
        // optionsData.push({ label: data[i][labelKey] + " (" + data[i][valuekey] + " )", value: data[i][valuekey] });
        optionsData.push({
          is_primary: Utils.checkIfPrimaryKey(data[i]),
          label: data[i]["fullname"] + ",  " + data[i]["business_name"] + " \n  (" + data[i]["district"] + " , " + data[i][valuekey] + " )",
          value: data[i][valuekey]
        });
      }
    }
    return optionsData;
  }

  formateDateForApi(data) {
    if (data && data !== "") {
      var dateVal = new Date(data);
      dateVal = dateVal.getFullYear() + "-" + ((dateVal.getMonth() + 1) < 10 ? "0" + (dateVal.getMonth() + 1) : dateVal.getMonth() + 1) + "-" + (dateVal.getDate() < 10 ? "0" + dateVal.getDate() : dateVal.getDate());
      return dateVal;
    } else {
      return "";
    }
  }

  getCommodityMappedName(data) {
    if (data) {
      return this.state.commodityList["optionE_N"][data]
    }
    return data;
  }

  setLocality = (locality) => {
    const { addDsData } = this.state;
    if (!CommonUtils.isEmpty(locality)) {
      addDsData.district = locality.description;
      addDsData.district_id = locality.place_id;
    } else {
      addDsData.district = addDsData.district_id = null;
    }
    this.setState({
      addDsData
    })
  }

  generateHtml = (lang) => {
    const { other_details } = this.state.addDsData;
    let html = null;

    if (!CommonUtils.isEmpty(other_details) &&
      (other_details[lang].specifics.length || !CommonUtils.isEmpty(other_details[lang].description))) {
      html = '<html><body><div>';
      other_details[lang].specifics.forEach(specific => {
        html += `<div>${specific.name}: <strong>${specific.value}</strong></div>`;
      })
      if (!CommonUtils.isEmpty(other_details[lang].description)) {
        html += `<div>${other_details[lang].description}</div>`;
      }
      html += '</div></body></html>'
    }
    return html;
  }

  preparePayload = () => {
    const { edit, repost, expire } = this.props;
    const payload = { ...this.state.addDsData };

    // convert to number
    payload.min_price = Number(payload.min_price);
    if (!CommonUtils.isEmpty(payload.max_price)) {
      payload.max_price = Number(payload.max_price);
    }
    payload.rate_range_id = Number(payload.rate_range_id);
    payload.commodity_quantity_id = Number(payload.commodity_quantity_id);
    payload.commodity_quantity_value = Number(payload.commodity_quantity_value);

    // extra data
    payload.extra_data = {
      en_html_data: this.generateHtml(CONSTANTS.COMMON_CONSTANTS.EN),
      hi_html_data: this.generateHtml(CONSTANTS.COMMON_CONSTANTS.HI),
      en_json_data: payload.other_details.en,
      hi_json_data: payload.other_details.hi,
    }
    // doesnt require CS team approval as created internally by OPS team
    payload.action_done = payload.followed_up = true;

    // expired false
    payload.is_expired = false;

    // delete unwanted fields
    delete payload.other_details;
    delete payload.buyerid;
    delete payload.buyer_mobile;
    delete payload.target_location;
    delete payload.description_en;
    delete payload.description_hi;
    delete payload.district;

    if (edit || repost) {
      delete payload.u_id;
    }

    if (expire) {
      payload.is_expired = true;
    }
    return payload;
  }

  async submitDsData(event) {
    try {
      const { addDsData } = this.state;
      const { repost, edit, expire } = this.props;
      if (this.checkForInvalidFields(addDsData)) {
        this.setState({ showLoader: true });


        // let resp = { data: { status: 1, message: "custom Mas" ,result:[]} }
        var resp = null;
        if (edit || expire) {
          resp = await dsFeedService.putDemand(this.preparePayload(), addDsData.u_id);
        } else {
          resp = await dsFeedService.postDemand(this.preparePayload(), addDsData.buyerid);
        }
        let sweetAlrtData = this.state.sweetAlertData;
        this.setState({ showLoader: false });
        if (resp.data.data && (resp.data.data.post_id || resp.data.data.id)) {
          // alert("Successfully added this order ");
          sweetAlrtData["type"] = "success";
          sweetAlrtData["title"] = "Success";
          sweetAlrtData["text"] = `Demand Post for IB saved successfully`;
        } else {
          // alert("There was an error while adding this order");
          // alert(resp && resp.data && resp.data.message ? resp.data.message : "There was an error while adding this order");
          sweetAlrtData["type"] = "error";
          sweetAlrtData["title"] = "Error";
          sweetAlrtData["text"] = resp && resp.data && resp.data.message ? resp.data.message : "There was an error while adding this demand";
        }
        this.setState({
          showSweetAlert: true,
          sweetAlertData: sweetAlrtData
        });
      } else {
        // alert("please fill the mandatory fields highlighted");
        this.setState({ showFormError: true });
      }
    } catch (err) {
      console.log(err);
      let sweetAlrtData = this.state.sweetAlertData;
      sweetAlrtData["type"] = "error";
      sweetAlrtData["title"] = "Error";
      sweetAlrtData["text"] = resp && resp.data && resp.data.message ? resp.data.message : "There was an error while adding this demand";
      this.setState({
        showLoader: false,
        showSweetAlert: true,
        sweetAlertData: sweetAlrtData
      });
    }
  }

  checkForInvalidFields(data) {
    let { fixedRate, errorMessages, addDsData } = this.state;
    var isValid = true;
    var error = {};
    var nonMandatoryFields = ['variety', 'contact_name', 'contact_phonenumber', 'commodity_id', 'district', 'description_en', 'description_hi'];
    var extraFields = ['contact_email', 'entry_from', 'expected_delivery_id', 'expiry_date', 'message', 'till_date'];
    if (fixedRate) {
      nonMandatoryFields = [...nonMandatoryFields, 'max_price']
    }
    for (var key in data) {
      if ((nonMandatoryFields.indexOf(key) === -1 && extraFields.indexOf(key) === -1)
        && (data[key] === "" || data[key] === null)) {
        error[key] = true;
        isValid = false;
      }
    }

    // check for contact number
    if (addDsData.contact_phonenumber.length !== 10) {
      error.contact_phonenumber = true;
      isValid = false;
      errorMessages = { ...errorMessages, contact_phonenumber: 'Contact number should be of 10 digits' };
    }

    // check for min max range
    if (!CommonUtils.isEmpty(addDsData.max_price) && Number(addDsData.max_price) <= Number(addDsData.min_price)) {
      error.max_price = true;
      isValid = false;
      errorMessages = { ...errorMessages, max_price: 'Max rate should be greater than Min rate' };
    }

    this.setState({ errorFields: error, errorMessages });
    return isValid;
  }

  handelSweetAlertClosed() {
    this.setState({ showSweetAlert: false }, () => {
      if (this.state.sweetAlertData.type !== 'warning') {
        this.props.onDemandPostCreated();
      }
    })
  }

  handelCommoditySelect = (event, values) => {
    let addDsDataVal = this.state.addDsData;
    addDsDataVal["commodity_name"] = values;
    this.setState({ addDsData: addDsDataVal });
  }

  handleCheckbox(id, event) {
    // console.log('id', id);
    let obj = this.state;
    obj[id] = !obj[id];
    this.setState({ QueryObj: obj });
    if (id === "fixedRate") {
      let addDsDataVal = this.state.addDsData;
      addDsDataVal['max_price'] = null;
      this.setState({ QueryObj: addDsDataVal });
    }
    if (id === CONSTANTS.COMMON_CONSTANTS.ENGLISH || id === CONSTANTS.COMMON_CONSTANTS.HINDI) {
      // reset other_details
      const { other_details } = this.state.addDsData;

      if (id === CONSTANTS.COMMON_CONSTANTS.ENGLISH) {
        other_details[CONSTANTS.COMMON_CONSTANTS.EN] = {
          specifics: [],
          description: ''
        }
      }

      if (id === CONSTANTS.COMMON_CONSTANTS.HINDI) {
        other_details[CONSTANTS.COMMON_CONSTANTS.HI] = {
          specifics: [],
          description: ''
        }
      }
    }
  }

  handleAddMoreSpecificsClick = (event, key) => {
    const newSpecific = {
      name: '',
      value: ''
    };
    const other_details = { ...this.state.addDsData.other_details };
    other_details[key].specifics = [...other_details[key].specifics, newSpecific];
    this.setState({
      other_details
    })
  }

  handleRemoveSpecificsClick = (event, index, key) => {
    // console.log(index, key);
    const other_details = { ...this.state.addDsData.other_details };
    other_details[key].specifics = other_details[key].specifics.filter((specific, i) => i !== index);
    this.setState({
      other_details
    })
  }

  handleSpecificChange = (event, index, languageKey, specificKey) => {
    // console.log(languageKey, specificKey);
    const other_details = { ...this.state.addDsData.other_details };
    other_details[languageKey].specifics[index][specificKey] = event.target.value;
    this.setState({
      other_details
    })
  }

  getLabelForMinField = () => {
    let label = 'Min Rate(₹)';
    const { fixedRate } = this.state;
    if (fixedRate) {
      label = 'Fixed Rate(₹)'
    }
    return label;
  }

  getLabelForButton = () => {
    let label = 'Add';
    const { edit, repost, expire } = this.props;
    if (edit) {
      label = 'Save changes';
    } else if (repost) {
      label = 'Repost';
    } else if (expire) {
      label = 'Force Expire';
    }
    return label;
  }

  getLabelForTitle = () => {
    let label = 'Create Demand for IB';
    const { edit, repost, expire } = this.props;
    if (edit) {
      label = 'Edit Demand for IB';
    } else if (repost) {
      label = 'Repost Demand for IB';
    } else if (expire) {
      label = 'Force Expire Demand for IB';
    }
    return label;

  }

  renderFormErrors = () => {
    const { errorMessages, errorFields } = this.state;
    return (
      <div className="form-errors">
        <p>Please fill the fields highlighted above</p>
        {
          Object.keys(errorMessages).map(key => <p key={`error-${key}`}>{errorMessages[key]}</p>)
        }
      </div>
    )

  }

  render() {
    const { classes, userdata, edit, expire } = this.props;
    const { showLoader, addDsData, buyerid,
      commodityList, tempVar, errorFields,
      showSweetAlert, sweetAlertData, showFormError,
      fixedRate } = this.state;
    return (<div>
      <Dialog style={{ zIndex: '1' }}
        open={this.state.open}
        classes={{ paper: classes.dialogPaper }}
        // disableBackdropClick={true}
        onClose={this.handleDialogCancel.bind(this)}
        aria-labelledby="form-dialog-title">
        {!showLoader ? <div>
          <DialogTitle
            style={{ background: '#05073a', textAlign: 'center', height: '50px' }}
            id="form-dialog-title">
            <p style={{ color: '#fff', marginTop: '-10px', fontFamily: 'Lato', fontSize: '18px' }}>
              {this.getLabelForTitle()}</p>
          </DialogTitle>
          <DialogContent>
            <div style={{ display: "flex" }}>

              {userdata && userdata.role === "ca" ?
                <TextField
                  required
                  margin="dense"
                  id="buyerid"
                  label="Buyer"
                  disabled={true}
                  required
                  // error={errorFields["amount"] ? true : false}
                  type="text"
                  style={{ width: '100%' }}
                  value={this.props.userdata.fullname}
                  // onChange={this.handleInputChange.bind(this)}
                  fullWidth />
                : <div style={{ borderBottom: errorFields["buyerid"] ? "2px solid red" : "1px solid gray", width: "100%" }}>
                  <AsyncSelect
                    cacheOptions
                    isDisabled={expire || edit}
                    value={this.props.buyerid || buyerid}
                    id={"reactSelectCustom"}
                    name={"buyerid"}
                    // onChange={( item )=>{ this.setState({ buyerid : item  })}}
                    onChange={(item) => {
                      // console.log(tempVar);
                      this.setState({ buyerid: item }, function () {
                        var data = addDsData;
                        if (errorFields["buyerid"]) {
                          delete errorFields["buyerid"];
                        }
                        if (item && item !== null) {
                          data["buyerid"] = tempVar[item["value"]]["app_id"];
                          data["buyer_mobile"] = tempVar[item["value"]]["mobile"];
                          data["target_location"] = tempVar[item["value"]]["district"];
                          data["contact_organisation"] = tempVar[item["value"]]["business_name"];
                          data["district_id"] = tempVar[item["value"]]["district_id"];
                          data["district"] = tempVar[item["value"]]["locality"];
                        } else {
                          data["buyerid"] = "";
                          data["buyer_mobile"] = "";
                        }
                        this.setState({ addDsData: data, errorFields: errorFields })
                      })
                    }}
                    isSearchable={true}
                    isClearable={true}
                    placeholder={`Select buyer..`}
                    defaultOptions={[]}
                    components={{ Option: CustomOption }}
                    loadOptions={this.getOptions.bind(this, "buyerid")}
                  />
                </div>}
            </div>

            <div className="display-flex-margin-top-5">
              <Place
                disabled={expire}
                props={addDsData.district}
                is_ib={true}
                setLocality={this.setLocality}
                error={errorFields.district_id} />
            </div>

            <div style={{ display: "flex", marginTop: 5 }}>
              <TextField
                margin="dense"
                id="contact_name"
                label="Contact Person"
                type="text"
                disabled={expire}
                style={{ width: '49%' }}
                value={addDsData.contact_name}
                onChange={this.handleInputChange.bind(this)}
                fullWidth />

                                &nbsp;
                                &nbsp;
                <TextField
                margin="dense"
                id="contact_phonenumber"
                label="Contact Phone Number"
                type="number"
                disabled={expire}
                // error={this.state.errorFields["contact_phonenumber"]}
                onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10) }}
                // disabled={this.state.isUpdate}
                style={{ width: '49%' }}
                value={addDsData.contact_phonenumber}
                onChange={this.handleInputChange.bind(this)}
                fullWidth
              />

            </div>
            <div style={{ display: "flex", marginTop: 5 }}>
              <Autocomplete
                id="commodity_name"
                name="commodity_name"
                error={errorFields["commodity_name"] ? true : false}
                options={commodityList["options"].sort()}
                value={addDsData.commodity_name}
                disabled={expire}
                getOptionLabel={e => e}
                onChange={this.handelCommoditySelect}
                required
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip label={option} {...getTagProps({ index })} />
                  ))
                }
                style={{ width: "49%", marginTop: '5px' }}
                renderInput={params => (
                  <TextField
                    {...params}
                    error={errorFields["commodity_name"] ? true : false}
                    label="Select Commodity"
                    placeholder="Commodity"
                    fullWidth
                    required
                  />
                )}
              />
                            &nbsp;
                            &nbsp;
              <TextField
                margin="dense"
                id="variety"
                label="Add Variety"
                error={errorFields["variety"] ? true : false}
                type="text"
                disabled={expire}
                style={{ width: '49%' }}
                value={addDsData.variety}
                onChange={this.handleInputChange.bind(this)}
                fullWidth />

            </div>
            <div style={{ display: "flex", marginTop: 5 }}>
              <div style={{ width: '20%', paddingTop: '30px' }}>
                <Checkbox
                  style={{ height: 24, width: 34 }}
                  checked={fixedRate}
                  onClick={this.handleCheckbox.bind(this, "fixedRate")}
                  tabIndex={-1}
                  disabled={expire}
                  disableRipple
                />Fixed Rate
              </div>
              &nbsp;
              &nbsp;
              <TextField
                margin="dense"
                id="min_price"
                required
                label={this.getLabelForMinField()}
                type="number"
                disabled={expire}
                error={errorFields["min_price"] ? true : false}
                style={{ width: '22%' }}
                value={addDsData.min_price}
                onChange={this.handleInputChange.bind(this)}
                fullWidth />
              &nbsp;
              &nbsp;
              {!this.state.fixedRate &&
                <TextField
                  margin="dense"
                  id="max_price"
                  label="Max Rate(₹)"
                  type="number"
                  disabled={expire}
                  error={errorFields["max_price"] ? true : false}
                  style={{ width: '20%' }}
                  value={addDsData.max_price}
                  required
                  onChange={this.handleInputChange.bind(this)}
                  fullWidth />
              }
              &nbsp;
              &nbsp;
              <TextField
                select
                id="rate_range_id"
                name="rate_range_id"
                label="Rate Range Unit"
                required
                disabled={expire}
                error={errorFields["rate_range_id"] ? true : false}
                type="text"
                style={{ width: '33%', marginTop: '5px' }}
                value={addDsData.rate_range_id}
                onChange={this.handleInputChange.bind(this)}>
                {this.getQuantityUnitsArray().map((unit, i) => (
                  <MenuItem key={unit.id} value={unit.id} selected={true}>
                    {unit.value}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div style={{ display: "flex", marginTop: 5 }} >
              <TextField
                required
                margin="dense"
                id="commodity_quantity_value"
                label="Quantity Required (per day)"
                type="number"
                disabled={expire}
                error={errorFields["commodity_quantity_value"] ? true : false}
                style={{ width: '49%' }}
                value={addDsData.commodity_quantity_value}
                onChange={this.handleInputChange.bind(this)}
                fullWidth />

                            &nbsp;
                              &nbsp;
            <TextField
                select
                required
                id="commodity_quantity_id"
                error={errorFields["commodity_quantity_id"] ? true : false}
                name="commodity_quantity_id"
                label="Quantity Required Unit"
                type="text"
                disabled={expire}
                style={{ width: '49%', marginTop: '5px' }}
                value={addDsData.commodity_quantity_id}
                onChange={this.handleInputChange.bind(this)}>
                {this.getQuantityUnitsArray().map((unit, i) => (
                  <MenuItem key={unit.id} value={unit.id} selected={true}>
                    {unit.value}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div style={{ display: "flex", marginTop: 30 }} >
              <h3>Other details <small style={{ color: '#888' }}>(Optional)</small></h3>
            </div>
            <div style={{ display: "flex", marginTop: 20 }} >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.english}
                    onClick={this.handleCheckbox.bind(this, "english")}
                    tabIndex={-1}
                    disabled={expire}
                    disableRipple
                  />}
                label="English" />
                &nbsp; &nbsp;
                <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.hindi}
                    onClick={this.handleCheckbox.bind(this, "hindi")}
                    tabIndex={-1}
                    disabled={expire}
                    disableRipple
                  />}
                label="हिंदी" />
            </div>
            {/* English */}
            {this.state.english &&
              <fieldset style={{ padding: "0 15px", borderRadius: 4, margin: "15px 0 20px", background: "#f5f5f5", border: "solid 1px #ccc" }}>
                <legend style={{ padding: "0 10px", fontWeight: "bold" }}>English</legend>
                {
                  this.state.addDsData.other_details.en.specifics.map((specific, index) =>
                    <div style={{ display: "flex" }}>
                      <TextField
                        margin="dense"
                        id="specifics_name"
                        label="Specifics name"
                        // error={errorFields["specifics_name"] ? true : false}
                        type="text"
                        style={{ width: '30%' }}
                        value={specific.name}
                        disabled={expire}
                        onChange={e => this.handleSpecificChange(e, index, "en", "name")}
                        fullWidth />


                      <span style={{ display: 'inline-block', marginTop: 30, marginRight: "4%" }}>:</span>
                      <TextField
                        margin="dense"
                        id="details"
                        label="Details"
                        // error={errorFields["details"] ? true : false}
                        type="text"
                        style={{ width: '63%' }}
                        value={specific.value}
                        disabled={expire}
                        onChange={e => this.handleSpecificChange(e, index, "en", "value")}
                        fullWidth />
                      {!expire ? <DeleteForeverIcon
                        disabled={expire}
                        style={{ marginTop: 25, cursor: 'pointer', marginLeft: 15 }}
                        onClick={e => this.handleRemoveSpecificsClick(e, index, "en")} /> : null}
                    </div>
                  )

                }
                <div style={{ display: "flex", marginTop: 15, marginBottom: 20 }} >
                  <Button
                    variant="contained"
                    color="default"
                    disabled={expire}
                    className={classes.button}
                    onClick={e => this.handleAddMoreSpecificsClick(e, "en")}
                    startIcon={<Icon className="fa fa-plus-circle" fontSize="small" />}
                  >
                    {addDsData.other_details.en.specifics.length ? 'Add more' : 'Add specific'}
                  </Button>
                </div>
                <div>
                  <TextField
                    style={{ width: '100%' }}
                    id="description_en"
                    label="Add some description"
                    type="text"
                    disabled={expire}
                    style={{ width: '100%', backgroundColor: "#fff", marginBottom: 15 }}
                    value={addDsData.other_details.en.description}
                    onChange={this.handleInputChange.bind(this)}
                    multiline
                    rows={2}
                    variant="outlined"
                    fullWidth />
                </div>
              </fieldset>
            }
            {/* Hindi */}
            {this.state.hindi &&
              <fieldset style={{ padding: "0 15px", borderRadius: 4, margin: "15px 0 20px", background: "#f5f5f5", border: "solid 1px #ccc" }}>
                <legend style={{ padding: "0 10px", fontWeight: "bold" }}>हिंदी</legend>
                {
                  this.state.addDsData.other_details.hi.specifics.map((specific, index) =>
                    <div style={{ display: "flex" }}>
                      <TextField
                        margin="dense"
                        id="specifics_name"
                        label="तथ्य"
                        // error={errorFields["specifics_name"] ? true : false}
                        type="text"
                        disabled={expire}
                        style={{ width: '30%' }}
                        value={specific.name}
                        onChange={e => this.handleSpecificChange(e, index, "hi", "name")}
                        fullWidth />


                      <span style={{ display: 'inline-block', marginTop: 30, marginRight: "4%" }}>:</span>
                      <TextField
                        margin="dense"
                        id="details"
                        label="विवरण"
                        // error={errorFields["details"] ? true : false}
                        type="text"
                        disabled={expire}
                        style={{ width: '63%' }}
                        value={specific.value}
                        onChange={e => this.handleSpecificChange(e, index, "hi", "value")}
                        fullWidth />
                      {!expire ? <DeleteForeverIcon
                        disabled={expire}
                        style={{ marginTop: 25, cursor: 'pointer', marginLeft: 15 }}
                        onClick={e => this.handleRemoveSpecificsClick(e, index, "hi")} /> : null}
                    </div>
                  )

                }
                <div style={{ display: "flex", marginTop: 15, marginBottom: 20 }} >
                  <Button
                    variant="contained"
                    color="default"
                    disabled={expire}
                    className={classes.button}
                    onClick={e => this.handleAddMoreSpecificsClick(e, "hi")}
                    startIcon={<Icon className="fa fa-plus-circle" fontSize="small" />}
                  >
                    {addDsData.other_details.hi.specifics.length ? 'और तथ्य जोड़ें' : 'तथ्य जोड़ें'}
                  </Button>
                </div>
                <div>
                  <TextField
                    style={{ width: '100%' }}
                    id="description_hi"
                    label="कुछ विवरण जोड़ें"
                    type="text"
                    disabled={expire}
                    style={{ width: '100%', backgroundColor: "#fff", marginBottom: 15 }}
                    value={addDsData.other_details.hi.description}
                    onChange={this.handleInputChange.bind(this)}
                    multiline
                    rows={2}
                    variant="outlined"
                    fullWidth />
                </div>
              </fieldset>
            }
            {showFormError &&
              this.renderFormErrors()
            }
          </DialogContent>
          <DialogActions>
            <Button className={classes.formCancelBtn} onClick={this.submitDsData.bind(this)} color="primary" style={{ color: expire ? "#cc5500" : "#3f51b5" }}>{this.getLabelForButton()}</Button>
            <Button className={classes.formCancelBtn} onClick={this.handleDialogCancel.bind(this)} color="primary">Cancel</Button>
          </DialogActions>
        </div> :
          <Loader primaryText="Please wait.." />}
      </Dialog>

      {showSweetAlert &&
        <SweetAlertPage
          show={true}
          type={sweetAlertData.type}
          title={sweetAlertData.title}
          text={sweetAlertData.text}
          sweetAlertClose={() => this.handelSweetAlertClosed()}
        />}
    </div>
    );
  }
}

AddDemandModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddDemandModal);