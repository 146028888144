/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import AsyncSelect from 'react-select/lib/Async';
import Loader from '../../common/Loader';
import SweetAlertPage from '../../../app/common/SweetAlertPage';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import relationshipManagerService from '../../../app/relationshipManagerService/relationshipManagerService';
import Select from 'react-select';


const styles = theme => ({
    heading: {
        fontSize: '21px',
        fontWeight: '500',
        marginTop: '0',
        marginBottom: '0',
        fontFamily: 'Montserrat, sans-serif',
    },
    dialogPaper: {
        minWidth: '700px',
        // maxWidth: '700px',
        minHeight: '250px',
        // maxHeight: '500px'
    },
    formAddBtn: {
        width: '90%',
        borderRadius: '10px',
        fontSize: '20px',
        textTransform: 'uppercase',
        backgroundColor: '#4d9fa0 ',
        color: '#fff',
        height: '45px',
        marginBottom: '15px',
        marginTop: "11px",
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    formControl: {
        margin: '0px 5px',
        padding: '0px 10px',
        minWidth: 120,
        color: '#373737 !important',
        borderRight: '1px solid #e6e7e8'
    },
    formRoot: {
        // display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        // marginLeft: '25%',
        border: '1px solid #ccc',
        boxShadow: '2px 2px 6px 0px  rgba(0,0,0,0.3)',
        borderRadius: '4px',
        marginBottom: '20px',
        marginTop: '8%',
        padding: '25px',
        textAlign: 'center'
    },
    input: {
        display: 'none',
    },
    bgColor: {
        "& > *": {
            // display: "inline-block",
            fontWeight: "500",
            fontFamily: "lato",
            fontSize: "14px",
            color: "#3e5569",
        }
    },
});
// const statusOption = ["pending", "settled", "partial_settled"];

const CustomOption = props => {
    const { data, innerRef, innerProps } = props;
    return data && data.is_primary ? (
        <div ref={innerRef} {...innerProps} className='custom_option'>
            <i className="fa fa-check" style={{ color: "#60c1d8" }} aria-hidden="true"></i> &nbsp; {data.label ? data.label : ""}
        </div>
    ) : (
            <div ref={innerRef} {...innerProps} className='custom_option' >
                {data.label ? data.label : ""}
            </div>

        );
};

class DeleteRelationshipManagerModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,

            errorFields: {},
            showLoader: false,

            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },
            payloadData: {
                "credit_limit": "",
                "group_name": "",
                "id": ""
            },
            deletePayload: {
                "active": false,
                "is_reassign_true": false,
                "reassign_id": 2,
                "is_reassign_done": "no"
            },
            rmUserid: null,
            rmOption: []
        }
    }

    componentDidMount() {
        // rmList
        this.setState({ rmOption: this.formatDataForDropDown(this.props.rmList) });

    }



    UNSAFE_componentWillReceiveProps() {
        if (this.props !== this.state) {
            this.setState({ open: this.props.open });
        }
    }

    handleDialogCancel(event) {
        this.props.onDeleteModalCancel();
    }

    getOptions = async (type, inputValue, callback) => {
        try {
            let options = this.state.rmOption
            await callback(this.state.rmOption);
        } catch (err) {
            console.error(err);
            callback(this.state.rmOption);
        }
    }

    formatDataForDropDown(data) {
        var optionsData = [];
        if (data) {
            for (var i = 0; i < data.length; i++) {
                if (this.props.deleteDataRow["id"] !== data[i]["id"]) {
                    if (data[i]["active"]) {
                        optionsData.push({
                            label: data[i]["name"] + " ,  " + data[i]["zone"] + " \n  ( " + data[i]["emp_id"] + " )",
                            value: data[i]["id"]
                        });
                    }
                }
            }
        }
        return optionsData;
    }


    getSearchAreaText = (id, event) => {
        try {
            console.log(event);
            let deletePayloadVal = this.state.deletePayload;
            let errorFields = this.state.errorFields;
            this.setState({ [id]: event !== null ? event : "" }, () => {
                errorFields[id] = false;
                deletePayloadVal["reassign_id"] = event !== null ? event["value"] : "";
                this.setState({ deletePayload: deletePayloadVal, errorFields: errorFields });
            });
        } catch (err) {
            console.log(err);
        }
    }

    checkForValidPayload(data) {
        let isvalid = true;
        let sweetAlertDataVal = this.state.sweetAlertData;
        sweetAlertDataVal["title"] = "Error";
        sweetAlertDataVal["type"] = "error";
        sweetAlertDataVal["text"] = "";
        let errorFields = {};
        if (this.state.deletePayload.is_reassign_done === "yes") {
            if (!this.state.rmUserid || Object.keys(this.state.rmUserid).length === 0) {
                sweetAlertDataVal["text"] = "Please select a relationship manager to whom you want to reassign";
                isvalid = false;
                errorFields = { "rmUserid": true };
            }
        }
        if (sweetAlertDataVal["text"] !== "") {
            this.setState({ sweetAlertData: sweetAlertDataVal, showSweetAlert: true })
        }
        this.setState({ errorFields: errorFields });
        return isvalid;
    }

    async handelDeleteClicked() {
        try {
            if (this.checkForValidPayload(this.state.deletePayload)) {
                this.setState({ showLoader: true });
                this.deleteRmFromList();
            }
        } catch (err) {
            console.log(err);
        }
    }


    async deleteRmFromList() {
        let sweetAlertDataVal = this.state.sweetAlertData;
        try {
            let payload = {
                "active": false,
                "is_reassign_true": this.state.deletePayload.is_reassign_done === "yes",
            };
            if (this.state.deletePayload.is_reassign_done === "yes") {
                payload["reassign_id"] = this.state.rmUserid["value"];
            }
            let idRmUser = this.props.deleteDataRow["id"];
            console.log(idRmUser, payload);
            let resp = await relationshipManagerService.updateRMUser(idRmUser, payload);

            if (resp.data.status === 1 && resp.data.result) {
                // 
                sweetAlertDataVal["type"] = "success",
                    sweetAlertDataVal["title"] = "Success",
                    sweetAlertDataVal["text"] = "Relationship manager disabled successfully";
            } else {
                sweetAlertDataVal["type"] = "error",
                    sweetAlertDataVal["title"] = "Error",
                    sweetAlertDataVal["text"] = resp && resp.data && resp.data.message ? resp.data.message : "Oops an error occured while disabling the relationship manager";

            }
            this.setState({ sweetAlertData: sweetAlertDataVal, showSweetAlert: true, showLoader: false })

        } catch (err) {
            console.log(err);
            sweetAlertDataVal["type"] = "error",
                sweetAlertDataVal["title"] = "Error",
                sweetAlertDataVal["text"] = "Oops an error occured while disabling the Relationship manager"
            this.setState({ sweetAlertData: sweetAlertDataVal, showSweetAlert: true, showLoader: false })
        }
    }

    handelSweetAlertClosed() {
        this.setState({ showSweetAlert: false }, () => {
            if (this.state.sweetAlertData["type"] === "success") {
                this.props.onRmDeleteSuccess();
            }
        });
    }


    handleCheckbox(event) {
        console.log(event.target.value);
        let obj = this.state.deletePayload;
        obj["is_reassign_done"] = event.target.value;
        this.setState({ deletePayload: obj });
    }


    render() {
        const { classes, deleteDataRow } = this.props;
        const { showLoader, rmUserid, errorFields, showSweetAlert, sweetAlertData } = this.state;

        return (<div>
            <Dialog style={{ zIndex: '1' }}
                open={this.state.open}
                classes={{ paper: classes.dialogPaper }}
                disableBackdropClick={true}
                onClose={this.handleDialogCancel.bind(this)}
                aria-labelledby="form-dialog-title"                >
                {!showLoader ? <div>
                    <DialogTitle
                        style={{ background: '#05073a', textAlign: 'center', height: 'auto', maxHeight: "100px" }}
                        id="form-dialog-title">
                        <p style={{ color: '#fff', marginTop: '-10px', fontFamily: 'Lato', fontSize: '18px' }}>
                            Disable relationship manager</p>
                    </DialogTitle>
                    <DialogContent >
                        <div style={{ fontFamily: 'Lato', fontSize: '16px', paddingTop: "12px" }}>
                            Are you sure to disable this relationship manager?
                            <div style={{ display: "flex", padding: "10px" }}>
                                <i className="fa fa-hand-o-right" style={{ margin: "auto 0px" }} aria-hidden="true"></i> &nbsp;
                                    {deleteDataRow["name"] ? <div className="text-ellpses" style={{ whiteSpace: "nowrap" }}>{deleteDataRow["name"]}</div> : ""}
                            </div>
                        </div>
                        <div style={{ fontFamily: 'Lato', fontSize: '15px', paddingTop: "12px" }}> Do you want to assign the users of this relationship manager to another ?</div>
                        <div style={{ display: "block", paddingTop: "15px" }} >
                            <RadioGroup aria-label="is_reassign_true" style={{ flexDirection: "unset" }} name="is_reassign_true" value={this.state.deletePayload.is_reassign_done} onChange={(ev) => this.handleCheckbox(ev)}>
                                <FormControlLabel value={"yes"} style={{ width: "50%" }} control={<Radio />} label="Yes" />
                                <FormControlLabel value={"no"} control={<Radio />} label="No" />
                            </RadioGroup>
                        </div>

                        {this.state.deletePayload.is_reassign_done && this.state.deletePayload.is_reassign_done === "yes" &&
                            <div style={{ display: "flex", paddingTop: "10px" }}>
                                <div style={{ borderBottom: errorFields["userid"] ? "2px solid red" : "1px solid gray", width: "98%" }}>
                                    {/* <AsyncSelect
                                        value={rmUserid}
                                        name={"rmUserid"}
                                        onChange={this.getSearchAreaText.bind(this, "rmUserid")}
                                        isSearchable={true}
                                        isClearable={true}
                                        placeholder={`Select user`}
                                        menuPortalTarget={document.body}
                                        defaultOptions={this.state.rmOption}
                                        components={{ Option: CustomOption }}
                                        loadOptions={this.state.rmOption}
                                    /> */}
                                    <Select
                                        name={"rmUserid"}
                                        value={rmUserid}
                                        onChange={this.getSearchAreaText.bind(this, "rmUserid")}
                                        options={this.state.rmOption}
                                        isSearchable={true}
                                        // menuIsOpen
                                        menuPortalTarget={document.body}
                                        isClearable={true}
                                        components={{ Option: CustomOption }}
                                        placeholder={`Select relationship manager`}
                                        className={"basic-single " + classes.bgColor} />
                                </div>
                            </div>}

                    </DialogContent>
                    <DialogActions>
                        <Button className={classes.formCancelBtn} onClick={this.handelDeleteClicked.bind(this)} color="primary">Disable</Button>
                        <Button className={classes.formCancelBtn} onClick={this.handleDialogCancel.bind(this)} color="primary">Cancel</Button>
                    </DialogActions>
                </div> :
                    <Loader primaryText="Please wait.." />}
            </Dialog>

            {showSweetAlert &&
                <SweetAlertPage
                    show={true}
                    type={sweetAlertData.type}
                    title={sweetAlertData.title}
                    text={sweetAlertData.text}
                    sweetAlertClose={() => this.handelSweetAlertClosed()}
                />}
        </div>
        );
    }
}

DeleteRelationshipManagerModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeleteRelationshipManagerModal);