import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
// import { Auth } from 'aws-amplify';
import Auth from '@aws-amplify/auth';
// import AsyncSelect from 'react-select/lib/Async';
import buyerService from '../../../app/buyerService/buyerService';
import supplierService from '../../../app/supplierService/supplierService';
// import brokerService from '../../../app/brokerService/brokerService';
// import { Storage } from 'aws-amplify';
import Storage from '@aws-amplify/storage';
import orderService from '../../../app/orderService/orderService';
import Switch from '@material-ui/core/Switch';
import Loader from '../../common/Loader';
import Utils from '../../../app/common/utils';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import SweetAlertPage from '../../../app/common/SweetAlertPage';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
// import commonService from '../../../app/commonService/commonService';
import { getBucketName } from '../../../config/appConfig';

const styles = theme => ({
    heading: {
        fontSize: '21px',
        fontWeight: '500',
        marginTop: '0',
        marginBottom: '0',
        fontFamily: 'Montserrat, sans-serif',
    },
    dialogPaper: {
        minWidth: '700px',
        // maxWidth: '700px',
        minHeight: '400px',
        // maxHeight: '500px'
    },
    formAddBtn: {
        width: '90%',
        borderRadius: '10px',
        fontSize: '20px',
        textTransform: 'uppercase',
        backgroundColor: '#4d9fa0 ',
        color: '#fff',
        height: '45px',
        marginBottom: '15px',
        marginTop: "11px",
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    formRoot: {
        // display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        // marginLeft: '25%',
        border: '1px solid #ccc',
        boxShadow: '2px 2px 6px 0px  rgba(0,0,0,0.3)',
        borderRadius: '4px',
        marginBottom: '20px',
        marginTop: '8%',
        padding: '25px',
        textAlign: 'center'
    },
    input: {
        display: 'none',
    },
    muiSwitchroot: {
        float: "right"
    },

});

// const statusOption = ["pending", "settled", "partial_settled"];
// const CustomOption = props => {
//     const { data, innerRef, innerProps } = props;
//     return data && data.is_primary ? (
//         <div ref={innerRef} {...innerProps} className='custom_option'>
//             <i className="fa fa-check" style={{ color: "#60c1d8" }} aria-hidden="true"></i> &nbsp; {data.label ? data.label : ""}
//         </div>
//     ) : (
//             <div ref={innerRef} {...innerProps} className='custom_option' >
//                 {data.label ? data.label : ""}
//             </div>

//         );
// };

class EditOrderDataModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            orderPayload: this.props.editableData,
            orderPayloadToUpdate: {
                "active": "",
                "buyerid": "",
                "actual_dispatch_date": "",
                "supplierid": "",
                "brokerid": "",
                "commodity": "",
                "rate": "",
                "qnt": "",
                "unit": "",
                "type": "BILTI",
                "bijak_amt": "",
                "supporting_images": [],
                "transport_info": "",
                "author_name": "",
                "app_order_id": "",
                // "author_mobile": "",
                "creator_role": "",
                "status": "",
                "remark": "",
                "other_info": "",
                "buyer_mobile": "",
                "supplier_mobile": "",
                "commission_rate": "",
                "commission_unit": "",
                "target_location": "",
                "source_location": "",
                "rate_unit": "",

                "broker_mobile": "",
                "bijak_total_amount": "",
                "invoice_no": "",
                "old_system_order_id": "",
                "pkt": "",
                "brokerage": "",
                "unsettled_amount_pltf": "",
                "tags": [],
                "order_type":""
            },

            buyerid: "",
            supplierid: "",
            brokerid: "",
            tempVar: {},
            errorFields: {},
            attachmentArray: [],
            commodityList: this.props.commodityList,
            showLoader: false,
            subId: "",

            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },
            showErrorMsg: false,

            tagsOption: this.props.tagsOption || [],
            orderTypeOptions: this.props.orderTypeOptions || [],
            isOrderShifted: false
        }
        // console.log(this.props.editableData)
    }

    componentDidMount() {

        // this.getTagsData();
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => this.setState({ subId: user.attributes.sub }))
            .catch(err => console.log(err));
        if (this.state.orderPayload) {


            let attachmentArrayVal = this.state.attachmentArray;
            if (this.state.orderPayload && this.state.orderPayload["supporting_images"] && this.state.orderPayload["supporting_images"] !== null) {
                for (var i = 0; i < this.state.orderPayload["supporting_images"].length; i++) {
                    let imgObj = {
                        bucket: getBucketName(),
                        image_url: this.state.orderPayload["supporting_images"][i],
                        key: this.state.orderPayload["supporting_images"][i]
                    }
                    attachmentArrayVal.push(imgObj)
                }
            }

            this.setState({
                attachmentArray: attachmentArrayVal,
                buyerid: [{ label: this.state.orderPayload["buyer_name"], value: this.state.orderPayload["buyer_mobile"] }],
                supplierid: [{ label: this.state.orderPayload["supplier_name"], value: this.state.orderPayload["supplier_mobile"] }],
                brokerid: [{ value: this.state.orderPayload["brokerid"], label: this.state.orderPayload["broker_name"] }],
            })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.open !== this.state.open) {
            this.setState({ open: this.props.open });
        }
    }

    // async getTagsData() {
    //     try {
    //         let tagsData = [];
    //         let resp = await commonService.getTagsData("orders");
    //         console.log(resp)
    //         if (resp.data.status === 1 && resp.data.result) {
    //             tagsData = resp.data.result;
    //         } else {
    //             tagsData = [];
    //         }
    //         this.setState({ tagsOptions: tagsData });
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }



    handleInputChange(event) {
        // console.log(event)
        var floatIds = ["rate", "qnt", "bijak_amt", "commission_rate", "bijak_total_amount", "pkt", "brokerage", "unsettled_amount_pltf"]; // this values need to be float
        var errors = this.state.errorFields;
        var id = event.target.id;
        if (!id && id === undefined) {
            id = event.target.name;
        }
        var val = event.target.value;
        var orderPayloadVal = this.state.orderPayload;
        if (floatIds.indexOf(id) > -1) {
            // if (val === "" || !isNaN(val)) {
            if (val === "" || val.match(/^(\d+\.?\d{0,9}|\.\d{1,9})$/)) {
                orderPayloadVal[id] = val;
            }
        } else {
            orderPayloadVal[id] = val;
        }

        if (errors.hasOwnProperty(id)) {
            delete errors[id];
        }
        this.setState({
            orderPayload: orderPayloadVal,
            errorFields: errors,
            showErrorMsg: false
        })
    }

    handleDialogCancel(event) {
        this.props.onEditModalCancel();
    }

    formateDateForApi(data) {
        if (data && data !== "") {
            var dateVal = new Date(data);
            dateVal = dateVal.getFullYear() + "-" + ((dateVal.getMonth() + 1) < 10 ? "0" + (dateVal.getMonth() + 1) : dateVal.getMonth() + 1) + "-" + (dateVal.getDate() < 10 ? "0" + dateVal.getDate() : dateVal.getDate());
            return dateVal;
        } else {
            return "";
        }
    }

    getCommodityMappedName(data) {
        if (data) {
            return this.state.commodityList["optionE_N"][data]
        }
        return data;
    }

    async updateOrder(event) {
        try {
            var payload = this.state.orderPayload;
            var id = payload.id;
            if (this.checkForInvalidFields(payload)) {
                this.setState({ showLoader: true });
                payload["supporting_images"] = this.prepareSupportingUrlArray(this.state.attachmentArray);
                payload = this.removeBlankNonMandatoryFields(payload);
                payload["actual_dispatch_date"] = this.formateDateForApi(payload["actual_dispatch_date"]);
                payload["commodity"] = this.getCommodityMappedName(payload["commodity"]);
                if (this.state.isOrderShifted) {
                    payload['isordershifted'] = this.state.isOrderShifted; // added to keep a check if user updates buyer/supplier
                }
                // console.log(payload)
                // var resp= { data:{ status : 1, result:{} }}
                var resp = await orderService.updateExistingOrder(this.state.subId, id, payload);
                this.setState({ showLoader: false });
                let sweetAlrtData = this.state.sweetAlertData;
                if (resp.data.status === 1 && resp.data.result) {
                    // alert("Successfully updated this order ");
                    // this.props.onOrderDataUpdated();

                    sweetAlrtData["type"] = "success";
                    sweetAlrtData["title"] = "Success";
                    sweetAlrtData["text"] = "Order updated successfully";
                } else {
                    // alert("There was an error while updating this order");
                    // alert(resp && resp.data && resp.data.message ? resp.data.message : "There was an error while updating this order");
                    sweetAlrtData["type"] = "error";
                    sweetAlrtData["title"] = "Error";
                    sweetAlrtData["text"] = resp && resp.data && resp.data.message ? resp.data.message : "There was an error while updating this order";
                }
                this.setState({
                    showSweetAlert: true,
                    sweetAlertData: sweetAlrtData
                });
            } else {
                // alert("please fill the mandatory fields highlighted");
                this.setState({ showErrorMsg: true });
            }
        } catch (err) {
            console.log(err);
        }
    }

    removeBlankNonMandatoryFields(data) {
        var floatIds = ["rate", "qnt", "bijak_amt", "commission_rate", "unsettled_amount_pltf"]
        var formateddata = {};
        for (var key in this.state.orderPayloadToUpdate) {
            if (data[key] && data[key] !== "") {
                formateddata[key] = data[key];
            }

            if (key === "active") {
                formateddata[key] = data[key];
            }

            if (formateddata[key] && floatIds.indexOf(key) > -1) {
                formateddata[key] = parseFloat(data[key]);
            }

            if (key === "cashback_value" && data[key] === "") {
                formateddata[key] = 0;
            }

            if (key === "cashback_allotted_to" && data[key] === "") {
                formateddata[key] = null;
            }

            if (key === "order_type" && !data[key]) {
                formateddata[key] = null;
            }
        }
        return formateddata;
    }

    prepareSupportingUrlArray(data) {
        var urlArray = [];
        for (var i = 0; i < data.length; i++) {
            urlArray.push(data[i]["image_url"]);
        }
        return urlArray;

    }

    checkForInvalidFields(data) {
        var isValid = true;
        var error = {};
        var nonMandatoryFields = ["transport_info", "type", "author_name", "brokerid",
            "remark", "other_info", "commission_rate", "commission_unit", "rate", "qnt",
            "unit", "rate_unit", "broker_mobile", "bijak_total_amount",
            "invoice_no", "old_system_order_id", "pkt", "brokerage"
        ];
        var extraMandatoryFields = ["creator_role"];
        for (var key in data) {
            // console.log( key +"---"+data[key])
            if (nonMandatoryFields.indexOf(key) === -1 && data[key] === "" && data[key] === null) {
                error[key] = true;
                isValid = false;
            }
            if (extraMandatoryFields.indexOf(key) > -1 && (data[key] === "" || data[key] === null)) {
                error[key] = true;
                isValid = false;
            }
        }
        this.setState({ errorFields: error });
        return isValid;
    }

    onStepChangeEventOccurs = (stepType) => {
        let { attachmentArray, step } = this.state;
        attachmentArray = [];
        step = stepType;
        this.setState({ attachmentArray, step });
    }

    deleteItem(key) {
        let { attachmentArray } = this.state;
        for (var i = 0; i < attachmentArray.length; i++) {
            var indFile = attachmentArray[i];
            if (indFile.key === key) {
                attachmentArray.splice(i, 1);
                this.setState({ attachmentArray });
            }
        }
    }


    fileChangedHandler = (event) => {
        let { selectedFileName, isFileLoading, file } = this.state;
        file = event.target.files[0];
        if (Utils.checkIfValidImageFile(file.name)) {
            selectedFileName = file ? file.name : null;
            isFileLoading = !file ? false : true;
            this.setState({ selectedFileName, isFileLoading, file })

            let updatedFileName = Utils.getImageName(file.name);

            Storage.configure({
                level: 'public',
                AWSS3: {
                    bucket: getBucketName(),//Your bucket name;
                    region: 'ap-south-1'//Specify the region your bucket was created in;
                }
            });
            Storage.put("order/" + updatedFileName, file, {
                // key: "UBIL-Register-Online.png"
                contentType: 'image/png'
            }).then(result => {
                // let data = result
                let attachmentObj = {
                    bucket: getBucketName(),
                    filename: updatedFileName,
                    key: result.key
                }
                let { attachmentArray } = this.state;

                Storage.get("order/" + updatedFileName)
                    .then(result => {
                        attachmentObj["image_url"] = result.split("?")[0];
                        attachmentArray.push(attachmentObj)
                        this.setState({
                            isFileUpload: false,
                            attachmentArray
                        });
                    })
                    .catch(err => console.log(err));
            }
            ).catch(err => {
                this.setState({
                    isFileUpload: false
                })
                console.log(err)
            });
        } else {
            let sweetAlrtData = this.state.sweetAlertData;
            sweetAlrtData["type"] = "warning";
            sweetAlrtData["title"] = "Info";
            sweetAlrtData["text"] = "Please select a valid image file.";
            this.setState({
                showSweetAlert: true,
                sweetAlertData: sweetAlrtData
            });
        }
    }

    handleStateChange = (id, event) => {
        let data = this.state.orderPayload;
        if (id === "active") {
            data[id] = event.target.checked;
        }
        this.setState({ orderPayload: data });
    };


    handelDateChange(dateval) {
        var orderPayloadVal = this.state.orderPayload;
        orderPayloadVal["actual_dispatch_date"] = dateval;
        this.setState({ orderPayload: orderPayloadVal })
    }

    handelSweetAlertClosed() {
        this.setState({ showSweetAlert: false }, () => {
            if (this.state.sweetAlertData.type !== 'warning') {
                this.props.onOrderDataUpdated();
            }
        })
    }

    handelTagsChanges = (event, values) => {
        let orderPayloadVal = this.state.orderPayload;
        orderPayloadVal["tags"] = values;
        this.setState({ orderPayload: orderPayloadVal });
    }

    handelOptionTypeChanges = (event, values) => {
        let orderPayloadVal = this.state.orderPayload;
        orderPayloadVal["order_type"] = values;
        this.setState({ orderPayload: orderPayloadVal });
    }

    getOptions = async (type, inputValue, callback) => {
        try {
            if (!inputValue) {
                callback([]);
            }
            let resp = {};
            let data = {};
            data["searchVal"] = inputValue;
            if (type === "buyerid") {

                data['role'] = 'ca';
                resp = await buyerService.serchUser(data);
            }

            if (type === "supplierid") {
                data['role'] = 'la';
                resp = await supplierService.serchUser(data);
            }

            if (resp.data.status === 1 && resp.data.result) {
                var respData = [];
                respData = this.formatDataForDropDown(resp.data.result.data, "fullname", "mobile");
                this.setTempArray(resp.data.result.data, "mobile");
                callback(respData);
            } else {
                callback([]);
            }
        } catch (err) {
            console.error(err);
            callback([]);
        }
    }

    setTempArray(data, type) {
        var tempVarVal = this.state.tempVar;
        for (var i = 0; i < data.length; i++) {
            tempVarVal[data[i][type]] = data[i];
        }
        this.setState({ tempVar: tempVarVal });
    }

    formatDataForDropDown(data, labelKey, valuekey) {
        var optionsData = [];
        if (data) {
            for (var i = 0; i < data.length; i++) {
                // optionsData.push({ label: data[i][labelKey]+ " ("+data[i][valuekey]+")",
                //  value: data[i][valuekey] });
                optionsData.push({ 
                    is_primary :  Utils.checkIfPrimaryKey( data[i] ),
                    label: data[i]["fullname"] + ",  " + data[i]["business_name"] +
                     " \n  (" + data[i]["locality"] + " , " + data[i][valuekey] + " )", 
                    value: data[i][valuekey] });
            }
        }
        return optionsData;
    }



    render() {
        const { classes } = this.props;
        const { showLoader, orderPayload, commodityList, errorFields, showSweetAlert, sweetAlertData,
            tagsOption, orderTypeOptions } = this.state; //, buyerid, tempVar, supplierid
        return (<div>
            <Dialog style={{ zIndex: '1' }}
                open={this.state.open}
                classes={{ paper: classes.dialogPaper }}
                // disableBackdropClick={true}
                onClose={this.handleDialogCancel.bind(this)}
                aria-labelledby="form-dialog-title"                >
                {!showLoader ? <div>
                    <DialogTitle
                        style={{ background: '#05073a', textAlign: 'center', height: '50px' }}
                        id="form-dialog-title">
                        <p style={{ color: '#fff', marginTop: '-10px', fontFamily: 'Lato', fontSize: '18px' }}>
                            Edit Order</p>
                    </DialogTitle>
                    <DialogContent>

                        <div >
                            <span style={{ lineHeight: "40px", fontFamily: "lato", fontSize: "17px" }}>Enable / disable order</span>
                            <Switch
                                classes={{ root: classes.muiSwitchroot }}
                                checked={orderPayload.active}
                                onChange={this.handleStateChange.bind(this, "active")}
                                value={orderPayload.active}
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />

                        </div>

                        <div style={{ display: "flex" }}>

                            <div style={{ width: "49%", lineHeight: "40px", fontFamily: "lato", fontSize: "17px" }}>
                                Order Date
                        </div>

                            <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ width: "49%" }} >
                                <div style={{ width: "49%" }} >
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="actual_dispatch_date"
                                        format="dd-MMM-yyyy"
                                        style={{ width: '100%' }}
                                        value={orderPayload["actual_dispatch_date"]}
                                        maxDate={new Date()}
                                        onChange={(dateval) => {
                                            this.handelDateChange(dateval);
                                        }}

                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </div>

                        <div style={{ display: "flex" }}>
                            {/* {getAccessAccordingToRole('ShiftingMergingOrderPayment') ?
                                <div style={{ borderBottom: errorFields["buyerid"] ? "2px solid red" : "1px solid gray", width: "49%" }}>
                                    <AsyncSelect
                                        cacheOptions
                                        value={buyerid}
                                        id={"reactSelectCustom"}
                                        name={"buyerid"}
                                        // onChange={( item )=>{ this.setState({ buyerid : item  })}}
                                        onChange={(item) => {
                                            console.log(item)
                                            console.log(tempVar)
                                            this.setState({ buyerid: item }, function () {
                                                var data = orderPayload;
                                                if (errorFields["buyerid"]) {
                                                    delete errorFields["buyerid"];
                                                }
                                                if (item && item !== null) {
                                                    data["buyerid"] = tempVar[item["value"]]["id"];
                                                    data["buyer_mobile"] = tempVar[item["value"]]["mobile"];
                                                } else {
                                                    data["buyerid"] = "";
                                                    data["buyer_mobile"] = "";
                                                }
                                                this.setState({ orderPayload: data, errorFields: errorFields, isOrderShifted: true })
                                            })
                                        }}
                                        isSearchable={true}
                                        isClearable={true}
                                        placeholder={`Select buyer..`}
                                        defaultOptions={[]}
                                        components={{ Option: CustomOption }}
                                        loadOptions={this.getOptions.bind(this, "buyerid")}
                                    />
                                </div> : */}
                                <TextField
                                    margin="dense"
                                    id="Buyer_name"
                                    label="Buyer name"
                                    type="text"
                                    disabled={true}
                                    style={{ width: '49%' }}
                                    value={orderPayload.buyer_name}
                                    onChange={() => { }}
                                    fullWidth />
                             {/* } */}

                            &nbsp;
                            &nbsp;

                            {/* {getAccessAccordingToRole('ShiftingMergingOrderPayment') ?
                                <div style={{ borderBottom: errorFields["supplierid"] ? "2px solid red" : "1px solid gray", width: "49%" }}>
                                    <AsyncSelect
                                        cacheOptions
                                        value={supplierid}
                                        id={"reactSelectCustom"}
                                        name={"supplierid"}
                                        onChange={(item) => {
                                            this.setState({ supplierid: item }, function () {
                                                if (errorFields["supplierid"]) {
                                                    delete errorFields["supplierid"];
                                                }
                                                var data = orderPayload;
                                                if (item && item !== null) {
                                                    data["supplierid"] = tempVar[item["value"]]["id"];
                                                    data["supplier_mobile"] = tempVar[item["value"]]["mobile"];
                                                    data["source_location"] = tempVar[item["value"]]["locality"]
                                                } else {
                                                    data["supplierid"] = "";
                                                    data["supplier_mobile"] = "";
                                                }
                                                this.setState({ orderPayload: data, errorFields: errorFields, isOrderShifted: true })
                                            })
                                        }}
                                        isSearchable={true}
                                        isClearable={true}
                                        placeholder={`Select supplier..`}
                                        defaultOptions={[]}
                                        components={{ Option: CustomOption }}
                                        loadOptions={this.getOptions.bind(this, "supplierid")} />
                                </div> : */}
                                <TextField
                                    margin="dense"
                                    id="supplier_name"
                                    label="supplier name"
                                    type="text"
                                    disabled={true}
                                    style={{ width: '49%' }}
                                    value={orderPayload.supplier_name}
                                    onChange={() => { }}
                                    fullWidth />
                                    {/* } */}
                        </div>

                        <div style={{ display: "flex" }}>

                            <TextField
                                select
                                id="creator_role"
                                name="creator_role"
                                label="Creater Role"
                                error={errorFields["creator_role"] ? true : false}
                                type="text"
                                style={{ width: '49%', marginTop: '5px' }}
                                value={orderPayload.creator_role}
                                onChange={this.handleInputChange.bind(this)}>
                                {["la", "ca"].map((key, i) => (
                                    <MenuItem key={i} value={key} selected={true}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </TextField>
                            &nbsp;
                            &nbsp;
                        <TextField
                                select
                                id="commodity"
                                error={errorFields["commodity"] ? true : false}
                                name="commodity"
                                label="Commodity"
                                type="text"
                                style={{ width: '49%', marginTop: '1%' }}
                                value={orderPayload.commodity && orderPayload.commodity !== null ? [orderPayload.commodity] : []} // sql
                                onChange={this.handleInputChange.bind(this)}>
                                {commodityList["options"].sort().map((key, i) => (
                                    <MenuItem key={i} value={key} selected={true}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>

                        <div style={{ display: "flex" }} >

                            <TextField
                                margin="dense"
                                id="source_location"
                                label="Source Location"
                                type="text"
                                error={errorFields["source_location"] ? true : false}
                                style={{ width: '49%' }}
                                value={orderPayload.source_location}
                                onChange={this.handleInputChange.bind(this)}
                                fullWidth />
                            &nbsp;
                            &nbsp;
<TextField
                                margin="dense"
                                id="target_location"
                                label="Target Location"
                                error={errorFields["target_location"] ? true : false}
                                type="text"
                                style={{ width: '49%' }}
                                value={orderPayload.target_location}
                                onChange={this.handleInputChange.bind(this)}
                                fullWidth />
                        </div>
                        <div style={{ display: "flex" }} >


                            <TextField
                                margin="dense"
                                id="bijak_amt"
                                label="Bijak Amount"
                                type="text"
                                error={errorFields["bijak_amt"] ? true : false}
                                style={{ width: '49%' }}
                                value={orderPayload.bijak_amt}
                                onChange={this.handleInputChange.bind(this)}
                                fullWidth />

                            &nbsp;
                          &nbsp;

                          <TextField
                                select
                                id="type"
                                name="type"
                                label="Type"
                                error={errorFields["type"] ? true : false}
                                type="text"
                                style={{ width: '49%', marginTop: '5px' }}
                                value={orderPayload.type}
                                onChange={this.handleInputChange.bind(this)}>
                                {["bilti", "commission"].map((key, i) => (
                                    <MenuItem key={i} value={key} selected={true}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </div>

                        <div style={{ display: "flex", marginTop: 4 }} >

                            <TextField
                                margin="dense"
                                id="qnt"
                                label="Quantity"
                                error={errorFields["qnt"] ? true : false}
                                type="text"
                                style={{ width: '49%' }}
                                value={orderPayload.qnt}
                                onChange={this.handleInputChange.bind(this)}
                                fullWidth />

                            &nbsp;
                                                    &nbsp;
                            <TextField
                                select
                                id="unit"
                                error={errorFields["unit"] ? true : false}
                                name="unit"
                                label="Unit"
                                type="text"
                                style={{ width: '49%', marginTop: '5px' }}
                                value={orderPayload.unit}
                                onChange={this.handleInputChange.bind(this)}>
                                {["kg", "quintal", "ton", "packet", "crate", "box", "pc"].map((key, i) => (
                                    <MenuItem key={i} value={key} selected={true}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </TextField>


                        </div>

                        <div style={{ display: "flex" }} >
                            <TextField
                                margin="dense"
                                id="rate"
                                label="Rate"
                                type="text"
                                error={errorFields["rate"] ? true : false}
                                style={{ width: '49%' }}
                                value={orderPayload.rate}
                                onChange={this.handleInputChange.bind(this)}
                                fullWidth />
                            &nbsp;
                          &nbsp;
                          <TextField
                                select
                                id="rate_unit"
                                name="rate_unit"
                                label="Rate Unit"
                                error={errorFields["rate_unit"] ? true : false}
                                type="text"
                                style={{ width: '49%', marginTop: '5px' }}
                                value={orderPayload.rate_unit}
                                onChange={this.handleInputChange.bind(this)}>
                                {["Rs/Kg", "Rs/Quintal", "Rs/Ton", "Rs/Packet", "Rs/Crate", "Rs/Box", "Rs/Pc"].map((key, i) => (
                                    <MenuItem key={i} value={key} selected={true}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </div>
                        <div style={{ display: "flex" }} >
                            <TextField
                                margin="dense"
                                id="transport_info"
                                label="Transport info"
                                error={errorFields["transport_info"] ? true : false}
                                type="text"
                                style={{ width: '49%' }}
                                value={orderPayload.transport_info}
                                onChange={this.handleInputChange.bind(this)}
                                fullWidth />
                            &nbsp;
                    &nbsp;
                            <TextField
                                margin="dense"
                                id="remark"
                                error={errorFields["remark"] ? true : false}
                                label="Remarks"
                                type="text"
                                style={{ width: '49%' }}
                                value={orderPayload.remark}
                                onChange={this.handleInputChange.bind(this)}
                                fullWidth />
                        </div>

                        {/*--------------- newly Added---------------- */}
                        <div style={{ display: "flex" }} >

                            <TextField
                                margin="dense"
                                id="broker_mobile"
                                error={errorFields["broker_mobile"] ? true : false}
                                label="Broker Mobile"
                                type="text"
                                style={{ width: '49%' }}
                                value={orderPayload.broker_mobile}
                                onChange={this.handleInputChange.bind(this)}
                                fullWidth />
                            &nbsp;
                        &nbsp;
                        <TextField
                                margin="dense"
                                id="bijak_total_amount"
                                label="Commerce Value"
                                error={errorFields["bijak_total_amount"] ? true : false}
                                type="text"
                                style={{ width: '49%' }}
                                value={orderPayload.bijak_total_amount}
                                onChange={this.handleInputChange.bind(this)}
                                fullWidth />
                        </div>

                        <div style={{ display: "flex" }} >

                            <TextField
                                margin="dense"
                                id="invoice_no"
                                error={errorFields["invoice_no"] ? true : false}
                                label="Invoice No."
                                type="text"
                                style={{ width: '49%' }}
                                value={orderPayload.invoice_no}
                                onChange={this.handleInputChange.bind(this)}
                                fullWidth />
                            &nbsp;
                        &nbsp;
                        <TextField
                                margin="dense"
                                id="old_system_order_id"
                                label="Old System Order id"
                                error={errorFields["old_system_order_id"] ? true : false}
                                type="text"
                                style={{ width: '49%' }}
                                value={orderPayload.old_system_order_id}
                                onChange={this.handleInputChange.bind(this)}
                                fullWidth />
                        </div>

                        <div style={{ display: "flex" }} >

                            <TextField
                                margin="dense"
                                id="pkt"
                                error={errorFields["pkt"] ? true : false}
                                label="Pkt"
                                type="text"
                                style={{ width: '49%' }}
                                value={orderPayload.pkt}
                                onChange={this.handleInputChange.bind(this)}
                                fullWidth />
                            &nbsp;
                            &nbsp;
                            <TextField
                                margin="dense"
                                id="brokerage"
                                label="brokerage"
                                error={errorFields["brokerage"] ? true : false}
                                type="text"
                                style={{ width: '49%' }}
                                value={orderPayload.brokerage}
                                onChange={this.handleInputChange.bind(this)}
                                fullWidth />
                        </div>

                        <div style={{ display: "flex" }} >
                            <TextField
                                margin="dense"
                                id="unsettled_amount_pltf"
                                error={errorFields["unsettled_amount_pltf"] ? true : false}
                                label="Unsettled Amount Pltf"
                                type="text"
                                style={{ width: '100%' }}
                                value={orderPayload.unsettled_amount_pltf}
                                editable = {false}
                                disabled = {true}
                                onChange={this.handleInputChange.bind(this)}
                                fullWidth />
                        </div>


                        {/*--------------- newly Added ends---------------- */}
                        <div style={{ display: "flex", paddingTop: "5px" }} >
                            <Autocomplete
                                multiple
                                id="fixed-demo"
                                options={tagsOption}
                                value={orderPayload.tags && orderPayload.tags !== null ? orderPayload.tags : []}
                                getOptionLabel={e => e}
                                onChange={this.handelTagsChanges}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                style={{ width: "98%" }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Select Tags"
                                        placeholder="Search"
                                        fullWidth
                                    />
                                )}
                            />
                        </div>

                        <div style={{ display: "flex", paddingTop: "5px" }} >
                            <Autocomplete
                                id="fixed-demo2"
                                options={orderTypeOptions}
                                value={orderPayload.order_type && orderPayload.order_type !== null ? orderPayload.order_type : ""}
                                getOptionLabel={e => e}
                                onChange={this.handelOptionTypeChanges}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                style={{ width: "98%" }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Select order type"
                                        placeholder="Search"
                                        fullWidth
                                    />
                                )}
                            />
                        </div>


                        {this.state.attachmentArray && this.state.attachmentArray.length !== 0 &&
                            <div style={{ fontFamily: "lato", padding: "10px" }}>
                                Uploaded Images
                        </div>}
                        <div style={{ display: "flex" }}>
                            {(this.state.attachmentArray && this.state.attachmentArray.length !== 0) && this.state.attachmentArray.map((keyObj, i) => (
                                // <div key={"imhs_" + i} style={{ width: "150px", marginLeft: "5px", boxShadow: " 0px 0px 10px 0px rgba(0,0,0,0.75)" }} >
                                //     <img src={key} alt={key} height="150px" />
                                // </div>
                                <div key={"imhs_" + i} className="transaction-supporting-image">
                                    <img src={keyObj["image_url"]} style={{ cursor: "zoom-in" }}
                                        onError={(e) => { e.target.onerror = null; e.target.src = "https://bijakteaminternal-userfiles-mobilehub-429986086.s3.ap-south-1.amazonaws.com/public/no_data_found.png" }}
                                        onClick={() => window.open(keyObj["image_url"], "_blank")} alt={keyObj["image_url"]} height="150px" width="150px" />
                                    <div className="transaction-delete-icon" onClick={this.deleteItem.bind(this, keyObj.key)}>
                                        <i className="fa fa-trash fa-lg"></i>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {(this.state.attachmentArray && this.state.attachmentArray.length === 0) &&
                            <div style={{ fontFamily: "lato", padding: "10px" }}>
                                No supporting images uploaded yet. you can add by clicking below
                        </div>}
                        <div >
                            <Grid container direction="row" alignItems="stretch">
                                <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'left', margin: "11px 0px 5px 0px", marginBottom: 5 }}>
                                    <input
                                        className={classes.input}
                                        id="flat-button2-file"
                                        type="file"
                                        style={{ width: "50% !important" }}
                                        onClick={(event) => {
                                            event.target.value = null
                                        }}
                                        accept="image/*"
                                        onChange={this.fileChangedHandler.bind(this)}
                                    />
                                    <label htmlFor="flat-button2-file">
                                        <Button component="span" style={{ border: '1px solid #d5d2d2', padding: '5px 10px', fontSize: 12, backgroundColor: '#dbdbdb' }}  >
                                            change/add supporting images
                            </Button>
                                    </label>
                                </Grid>
                            </Grid>
                        </div>

                    </DialogContent>
                    {this.state.showErrorMsg &&
                        <div style={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: "12px",
                            color: "red",
                            textAlign: "right",
                            paddingRight: "10px"
                        }}
                        > Please fill the mandatory fields highlighted</div>}
                    <DialogActions>
                        <Button className={classes.formCancelBtn} onClick={this.updateOrder.bind(this)} color="primary">Update</Button>
                        <Button className={classes.formCancelBtn} onClick={this.handleDialogCancel.bind(this)} color="primary">Cancel</Button>
                    </DialogActions>
                </div> :
                    <Loader primaryText="Please wait.." />}

                {showSweetAlert &&
                    <SweetAlertPage
                        show={true}
                        type={sweetAlertData.type}
                        title={sweetAlertData.title}
                        text={sweetAlertData.text}
                        sweetAlertClose={() => this.handelSweetAlertClosed()}
                    />}

            </Dialog>
        </div>
        );
    }
}

EditOrderDataModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EditOrderDataModal);