import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FilterGroupComponent from './components/FilterGroupComponent';
import GroupUserTable from './components/GroupUserTable';
import FilterUserComponent from './components/FilterUserComponent';
import GroupMetaInfo from './components/GroupMetaInfo';
import GroupSubUserTable from './components/GroupSubUserTable';
import GroupUserMetaInfo from './components/GroupUserMetaInfo';
import CreateGroupModal from './components/CreateGroupModal';

const styles = theme => ({
    root: {
        width: '100%',
        overflow: 'auto',
        fontFamily: 'Lato !important',
        maxWidth: '1200px',
        minHeight: '80vh',
    },
    card: {
        maxWidth: '100%',
        minHeight: '80vh',
        paddingTop: "2%",
        marginTop: '15px',
        height: '97%',
    },
    defaultSpan: { display: 'grid', fontSize: '25px' },

});


class GroupUserContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },
            userOne: "",
            userTwo: "",
            showConfirmDialog: false,
            dialogText: "",
            dialogTitle: "  Confirm Grouping ",

            // new Variable 
            selectedTab: "all",
            selectedUser: {},
            selectedGroupData: {},
            showCreateModal: false,
            refreshData: false,
            isGroupTableLoading: false,
            isGroupDetailsEdited: false,

            isUserDetailsEdited: false
        }
    }

    handelTabChange(type) {
        console.log(type)
        this.setState({ selectedTab: type, isUserDetailsEdited: !this.state.isUserDetailsEdited })
    }

    OnUserSelected(userData) {
        this.setState({ selectedUser: userData });
    }

    handelCreateModalOpen(event) {
        this.setState({ showCreateModal: true })
    }

    onDataAttachedOrDetached(updatedData) {
        this.setState({ selectedUser: updatedData });
    }

    onUserDetailsEdited() {
        this.setState({ isUserDetailsEdited: !this.state.isUserDetailsEdited });
    }

    onGroupCreatedSuccess() {
        if (this.state.selectedTab === "all") {
            this.setState({ isUserDetailsEdited: !this.state.isUserDetailsEdited });
        } else {
            this.setState({ isGroupDetailsEdited: !this.state.isGroupDetailsEdited })
        }
        this.setState({ showCreateModal: false });
    }

    render() {
        const { classes } = this.props;
        const { selectedTab, selectedUser, selectedGroupData } = this.state;
        console.log(selectedUser)
        return (
            <div className={classes.root}>
                <Paper className={classes.card} >

                    <div style={{ paddingTop: "6px", textAlign: "center", marginBottom: '2.2%' }}>
                        <span>
                            {selectedTab === "all" &&
                                <i className="fa fa-caret-left translabelIcon" style={{ marginRight: "-0.75px" }} aria-hidden="true"></i>}
                            <span
                                onClick={() => this.handelTabChange("all")}
                                className=" translabeltag  labeltag"
                                style={{ cursor: "pointer", color: "#fff", padding: "10px", background: selectedTab === "all" ? "#60c1d8" : "#1d6b7d" }}>
                                All User
                                </span>
                        </span>
                        <span style={{ paddingLeft: "15px" }} >
                            {selectedTab === "groups" &&
                                <i className="fa fa-caret-left translabelIcon" style={{ marginRight: "-0.75px" }} aria-hidden="true"></i>}
                            <span
                                onClick={() => this.handelTabChange("groups")}
                                className=" translabeltag  labeltag"
                                style={{ cursor: "pointer", color: "#fff", padding: "10px", background: selectedTab === "groups" ? "#60c1d8" : "#1d6b7d" }}>
                                Groups
                                </span>
                        </span>
                    </div>


                    {selectedTab === "all" &&
                        <div>
                            <FilterUserComponent
                                selectedUser={selectedUser}
                                // refreshData = { this.state.refreshData}
                                isUserDetailsEdited={this.state.isUserDetailsEdited}
                                onUserSelected={(uData) => this.OnUserSelected(uData)} />

                            {/* User Group Meta info  */}
                            {selectedUser && Object.keys(selectedUser).length !== 0 &&
                                <GroupUserMetaInfo
                                    selectedUser={selectedUser} />}
                            {/* Default state of Search */}
                            {selectedUser && Object.keys(selectedUser).length === 0 &&
                                <div style={{ padding: "10%" }}>
                                    <span className={classes.defaultSpan}>
                                        <i className={classes.defaultIcon + " fa fa-search"} aria-hidden="true"></i>
                                        {"Search for user by name to check details"} </span>
                                </div>}

                            {/* User Table info  */}
                            {selectedUser && Object.keys(selectedUser).length !== 0 &&
                                <GroupUserTable
                                    selectedTab={selectedTab}
                                    onDataAttachedOrDetached={(data) => this.onDataAttachedOrDetached(data)}
                                    onUserDetailsEdited={() => this.onUserDetailsEdited()}
                                    tableData={[selectedUser]}
                                />}
                        </div>}

                    {selectedTab === "groups" &&
                        <div>
                            <FilterGroupComponent
                                defaultData={this.state.selectedGroupData}
                                isGroupDetailsEdited={this.state.isGroupDetailsEdited}
                                onGroupSelected={(data) => { console.log(data); this.setState({ selectedGroupData: data, isGroupTableLoading: false }); }}
                                groupDataLoading={(data) => { this.setState({ selectedGroupData: data, isGroupTableLoading: true }); }}
                                getSearchedOrderListData={(data) => { this.setState({ selectedGroupData: data, isGroupTableLoading: false }); }} />

                            {selectedGroupData && Object.keys(selectedGroupData).length === 0 &&
                                <div style={{ padding: "10%" }}>
                                    <span className={classes.defaultSpan}>
                                        <i className={classes.defaultIcon + " fa fa-search"} aria-hidden="true"></i>
                                        {"Search for a group by name to check details"} </span>
                                </div>}

                            {/* User Group Meta info  */}
                            {selectedGroupData && selectedGroupData["groupData"] && Object.keys(selectedGroupData["groupData"]).length !== 0 &&
                                <GroupMetaInfo
                                    onGroupDeleted={() => this.setState({ selectedGroupData: {} })}
                                    OnGroupDetailsEdited={() => this.setState({ isGroupDetailsEdited: !this.state.isGroupDetailsEdited })}
                                    onUserAttached={() => this.setState({ isGroupDetailsEdited: !this.state.isGroupDetailsEdited })}
                                    selectedUser={selectedGroupData["groupData"]} />}

                            {selectedGroupData && selectedGroupData["subUserDetails"] && Object.keys(selectedGroupData["subUserDetails"]).length !== 0 &&
                                <GroupSubUserTable
                                    selectedTab={selectedTab}
                                    showLoader={this.state.isGroupTableLoading}
                                    onUserDettached={() => this.setState({ isGroupDetailsEdited: !this.state.isGroupDetailsEdited })}
                                    tableData={selectedGroupData["subUserDetails"]}
                                />}
                        </div>}

                    {<div className="updateBtndef" style={{ zIndex: "9" }}>
                        <div className="updateBtnFixed" style={{ display: 'flex', zIndex: "99999" }}
                            onClick={this.handelCreateModalOpen.bind(this)}>
                            <i className="fa fa-plus-circle add-icon" aria-hidden="true"></i>
                            <p style={{
                                fontSize: "14px",
                                fontFamily: "lato",
                                fontWeight: 600
                            }}>Add Group</p></div>
                    </div>}
                    {this.state.showCreateModal &&
                        <CreateGroupModal
                            open={this.state.showCreateModal}
                            onGroupCreated={(event) => this.onGroupCreatedSuccess()}
                            onAddModalCancel={(event) => this.setState({ showCreateModal: false })}
                        />}
                </Paper>
            </div>
        );
    }
}
GroupUserContainer.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(GroupUserContainer);