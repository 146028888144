import Api from '../../config/dev';
import Utils from '../common/utils';


let orderService = {

    getOrderListData: async function ( params ) {
        return Api({
            method: 'get',
            headers: {
                "Authorization": Utils.getToken()
            },
            url: '/order/detail/user',
            params: params
        },1);
    },
// /https://1ueogcah0b.execute-api.ap-south-1.amazonaws.com/dev/order/detail
    addNewOrder : async function ( subId, payload ) {
        return Api({
            method: 'post',
            headers: {
                "Authorization": Utils.getToken(),
                "subid" : subId
            },
            url: '/order/detail',
            data : payload
        },4);
    },

    // https://1ueogcah0b.execute-api.ap-south-1.amazonaws.com/dev/order/detail/7
    updateExistingOrder : async function ( subid, id, payload ) {
        if(payload.id){
            delete payload.id;
        }
        return Api({
            method: 'post',
            headers: {
                "Authorization": Utils.getToken(),
                "subid": subid
            },
            url: 'order/detail/'+id,
            data : payload
        },4);
    },
    uploadOrder : async function ( payload ) {
        return Api({
            method: 'post',
            headers: {
                "Authorization": Utils.getToken()
            },
            url: 'order/bulkadd',
            data : payload
        },2);
    },
    getOrderAcount : async function ( mobile ) {
//  https://f51qgytp3d.execute-api.ap-south-1.amazonaws.com/dev/getBankDetails/{mobile}
        return Api({
            method: 'get',
            headers: {
                "Authorization": Utils.getToken()
            },
            url: '/getBankDetails/'+mobile
        },2);
    },
    
    getAvailableCredit : async function ( payload ) {
        //https://f51qgytp3d.execute-api.ap-south-1.amazonaws.com/dev/payment/getAvailableCredit
                return Api({
                    method: 'post',
                    headers: {
                        "Authorization": Utils.getToken()
                    },
                    url: "/payment/getAvailableCredit",
                    data: payload
                },2);
            },
    getOrderDataById : async function ( id ){
        // https://f9ol52l7gl.execute-api.ap-south-1.amazonaws.com/dev/order/detail/{id}
        return Api({
            method: 'get',
            headers: {
                "Authorization": Utils.getToken()
            },
            url: "order/detail/"+id,
        },4);
    },
    getOrderDataByLinkedOrderId : async function ( id , param ){
        // https://f51qgytp3d.execute-api.ap-south-1.amazonaws.com/dev/payment/linkedorderdetail/{id}
        return Api({
            method: 'get',
            headers: {
                "Authorization": Utils.getToken()
            },
            url: "/payment/linkedorderdetail/"+id,
            params : param
        },2);
    }
}

export default orderService;