import React from 'react';
import * as zlib from "react-zlib-js";
import { getAccessAccordingToRole } from '../../config/appConfig';
import userAuth from '../../config/authActions';
var moment = require('moment');

// to vget the current dateno
let notificationDataArray = [];
let districtData = {};
let stateList = [
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Ladakh",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "National Capital Territory of Delhi",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal"
];
const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

function getCurrentDate(data) {
    // DD-MM-YYY
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    return dd + "-" + mm + "-" + yyyy;
}

// to convert iso date to dd-mm-yyyy
function formateDate(date) {
    date = new Date(date);
    return date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
}

function getCurrentTime() {
    var d = new Date();
    var n = d.getHours();
    // var m = d.getMinutes();
    return n + "" + d;
}

function getToken() {
    // let bijak_token = window.sessionStorage.getItem('bijak_token', null) ? window.sessionStorage.getItem('bijak_token') : null;
    // return "Bearer " + bijak_token;
    return "Bearer " + userAuth.getToken();
}

function getDistrictData() {
    return districtData;
}

function setDistrictData(data) {
    districtData = data;
}


function getStateData() {
    return stateList;
}

function formatDateData(apiDate) {
    try {
        var formatedDate = new Date(apiDate);
        formatedDate = formatedDate.getDate() + "-" + months[formatedDate.getMonth()] + "-" + formatedDate.getFullYear();
        return formatedDate;
    } catch (err) {
        console.log(err);
        return apiDate;
    }
}

function downloadDataInCSV(json, filename) {
    try {
        var csv = "";
        var keys = (json[0] && Object.keys(json[0])) || [];
        csv += keys.join(',') + '\n';
        for (let line of json) {
            csv += keys.map(key => line[key]).join(',') + '\n';
        }
        // console.log(csv);
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + csv;
        hiddenElement.target = '_blank';
        hiddenElement.download = (filename + ".csv");
        hiddenElement.click();
        //   }

    } catch (err) {
        console.log(err);
    }
}

function downloadFormattedDataInCSV(json, filename, keysInFile) {
    try {
        var csv = "";
        var keys = (keysInFile && Object.keys(keysInFile)) || [];
        var values = (keysInFile && Object.values(keysInFile)) || [];
        csv += values.join(',') + '\n';
        for (let line of json) {
            csv += keys.map(key => {
                // console.log( key )
                // console.log( line[key]  )
                // console.log( typeof(line[key] )  )
                // console.log(typeof(line[key] ) === "string" &&  line[key].indexOf(",") > -1  )
                if (line[key] && typeof (line[key]) === "object" && line[key].length > 0) {
                    let fstr = line[key].toString();
                    fstr = fstr.replace(/,/g, "|");
                    return fstr;
                }

                if (line[key] && typeof (line[key]) === "string" && line[key].indexOf(",") > -1) {
                    let fArry = [];
                    fArry.push(line[key].replace(/,/g, "|"));
                    return fArry;
                }

                if (line[key] === null || line[key] === "null") {
                    return "";
                }

                return line[key]
            }).join(',') + '\n';
        }
        // console.log(csv);
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + csv;
        hiddenElement.target = '_blank';
        hiddenElement.download = (filename + ".csv");
        // console.log(csv);
        hiddenElement.click();
    } catch (err) {
        console.log(err);
    }
}


function formatDownloadDataInCSVThroughApi(json, filename) {
    try {
        var csv = "";
        var keys = (json[0] && Object.keys(json[0])) || [];
        csv += keys.join(',') + '\n';
        for (let line of json) {
            csv += keys.map(key => {
                // console.log( key )
                // console.log( line[key]  )
                // console.log( typeof(line[key] )  )
                // console.log(typeof(line[key] ) === "string" &&  line[key].indexOf(",") > -1  )
                if (line[key] && typeof (line[key]) === "object" && line[key].length > 0) {
                    let fstr = line[key].toString();
                    fstr = fstr.replace(/,/g, "|");
                    return fstr;
                }

                if (line[key] && typeof (line[key]) === "string" && line[key].indexOf(",") > -1) {
                    let fArry = [];
                    fArry.push(line[key].replace(/,/g, "|"));
                    return fArry;
                }

                return line[key]
            }).join(',') + '\n';
        }
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + csv;
        hiddenElement.target = '_blank';
        hiddenElement.download = (filename + ".csv");
        hiddenElement.click();
        //   }

    } catch (err) {
        console.log(err);
    }
}


function formatNumberWithComma(x) {
    try {
        let rplaceString = "";
        x = x.toString();
        if (x.indexOf("-") > -1) {
            x = x.replace("-", "");
            rplaceString = "-";
        }

        let cNum = x;
        let y = "";
        if (x.indexOf(".") > -1) {
            x = cNum.split(".")[0];
            y = "." + cNum.split(".")[1];
        }
        var lastThree = x.substring(x.length - 3);
        var otherNumbers = x.substring(0, x.length - 3);
        if (otherNumbers !== '')
            lastThree = ',' + lastThree;
        var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + y;
        return rplaceString + "" + res;
    } catch (err) {
        console.log(err);
        return x;
    }
}

function getImageName(fileName) {
    try {
        let fileExt = fileName.split('.').pop();
        let filenameWithoutSpace = fileName.split(' ').join('').replace(`.${fileExt}`, "");
        let epocTime = (new Date()).getTime();
        return filenameWithoutSpace.toLowerCase() + "_" + epocTime + "." + fileExt.toLowerCase();

    } catch (err) {
        return fileName;
    }
}

function checkIfValidImageFile(fileName) {
    let fileExt = fileName.split('.').pop();
    let fileTypeAllowed = ["gif", "png", "bmp", "jpeg", "jpg", "svg"];
    if (fileTypeAllowed.indexOf(fileExt.toLowerCase()) > -1) {
        return true;
    } else {
        return false;
    }

}

function maskMobileNumber(mobNum) {

    if (getAccessAccordingToRole("ViewMobileNumber")) { // if true then show mobile number
        return mobNum;
    } else {
        if (mobNum) {
            var lastFive = mobNum.substr(mobNum.length - 4);
            var lastChar = mobNum.slice(0, mobNum.length - 4);
            var fstr = lastChar.replace(/\d/g, "X");
            // console.log("----->" + fstr + lastFive);
            return fstr + lastFive;
        } else {
            return mobNum;
        }
    }
}

function decryptResponse(input) {
    var inflated = zlib.inflateSync(new Buffer(input, 'base64')).toString();
    return JSON.parse(inflated);
}


// to get the key value mapping
function getCommodityNamesArrayKeysMap(data) {

    var listObj = { options: [], optionN_E: {}, optionE_N: {} }
    try {
        if (data) {
            for (var i = 0; i < data.length; i++) {
                if (data[i]["name"] && data[i]["name_en"]) {
                    listObj["options"].push(data[i]["name_en"]);
                    listObj["optionN_E"][data[i]["name"]] = data[i]["name_en"];
                    listObj["optionE_N"][data[i]["name_en"]] = data[i]["name"];
                }
            }
        }
        return listObj;
    } catch (err) {
        console.log(err);
        return listObj;
    }
}

function displayReadableDateTime(dateval) {
    try {
        var fdate = moment.utc(new Date(dateval)).format('DD-MMM-YYYY hh:mm A')
        return fdate;
    } catch (err) {
        console.log(err);
        return dateval;
    }
}

function formatDateAndTimeForDisplay(dateval) {
    var fdate = moment.utc(new Date(dateval)).format('DD-MMM-YYYY hh:mm A')
    // return <div style={{ width: "95px", display: "inline-block" }}> {fdate.split(" ")[0]}</div>
    return <div style={{ width: "95px", display: "inline-block" }}> {fdate.split(" ")[0] + " \n" + fdate.split(" ")[1] + " " + fdate.split(" ")[2]}</div>
}

function getArrowIconType(sortKeys, columnName) {
    let iconClass = 'fa fa-arrow-';
    if (sortKeys.hasOwnProperty(columnName)) {
        iconClass += sortKeys[columnName] === 'asc' ? 'down' : 'up';
    } else {
        iconClass = iconClass + 'down';
    }
    return iconClass;
}

function getIconOnType(type) {
    try {
        if (type === "user") {
            return <i className="fa fa-user" aria-hidden="true" style={{ color: "rgb(71, 125, 227)", fontSize: "34px" }}></i>
        } else if (type === "order") {
            return <i className="fa fa-shopping-cart" aria-hidden="true" style={{ color: 'rgba(78, 36, 0, 1)', fontSize: "34px" }}></i>
        } else if (type === "payment") {
            return <i className="fa fa-credit-card" aria-hidden="true" style={{ color: '#02a735', fontSize: "34px" }}></i>;
        }
    } catch (err) {
        return "";
    }
}

function getIconOnTypeText(type) {
    try {
        if (type === "user") {
            return `<i class="fa fa-user" aria-hidden="true" style="color:rgb(71, 125, 227); font-size: 34px;margin-left: 6px;"></i>`;
        } else if (type === "order") {
            return `<i class="fa fa-shopping-cart" aria-hidden="true" style="color:rgba(78, 36, 0, 1); font-size: 34px;"></i>`;
        } else if (type === "payment") {
            return `<i class="fa fa-credit-card" aria-hidden="true" style="color:#02a735;font-size:34px;"></i>`;
        }
    } catch (err) {
        return "";
    }
}

function isValidNarration(str) {
    var letterNumber = /^[0-9a-zA-Z\s]+$/;
    if (str.match(letterNumber) && str.length > 0 && str.length <= 30) {
        return true;
    } else {
        return false;
    }
}

function ifDataHasId(data, id) {
    return data.some(function (el) {
        return el.id === id;
    });
}
function getIndexOfTheID(data, val) {
    let index = data.findIndex(function (item, i) {
        return item['id'] === val
    });
    return index;
}

function setNotificationData(data) {
    notificationDataArray = data;
}

function getNotificationData() {
    return notificationDataArray;
}

function getNotificationDataLength() {
    return notificationDataArray.length;
}

function checkIfPrimaryKey(data) {
    try {
        if (data && data.hasOwnProperty('is_primary')) {
            if (data['is_primary'] && data['is_primary'] !== 'false') {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch (err) {
        console.log(err);
    }
    // data[i].hasOwnProperty("is_primary") ? ((data[i]["is_primary"] && data[i]["is_primary"] !== 'false') || data[i]["is_primary"] === 'true' ? true : false) : false, 
}

function validateEmail(emailId) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailId)) //eslint-disable-line
    {
        return (true)
    }
    return (false)
}


const Utils = {

    getToken,
    getCurrentDate,
    formateDate,
    getCurrentTime,
    getDistrictData,
    setDistrictData,
    getStateData,
    formatDateData,
    downloadDataInCSV,
    downloadFormattedDataInCSV,
    formatNumberWithComma,
    getImageName,
    checkIfValidImageFile,
    maskMobileNumber,
    decryptResponse,
    formatDownloadDataInCSVThroughApi,
    getCommodityNamesArrayKeysMap,

    displayReadableDateTime,
    formatDateAndTimeForDisplay,

    getArrowIconType,
    getIconOnType,

    isValidNarration,

    ifDataHasId,
    getIndexOfTheID,

    setNotificationData,
    getNotificationData,
    getNotificationDataLength,
    getIconOnTypeText,
    checkIfPrimaryKey,
    validateEmail

}

export default Utils;


