import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import logsService from '../../../app/logsService/logsService';
import Loader from '../../common/Loader';


const styles = theme => ({
    dialogPaper: {
        minWidth: '40%',
        maxWidth: '50%',
        // minHeight: '700px',
        // maxHeight: '500px'
    },
    appBar: {
        position: 'relative',
        background: "#05073a",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        fontSize: "20px !important",
        fontFamily: "lato !important",
        fontWeight: 500
    },
    closeBtn: {
        fontSize: "15px !important",
        fontFamily: "lato !important",
        fontWeight: 500
    },
    tableCell: {
        paddingLeft: '4px',
        paddingRight: '4px',
        textAlign: 'center',
        maxWidth: '200px'
    },
    formControl: {
        color: "#fff"
    },
    dataHeader: {
        width: "20%"
    },
    lightTooltip: {
        fontSize: '15px',
        fontWeight: 500,
        maxWidth: 'none',
    },
    keyText: {
        fontSize: "15px !important",
        fontFamily: "lato !important",
        fontWeight: 500
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// const statusOption = ["approved", "failed"];


class LogsInfoModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            showLoader: false,
            eventDialogData: this.props.eventDialogData || [],
            eventResponseData: []


        }
    }

    componentDidMount() {

        if (this.props.eventDialogData["event_id"] && this.props.eventDialogData["event_id"] !== null) {
            this.setState({ showLoader: true })
            this.getEventInfoById(this.props.eventDialogData["event_id"]);
        }
    }

    getEventInfoById = async (id) => {
        try {
            let resp = await logsService.getEventDataByEventId(id);
            if (resp.data.status === 1 && resp.data.result) {
                var respData = resp.data.result;
                this.setState({
                    eventResponseData: respData,
                    showLoader: false
                });
            } else {
                this.setState({
                    eventResponseData: [],
                    showLoader: false
                });
            }
        } catch (err) {
            console.error(err);
        }
    }

    handelModalClose(event) {
        this.setState({ open: false }, function () {
            this.props.onEventModalClosed();
        })
    }



    render() {
        const { classes } = this.props;
        const { eventDialogData, eventResponseData, showLoader } = this.state;
        return (
            <div>
                <Dialog
                    fullScreen={false}
                    open={true}
                    classes={{ paper: classes.dialogPaper }}
                    onClose={(event) => { this.handelModalClose(event) }}
                    TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Typography
                                variant="h6"
                                className={classes.title}>
                                Event Id : {eventDialogData["event_id"]}
                            </Typography>
                            <Button autoFocus className={classes.closeBtn} color="inherit" onClick={(event) => { this.handelModalClose(event) }}>
                                Close
                            </Button>
                        </Toolbar>
                    </AppBar>
                    {!showLoader && eventResponseData &&
                        eventResponseData.length > 0 &&
                        <div>
                            {eventResponseData.map((obj, i) =>
                                <div key={i + "_t"} style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                                    {Object.keys(obj["raw_data"]).map((item, index) =>
                                            ((obj["raw_data"][item] || obj["raw_data"][item] === 0 ) && <div key={index + "_s"} style={{ display: "flex", padding: "1px 15px" }}>
                                                <div className={classes.keyText} style={{ width: "35%" }}>{item}</div> : &nbsp;&nbsp;
                                                <div className={classes.keyText} style={{ width: "60%" }}>{obj["raw_data"][item] && typeof(obj["raw_data"][item]) ==="string" ? obj["raw_data"][item] : (obj["raw_data"][item] ? obj["raw_data"][item].toString() : obj["raw_data"][item] )}</div>
                                            </div>))}
                                </div>)}
                        </div>}
                    {showLoader && <Loader />}
                </Dialog>
            </div>);

    }
}

LogsInfoModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LogsInfoModal);