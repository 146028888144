import aws_exports from '../aws_exports';
import aws_exports_dev from '../aws_exports_dev';
import aws_exports_staging from '../aws_exports_staging';
import CONSTANTS from '../utils/constants';
import CommonUtils from '../utils/helper';


const currentAppversion = {
    "prod": "22",
    "staging": "13",
    "dev": "21"
};
export function checkIfAppVersionMatches() {
    let appVersion = window.sessionStorage.getItem("app_version");
    // console.log(getEnviornmentType(),appVersion );
    if (appVersion && appVersion === currentAppversion[getEnviornmentType()]) {
        return true;
    } else {
        if (!appVersion) {
            return true;
        } else {
            return false;
        }
    }
}

// checking if dev / staging / prod
export function getEnviornmentType() {
    var hostName = window.location.hostname;
    const REACT_APP_ENV = process.env.REACT_APP_ENV;
    let envType = '';
    // backward compatibility
    if (hostName.indexOf("www.bijakbiz.com") > -1) {
        envType = "prod";
    } else if (hostName.indexOf('staging') > -1) {
        envType = "staging";
    } else {
        envType = "dev";
    }
    // check for env variable
    if(!CommonUtils.isEmpty(REACT_APP_ENV)) {
      envType = REACT_APP_ENV;
    }
    return envType;

}

//  get bucket name
export function getAwsConfig() {
    let type = getEnviornmentType();
    console.log("Current app is running on : " + type);
    if (type === "dev") {
        return aws_exports_dev;
    } else if (type === "staging") {
        return aws_exports_staging;
    } else {
        return aws_exports;
    }
}

//  get bucket name
export function getBucketName() {
    let type = getEnviornmentType();
    if (type === "dev") {
        return aws_exports_dev.aws_user_files_s3_bucket;
    } else if (type === "staging") {
        return aws_exports_staging.aws_user_files_s3_bucket;
    } else {
        return aws_exports.aws_user_files_s3_bucket;
    }
}

export function getAccessAccordingToRole(option) {
    try {
        // if( window.location.href.indexOf("localhost/")){
        //     sessionStorage.setItem("userRole", "SuperAdmin,ViewMobileNumber"); // special check for developer
        // }
        // console.log( sessionStorage.getItem("userRole") )
        let role = "";
        if (sessionStorage.getItem("userRole")) {
            role = sessionStorage.getItem("userRole").split(",");
        } else {
            role = "restricted";
        }

        let roles = {
            "BasicUser": {

            },
            "UserManagement": {
                "addUser": true,
                "editUser": true,
                "updateCreditLimit": true,
                "addBankAccount": true
            },
            "OrderManagement": {
                "addOrder": true,
                "editOrder": true,
                "addSupportingImages": true
            },
            "PayoutMaker": {
                "payViaCredit": true,
            },
            "PayoutChecker": {
                "makePayout": true
            },
            "PaymentManagment": {
                "addPayment": true,
                "editPayment": true,
                "addSupportingImages": true
            },
            "RelationshipManagerRole":{
                "RelationshipManagerRole":true
            },
            "SupportingDataManagement": {
                "addLocation": true,
                "editMandi": true,
                "addMandiRates": true,
                "editCommodity": true,
                "addCommodity": true,
                "addMandi": true,
                "addSupportingImages": true
            },
            "ShiftingMergingOrderPayment": {
                "ShiftingMergingOrderPayment": true
            },
            "SuperAdmin": {
                "allowAll": true
            },
            "ViewUserMobileNumber": {
                "ViewMobileNumber": true
            },
            "DownloadData": {
                "allowDownload": true
            },
            [CONSTANTS.ROLE_CONSTANTS.CSUSER]: {
                [CONSTANTS.ROLE_CONSTANTS.MODIFYPOSTS]: true
            }
            // "RazorpayX":{
            //     "viewRazorpayX" : true
            // }
        }

        // let roles2 = {
        //     "user-creation": {
        //         "addUser": true,
        //         "editUser": true
        //     },
        //     "add-bank": {
        //         "addBankAccount": true
        //     },
        //     "order-creation": {
        //         "addOrder": true,
        //         "editOrder": true,

        //     },
        //     "payout-role": { //yyy
        //         "makePayout": true
        //     },
        //     "request-payout": {
        //         "payViaCredit": true,
        //     },
        //     "manage-credit": {
        //         "updateCreditLimit": true
        //     },
        //     "manage-images-data": {
        //         "addCommodity": true,
        //         "addMandi": true,
        //         "addSupportingImages": true
        //     },
        //     "payment-update": {
        //         "addPayment": true,
        //         "editPayment": true
        //     },
        //     "super-admin": {
        //         "allowAll": true
        //     },
        //     "supporting-data-role": {
        //         "addLocation": true,
        //         "editMandi": true,
        //         "addMandiRates": true,
        //         "editCommodity": true
        //     },

        //     // "mandi-data-update":{
        //     //     "addLocation": true,
        //     //     "editMandi": true
        //     // },
        //     // "mandi-rates-update":{
        //     //     "addMandiRates" : true
        //     // },
        //     // "commodityList-update":{
        //     //     "editCommodity" :true
        //     // }
        // }
        // if (role === "dev") {
        //     return devAccess[appRoute][option];
        // } else {
        //     return devAccess[appRoute][option];
        // }
        if (role === "restricted") {
            return false;
        } else if (role.indexOf("super-admin") > -1 || role.indexOf("SuperAdmin") > -1) {
            return true;
        } else {
            let roleList = {};
            for (let key in roles) {
                if (role.indexOf(key) > -1) {
                    for (let subkeys in roles[key]) {
                        roleList[subkeys] = roles[key][subkeys];
                    }
                }
            }
            // console.log(roleList)
            if (roleList.hasOwnProperty(option)) {
                return true;
            } else {
                return false;
            }
        }
    } catch (err) {
        console.log(err)
    }
}


export function getStatusOfRole(role) {
    // return true;
    if (sessionStorage.getItem("userRole")) {
      // if(sessionStorage.getItem("userRole").indexOf("SuperAdmin") > -1 || sessionStorage.getItem("userRole").indexOf(role) > -1) {
      //   console.log('@@@@here')
      //   return true;
      // }
        if (sessionStorage.getItem("userRole").indexOf("super-admin") > -1 || sessionStorage.getItem("userRole").indexOf("SuperAdmin") > -1) {
            return true;
        } else {
            if (role === "SupportingDataManagement") {
                if (sessionStorage.getItem("userRole").indexOf(role) > -1) {
                    return true;
                } else {
                    return false;
                }
            } else if (role === "permissions") { // not avalaiable for any user except superAdmin
                return false;
            } else if (role === "BasicUser" && sessionStorage.getItem("userRole").indexOf(role) === -1) {
                return false;
            } else if (role === "DownloadData" && sessionStorage.getItem("userRole").indexOf(role) === -1) {
                return false;
            } else if (role === "ShiftingMergingOrderPayment" && sessionStorage.getItem("userRole").indexOf(role) === -1) {
                return false;
            } else if (role === "RelationshipManagerRole" && sessionStorage.getItem("userRole").indexOf(role) === -1) {
                return false;
            } else if (role === CONSTANTS.ROLE_CONSTANTS.CSUSER && sessionStorage.getItem("userRole").indexOf(role) === -1) {
              return false;
          }
            return true;
        }
    } else {
        return false;
    }
}

