import React from 'react';
import PropTypes from 'prop-types';
import '../../assets/css/app.css';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import SweetAlertPage from '../../app/common/SweetAlertPage';
import RelationshipTable from './components/RelationshipTable';

const styles = theme => ({
    root: {
        width: '100%',
        overflow: 'auto',
        fontFamily: 'Lato !important',
        maxWidth: '1200px'
    },
    card: {
        maxWidth: '100%',
        marginTop: '15px',
        height: '97%',
    },
    heading: {
        marginTop: '15px',
        fontSize: '20px',
        alignTtems: 'center',
        display: '-webkit-inline-box'
    }
});

class UserDataContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            showAddModal: false,
            showUploader: false,
            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },
            refreshData: false
        };
    }

    handleClose(event) {
        this.setState({ open: false, showAddModal: false, refreshData: true }, () => {
            this.setState({ refreshData: false })
        });
    }

    onModalCancel(event) {
        this.setState({ open: false, showAddModal: false, showUploader: false });
    }


    handelSweetAlertClosed() {
        this.setState({ showSweetAlert: false }, () => {
            if (this.state.sweetAlertData.type !== "error") {
                this.setState({ refreshData: true }, () => {
                    this.setState({ refreshData: false });
                });
            }
        });
    }

    render() {
        const { classes } = this.props;
        const { showSweetAlert, sweetAlertData, refreshData } = this.state;
        return (
            <div className={classes.root}>
                <Card className={classes.card}>
                    {!refreshData &&
                        <RelationshipTable
                            userRole={"all"}
                            downloadAbleFileName="user_list_data" />}

                    
                </Card>


                {showSweetAlert &&
                    <SweetAlertPage
                        show={true}
                        type={sweetAlertData.type}
                        title={sweetAlertData.title}
                        text={sweetAlertData.text}
                        sweetAlertClose={() => this.handelSweetAlertClosed()}
                    />}
            </div>
        );
    }
}

UserDataContainer.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(UserDataContainer);