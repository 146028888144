import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Tooltip from '@material-ui/core/Tooltip';
import groupService from '../../../app/groupService/groupService';

// import ConfirmDialog from '../../../app/common/ConfirmDialog';
import SweetAlertPage from '../../../app/common/SweetAlertPage';
import Utils from '../../../app/common/utils';
import AttachUserToGroup from './AttachUserToGroup';
import Loader from '../../common/Loader';
import ConfirmDialogWithLoader from '../common/ConfirmDialogWithLoader';
import BusinessInfoDialog from '../../common/BusinessInfoDialog';

const theme = createMuiTheme({
    overrides: {
        MuiTableCell: {
            head: {
                color: '#2e3247',
                fontWeight: 600,
                fontSize: '13px !important',
                fontFamily: 'lato !important',
                textTransform: 'uppercase',
                lineHeight: "1em"

            },
            body: {
                color: 'rgba(0, 0, 0, 0.87)',
                fontWeight: 500,
                fontSize: '13px !important',
                fontFamily: 'lato !important',
            },
            root: {
                padding: '10px'
            }
        },
        MuiTablePagination: {
            toolbar: {
                paddingRight: '250px'
            }
        },
    }
});

const styles = theme => ({

    tableCell: {
        // paddingLeft: '4px',
        // paddingRight: '1px',
        // // textAlign: 'center',
        // maxWidth: '200px',
        // fontSize: '13px !important'
        paddingLeft: '4px',
        paddingRight: '4px',
        textAlign: 'center',
        maxWidth: '180px',
        padding: '12px',
        maxHeight: '40px',
        whiteSpace: "unset",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },

    root: {
        width: '100%',
        minHeight: '80vh',
        padding: "1%"
    },
    lightTooltip: {
        fontSize: '15px',
        maxWidth: 'none',
    },
    textEllpses: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: "110px",
        lineHeight: "18px",
        display: "block"
    },
    textEllipseForAppid: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        direction: 'rtl',
        textAlign: 'left',
        maxWidth: '75px',
        textOverflow: 'ellipsis'
    }
});


class GroupUserTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableHeadData: ["id", "fullname (business name)", "mobile", "role", "Credit Limit", "is Connected", "Group id/ name ", "Action"],
            tableHeadDataKeys: ["id", "fullname", "mobile", "role", "bijak_credit_limit", "is_connected", "groupName", ""],
            tableBodyData: this.props.tableData,
            totalDataCount: this.props.totalDataCount || 0,
            currentOffset: this.props.currentOffset || 0,
            searchedText: "",
            editableData: {},
            showServerDialog: false,
            showOptionModal: false,
            anchorEl: null,
            userData: {},
            payload: null,
            showAddModal: false,
            infoData: null,
            open: false,

            rowsPerPage: 50,
            page: 0,

            showSupportingInvoiceModal: false,
            invoiceModalData: [],

            showAddOrderModal: false,

            showEditDataModal: false,
            commodityList: { options: [], optionN_E: {}, optionE_N: {} },
            showUploader: false,

            showPayoutModal: false,
            payoutData: undefined,

            showUserInfo: false,
            userInfoData: undefined,
            isLimitUpdate: false,
            userId: undefined,

            sortKeys: this.props.sortKeys,
            sortKeysOrder: this.props.sortKeysOrder,
            sortingEnabled: ["id", "unsettled_amount_pltf", "createdtime", "bijak_amt"],
            showPaymentsOfOrderModal: false,
            orderInfo: undefined,


            // new decelartion from here 
            showCreateModal: false,
            dataForAttachDettach: undefined,
            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },
            showAttachModal: false,
            showConfirmLoader: false,
            showUserModal: false
        }
    }


    componentWillReceiveProps(nextProps) {
        if (this.state.tableBodyData !== nextProps.tableData) {
            this.setState({ tableBodyData: nextProps.tableData });
        }
    }

    onAttachedClicked(row) {
        this.setState({ showAttachModal: true, dataForAttachDettach: row });
    }

    onDetachedClicked(row) {
        this.setState({
            dialogTitle: "Are you sure to remove this user from the group ?",
            showConfirmLoader: false,
            dialogText:
                <div style={{ display: "block" }}><div style={{ fontSize: "15px" , fontFamily: 'Lato'}}>{`${row["fullname"]} will be removed from the group ${row['group_name']}`}</div>
                    <div style={{ fontSize: "13px", fontFamily: 'Lato', marginTop: "10px" }}>{"Note : You can latter on attach this user to any group"}</div></div>,
            showConfirmDialog: true,
            dataForAttachDettach: row
        });
    }

    handelCancelUpdate(event) {
        this.setState({ showConfirmDialog: false, showConfirmLoader: false, dataForAttachDettach: undefined });
    }

    async handelConfirmBtnClicked() {
        let sweetAlrtData = this.state.sweetAlertData;
        try {

            this.setState({ showConfirmLoader: true });
            let payload = {
                "is_connected_to_group": false
            };
            let idOfUser = this.state.dataForAttachDettach["id"];
            // if( this.state.dataForAttachDettach && this.state.dataForAttachDettach[""]){

            // }
            let resp = await groupService.attacheDetacheUserApi(idOfUser, payload);
            console.log(resp);
            if (resp.data.status === 1) {
                sweetAlrtData["type"] = "success";
                sweetAlrtData["title"] = "Success";
                sweetAlrtData["text"] = "User account successfully deleted";
                let dataForAttachDettachVal = this.state.dataForAttachDettach;
                dataForAttachDettachVal["is_connected_to_group"] = false;
                this.setState({
                    // showConfirmDialog: false,
                    showConfirmLoader: false,
                    dataForAttachDettach: dataForAttachDettachVal,
                    showSweetAlert: true,
                    sweetAlertData: sweetAlrtData
                });

            } else {
                sweetAlrtData["type"] = "error";
                sweetAlrtData["title"] = "Error";
                sweetAlrtData["text"] = "Oops an error occured";
                this.setState({
                    // showConfirmDialog: false,
                    showConfirmLoader: false,
                    showSweetAlert: true,
                    sweetAlertData: sweetAlrtData
                });
            }
        } catch (err) {
            console.log(err);
            sweetAlrtData["type"] = "error";
            sweetAlrtData["title"] = "Error";
            sweetAlrtData["text"] = err
            this.setState({

                // showConfirmDialog: false,
                showConfirmLoader: false,
                showSweetAlert: true,
                sweetAlertData: sweetAlrtData
            });
        }
    }

    handelSweetAlertClosed() {
        this.setState({ showSweetAlert: false, showConfirmDialog: false }, () => {
            if (this.state.sweetAlertData.type !== "error") {

                this.props.onDataAttachedOrDetached(this.state.dataForAttachDettach);
            }
        });
    }
    getRole(row) {

        let role = "NA";
        let isGlobal = false;

        if (row.role === "ca") {
            role = "Buyer";
        } else if (row.role === 'la') {
            role = "Supplier";
        } else if (row.role === 'broker') {
            role = "Broker";
        } else {
            role = "NA";
        }

        if (row.hasOwnProperty("global_ca") && row.global_ca) {
            isGlobal = true;
        }
        if (row.hasOwnProperty("global_la") && row.global_la) {
            isGlobal = true;
        }

        return (<span style={{
            color: "white",
            background: this.getBackgroundColor(row),
            padding: "4px 12px",
            borderRadius: "13px"
        }}> {role} {isGlobal ? <i className="fa fa-globe" aria-hidden="true"></i> : ""}</span>)
    }
    getBackgroundColor(obj) {
        if (obj.role === "ca") {
            return "#f94141";
        } else if (obj.role === 'la') {
            return "#00a700";
        } else if (obj.role === 'broker') {
            return "#7070fd";
        } else {
            return "#757575";
        }
    }

    onDataAttached(updateData) {
        this.setState({ showAttachModal: false, dataForAttachDettach: undefined }, () => {
            this.props.onDataAttachedOrDetached(updateData);
        })
    }

    onUserInfoClicked = (info, type, event) => {
        console.log(info)
        let id = "";
        if (info.role === "ca") {
            id = info["mobile"];
        } else {
            id = info["mobile"];
        }
        this.setState({ userId: id, showUserInfo: true, userInfoData: JSON.parse(JSON.stringify(info)) });
    }

    handleUserInfoClose(event) {
        this.setState({ showUserInfo: false},()=>{
            if(this.state.isLimitUpdate) {
                this.props.onUserDetailsEdited();
            }
        });
    }

changeLimitSucces(event) {
    if (event) {
        let data = this.state.tableBodyData;
        if (data.length > 0 && data[0].hasOwnProperty("bijak_credit_limit")) {
            data[0]['bijak_credit_limit'] = event;
        }
        this.setState({ tableBodyData: data, isLimitUpdate: true });
    } else {
        this.setState({ isLimitUpdate: true });
    }
}

onUserInfoModalCancel(event) {
    this.setState({ showUserInfo: false });
    if (this.state.isLimitUpdate) {
        this.props.onUserDetailsEdited();
    }
}

render() {
    const { classes, isGroupTableLoading } = this.props;
    const { tableHeadDataKeys, sortingEnabled, showSweetAlert, sweetAlertData } = this.state;
    const leftAlignedIndexs = [0, 1];
    const rightAlignedIndexs = [4];
    const minWidthIndexs = [0, 2, 3, 5, 6, 8];
    return (
        <MuiThemeProvider theme={theme}>
            {!isGroupTableLoading ? <Paper className={classes.root} >
                {this.state.tableBodyData && <div style={{ maxHeight: "65vh", overflowY: "scroll" }}>
                    <Table className='table-body' stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow style={{ borderBottom: "2px solid #858792" }} >
                                {this.state.tableHeadData.map((option, i) => (
                                    <TableCell
                                        key={option}
                                        // onClick={() => this.addKeysToSortList(tableHeadDataKeys[i])}
                                        className={classes.tableCell}
                                        style={{
                                            paddingLeft: i === 0 ? '22px' : '',
                                            minWidth: i === 0 ? "85px" : (minWidthIndexs.indexOf(i) > -1 || i === (this.state.tableHeadData.length - 1)) ? (i !== 1 ? "100px" : "80px") : '120px',
                                            textAlign: leftAlignedIndexs.indexOf(i) > -1 ? "left" : rightAlignedIndexs.indexOf(i) > -1 ? "right" : "center",
                                            cursor: sortingEnabled.indexOf(tableHeadDataKeys[i]) > -1 ? "pointer" : "unset"
                                        }}>{option}
                                        {/* {sortingEnabled.indexOf(tableHeadDataKeys[i]) > -1 &&
                                                        <i className={Utils.getArrowIconType(sortKeys, tableHeadDataKeys[i])} aria-hidden="true"  style={{ color : sortKeys.hasOwnProperty(tableHeadDataKeys[i]) ? '#e72e89':'unset'}}></i>} */}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.tableBodyData &&
                                this.state.tableBodyData
                                    .map((row, i) => {
                                        return (
                                            <TableRow key={'table_' + i} id={"order_" + row.id} style={{ background: i % 2 !== 0 ? "#e8e8e8" : "#fff" }}>
                                                <TableCell component="th" scope="row" className={classes.tableCell} style={{ textAlign: "left" }}>

                                                    {Utils.checkIfPrimaryKey(row) ?
                                                        <Tooltip title={"Primary"} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                            <i className="fa fa-check" style={{ color: "#60c1d8" }} aria-hidden="true"></i>
                                                        </Tooltip> : <i className="fa fa-chec" style={{ color: "#60c1d8", width: "14px" }} aria-hidden="true"></i>
                                                    }

                                                    <div style={{ display: "inline-table" }}>
                                                        <Tooltip title={row.active ? "Enabled" : "Disabled"} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                            <div style={{
                                                                color: "#fff",
                                                                background: row.active ? "green" : "red",
                                                                padding: "4px 12px",
                                                                width: 'fit-content',
                                                                marginLeft: '2%',
                                                                borderRadius: "13px"
                                                            }}> {row.id}</div>
                                                        </Tooltip>
                                                    </div>
                                                </TableCell>

                                                <TableCell className={classes.tableCell}>
                                                    <Tooltip title={row.fullname ? row.fullname : ""} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                        <div className=" name-span" style={{ display: "grid", textAlign: "left", textTransform: "capitalize", cursor: "pointer" }}
                                                            onClick={this.onUserInfoClicked.bind(this, row, "buyer_name")}>
                                                            <div className="text-ellpses">{row.fullname ? row.fullname : ""} </div>
                                                            <div className="text-ellpses" style={{ fontSize: "12px" }}>{row.business_name ? row.business_name : " "}</div>
                                                        </div>
                                                    </Tooltip>
                                                </TableCell>

                                                <TableCell component="th" scope="row" className={classes.tableCell} style={{ textAlign: "center" }}>
                                                    {row.mobile ? row.mobile : ""}
                                                </TableCell>
                                                <TableCell component="th" scope="row" className={classes.tableCell} style={{ textAlign: "center" }}>
                                                    {this.getRole(row)}
                                                </TableCell>

                                                <TableCell component="th" scope="row" className={classes.tableCell} style={{ textAlign: "right" }} >
                                                    ₹  {row.bijak_credit_limit ? row.bijak_credit_limit : "0"}
                                                </TableCell>

                                                <TableCell component="th" scope="row" className={classes.tableCell} style={{ textAlign: "center" }}>
                                                    {row.is_connected_to_group ? <i className="fa fa-check" style={{ color: "green" }} aria-hidden="true"></i> : <i className="fa fa-times" style={{ color: "red" }} aria-hidden="true"></i>}
                                                </TableCell>

                                                <TableCell className={classes.tableCell} style={{ textAlign: "center" }}>
                                                    {row.is_connected_to_group ?
                                                        <div style={{ display: "grid", textAlign: "center" }}>
                                                            <span className="text-ellpses" >{row.connect_group_id ? row.connect_group_id : "-"} </span>
                                                            <span className="text-ellpses" style={{ fontSize: "12px" }}>{row.group_name ? row.group_name : " "}</span>
                                                        </div> : "-"}
                                                </TableCell>
                                                <TableCell className={classes.tableCell} style={{ textAlign: "center" }}>
                                                    {row.is_primary ?
                                                        <span style={{ marginLeft: "10px" }}>
                                                            <i className="fa fa-caret-left translabelIcon" style={{ marginRight: "-0.75px", color: "red", display: "contents" }} aria-hidden="true"></i>
                                                            <span
                                                                className=" translabeltag  labeltag"
                                                                style={{ cursor: "no-drop", color: "#fff", background: "red", paddingTop: "2px", paddingBottom: "4px", fontSize: "12px" }}>
                                                                primary user
                                                        </span>
                                                        </span>

                                                        : <Tooltip interactive title={row.is_connected_to_group ? "Remove this user from this group" : "Add this user in a group"} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                            <div>{row.is_connected_to_group ?
                                                                <RemoveCircleOutlineIcon
                                                                    className="material-Icon"
                                                                    onClick={() => this.onDetachedClicked(row)}
                                                                    style={{ color: "#e72e89", cursor: "pointer", height: "18px", fontSize: "18px" }} /> :
                                                                <AddCircleOutlineIcon
                                                                    className="material-Icon"
                                                                    onClick={() => this.onAttachedClicked(row)}
                                                                    style={{ color: "#e72e89", cursor: "pointer", height: "18px", fontSize: "18px" }} />}
                                                            </div>
                                                        </Tooltip>}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                        </TableBody>
                    </Table>
                </div>}



                {this.state.showConfirmDialog ?
                    <ConfirmDialogWithLoader
                        dialogText={this.state.dialogText}
                        showConfirmLoader={this.state.showConfirmLoader}
                        dialogTitle={this.state.dialogTitle}
                        showSweetAlertShown={showSweetAlert}
                        show={this.state.showConfirmDialog}
                        onConfirmed={() => this.handelConfirmBtnClicked()}
                        onCanceled={() => this.handelCancelUpdate()} /> : ""}

                {showSweetAlert &&
                    <SweetAlertPage
                        show={true}
                        type={sweetAlertData.type}
                        title={sweetAlertData.title}
                        text={sweetAlertData.text}
                        sweetAlertClose={() => this.handelSweetAlertClosed()}
                    />}
                {this.state.showAttachModal &&
                    <AttachUserToGroup
                        open={this.state.showAttachModal}
                        showSweetAlertShown={showSweetAlert}
                        onDataAttachedSuccess={(newData) => this.onDataAttached(newData)}
                        dataForAttachDettach={this.state.dataForAttachDettach}
                        onAddModalCancel={(event) => this.setState({ showAttachModal: false, dataForAttachDettach: undefined })}
                    />}

            </Paper> : <Loader />}

            {this.state.showUserInfo ?
                <BusinessInfoDialog
                    openModal={this.state.showUserInfo}
                    onEditModalClosed={this.handleUserInfoClose.bind(this)}
                    data={this.state.userInfoData}
                    isInfo={true}
                    userId={this.state.userId}
                    onLimitUpdate={this.changeLimitSucces.bind(this)}
                    onEditModalCancel={this.onUserInfoModalCancel.bind(this)} /> : ""}

        </MuiThemeProvider>
    );
}
}

GroupUserTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GroupUserTable);