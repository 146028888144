import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Loader from '../../common/Loader';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import { getAccessAccordingToRole } from '../../../config/appConfig';
import Utils from '../../../app/common/utils';
import ConfirmAndPayoutModal from './ConfirmAndPayoutModal';
import SweetAlertPage from '../../../app/common/SweetAlertPage';

const styles = theme => ({
    appBar: {
        position: 'relative',
        background: "#05073a",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        fontSize: "20px !important",
        fontFamily: "lato !important",
        fontWeight: 500
    },
    closeBtn: {
        fontSize: "15px !important",
        fontFamily: "lato !important",
        fontWeight: 500
    },
    tableCell: {
        paddingLeft: '4px',
        paddingRight: '4px',
        textAlign: 'center',
        maxWidth: '200px'
    },
    formControl: {
        color: "#fff"
    },
    dataHeader: {
        width: "20%"
    },
    lightTooltip: {
        fontSize: '15px',
        fontWeight: 500,
        maxWidth: 'none',
    },
    actCardc: {
        boxShadow: "0px 0px 7px 0px rgba(0,0,0,0.75)",
        padding: "10px",
        margin: "10px",
        // width:"80%",
        borderLeft: "5px solid #ec7596",
        borderRadius: "5px"
    },
    actcardtext: {
        fontSize: "15px",
        fontFamily: "lato"
    }
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class BulkPayoutModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            tableHeadData: ["Payout", "Payout Type", "Details"],
            transactionData: this.props.selectedPayoutIds,
            transactionTypeData: {},
            showConfirmPayoutDialog: false,
            errorFields: {},
            payoutBulkData: [],
            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },

        }
        // console.log(this.props.selectedPayoutIds);
    }

    componentDidMount() {
        let transactionTypeDataVal = this.state.transactionTypeData;
        if (this.props.selectedPayoutIds) {
            for (let keys in this.props.selectedPayoutIds) {
                let narationkey = this.props.selectedPayoutIds[keys]["buyer_business_name"] || this.props.selectedPayoutIds[keys]["buyer_fullname"];
                transactionTypeDataVal[keys] = {
                    idData: this.props.selectedPayoutIds[keys],
                    transactionType: "razorpay",
                    paymentThrough: 'NEFT',
                    narration:  narationkey,
                    narrationError: !Utils.isValidNarration(narationkey),
                    skipRazorPayData: {
                        remarks: '',
                        utr: '',
                        bank_id: ''
                    }
                };
            }
        }
        this.setState({ transactionTypeData: transactionTypeDataVal });
    }

    handelModalClose(event) {
        this.setState({ open: false }, function () {
            this.props.onBulkPayOutClosed();
        })
    }

    getUserRowData(classes, row) {
        return (
            <span>
                {/* display: 'flex' */}
                <div style={{ display: 'flex' }} className={classes.actcardtext}><span style={{ width: '50%', textAlign: 'left', paddingLeft: '10%' }}>Id</span>:&nbsp;&nbsp;<span>{row.id ? row.id : "-"}</span></div>
                <div style={{ display: 'flex' }} className={classes.actcardtext}><span style={{ width: '50%', textAlign: 'left', paddingLeft: '10%' }}>Pay id</span>:&nbsp;&nbsp;<span>{row.pay_id ? row.pay_id : "-"}</span></div>
                <div style={{ display: 'flex' }} className={classes.actcardtext}><span style={{ width: '50%', textAlign: 'left', paddingLeft: '10%' }}>Buyer</span>:&nbsp;&nbsp;<span>{row.buyer_fullname ? row.buyer_fullname : '-'}</span></div>
                <div style={{ display: 'flex' }} className={classes.actcardtext}><span style={{ width: '50%', textAlign: 'left', paddingLeft: '10%' }}>Supplier</span>:&nbsp;&nbsp;<span>{row.supplier_fullname ? row.supplier_fullname : '-'}</span></div>
            </span>
        );
    }

    getDataDetails(classes, row) {
        let transactionTypeDataVal = this.state.transactionTypeData;
        if (transactionTypeDataVal[row['id']] && transactionTypeDataVal[row['id']]['transactionType']) {
            if (transactionTypeDataVal[row['id']]['transactionType'] === 'razorpay') {
                return (this.getRazorPayDetails(classes, row));
            } else if (transactionTypeDataVal[row['id']]['transactionType'] === 'skiprazorpay') {
                return (this.getSkipRazorPayDetails(row))
            } else {
                return ("Oops an error !!");
            }
        } else {
            return "";
        }
    }

    getSkipRazorPayDetails(row) {
        try {
            let id = row['id'];
            let transactionTypeDataVal = this.state.transactionTypeData;

            if (transactionTypeDataVal[id] && transactionTypeDataVal[id]['skipRazorPayData']) {
                let skipRzrPaydata = transactionTypeDataVal[id]['skipRazorPayData'];

                return (<div >

                    <TextField
                        margin="dense"
                        id="bank_id"
                        error={this.state.errorFields.hasOwnProperty("bank_id_" + id) ? true : false}
                        label="Bank id"
                        type="text"
                        style={{ width: '100%' }}
                        value={skipRzrPaydata.bank_id}
                        onChange={this.handleSkipRazorInputChange.bind(this, id)}
                        fullWidth />

                    <TextField
                        margin="dense"
                        id="utr"
                        label="UTR"
                        error={this.state.errorFields.hasOwnProperty("utr_" + id) ? true : false}
                        type="text"
                        style={{ width: '100%', textTransform: "uppercase" }}
                        value={skipRzrPaydata.utr}
                        onChange={this.handleSkipRazorInputChange.bind(this, id)}
                        fullWidth />

                    <TextField
                        margin="dense"
                        id="remarks"
                        label="Remark"
                        error={this.state.errorFields.hasOwnProperty("remarks_" + id) ? true : false}
                        type="text"
                        style={{ width: '100%' }}
                        value={skipRzrPaydata.remarks}
                        onChange={this.handleSkipRazorInputChange.bind(this, id)}
                        fullWidth />
                </div>);
            } else {
                return (<div> no data </div>)
            }
        } catch (er) {
            console.log(er);
            return "";
        }
    }

    handleSkipRazorInputChange(id, event) {
        let inputId = event.target.id;
        let val = event.target.value;
        let transactionTypeDataVal = this.state.transactionTypeData;
        transactionTypeDataVal[id]['skipRazorPayData'][inputId] = val;
        this.setState({ transactionTypeData: transactionTypeDataVal });
    }

    handelPaymentThroughChanged(id, event) {
        let val = event.target.value;
        let transactionTypeDataVal = this.state.transactionTypeData;
        transactionTypeDataVal[id]['paymentThrough'] = val;
        this.setState({ transactionTypeData: transactionTypeDataVal });
    }

    getRazorPayDetails(classes, row) {
        try {
            let acctDetails = row["bank_details"];
            // let id = row['id'];
            let amount = row['amount'];
            // let transactionTypeDataVal = this.state.transactionTypeData;
            // let narrationData = transactionTypeDataVal[id]['narration'];
            // let narrationErrorKey = transactionTypeDataVal[id]['narrationError'];
            // console.log(acctDetails)
            return (<div>
                {/* <div style={{ display: "flex", paddingBottom: "5px", paddingTop: '5px' }}>
                    <span className={classes.actcardtext} > Amount  : &nbsp;<strong style={{ color: "red" }}> ₹ {Utils.formatNumberWithComma(amount)}  </strong></span>
                </div> */}
                <div className={classes.actCardc} >
                    <div className={classes.actcardtext} style={{ paddingBottom: "4px", display: 'flex' }}>
                        <div className={classes.actcardtext} style={{ textDecoration: "underline", textTransform: "uppercase", width: '50%', textAlign: 'left' }}> Account details </div>
                        <div className={classes.actcardtext} style={{ width: '50%', textAlign: 'right' }} > Amount  : &nbsp;<strong style={{ color: "red" }}> ₹ {Utils.formatNumberWithComma(amount)}  </strong></div>
                    </div>
                    {acctDetails !== "-" && acctDetails !== "" && acctDetails['bank_account_number'] && acctDetails['bank_account_number'] !== '' && acctDetails['bank_account_number'] !== '-' ?
                        <span>
                            <div style={{ display: "flex" }}> <span className={classes.actcardtext} style={{ width: "40%" }}> Account Number     </span>: &nbsp;<strong className={classes.actcardtext} > {acctDetails["bank_account_number"]} </strong> </div>
                            <div style={{ display: "flex" }}> <span className={classes.actcardtext} style={{ width: "40%" }}> Ifsc               </span>: &nbsp;<strong className={classes.actcardtext} style={{ textTransform: "uppercase" }} > {acctDetails["bank_ifsc_code"]} </strong> </div>
                            <div style={{ display: "flex" }}> <span className={classes.actcardtext} style={{ width: "40%" }}> Account Holder Name</span>: &nbsp;<strong className={classes.actcardtext} > {acctDetails["bank_account_holder_name"]} </strong> </div>
                        </span> :
                        <div style={{ padding: "14px" }}
                            className={classes.actcardtext} >
                            Oops no bank account details available.
                    </div>}
                </div>
                {/* <div>
                    {acctDetails !== "-" && acctDetails !== "" && acctDetails['bank_account_number'] && acctDetails['bank_account_number'] !== '' && acctDetails['bank_account_number'] !== '-' && <FormControl component="fieldset" style={{ padding: "5px" }}>
                        <FormLabel component="legend" style={{ fontSize: "15px", fontFamily: "lato" }}>Select transfer type</FormLabel>
                        <RadioGroup aria-label="position" name="position" value={this.state.transactionTypeData[row['id']]['paymentThrough']} onChange={this.handelPaymentThroughChanged.bind(this, id)} row>
                            <FormControlLabel
                                value="NEFT"
                                control={<Radio color="primary" />}
                                label="NEFT"
                                style={{ fontSize: "14px", fontFamily: "lato" }}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="IMPS"
                                control={<Radio color="primary" />}
                                label="IMPS"
                                disabled={amount > 200000}
                                style={{ fontSize: "14px", fontFamily: "lato" }}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="RTGS"
                                control={<Radio color="primary" />}
                                label="RTGS"
                                disabled={amount < 200000}
                                style={{ fontSize: "14px", fontFamily: "lato" }}
                                labelPlacement="end"
                            />
                        </RadioGroup>
                        {this.state.transactionTypeData[id]["paymentThrough"] === "NEFT" && <FormHelperText>*After 6 PM (IST) This request will be processed on Next Working Day</FormHelperText>}
                        {this.state.transactionTypeData[id]["paymentThrough"] === "RTGS" && <FormHelperText>*After 5 PM (IST) This request will be processed on Next Working Day</FormHelperText>}
                        {amount > 200000 && <FormHelperText>*IMPS is not available as amount is greater than 2,00,000</FormHelperText>}

                        <TextField
                            margin="dense"
                            id="narration"
                            error={narrationErrorKey}
                            success={true}
                            label="Enter narration *"
                            type="text"
                            style={{ width: '100%' }}
                            value={narrationData}
                            onChange={this.handelNarrationChange.bind(this, id)}
                            helperText={narrationErrorKey ? "Please enter a valid narration" : ""}
                            fullWidth />

                    </FormControl>}
                </div> */}
            </div>
            );
        } catch (er) {
            console.log(er);
        }

    }

    handelNarrationChange(id, event) {
        try {
            let val = event.target.value;
            let transactionTypeDataVal = this.state.transactionTypeData;
            transactionTypeDataVal[id]['narration'] = val;
            transactionTypeDataVal[id]['narrationError'] = !Utils.isValidNarration(val);
            // console.log(transactionTypeDataVal)
            this.setState({ transactionTypeData: transactionTypeDataVal });
        } catch (err) {
            console.log(err)
        }
    }

    onTransactionTypeChange(event, tId) {
        let transactionTypeDataVal = this.state.transactionTypeData;
        transactionTypeDataVal[tId]['transactionType'] = event.target.value;
        this.setState({ transactionTypeData: transactionTypeDataVal });
    }

    onConfirmPay(event) {
        if (this.checkValidInfoFilled()) {
            let preconfirmedDataVal = this.getFormatedObjectForApi();
            this.setState({ payoutBulkData: preconfirmedDataVal }, () => {
                this.setState({ showConfirmPayoutDialog: true });
            });
        } else {
            // alert("Please fill the highlighted fields")
            let sweetAlrtData = this.state.sweetAlertData;
            sweetAlrtData["type"] = "warning";
            sweetAlrtData["title"] = "Info";
            sweetAlrtData["text"] = "Please fill the mandatory fields";
            this.setState({ showSweetAlert: true, sweetAlertData: sweetAlrtData });

        }
    }

    getFormatedObjectForApi() {
        // RazorPay
        // idData: this.props.selectedPayoutIds[keys],
        // transactionType: "razorpay",
        // paymentThrough: 'NEFT',
        // narration: '',
        // narrationError: false,
        // skipRazorPayData: {
        //     remarks: '',
        //     utr: '',
        //     bank_id: ''
        try {
            let finalData = [];
            let transactionTypeDataVal = this.state.transactionTypeData;
            for (let keys in transactionTypeDataVal) {
                let payoutData = transactionTypeDataVal[keys];
                let payload = {};
                payload["id"] = payoutData['idData']["id"];
                payload["name"] = payoutData['idData']["supplier_fullname"];
                payload["contact"] = payoutData['idData']["supplier_mobile"];
                payload["type"] = "Loader";
                payload["buyer_name"] = payoutData['idData']["buyer_business_name"] || payoutData['idData']["buyer_fullname"];
                payload["amount"] = payoutData['idData']["amount"];
                payload["reference_id"] = payoutData['idData']["supplier_fullname"];
                payload["notes"] = {};
                payload["mode"] = payoutData.paymentThrough;
                payload["narration"] = payoutData.narration;
                if (transactionTypeDataVal[keys]['transactionType'] !== 'razorpay') { // incase of skip razor pay
                    payload["skipRazorpayX"] = true;
                    payload["bank_id"] = payoutData['skipRazorPayData']["bank_id"];
                    payload["utr"] = payoutData['skipRazorPayData']["utr"];
                    payload["remarks"] = payoutData['skipRazorPayData']["remarks"];
                }
                finalData.push(payload);
            }
            // console.log(finalData);
            return finalData;
        } catch (er) {
            console.log(er);
        }
    }

    checkValidInfoFilled() {
        let transactionTypeDataVal = this.state.transactionTypeData;
        let errorFields = {};
        let isValid = true;
        for (let keys in transactionTypeDataVal) {
            if (transactionTypeDataVal[keys]['transactionType'] === 'razorpay') {
                if (!transactionTypeDataVal[keys]['narration'] || transactionTypeDataVal[keys]['narration'].trim() === "" || !Utils.isValidNarration( transactionTypeDataVal[keys]['narration'] )) {
                    errorFields['narration_' + keys] = true;
                    transactionTypeDataVal[keys]['narrationError'] = true;
                    isValid = false;
                }

            } else {
                // SkipRazorPay
                let skipRazorPayData = transactionTypeDataVal[keys]['skipRazorPayData'];
                if (!skipRazorPayData.remarks && skipRazorPayData.remarks.trim() === '') {
                    errorFields['remarks_' + keys] = true;
                    isValid = false;
                }
                if (!skipRazorPayData.utr && skipRazorPayData.utr.trim() === '') {
                    errorFields['utr_' + keys] = true;
                    isValid = false;
                }
                if (!skipRazorPayData.utr && skipRazorPayData.utr.trim() === '') {
                    errorFields['bank_id_' + keys] = true;
                    isValid = false;
                }
            }
        }
        this.setState({ 'errorFields': errorFields, "transactionTypeData": transactionTypeDataVal });
        return isValid;
    }

    getPaymentType( row ) {
        try {
            let id = row['id'];
            let amount = row['amount'];
            let transactionTypeDataVal = this.state.transactionTypeData;
            let narrationData = transactionTypeDataVal[id]['narration'];
            let narrationErrorKey = transactionTypeDataVal[id]['narrationError'];
            // let acctDetails = row["bank_details"];
 

            return (<div>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Selected transaction type</FormLabel>
                    <RadioGroup row aria-label="position" name="position" value={transactionTypeDataVal[id] && transactionTypeDataVal[id]["transactionType"] ? transactionTypeDataVal[id]["transactionType"] : true} 
                    onChange={(event) => this.onTransactionTypeChange(event, id)}>
                        <FormControlLabel value="razorpay" control={<Radio color="primary" />} label="RazorPayX" />
                        <FormControlLabel value="skiprazorpay" control={<Radio color="primary" />} label="Skip RazorpayX" />
                    </RadioGroup>
                </FormControl>
                { <FormControl component="fieldset" style={{ padding: "5px" }}>
                    <FormLabel component="legend" style={{ fontSize: "15px", fontFamily: "lato" }}>Select transfer type</FormLabel>
                    <RadioGroup aria-label="position" name="position" value={transactionTypeDataVal[row['id']]['paymentThrough']} onChange={this.handelPaymentThroughChanged.bind(this, id)} row>
                        <FormControlLabel
                            value="NEFT"
                            control={<Radio color="primary" />}
                            label="NEFT"
                            style={{ fontSize: "14px", fontFamily: "lato" }}
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="IMPS"
                            control={<Radio color="primary" />}
                            label="IMPS"
                            disabled={amount > 200000}
                            style={{ fontSize: "14px", fontFamily: "lato" }}
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="RTGS"
                            control={<Radio color="primary" />}
                            label="RTGS"
                            disabled={amount < 200000}
                            style={{ fontSize: "14px", fontFamily: "lato" }}
                            labelPlacement="end"
                        />
                    </RadioGroup>
                    {transactionTypeDataVal[id]["paymentThrough"] === "NEFT" && <FormHelperText>*After 6 PM (IST) This request will be processed on Next Working Day</FormHelperText>}
                    {transactionTypeDataVal[id]["paymentThrough"] === "RTGS" && <FormHelperText>*After 5 PM (IST) This request will be processed on Next Working Day</FormHelperText>}
                    {amount > 200000 && <FormHelperText>*IMPS is not available as amount is greater than 2,00,000</FormHelperText>}

                    {transactionTypeDataVal[id]["transactionType"] === 'razorpay' && <TextField
                        margin="dense"
                        id="narration"
                        error={narrationErrorKey}
                        success={true}
                        label="Enter narration *"
                        type="text"
                        style={{ width: '100%' }}
                        value={narrationData}
                        onChange={this.handelNarrationChange.bind(this, id)}
                        helperText={narrationErrorKey ? "Please enter a valid narration" : ""}
                        fullWidth />}

                </FormControl>}
            </div>)
        } catch (err) {
            console.log(err);
        }
    }

    handelSweetAlertClosed() {
        this.setState({ showSweetAlert: false }, () => {

        });
    }

    render() {
        const { classes } = this.props;
        const { transactionData, tableHeadData, showSweetAlert, sweetAlertData, } = this.state;
        const leftAlignedIndexs = [];
        const rightAlignedIndexs = [];
        return (
            <div>
                <Dialog fullScreen open={true} onClose={(event) => { this.handelModalClose(event) }} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Typography variant="h6" className={classes.title}>
                                Bulk payout
                            </Typography>
                            <Button autoFocus className={classes.closeBtn} color="inherit" onClick={(event) => { this.handelModalClose(event) }}>
                                Close
                            </Button>
                        </Toolbar>
                    </AppBar>
                    {transactionData ? <div style={{ margin: 20 }}>
                        {transactionData && Object.keys(transactionData).length > 0 &&
                            <Table className='table-body'>
                                <TableHead style={{}}>
                                    <TableRow style={{ borderBottom: "2px solid #858792" }} >
                                        {tableHeadData.map((option, i) => (
                                            <TableCell
                                                key={option}
                                                className={classes.tableCell}
                                                style={{
                                                    minWidth: '120px', paddingLeft: i === 0 ? '22px' : '',
                                                    textAlign: leftAlignedIndexs.indexOf(i) > -1 ? "left" : rightAlignedIndexs.indexOf(i) > -1 ? "right" : ""
                                                }}>
                                                {option}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {<TableBody>
                                    {Object.keys(transactionData).map((keyId, i) => {
                                        return (
                                            <TableRow key={'table_' + i} style={{ background: (i % 2 === 0 ? "#e5e8ec" : "#fff") }}>
                                                <TableCell component="th" scope="row" className={classes.tableCell}  >
                                                    {this.getUserRowData(classes, transactionData[keyId])}
                                                </TableCell>
                                                <TableCell component="th" scope="row" className={classes.tableCell} >
                                                    {/* <FormControl component="fieldset">
                                                        <FormLabel component="legend">Selected transaction type</FormLabel>
                                                        <RadioGroup row aria-label="position" name="position" value={transactionTypeData[keyId] && transactionTypeData[keyId]["transactionType"] ? transactionTypeData[keyId]["transactionType"] : true} onChange={(event) => this.onTransactionTypeChange(event, keyId)}>
                                                            <FormControlLabel value="razorpay" control={<Radio color="primary" />} label="RazorPayX" />
                                                            <FormControlLabel value="skiprazorpay" control={<Radio color="primary" />} label="Skip RazorpayX" />
                                                        </RadioGroup>
                                                    </FormControl> */}
                                                    {this.getPaymentType( transactionData[keyId] )}
                                                </TableCell>
                                                <TableCell component="th" scope="row" className={classes.tableCell} >
                                                    {this.getDataDetails(classes, transactionData[keyId])}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>}
                            </Table>}

                        {transactionData && Object.keys(transactionData).length > 0 && !this.state.showLoader ?
                            "" :
                            <div className={classes.defaultTemplate}
                                style={{
                                    marginTop: "20%",
                                    textAlign: "center",
                                    fontSize: "24px"
                                }}>
                                {this.state.showLoader ? <Loader /> :
                                    <span className={classes.defaultSpan}>
                                        <i className={classes.defaultIcon + " fa fa-frown-o"} aria-hidden="true"></i>
                                        {"No data available"}
                                    </span>}
                            </div>}
                    </div> : <Loader />}
                    {<div className="updateBtndef">
                        {getAccessAccordingToRole("payViaCredit") &&
                            <div
                                className="updateBtnFixed"
                                style={{ display: 'flex' }}
                                onClick={(event) => this.onConfirmPay()}>
                                <i className="fa fa-plus-circle add-icon" aria-hidden="true"></i>
                                <p>{"Confirm & Payout"}</p></div>}
                    </div>}
                    {this.state.showConfirmPayoutDialog &&
                        <ConfirmAndPayoutModal
                            open={this.state.showConfirmPayoutDialog}
                            payoutBulkData={this.state.payoutBulkData}
                            onConfirmPayoutSuccess={() => this.setState({ showConfirmPayoutDialog: false, payoutBulkData: [] }, () => this.props.onBulkPayoutSuccess())}
                            onModalCancel={() => this.setState({ showConfirmPayoutDialog: false, payoutBulkData: [] })} />}

                    {showSweetAlert &&
                        <SweetAlertPage
                            show={true}
                            type={sweetAlertData.type}
                            title={sweetAlertData.title}
                            text={sweetAlertData.text}
                            sweetAlertClose={() => this.handelSweetAlertClosed()}
                        />}
                </Dialog>
            </div>);

    }
}

BulkPayoutModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BulkPayoutModal);