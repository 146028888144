import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';


const styles = theme => ({
    root: {
        // height: "20%",
        width: '100%',
        overflow: 'auto',
        fontFamily: 'Lato !important',
        padding: "1%",
        maxWidth: '1200px',
        // padding: "15px 20%",
        // maxHeight: '40vh',
    },
    card: {
        maxWidth: '100%',
        minHeight: '20%',
        border: "1px solid gray",
        padding: "1%",

        // marginTop: '15px',
        // backgroundColor: "#ececec",
        // height: '25vh',
    },
    defaultSpan: { display: 'grid', fontSize: '25px' },
    gpinfoTitle: {
        width: "35%",
        paddingLeft: "6%",
        textAlign: "left",
        fontFamily: 'Lato !important',
    },
    gpinfoTitle2: {
        width: "40%",
        paddingLeft: "2%",
        textAlign: "left",
        fontFamily: 'Lato !important',
    },
});

class GroupMetaInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },

        }
    }

    render() {
        const { classes, selectedUser } = this.props; 
        console.log( selectedUser )
        return (
            <div className={classes.root}>
                {selectedUser && selectedUser['is_connected_to_group'] &&
                <Paper className={classes.card} >
                    <div>
                        <div style={{ fontSize: "17px", fontFamily: "lato", textDecoration: "underline" }}> <strong> Group Details </strong></div>
                        < div style={{ display: "flex" }}>
                            <div style={{ width: "50%"}}>
                                <div style={{ display: "flex" ,paddingTop: "10px"}} >
                                    <span  className={classes.gpinfoTitle} > Group Id </span> : &nbsp; &nbsp;<span> <strong>{selectedUser['connect_group_id']?selectedUser['connect_group_id']:""}</strong> </span>
                                </div>
                                {/* <div style={{ display: "flex" }} >
                                    <span className={classes.gpinfoTitle} > Group Name </span> : &nbsp; &nbsp;<span> <strong>{selectedUser['group_name']}</strong> </span>
                                </div> */}
                                <div style={{ display: "flex" }} >
                                    <span className={classes.gpinfoTitle} > Group name </span> : &nbsp; &nbsp;<span style={{ width: "55%" }}> <div
                                        style={{ textOverflow: "ellipsis", overflow: "hidden", fontWeight: "600", fontFamily: "lato", textAlign: "left", whiteSpace: "nowrap" }}
                                    >{selectedUser['group_name']?selectedUser['group_name']:""}</div> </span>
                                </div>
                                <div style={{ display: "flex" }} >
                                    <span className={classes.gpinfoTitle} > Group Credit Limit </span> : &nbsp; &nbsp; <span><strong>{selectedUser['group_credit_limit']?selectedUser['group_credit_limit']:""}</strong></span>
                                </div>
                                {/* <div style={{ display: "flex" }} >
                                    <span style={{ width: " 30%" }}>  </span>&nbsp; &nbsp; <span> <strong></strong></span>
                                </div> */}
                            </div>
                            <div style={{ width: "50%" }}>
                                {/* <div style={{ display: "flex" }} >
                                    <span style={{ width: " 30%" }}> Primary user Id </span> : &nbsp; &nbsp; <span> <strong>{selectedUser['primary_user_id']}</strong> </span>
                                </div> */}
                                <div style={{ display: "flex" ,paddingTop: "10px" }} >
                                    <span  className={classes.gpinfoTitle2} > Primary user Name</span> : &nbsp; &nbsp; <span style={{ width: "55%" }}> <div
                                        style={{ textOverflow: "ellipsis", overflow: "hidden", fontWeight: "600", fontFamily: "lato", textAlign: "left", whiteSpace: "nowrap" }}
                                    >{selectedUser['group_fullname']?selectedUser['group_fullname'] :""}</div> </span>
                                </div>
                               
                                <div style={{ display: "flex" }} >
                                    <span  className={classes.gpinfoTitle2} > Primary user Mobile</span> : &nbsp; &nbsp; <span><strong>{selectedUser['group_user_mobile']?selectedUser['group_user_mobile']:""} </strong></span>
                                </div>
                                <div style={{ display: "flex" }} >
                                    <span className={classes.gpinfoTitle2} > Primary Business name </span> : &nbsp; &nbsp; <span style={{ width: "55%" }}> <div
                                        style={{ textOverflow: "ellipsis", overflow: "hidden", fontWeight: "600", fontFamily: "lato", textAlign: "left", whiteSpace: "nowrap" }}
                                    >{selectedUser['group_business_name']?selectedUser['group_business_name'] :""}</div> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Paper>}
            </div>
        );
    }
}
GroupMetaInfo.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(GroupMetaInfo);