/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import groupService from '../../app/groupService/groupService';
import SweetAlertPage from '../../app/common/SweetAlertPage';
import Paper from '@material-ui/core/Paper';
import Utils from '../../app/common/utils';
import Loader from './Loader';


const styles = theme => ({
    heading: {
        fontSize: '21px',
        fontWeight: '500',
        marginTop: '0',
        marginBottom: '0',
        fontFamily: 'Montserrat, sans-serif',
    },
    dialogPaper: {
        minWidth: '700px',
        // maxWidth: '700px',
        minHeight: '200px',
        // maxHeight: '500px'
    },
    formAddBtn: {
        width: '90%',
        borderRadius: '10px',
        fontSize: '20px',
        textTransform: 'uppercase',
        backgroundColor: '#4d9fa0 ',
        color: '#fff',
        height: '45px',
        marginBottom: '15px',
        marginTop: "11px",
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    formControl: {
        margin: '0px 5px',
        padding: '0px 10px',
        minWidth: 120,
        color: '#373737 !important',
        borderRight: '1px solid #e6e7e8'
    },
    formRoot: {
        // display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        // marginLeft: '25%',
        border: '1px solid #ccc',
        boxShadow: '2px 2px 6px 0px  rgba(0,0,0,0.3)',
        borderRadius: '4px',
        marginBottom: '20px',
        marginTop: '8%',
        padding: '25px',
        textAlign: 'center'
    },
    gpinfoTitle: {
        width: "45%",
        paddingLeft: "6%",
        textAlign: "left",
        fontFamily: 'Lato !important',
    },
    gpinfoTitle2: {
        width: "42%",
        paddingLeft: "2%",
        textAlign: "left",
        fontFamily: 'Lato !important',
    },
    input: {
        display: 'none',
    },

});

class GroupDetailModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },
            showLoader: false,
            selectedUser: {}
        }
    }

    componentDidMount() {
        this.setState({ showLoader: true }, () => {
            this.getSelectedGroupUser(this.props.selectedGroupId);
        })
    }

    UNSAFE_componentWillReceiveProps() {
        if (this.props !== this.state) {
            this.setState({ open: this.props.open });
        }
    }

    async getSelectedGroupUser(id) {
        try {
            let selectedUserVal = this.state.selectedUser;
            let data = {};
            let respData = [];
            data["connect_group_id"] = id;
            let resp = await groupService.searchGroupList(data);
            if (resp.data.status === 1 && resp.data.result) {
                respData = resp.data.result.data;
            } else {
                respData = [];
            }
            if (respData.length > 0) {
                selectedUserVal = respData[0];
            }
            console.log(selectedUserVal)
            this.setState({ selectedUser: selectedUserVal, showLoader: false })

        } catch (err) {
            console.error(err);
        }
    }

    handleDialogCancel(event) {
        this.props.onAddModalCancel();
    }

    handelSweetAlertClosed() {
        this.setState({ showSweetAlert: false }, () => {
            if (this.state.sweetAlertData["type"] === "success") {
                this.props.onGroupEdited();
            }
        });
    }

    render() {
        const { classes } = this.props;
        const { showLoader, showSweetAlert, sweetAlertData, selectedUser } = this.state;
        console.log(selectedUser)
        return (<div>
            <Dialog style={{ zIndex: '1', display: showSweetAlert ? "none" : "unset" }}
                open={this.state.open}
                classes={{ paper: classes.dialogPaper }}
                disableBackdropClick={true}
                onClose={this.handleDialogCancel.bind(this)}
                aria-labelledby="form-dialog-title"                >
                {!showLoader ? <div>
                    <DialogTitle
                        style={{ background: '#05073a', textAlign: 'center', height: '50px' }}
                        id="form-dialog-title">
                        <p style={{ color: '#fff', marginTop: '-10px', fontFamily: 'Lato', fontSize: '18px' }}>
                            Group Details</p>
                    </DialogTitle>
                    <DialogContent >
                        <Paper className={classes.card} >
                            <div>
                                < div style={{ display: "flex", paddingTop: "10px" }}>
                                    <div style={{ width: "98%" }}>
                                        <div style={{ display: "flex" }} >
                                            <span className={classes.gpinfoTitle} > Group id </span> : &nbsp; &nbsp;<span style={{ width: "55%" }}> <strong>{selectedUser['id'] ? selectedUser['id'] : ""}</strong> </span>
                                        </div>
                                        <div style={{ display: "flex" }} >
                                            <span className={classes.gpinfoTitle} > Group name </span> : &nbsp; &nbsp;<span style={{ width: "55%" }}> <div
                                                style={{ textOverflow: "ellipsis", overflow: "hidden", fontWeight: "600", fontFamily: "lato", textAlign: "left", whiteSpace: "nowrap" }}
                                            >{selectedUser['group_name'] ? selectedUser['group_name'] : ""}</div> </span>
                                        </div>
                                        <div style={{ display: "flex" }} >
                                            <span className={classes.gpinfoTitle}> Group credit limit </span> : &nbsp; &nbsp; <span style={{ width: "55%" }}><strong>{selectedUser['group_credit_limit'] ? selectedUser['group_credit_limit'] : ""}</strong></span>
                                        </div>
                                    </div>
                                    {/* <div style={{ width: "50%" }}>
                                        <div style={{ display: "flex" }} >
                                            <span className={classes.gpinfoTitle2}> Primary user name</span> : &nbsp; &nbsp; <span><strong>{selectedUser['group_fullname'] ? selectedUser['group_fullname'] : ""} </strong></span>
                                        </div>
                                        <div style={{ display: "flex" }} >
                                            <span className={classes.gpinfoTitle2}> Primary user mobile</span> : &nbsp; &nbsp; <span><strong>{selectedUser['group_user_mobile'] ? selectedUser['group_user_mobile'] : ""} </strong></span>
                                        </div>
                                        <div style={{ display: "flex" }} >
                                            <span className={classes.gpinfoTitle2}> Primary user business name</span> : &nbsp; &nbsp; <span> <strong>{selectedUser['group_business_name'] ? selectedUser['group_business_name'] : ""} </strong></span>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button className={classes.formCancelBtn} onClick={this.handleDialogCancel.bind(this)} color="primary">Close</Button>
                    </DialogActions>
                </div> :
                    <Loader primaryText="Please wait.." />}
            </Dialog>

            {showSweetAlert &&
                <SweetAlertPage
                    show={true}
                    type={sweetAlertData.type}
                    title={sweetAlertData.title}
                    text={sweetAlertData.text}
                    sweetAlertClose={() => this.handelSweetAlertClosed()}
                />}
        </div>
        );
    }
}

GroupDetailModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GroupDetailModal);