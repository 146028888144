import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ConfirmDialog from '../../app/common/ConfirmDialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NoDataAvailable from './NoDataAvailable';
import AddTransactionModal from './../payment/components/AddTransactionModal';
import Utils from '../../app/common/utils';
import paymentService from '../../app/paymentService/paymentService';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { getAccessAccordingToRole } from '../../config/appConfig';
import AccountBalanceWalletSharpIcon from '@material-ui/icons/AccountBalanceWalletSharp';
import Loader from './Loader';
import OrderInfoDialog from '../orders/components/OrderInfoDialog';
import razorpay_skipped from "../../assets/images/razorpay_skipped.png";


const styles = theme => ({
    heading: {
        fontSize: '21px',
        fontWeight: '500',
        marginTop: '0',
        marginBottom: '0',
        fontFamily: 'Montserrat, sans-serif',
    },
    dialogPaper: {
        minWidth: '80%',
        // maxWidth: '700px',
        minHeight: '700px',
        // maxHeight: '500px'
    },
    tableCell: {
        paddingLeft: '4px',
        paddingRight: '4px',
        textAlign: 'center',
        maxWidth: '200px'
    },
    formAddBtn: {
        width: '90%',
        borderRadius: '10px',
        fontSize: '20px',
        textTransform: 'uppercase',
        backgroundColor: '#4d9fa0 ',
        color: '#fff',
        height: '45px',
        marginBottom: '15px',
        marginTop: "11px",
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    formRoot: {
        // display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        // marginLeft: '25%',
        border: '1px solid #ccc',
        boxShadow: '2px 2px 6px 0px  rgba(0,0,0,0.3)',
        borderRadius: '4px',
        marginBottom: '20px',
        marginTop: '8%',
        padding: '25px',
        textAlign: 'center'
    }

});

class PaymentTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableHeadData: ["Id", "APP ID", "Supplier Name", "Supplier Bussiness Name", "Linked Order Id", "Created Time", "mode/type", "Amount"],
            tableHeadDataKeys: ["id", "pay_id", "supplier_fullname", "supplier_business_name", "linked_order_id", "createdtime", "payment_mode", "amount"],
            open: this.props.openModal,
            tableData: [],
            showAddTransactionModal: this.props.showAddTransactionModal || false,
            rowsPerPage: 50,
            page: 0,

            showOrderInfo: false,
            orderInfo: undefined,
            totalDataCount: 0,
            isTableDataLoading: false,
            params: {
                limit: 1000, // total amount of data 
                offset: 0 // data from which data needs to be get
            },
            showLoader: false,

            sortKeys: {},
            sortParams: {},
            sortKeysOrder: [],
            sortingEnabled: ["id", "createdtime", "amount"],
        }

    }
    componentDidMount() {
        if (this.props.userdata.role === "la") {
            let tableHeadData = ["Id", "APP ID", "Buyer Name", "Buyer Bussiness Name", "Linked Order Id", "Created Time", "mode/type", "Amount"];
            let tableHeadDataKeys = ["id", "pay_id", "buyer_fullname", "buyer_business_name", "linked_order_id", "createdtime", "payment_mode", "amount"];

            this.setState({ tableHeadData: tableHeadData, tableHeadDataKeys: tableHeadDataKeys });
        }
        this.setState({
            showLoader: true
        }, () =>
            this.getTransactionList()
        )

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.showAddTransactionModal !== this.state.showAddTransactionModal) {
            this.setState({ showAddTransactionModal: nextProps.showAddTransactionModal });
        }
        // if (nextProps.data && nextProps.data !== this.state.tableData) {
        //     this.setState({ tableData: nextProps.data });
        // }
    }


    getTableCellClass(classes, index) {
        return classes.tableCell;
    }

    handleDialogCancel(event) {
        this.props.onEditModalCancel();
    }

    onTransactionDataAdded(event) {
        this.setState({ showAddTransactionModal: false,tableData: [], isTableDataLoading: true, showLoader: true, params: { limit: 1000, offset: 0 } }, function () {
            this.getTransactionList();
            this.props.onPaymentAdded();
        })
    }

    getTransactionList = async () => {
        try {
            let param = { "role": this.props.role, userInfo: true }
            if (Object.keys(this.state.sortParams).length > 0) {
                param["sortkey"] = this.state.sortParams["sortkey"];
                param["sortorder"] = this.state.sortParams["sortorder"];
            }
            param["limit"] = this.state.params["limit"];
            param["offset"] = this.state.params["offset"];
            let resp = await paymentService.getTransactionDetailsOfBuyer(this.props.userdata.mobile, param);
            if (resp.data.status === 1 && resp.data.result) {
                // var respData = resp.data.result;
                // this.setState({ tableData: respData["allTransactions"] });
                let rawData = resp.data.result;
                this.setState({
                    tableData: this.state.tableData.concat(rawData["allTransactions"]),
                    totalDataCount: rawData.totalCount && rawData.totalCount[0] && rawData.totalCount[0]["count"] ? parseInt(rawData.totalCount[0]["count"], 10) : 0,
                    isTableDataLoading: false,
                    showLoader: false
                });
            } else {
                this.setState({
                    tableData: [],
                    isTableDataLoading: false,
                    showLoader: false
                });
            }
        } catch (err) {
            console.error(err);
        }
    }

    getTransactionTypeColor(transaction_type) {
        if (transaction_type === "b_out") {
            return "rgb(212, 58, 58)"; // red
        } else if (transaction_type === "b_in") {
            return "rgb(56, 122, 57)"; // green
        } else {
            return "rgba(0, 0, 0, 0.87)" // default black color
        }
    }

    getBackgroundColor(type) {
        if (type === 'bank') {
            return "#00a700";
        } else if (type === 'cash') {
            return "#f50057";
        } else {
            return "#757575";
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
        if (this.state.tableData.length === (newPage * this.state.rowsPerPage)) {
            this.resetOffsetAndGetData();
        }
    };

    handleChangeRowsPerPage = event => {
        this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
    };

    resetOffsetAndGetData() {
        let paramsval = this.state.params;
        paramsval["offset"] = paramsval["offset"] + 1000;
        this.setState({ params: paramsval, isTableDataLoading: true }, function () {
            this.getTransactionList();
        })
    }


    getStatusOption(event, row) {
        if ((row["transaction_type"] === "b_out" && row["payment_mode"] === "bijak") || (row["transaction_type"] === "b_in" && row["payment_mode"] === "bank")) {

            if (row["transaction_type"] === "b_in" && row["status"] && row["status"].indexOf("pending") > -1) {
                return (
                    <span
                        style={{ paddingRight: "4%" }}
                        data-toggle="tooltip"
                        data-placement="center"
                        title={row["status"]}>
                        <i className="fa fa-circle" style={{ color: "yellow" }} aria-hidden="true"></i></span>)
            } else if (row["transaction_type"] === "b_in" && row["status"] && row["status"].indexOf("validated") > -1) {
                return (
                    <span
                        style={{ paddingRight: "4%" }}
                        data-toggle="tooltip"
                        data-placement="center"
                        title={row["status"]}>
                        <i className="fa fa-circle" style={{ color: "green" }} aria-hidden="true"></i></span>)
            } else if (row["status"] === "transaction_failed") {
                return (<span
                    style={{ paddingRight: "4%" }}
                    data-toggle="tooltip"
                    data-placement="center"
                    title={row["status"]}>
                    <i className="fa fa-circle" style={{ color: "red" }} aria-hidden="true"></i>
                </span>);
            } else if (row["status"] === "transaction_initiated") {
                return (<span
                    style={{ paddingRight: "4%" }}
                    data-toggle="tooltip"
                    data-placement="center"
                    title={row["status"]}>
                    <i className="fa fa-circle" style={{ color: "yellow" }} aria-hidden="true"></i>
                </span>);
            } else if (row["status"] === "payout_reversed" ||
                row["status"] === "payout_cancelled" ||
                row["status"] === "payout_rejected") {
                return (<span
                    style={{ paddingRight: "4%" }}
                    data-toggle="tooltip"
                    data-placement="center"
                    title={row["status"]}>
                    <i className="fa fa-circle" style={{ color: "red" }} aria-hidden="true"></i>
                </span>);
            } else if (row["status"] === "payout_processed") {
                return (<span
                    style={{ paddingRight: "4%" }}
                    data-toggle="tooltip"
                    data-placement="center"
                    title={row["status"]}>
                    <i className="fa fa-circle" style={{ color: "green" }} aria-hidden="true"></i>
                </span>);
            } else if (
                row["status"] === "payout_initiated" ||
                row["status"] === "payout_queued" ||
                row["status"] === "payout_pending" ||
                row["status"] === "payout_processing") {
                return (<span
                    style={{ paddingRight: "4%" }}
                    data-toggle="tooltip"
                    data-placement="center"
                    title={row["status"]}>
                    <i className="fa fa-circle" style={{ color: "yellow" }} aria-hidden="true"></i>
                </span>);
            } else if (row["status"] === "approved") {
                return (
                    <span
                        style={{ paddingRight: "4%" }}
                        data-toggle="tooltip"
                        data-placement="center"
                        title={row["status"]}>
                        <i className="fa fa-circle" style={{ color: "green" }} aria-hidden="true"></i>
                    </span>
                );
            } else if (row["status"] === "pending" || row["status"] === "pending_approved" || row["status"] === null) {
                return (
                    <span
                        style={{ paddingRight: "4%" }}
                        data-toggle="tooltip"
                        data-placement="center"
                        title={row["status"]}>
                        <i className="fa fa-circle" style={{ color: "yellow" }} aria-hidden="true"></i>
                    </span>
                );
            } else if (row["status"] === "failed") {
                return (<span
                    style={{ paddingRight: "4%" }}
                    data-toggle="tooltip"
                    data-placement="center"
                    title={row["status"]}>
                    <i className="fa fa-circle" style={{ color: "red" }} aria-hidden="true"></i>
                </span>)
            } else {
                return (<span
                    style={{ paddingRight: "4%" }}
                    data-toggle="tooltip"
                    data-placement="center"
                    title={row["status"]}>
                    <i className="fa fa-circle" style={{ color: "red" }} aria-hidden="true"></i>
                </span>)
            }
        } else if (row["transaction_type"] === "b_out" && row["payment_mode"] !== "bijak") {

            if (row["status"] === "transaction_failed") {
                return (<span
                    style={{ paddingRight: "4%" }}
                    data-toggle="tooltip"
                    data-placement="center"
                    title={row["status"]}>
                    <i className="fa fa-circle" style={{ color: "red" }} aria-hidden="true"></i>
                </span>);
            } else if (row["status"] === "transaction_initiated") {
                return (<span
                    style={{ paddingRight: "4%" }}
                    data-toggle="tooltip"
                    data-placement="center"
                    title={row["status"]}>
                    <i className="fa fa-circle" style={{ color: "yellow" }} aria-hidden="true"></i>
                </span>);
            } else if (row["status"] === "payout_reversed" ||
                row["status"] === "payout_cancelled" ||
                row["status"] === "payout_rejected") {
                return (<span
                    style={{ paddingRight: "4%" }}
                    data-toggle="tooltip"
                    data-placement="center"
                    title={row["status"]}>
                    <i className="fa fa-circle" style={{ color: "red" }} aria-hidden="true"></i>
                </span>);
            } else if (row["status"] === "payout_processed") {
                return (<span
                    style={{ paddingRight: "4%" }}
                    data-toggle="tooltip"
                    data-placement="center"
                    title={row["status"]}>
                    <i className="fa fa-circle" style={{ color: "green" }} aria-hidden="true"></i>
                </span>);
            } else if (
                row["status"] === "payout_initiated" ||
                row["status"] === "payout_queued" ||
                row["status"] === "payout_pending" ||
                row["status"] === "payout_processing") {
                return (<span
                    style={{ paddingRight: "4%" }}
                    data-toggle="tooltip"
                    data-placement="center"
                    title={row["status"]}>
                    <i className="fa fa-circle" style={{ color: "yellow" }} aria-hidden="true"></i>
                </span>);
            } else if (row["status"] === "approved") {
                return (
                    <span
                        style={{ paddingRight: "4%" }}
                        data-toggle="tooltip"
                        data-placement="center"
                        title={row["status"]}>
                        <i className="fa fa-circle" style={{ color: "green" }} aria-hidden="true"></i>
                    </span>
                );
            } else if (row["status"] === "pending" || row["status"] === "pending_approved" || row["status"] === null) {
                return (
                    <span
                        style={{ paddingRight: "4%" }}
                        data-toggle="tooltip"
                        data-placement="center"
                        title={row["status"]}>
                        <i className="fa fa-circle" style={{ color: "green" }} aria-hidden="true"></i>
                    </span>
                );
            } else if (row["status"] === "failed") {
                return (<span
                    style={{ paddingRight: "4%" }}
                    data-toggle="tooltip"
                    data-placement="center"
                    title={row["status"]}>
                    <i className="fa fa-circle" style={{ color: "red" }} aria-hidden="true"></i>
                </span>)
            } else {
                return (<span
                    style={{ paddingRight: "4%" }}
                    data-toggle="tooltip"
                    data-placement="center"
                    title={row["status"]}>
                    <i className="fa fa-circle" style={{ color: "red" }} aria-hidden="true"></i>
                </span>)
            }
        } else if (row["transaction_type"] === "b_in" && row["status"].indexOf("pending") > -1) {
            return (
                <span
                    style={{ paddingRight: "4%" }}
                    data-toggle="tooltip"
                    data-placement="center"
                    title={row["status"]}>
                    <i className="fa fa-circle" style={{ color: "yellow" }} aria-hidden="true"></i></span>)
        } else if (row["transaction_type"] === "b_in" && row["status"].indexOf("validated") > -1) {
            return (
                <span
                    style={{ paddingRight: "4%" }}
                    data-toggle="tooltip"
                    data-placement="center"
                    title={row["status"]}>
                    <i className="fa fa-circle" style={{ color: "green" }} aria-hidden="true"></i></span>)
        } else {
            return (
                <span >
                    <AccountBalanceWalletSharpIcon style={{ color: "gray", height: "16px" }} />
                </span>);
        }
    }

    onInfoClick = (info, event) => {
        this.setState({
            orderInfo: info, showOrderInfo: true
        })
    }

    onOrderInfoModalClose = () => {
        this.setState({
            orderInfo: undefined, showOrderInfo: false
        })
    }

    addKeysToSortList = (keys) => {
        if (this.state.sortingEnabled.indexOf(keys) > -1) {
            let srtkys = Object.assign({}, this.state.sortKeys);
            let srtKeyOrders = this.state.sortKeysOrder;
            if (srtKeyOrders.indexOf(keys) === -1) {
                srtKeyOrders.splice(0, 0, keys);
            } else {
                srtKeyOrders.splice(srtKeyOrders.indexOf(keys), 1);
                srtKeyOrders.splice(0, 0, keys);
            }

            if (srtkys.hasOwnProperty(keys)) {
                if (srtkys[keys] === "asc") {
                    srtkys[keys] = "desc";
                } else {
                    srtkys[keys] = "asc";
                }
            } else {
                srtkys[keys] = "asc";
            }
            this.setState({ sortKeys: srtkys, sortKeysOrder: srtKeyOrders });
            // ?sortkey=id,fullname,rating&sortorder=asc,asc,asc&limit=2000&offset=0
            let params = {
                sortkey: [],
                sortorder: []
            }
            for (let i = 0; i < srtKeyOrders.length; i++) {
                params["sortkey"].push(srtKeyOrders[i]);
                params["sortorder"].push(srtkys[srtKeyOrders[i]]);
            }
            params["sortkey"] = params["sortkey"].toString();
            params["sortorder"] = params["sortorder"].toString();
            this.setState({
                sortParams: params,
                tableData: [],
                page: 0,
                showLoader: true
            }, () => {
                this.getTransactionList();
            })

        }
    }

    render() {
        const { classes } = this.props;
        const { rowsPerPage, page, totalDataCount, isTableDataLoading, showLoader,
            tableHeadDataKeys, sortKeys, sortingEnabled } = this.state;
        const leftAlignedIndexs = [0, 1, 2];
        const rightAlignedIndexs = [5];
        // console.log(this.state.tableData);
        return (<div style={{ marginTop: '50px' }}>
            {this.state.tableData ?
                <div>
                    <div style={{ width: '100%', marginTop: '50px', maxHeight: "70vh", overflowY: "scroll" }}>
                        <Table stickyHeader aria-label="sticky table" className='table-body'>
                            <TableHead>
                                <TableRow  >
                                    {this.state.tableHeadData.map((option, i) => (
                                        <TableCell
                                            onClick={() => this.addKeysToSortList(tableHeadDataKeys[i])}
                                            key={option}
                                            className={this.getTableCellClass(classes, i)} style={{
                                                minWidth: (i === 0 || i === 1 ? '80px' : "120px"), paddingLeft: i === 0 || i === 1 ? '10px' : ''
                                                , textAlign: leftAlignedIndexs.indexOf(i) > -1 ? "left" : rightAlignedIndexs.indexOf(i) > -1 ? "right" : "",
                                                cursor: sortingEnabled.indexOf(tableHeadDataKeys[i]) > -1 ? "pointer" : "unset",
                                                paddingRight: i === 4 ? "10px" : '', textTransform: 'uppercase'
                                            }}>{option}
                                            {sortingEnabled.indexOf(tableHeadDataKeys[i]) > -1 &&
                                                        <i className={Utils.getArrowIconType(sortKeys, tableHeadDataKeys[i])} aria-hidden="true"  style={{ color : sortKeys.hasOwnProperty(tableHeadDataKeys[i]) ? '#e72e89':'unset'}}></i>}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {!isTableDataLoading && <TableBody>
                                {this.state.tableData && this.state.tableData.length > 0 &&
                                    (rowsPerPage > 0
                                        ? this.state.tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : this.state.tableData
                                    ).map((row, i) => {
                                        return (

                                            <TableRow key={'table_' + i} id={"payment_"+row.id} style={i % 2 !== 0 ? { background: "#e5e8ec" } : { background: "#fff" }}>
                                                <TableCell component="th" style={{ paddingLeft: '10px', textAlign: "left" }} scope="row" className={this.getTableCellClass(classes, 0)}>
                                                    {this.getStatusOption(this, row)}
                                                    {row.id ? row.id : "-"}
                                                </TableCell>
                                                <TableCell component="th" style={{ paddingLeft: '10px', textAlign: "left" }} scope="row" className={this.getTableCellClass(classes, 0)}>
                                                    {row.pay_id ? row.pay_id : "-"}
                                                </TableCell>
                                                {this.props.userdata.role === "la" ?
                                                    <TableCell component="th" style={{ paddingLeft: '10px', textAlign: "left" }} scope="row" className={this.getTableCellClass(classes, 0)}>
                                                        {/* {this.getStatusOption(this, row)} */}
                                                        {row.buyer_fullname ? row.buyer_fullname : "-"}
                                                    </TableCell>
                                                    : <TableCell component="th" style={{ paddingLeft: '10px', textAlign: "left" }} scope="row" className={this.getTableCellClass(classes, 0)}>
                                                        {/* {this.getStatusOption(this, row)} */}
                                                        {row.supplier_fullname ? row.supplier_fullname : "-"}
                                                    </TableCell>}
                                                {this.props.userdata.role === "la" ? <TableCell style={{ textAlign: "left" }} className={this.getTableCellClass(classes, 2)}>
                                                    <div className="text-ellpses">
                                                        {row.buyer_business_name ? row.buyer_business_name : "-"}
                                                    </div>
                                                </TableCell> : <TableCell style={{ textAlign: "left" }} className={this.getTableCellClass(classes, 2)}>
                                                        <div className="text-ellpses">
                                                            {row.supplier_business_name ? row.supplier_business_name : "-"}
                                                        </div>
                                                    </TableCell>
                                                }
                                                <TableCell className={this.getTableCellClass(classes, 3)}>
                                                    <span
                                                        data-toggle="tooltip" data-placement="center" title="info"
                                                        onClick={this.onInfoClick.bind(this, row)}
                                                        style={{ color: "#3f51b5", borderBottom: "2px solid #3f51b5", padding: "0px 2px", cursor: "pointer" }}>
                                                        {row.linked_order_id}
                                                    </span>
                                                </TableCell>

                                                <TableCell className={this.getTableCellClass(classes, 3)}>
                                                    <div className="text-ellpses" style={{ fontSize: '12px' }}>
                                                        {row.createdtime ? Utils.formatDateData(row.createdtime.split("T")[0]) : "-"}
                                                    </div>
                                                </TableCell>

                                                <TableCell className={this.getTableCellClass(classes, 4)} >

                                                    <span style={{
                                                        // color: "white",
                                                        // background: this.getBackgroundColor(row.payment_mode),
                                                        padding: "4px 12px",
                                                        borderRadius: "13px"
                                                    }} >  {row.razx_skipped ? <img width="30px" height="30px" src={razorpay_skipped} alt="" /> : ""}  {(row.payment_mode ? row.payment_mode : "-") + "/" + (row.transaction_type ? row.transaction_type : "-")} </span>
                                                </TableCell>

                                                <TableCell className={this.getTableCellClass(classes, 4)} style={{ color: this.getTransactionTypeColor(row.transaction_type), textAlign: "right", paddingRight: '10px' }}>
                                                    ₹ {row.amount ? row.amount : "-"}
                                                </TableCell>


                                            </TableRow>
                                        );
                                    })}
                            </TableBody>}
                        </Table>
                    </div>
                    {showLoader && !isTableDataLoading && <Loader />}
                    {isTableDataLoading && <Loader />}
                    {this.state.tableData && this.state.tableData.length > 0 && <Table><TableFooter style={{ borderTop: "2px solid #858792" }}>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[25, 50, 100]}
                                colSpan={6}
                                count={totalDataCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={this.handleChangePage.bind(this)}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                            />
                        </TableRow>
                    </TableFooter></Table>}

                    {!showLoader && !isTableDataLoading && this.state.tableData && this.state.tableData.length === 0 && < NoDataAvailable style={{ height: '25vh' }} />}
                </div> : <Loader />}

            {/* <Button className={classes.formCancelBtn} onClick={this.handleAddClick.bind(this)} color="primary">Sumbit</Button> */}
            {/* <Button style={{float:'right',marginRight:'28px'}} onClick={this.handleDialogCancel.bind(this)} color="primary">Cancel</Button> */}

            {getAccessAccordingToRole("addPayment") && this.props.userdata && (this.props.userdata.role === "la" || this.props.userdata.role === "ca") &&
                <div className="updateBtndef" style={{ bottom: "-50px", right: "-5%" }}>
                    <div
                        className="updateBtnFixedModal"
                        style={{ display: 'flex' }}
                        onClick={(event) => this.setState({ showAddTransactionModal: true })}
                    >
                        <i className="fa fa-plus-circle add-icon" aria-hidden="true"></i>
                        <p>Add Payment</p></div>
                </div>}

            {this.state.showOrderInfo &&
                <OrderInfoDialog
                    LoadDataFromApi={true}
                    openModal={this.state.showOrderInfo}
                    data={this.state.orderInfo}
                    onEditModalCancel={this.onOrderInfoModalClose.bind(this)} />}

            {this.state.showAddTransactionModal &&
                <AddTransactionModal
                    open={this.state.showAddTransactionModal}
                    userdata={this.props.userdata}
                    onTransactionAdded={(event) => this.onTransactionDataAdded(event)}
                    onEditModalCancel={(event) => {
                        this.setState({ showAddTransactionModal: false });
                        this.props.onTransactionModalClosed();
                    }}
                />}
            {this.state.showConfirmDialog ?
                <ConfirmDialog
                    dialogText={this.state.dialogText}
                    dialogTitle={this.state.dialogTitle}
                    show={this.state.showConfirmDialog}
                    onConfirmed={this.handelConfirmUpdate}
                    onCanceled={this.handelCancelUpdate} /> : ""}
        </div>
        );
    }
}

PaymentTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PaymentTable);