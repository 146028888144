import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import ReactDOM from 'react-dom';
import FormControl from '@material-ui/core/FormControl';
import AsyncSelect from 'react-select/lib/Async';
import groupService from "../../../app/groupService/groupService";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: '0px 5px',
        padding: '0px 10px',
        minWidth: 120,
        color: '#373737 !important',
        // borderRight: '1px solid #e6e7e8'
    },
    'input': {
        '&::placeholder': {
            color: 'blue'
        }
    },
    bgColor: {
        "& > *": {
            // display: "inline-block",
            fontWeight: "600",
            fontFamily: "Montserrat",
            fontSize: "12px",
            color: "#3e5569",
        }
    }
});

const CustomOption = props => {
    const { data, innerRef, innerProps } = props;
    return data && data.is_primary ? (
        <div ref={innerRef} {...innerProps} className='custom_option'>
            <i className="fa fa-check" style={{ color: "#60c1d8" }} aria-hidden="true"></i> &nbsp; {data.label ? data.label : ""}
        </div>
    ) : (
            <div ref={innerRef} {...innerProps} className='custom_option' >
                {data.label ? data.label : ""}
            </div>

        );
};

class FilterGroupComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            labelWidth: 0,
            groupId: [],
            groupUserDetails: { "groupData": {}, "subUserDetails": [] },
            isGroupDetailsEdited : this.props.isGroupDetailsEdited,

            defaultOption : []
        }
    }

    componentDidMount() {

        if (this.InputLabelRef) {
            this.setState({
                labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
            });
        }
        console.log(this.props.defaultData)
        if (this.props.defaultData && this.props.defaultData['groupData'] && Object.keys(this.props.defaultData['groupData']).length > 0) {
            this.setState({
                groupId: {
                    rawdata: this.props.defaultData["groupData"],
                    label: this.props.defaultData['groupData']['group_name'] + " \n  ( Group primary user mobile : " + this.props.defaultData["groupData"]['group_user_mobile'] + " )",//" \n  ( id : " + this.props.defaultData['groupData']['id'] + " )",
                    value: this.props.defaultData['groupData']['id']
                },
                groupUserDetails: this.props.defaultData
            }, () => {
                console.log(this.state.userid)
            })
        }
        
        if(this.state.defaultOption && this.state.defaultOption.length === 0){
            this.getOptions("userid", "a", (data) => {
                this.setState({ defaultOption: data });
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        
        if (nextProps.defaultData && !nextProps.defaultData["groupData"]) {
            this.setState({ groupId: {} });
        }

        if( nextProps.isGroupDetailsEdited !== this.state.isGroupDetailsEdited ){
            this.setState({ isGroupDetailsEdited: nextProps.isGroupDetailsEdited },()=>{
                if(nextProps.defaultData && nextProps.defaultData["groupData"] && nextProps.defaultData["groupData"]["id"]){
                    this.getSelectedGroupUser(nextProps.defaultData["groupData"]["id"]);
                }
            });
        }
    }

    getSearchAreaText = (id, event) => {
        try {
            let groupUserDetailsVal = this.state.groupUserDetails;
            this.setState({ [id]: event !== null ? event : "" }, () => {
                let sData = {};
                if (event !== null) {
                    sData = event["rawdata"];
                    groupUserDetailsVal["groupData"] = sData;
                    console.log(sData)

                    this.setState({ groupUserDetails: groupUserDetailsVal }, () => {
                        this.getSelectedGroupUser(sData["id"]);
                    });
                } else {
                    groupUserDetailsVal = {};
                    this.setState({ groupUserDetails: groupUserDetailsVal }, () => {
                        this.props.onGroupSelected(groupUserDetailsVal)
                    });
                }
            });
        } catch (err) {
            console.log(err);
        }
    }

    async getSelectedGroupUser(id) {
        try {
            let groupUserDetailsVal = this.state.groupUserDetails;
            this.props.groupDataLoading(groupUserDetailsVal);
            let data = {};
            let respData = [];
            data["connect_group_id"] = id;
            let resp = await groupService.searchGroupList(data);

            if (resp.data.status === 1 && resp.data.result) {
                respData = resp.data.result.data;
                if (respData.length > 0) {
                    groupUserDetailsVal["groupData"]["group_credit_limit"] = respData[0]["group_credit_limit"];
                    groupUserDetailsVal["groupData"]["group_name"] = respData[0]["group_name"];
                }

            } else {
                respData = [];
            }
            groupUserDetailsVal["subUserDetails"] = respData;
            this.setState({ groupUserDetails: groupUserDetailsVal }, () => {
                this.props.onGroupSelected(groupUserDetailsVal);
            })

        } catch (err) {
            console.error(err);
        }
    }


    getOptions = async (type, inputValue, callback) => {
        try {
            if (!inputValue) {
                callback([]);
            }
            let data = {};
            data["searchVal"] = inputValue;
            let resp = await groupService.searchGroupList(data);
            if (resp.data.status === 1 && resp.data.result) {
                var respData = this.formatDataForDropDown(resp.data.result.data, "group_name", "id");
                callback(respData);
            } else {
                callback([]);
            }
        } catch (err) {
            console.error(err);
            callback([]);
        }
    }

    formatDataForDropDown(data, labelKey, valuekey) {
        console.log(data)
        var optionsData = [];
        if (data) {
            for (var i = 0; i < data.length; i++) {
                optionsData.push({
                    rawdata: data[i],
                    label: data[i][labelKey] + " \n  ( Group primary user mobile : " + data[i]['group_user_mobile'] + " )",
                    value: data[i][valuekey]
                });
            }
        }
        return optionsData;
    }

    onRefreshClicked() {
        if (this.state.groupId && Object.keys(this.state.groupId).length > 0) {
            this.getSelectedGroupUser(this.state.groupId["value"] || this.props.defaultData['groupData']['id'])
        }
    }


    render() {
        const { classes } = this.props;
        return (
            <div>
                <Grid container direction="row" alignItems="stretch">
                    <Grid item xs={12} sm={12} md={12}>

                        <form className={classes.root} autoComplete="off" style={{ padding: '15px 0px', backgroundColor: "#fff", color: "#000", borderRadius: "4px" }}>
                            {this.state.groupId && Object.keys(this.state.groupId).length > 0 &&
                                <i className="fa fa-refresh" aria-hidden="true" style={{ color: "#2faecc", paddingTop: "18px", cursor: "pointer" }} onClick={() => this.onRefreshClicked()}></i>}
                            <React.Fragment >

                                <FormControl variant="outlined" className={classes.formControl}
                                    style={{ flex: 1, zIndex: "999" }}>
                                    <AsyncSelect
                                        cacheOptions
                                        value={this.state["groupId"]}
                                        name={"groupId"}
                                        onChange={this.getSearchAreaText.bind(this, "groupId")}
                                        isSearchable={true}
                                        isClearable={true}
                                        placeholder={`Select Group`}
                                        defaultOptions={this.state.defaultOption}
                                        components={{ Option: CustomOption }}
                                        loadOptions={this.getOptions.bind(this, "groupId")}
                                    />
                                </FormControl>

                            </React.Fragment>
                        </form>


                    </Grid>
                </Grid>
            </div >
        );
    }
}

FilterGroupComponent.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FilterGroupComponent);
