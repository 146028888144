const CONSTANTS = {
  COMMON_CONSTANTS: {
    EN: 'en',
    HI: 'hi',
    ENGLISH: 'english',
    HINDI: 'hindi',
    IMAGE_NOT_FOUND_URL: 'https://bijakteaminternal-userfiles-mobilehub-429986086.s3.ap-south-1.amazonaws.com/public/no_data_found.png',
    OFFSET_LIMIT: {
      offset: 0,
      limit: 1000
    },
    OFFSET_LIMIT_500: {
      offset: 0,
      limit: 500
    }
  },
  DSFEED_CONSTANTS: {
    U_ID: 'u_id',
    CONTACT_PHONENUMBER: '8588998844',
    DEMAND: 'demand',
    SUPPLY: 'supply'
  },
  HTTP_CONSTANTS: {
    GET: 'get',
    PUT: 'put',
    POST: 'post',
    DELETE: 'delete'
  },
  ROLE_CONSTANTS: {
    CSUSER: 'CustomerSupportUser',
    MODIFYPOST: 'modifyDemandOrSupply'
  },
  FILTER_ACTIONS: {
    APPROVE:'approve',
    REJECT:'reject',
    PENDING:'pending'
  }
}

export default CONSTANTS;