/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Auth from '@aws-amplify/auth';
import ConfirmDialog from '../../../app/common/ConfirmDialog';
import SweetAlertPage from '../../../app/common/SweetAlertPage';
import Loader from '../../common/Loader';
import Utils from '../../../app/common/utils';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import relationshipManagerService from '../../../app/relationshipManagerService/relationshipManagerService';
import AddRelationShipManagerModal from './AddRelationShipManagerModal';
import EditRelationShipManagerModal from './EditRelationShipManagerModal';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DeleteRelationshipManagerModal from './DeleteRelationshipManagerModal';
import RmFilterSearch from './RmFilterSearch';
import RestoreIcon from '@material-ui/icons/Restore';
import EnableRelationManagerModal from './EnableRelationManagerModal';

const theme = createMuiTheme({
    overrides: {
        MuiTableCell: {
            head: {
                color: '#2e3247',
                fontWeight: 600,
                fontSize: '12px !important',
                fontFamily: 'lato !important',
                textTransform: 'uppercase',
                lineHeight: "1em"

            },
            body: {
                color: 'rgba(0, 0, 0, 0.87)',
                fontWeight: 500,
                fontSize: '14px !important',
                fontFamily: 'lato !important',
            }
        },
        MuiTablePagination: {
            toolbar: {
                paddingRight: '250px'
            }
        },
    }
});

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        verticalAlign: "middle",
        marginRight: theme.spacing.unit,
        fontSize: 10,
    },
    tableCell: {
        paddingLeft: '4px',
        paddingRight: '4px',
        textAlign: 'center',
        maxWidth: '180px',
        padding: '12px',
        maxHeight: '40px',
        whiteSpace: "unset",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    titleText: { width: '50%', textAlign: 'left', paddingLeft: '15px', paddingTop: '7px', fontFamily: 'lato !important', },
    defaultTemplate: { height: '30vh', paddingTop: '10vh', },
    defaultSpan: { display: 'grid', fontSize: '25px' },
    defaultIcon: { fontSize: '65px', color: "#384952" },
    editIcon: { fontSize: '20px', color: "#1e459c", paddingLeft: 3, cursor: 'pointer', marginRight: '2px', float: 'left' },
    infoIcon: { color: '#d46262', fontSize: '18px', cursor: 'pointer' },
    cellDiv: {
        maxWidth: '180px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    root: {
        width: '100%',
        minHeight: '80vh'
    },
    lightTooltip: {
        fontSize: '18px',
        maxWidth: '800px',
    },
    info: {
        fontSize: '18px',
        marginRight: '8px',
        color: '#000000',
        cursor: 'pointer',
        float: 'right'
    },
    name: {
        fontWeight: '600',
        fontSize: '15px',
        color: '#242529'
    },
    container: {
        maxHeight: 440,
    },
    commodityDataClass: {
        display: "-webkit-box",
        "-webkit-line-clamp": "3",
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        fontSize: "12px",
        lineHeight: "unset"
    },
    commodityitem: {
        fontSize: "11px",
    },
    roleCss: {
        height: "28px",
        width: "28px",
        display: "table-cell",
        textAlign: "center",
        color: "#fff",
        verticalAlign: "middle",
        borderRadius: "50%",
        background: "yellow"
    }

});

const colorArray = [
    '#f32379', '#f90496', '#476096', '#f8ba03', '#ba53c0',
    '#2b439a', '#db2929', '#a3b419', '#ffe912', '#d96f06',
    '#437800', '#c92828', '#223999'
]
class RelationshipTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userRole: this.props.userRole || "",
            tableHeadData: ["Emp id", "Name", "Mobile", "Email", "Role", "Zone"],
            tableHeadDataKeys: ["emp_id", "name", "mobile", "email", "role", "zone"],
            tableBodyData: [],
            rawTableBodyData: [],
            editableData: {},
            showServerDialog: false,
            showOptionModal: false,
            anchorEl: null,
            showUserModal: false,
            open: false,
            userData: {},
            userId: null,
            payload: null,
            showOrderModal: false,
            isInfo: false,
            stateList: this.getStateData(),


            rowsPerPage: 50,
            page: 0,
            isLimitUpdate: false,


            totalDataCount: 0,
            isTableDataLoading: false,
            subId: "",

            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },

            filterParams: {},

            //sorting Enabled
            sortKeys: {},
            sortKeysOrder: [],
            sortParams: {},
            sortingEnabled: ["emp_id", "name"],
            params: {
                limit: 1000, // total amount of data 
                offset: 0 // data from which data needs to be get
            },
            showAddRMModal: false,
            showEditRMmodal: false,
            editUserData: undefined,
            showConfirmDialog: false,
            dialogText: "",
            dialogTitle: "",
            deletePayload: {
                "active": false,
                "is_reassign_true": "yes",
                "reassign_id": 2
            },
            rmOption: [],
            searchedText: "",
            showEnableLoader: false,
            sort: {
                column: null,
                direction: 'desc',
            },
            showEnableRmModal: false
        }
    }


    componentDidMount() {
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => this.setState({ subId: user.attributes.sub }))
            .catch(err => console.log(err));

        this.handelFilter({});

    }

    getStateData() {
        let data = Utils.getStateData();
        var optionsData = [];
        if (data) {
            for (var i = 0; i < data.length; i++) {
                optionsData.push({ label: data[i].toLowerCase(), value: data[i].toLowerCase() });
            }
        }
        return optionsData;
    }

    async handelFilter(data) {

        this.setState({ isTableDataLoading: true, filterParams: data });
        let rows = this.state.tableBodyData;
        let respData = {};
        // data["limit"] = 2000;
        // data["offset"] = 0;
        data["limit"] = this.state.params["limit"];
        data["offset"] = this.state.params["offset"];
        if (this.props.userRole !== "all") {
            data["role"] = this.props.userRole;
        }

        if (Object.keys(this.state.sortParams).length > 0 && this.state.sortParams["sortkey"] !== "") {
            data["sortkey"] = this.state.sortParams["sortkey"];
            data["sortorder"] = this.state.sortParams["sortorder"];
        } else {
            delete data["sortkey"];
            delete data["sortorder"];
        }

        let resp = await relationshipManagerService.getRMList(data);
        // console.log(resp.data);
        if (resp.data.status === 1 && resp.data.result) {
            respData = resp.data.result;
            rows = rows.concat(resp.data.result.data);

        } else {
            // alert(resp && resp.data && resp.data.message ? resp.data.message : "Oops an error occured while getting the data");
            let sweetAlrtData = this.state.sweetAlertData;
            sweetAlrtData["type"] = "error";
            sweetAlrtData["title"] = "Error";
            sweetAlrtData["text"] = resp && resp.data && resp.data.message ? resp.data.message : "Oops an error occured while getting the data";
            this.setState({
                showSweetAlert: true,
                sweetAlertData: sweetAlrtData
            });
        }
        // && respData.totalCount[0] && respData.totalCount[0]["count"]
        this.setState({
            tableBodyData: rows,
            totalDataCount: respData.totalCount ? parseInt(respData.totalCount, 10) : 0,
            // page: 0,
            isTableDataLoading: false
        });
    }


    getInfoSTring(obj) {
        return obj.locality ? obj.locality : "-/" + obj.district ? obj.district : "-";
    }

    handleClose(event) {
        this.setState({ open: false, showUserModal: false, showOrderModal: false, isInfo: false }, () =>
            this.resetFilterAndParamsData()
        );
    }

    resetFilterAndParamsData() {
        this.setState({
            tableBodyData: [],
            searchedText: "",
            showLoader: true,
            filterParams: {},
            page: 0,
            sortParams: {},
            sortKeys: {},
            sort: {
                column: null,
                direction: 'desc',
            },
            sortKeysOrder: [],
            params: { limit: 1000, offset: 0 },
        }, () =>
            this.handelFilter({})
        );
    }

    onUpdateMadeRecallApi() {
        this.setState({
            tableBodyData: [],
            showLoader: true,
            filterParams: {},
            sortParams: {},
            sortKeys: {},
            params: { limit: 1000, offset: 0 },
        }, () =>
            this.handelFilter({})
        );
    }

    onHeaderFilterChanged(filterParams) {
        this.setState({
            tableBodyData: [],
            showLoader: true,
            params: { limit: 1000, offset: 0 },
        }, () =>
            this.handelFilter(filterParams)
        );
    }

    onModalCancel(event) {
        this.setState({ open: false, showUserModal: false, showOrderModal: false, isInfo: false });
        if (this.state.isLimitUpdate) {
            this.setState({
                tableBodyData: [],
                showLoader: true,
                params: { limit: 1000, offset: 0 },
            }, () =>
                this.handelFilter(this.state.filterParams))

        }

    }


    handelConfirmUpdate = async () => {

        try {
            this.setState({ showEnableLoader: true, showConfirmDialog: false })
            let payload = {
                active: true
            }
            let userId = this.state.editUserData["id"]
            let resp = await relationshipManagerService.updateRMUser(userId, payload);
            this.setState({ showConfirmDialog: false, alertData: {} });
            let sweetAlrtData = this.state.sweetAlertData;
            if (resp.data.status === 1) {
                sweetAlrtData["type"] = "success";
                sweetAlrtData["title"] = "Success";
                sweetAlrtData["text"] = "Successfully enabled";
            } else {
                sweetAlrtData["type"] = "error";
                sweetAlrtData["title"] = "Error";
                sweetAlrtData["text"] = resp && resp.data && resp.data.message ? resp.data.message : "Oops there was an error, while enabling";
            }
            this.setState({
                showEnableLoader: false,
                showSweetAlert: true,
                sweetAlertData: sweetAlrtData
            });

        } catch (err) {
            console.log(err);
        }
    }

    handelCancelUpdate = () => {
        this.setState({ showConfirmDialog: false, alertData: {} });
    }

    getRole(row) {

        let role = "NA";
        let isGlobal = false;

        if (row.role === "ca") {
            role = "Buyer";
        } else if (row.role === 'la') {
            role = "Supplier";
        } else if (row.role === 'broker') {
            role = "Broker";
        } else {
            role = "NA";
        }

        if (row.hasOwnProperty("global_ca") && row.global_ca) {
            isGlobal = true;
        }
        if (row.hasOwnProperty("global_la") && row.global_la) {
            isGlobal = true;
        }

        return (<span style={{
            color: "white",
            background: this.getBackgroundColor(row),
            padding: "4px 12px",
            borderRadius: "13px"
        }}> {role} {isGlobal ? <i className="fa fa-globe" aria-hidden="true"></i> : ""}</span>)
    }
    getBackgroundColor(obj) {
        if (obj.role === "ca") {
            return "#f94141";
        } else if (obj.role === 'la') {
            return "#00a700";
        } else if (obj.role === 'broker') {
            return "#7070fd";
        } else {
            return "#757575";
        }
    }

    getOrderNoBackgroundColor(obj) {
        if (obj.ordercount === "0" || obj.paymentcount === "0") {
            return "#757575";
        } else {
            return "#377c3b";
        }
    }

    onInfoClick = (info, event) => {
        this.setState({ showUserModal: true, open: true, userData: JSON.parse(JSON.stringify(info)), isInfo: true });
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
        if (this.state.tableBodyData.length === (newPage * this.state.rowsPerPage)) {
            this.resetOffsetAndGetData();
        }
    };

    resetOffsetAndGetData() {
        let paramsval = this.state.params;
        paramsval["offset"] = paramsval["offset"] + 1000;
        this.setState({ params: paramsval, isTableDataLoading: true }, function () {
            this.handelFilter(this.state.filterParams);
        })
    }

    handleChangeRowsPerPage = event => {
        this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
    };

    getNameStr(row) {
        return row.fullname + "(" + row.business_name + ")"
    }

    handelDownloadClicked = () => {
        Utils.downloadDataInCSV(this.state.tableBodyData, this.props.downloadAbleFileName)
    }

    getCommmodityList2(commoditCellList) {
        var formatedCommdityText = "";
        if (commoditCellList) {
            for (var i = 0; i < commoditCellList.length; i++) {
                if (i === 0) {
                    formatedCommdityText = commoditCellList[i].charAt(0).toUpperCase() + commoditCellList[i].slice(1);
                } else {
                    formatedCommdityText = formatedCommdityText + ", " + commoditCellList[i].charAt(0).toUpperCase() + commoditCellList[i].slice(1);
                }
            }
        }
        return formatedCommdityText;
    }

    getCommmodityList(classes, commoditCellList, isTooltip) {
        if (commoditCellList && typeof (commoditCellList) === "object") {
            return (
                <p className={classes.commodityDataClass} style={{ fontSize: isTooltip ? "15px" : "12px" }}>
                    {commoditCellList.map((commodity, i) =>
                        (commodity && commodity !== null && <span key={i + "_commodity"} style={{ fontSize: isTooltip ? "15px" : "12px", fontFamily: "lato" }}>
                            <strong style={{ fontSize: isTooltip ? "14px" : "13px", fontFamily: "lato", color: isTooltip ? "#ff" : (colorArray[i < colorArray.length ? i : i % colorArray.length]) }}>
                                {commodity.charAt(0).toUpperCase()}</strong>{commodity.slice(1) + (i !== commoditCellList.length - 1 ? ", " : "")}
                        </span>)
                    )}
                </p>
            )
        } else {
            return ""
        }
    }

    changeLimitSucces(event) {
        // alert(event);
        if (event) {
            let obj = this.state.userData;
            obj['bijak_credit_limit'] = event;
            this.setState({ userData: obj, isLimitUpdate: true });
        } else {
            this.setState({ isLimitUpdate: true });
        }
    }

    handelSweetAlertClosed() {
        this.setState({ showSweetAlert: false }, () =>
            this.onUpdateMadeRecallApi()
        )
    }

    addKeysToSortList = (keys) => {
        if (this.state.sortingEnabled.indexOf(keys) > -1) {
            let srtkys = Object.assign({}, this.state.sortKeys);
            let srtKeyOrders = this.state.sortKeysOrder;
            if (srtKeyOrders.indexOf(keys) === -1) {
                srtKeyOrders.splice(0, 0, keys);
            } else {
                srtKeyOrders.splice(srtKeyOrders.indexOf(keys), 1);
                srtKeyOrders.splice(0, 0, keys);
            }

            if (srtkys.hasOwnProperty(keys)) {
                if (srtkys[keys] === "asc") {
                    srtkys[keys] = "desc";
                } else {
                    srtkys[keys] = "asc";
                }
            } else {
                srtkys[keys] = "asc";
            }
            this.setState({ sortKeys: srtkys, sortKeysOrder: srtKeyOrders });
            let params = {
                sortkey: [],
                sortorder: []
            }
            for (let i = 0; i < srtKeyOrders.length; i++) {
                params["sortkey"].push(srtKeyOrders[i]);
                params["sortorder"].push(srtkys[srtKeyOrders[i]]);
            }
            params["sortkey"] = params["sortkey"].toString();
            params["sortorder"] = params["sortorder"].toString();

            this.setState({
                sortParams: params
            }, () =>
                this.onHeaderFilterChanged(this.state.filterParams)
            );
        }
    }

    onFilterChanged(serachTxt) {
        // this.setState({
        //     tableBodyData: [],
        //     showLoader: true,
        //     page: 0,
        //     params: { limit: 1000, offset: 0 },
        // }, () =>
        //     this.handelFilter(params)
        // );
        this.setState({ searchedText: serachTxt })
    }

    handleClickOpen(event) {
        this.setState({ showAddRMModal: true });
    }

    onEditClicked(row) {
        this.setState({ showEditRMmodal: true, editUserData: row });
    }

    handleCheckbox(event) {

        console.log(event.target.value);
        let obj = this.state.deletePayload;
        obj["is_reassign_true"] = event.target.value;
        // if (id === "yes") {
        //     obj["is_reassign_true"] = true;
        // } else {
        //     obj["is_reassign_true"] = false;
        // }
        this.setState({ deletePayload: obj }, () => {
            this.setState({ dialogText: this.getDeleteTextHtml(obj["is_reassign_true"]) });
        });
    }

    getSelectionDropDown() {
        let rmOption = [];
        for (let i = 0; i < this.state.tableBodyData.length; i++) {
            let rmData = this.state.tableBodyData[i];
            if (this.state.editUserData["id"] !== rmData["id"]) {
                let obj = { value: rmData["id"], label: rmData["name"] }
                rmOption.push(obj)
            }
        }
        return rmOption;
    }

    getDeleteTextHtml(val) {
        return (<div>
            <FormControl component="fieldset">
                <FormLabel component="legend"> {val}</FormLabel>
            </FormControl>
        </div>);
    }

    onDeleteClicked(row) {
        this.setState({
            showDeleteRmModal: true,
            editUserData: row
        });
    }

    onEnableRMClicked(row) {
        this.setState({
            showEnableRmModal: true,
            editUserData: row
        });
    }

    setArrow = (column) => {
        let iconClass = 'fa fa-arrow-';

        if (this.state.sort.column === column) {
            iconClass += this.state.sort.direction === 'asc' ? 'down' : 'up';
        } else {
            iconClass = iconClass + 'down';
        }
        return iconClass;
    };

    onSort = (column) => {
        if (this.state.sortingEnabled.indexOf(column) > -1) {
            if (column !== "") {
                return e => {
                    const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc'
                    const sortedUsers = this.state.tableBodyData.sort((a, b) => {
                        if (a[column] && a[column] !== null && b[column] && b[column] !== null) {
                            if (typeof (a[column]) === 'string') {
                                const nameA = a[column].toUpperCase() // ignore upper and lowercase
                                const nameB = b[column].toUpperCase() // ignore upper and lowercase

                                if (nameA < nameB)
                                    return -1
                                if (nameA > nameB)
                                    return 1
                                else return 0
                            }
                            else {
                                return a[column] - b[column]
                            }
                        }
                    })

                    if (direction === 'desc') {
                        sortedUsers.reverse()
                    }
                    this.setState({
                        tableBodyData: sortedUsers,
                        sort: {
                            column,
                            direction,
                        },
                    })
                }
            }
        }
    }

    render() {
        // sortKeys,
        const { classes, showLoader } = this.props;
        const { rowsPerPage, page, totalDataCount, isTableDataLoading,
            showSweetAlert, sweetAlertData, tableHeadDataKeys, sortingEnabled, searchedText } = this.state;
        return (
            <MuiThemeProvider theme={theme}>
                <Paper className={classes.root} >
                    <div style={{ display: 'flex' }}>
                        <RmFilterSearch
                            stateList={this.state.stateList}
                            role={this.props.role}
                            onRefreshButtonClicked={(event) => this.resetFilterAndParamsData(event)}
                            showLoader={isTableDataLoading}
                            onHeaderFilterChange={this.onFilterChanged.bind(this)}
                        />
                    </div>
                    {!showLoader && <div>
                        <div style={{ maxHeight: "70vh", overflowY: "scroll" }}>
                            <Table className='table-body' stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow style={{ borderBottom: "2px solid #858792" }} >
                                        {this.state.tableHeadData.map((option, i) => (
                                            <TableCell
                                                key={i + "_"}
                                                // onClick={() => this.addKeysToSortList(tableHeadDataKeys[i])}
                                                onClick={this.onSort(tableHeadDataKeys[i])}
                                                className={classes.tableCell}
                                                style={{
                                                    textAlign: i === 1 || i === 0 ? "left" : "center",
                                                    minWidth: i === 5 ? '50px' : '100px',
                                                    paddingLeft: i === 0 ? '22px' : '',
                                                    cursor: sortingEnabled.indexOf(tableHeadDataKeys[i]) > -1 ? "pointer" : "unset"
                                                }}>
                                                {option}
                                                {sortingEnabled.indexOf(tableHeadDataKeys[i]) > -1 &&
                                                    // <i className={Utils.getArrowIconType(sortKeys, tableHeadDataKeys[i])}
                                                    //     aria-hidden="true"
                                                    //     style={{ color: sortKeys.hasOwnProperty(tableHeadDataKeys[i]) ? '#e72e89' : 'unset' }}>
                                                    // </i>
                                                    <i className={this.setArrow(tableHeadDataKeys[i])} aria-hidden="true" style={{ color: this.state.sort.column && this.state.sort.column === tableHeadDataKeys[i] ? 'rgb(231, 46, 137)' : 'unset' }}></i>
                                                }
                                            </TableCell>
                                        ))}

                                        <TableCell
                                            className={classes.tableCell}
                                            style={{
                                                textAlign: "center",
                                                minWidth: '50px', maxWidth: "50px",
                                                // paddingLeft: '22px'
                                            }}>Action
                                        </TableCell>

                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {!isTableDataLoading && this.state.tableBodyData && this.state.tableBodyData &&
                                        (rowsPerPage > 0
                                            ? this.state.tableBodyData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : this.state.tableBodyData
                                        ).filter(e => {
                                            if (e.name.toLowerCase().indexOf(searchedText.toLowerCase()) > -1 ||
                                                e.mobile.indexOf(searchedText) > -1 ||
                                                e.email.toLowerCase().indexOf(searchedText.toLowerCase()) > -1 ||
                                                e.emp_id.toLowerCase().indexOf(searchedText.toLowerCase()) > -1 ||
                                                (e.role && e.role !== null && e.role.toLowerCase().indexOf(searchedText.toLowerCase()) > -1) ||
                                                (e.zone && e.zone !== null && e.zone.toLowerCase().indexOf(searchedText.toLowerCase()) > -1)
                                            ) {
                                                return e;
                                            } else { return null; }
                                        })
                                            .map((row, i) => {
                                                return (
                                                    <TableRow key={'table_' + i} id={"user_" + row.id} style={{ background: i % 2 !== 0 ? "#e8e8e8" : "#fff" }}>
                                                        <TableCell component="th" scope="row" className={classes.tableCell} style={{ textAlign: "left", paddingLeft: "10px" }}>

                                                            <div style={{ display: "inline-table" }}>
                                                                <Tooltip title={row.active ? "Active" : "Disabled"} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                                    <div className={classes.cellDiv} style={{
                                                                        color: "#fff",
                                                                        background: row.active ? "green" : "red",
                                                                        padding: "4px 12px",
                                                                        width: 'fit-content',
                                                                        marginLeft: '2%',
                                                                        borderRadius: "13px"
                                                                    }}> {row.emp_id}</div>
                                                                </Tooltip>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" className={classes.tableCell} style={{ maxWidth: "140px", textAlign: "left" }}>
                                                            <Tooltip title={row.name ? row.name : ""} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                                <div className={classes.cellDiv}>
                                                                    {row.name ? row.name : ""}
                                                                </div>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell
                                                            style={{ maxWidth: "140px" }}
                                                            className={classes.tableCell}>
                                                            {Utils.maskMobileNumber(row.mobile)}
                                                        </TableCell>

                                                        <TableCell className={classes.tableCell}>
                                                            <Tooltip title={row.email ? row.email : "-"} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                                <div className="text-ellpses" >{row.email}</div>
                                                            </Tooltip>
                                                        </TableCell>


                                                        <TableCell className={classes.tableCell} >
                                                            {row.role && row.role.length > 30 ?
                                                                <Tooltip title={row.role ? row.role : ""} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                                    <div className="text-ellpses">{row.role ? row.role : ""}</div>
                                                                </Tooltip>
                                                                :
                                                                (row.role ? row.role : "")}
                                                        </TableCell>


                                                        <TableCell className={classes.tableCell}>
                                                            {/* <Tooltip title={row.zone ? row.zone : "-"} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                                <div className="text-ellpses">{row.zone ? row.zone : "-"}</div>
                                                            </Tooltip> */}
                                                            {row.zone && row.zone.length > 30 ?
                                                                <Tooltip title={row.zone ? row.zone : "-"} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                                    <div className="text-ellpses">{row.zone ? row.zone : ""}</div>
                                                                </Tooltip>
                                                                :
                                                                (row.zone ? row.zone : "")}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <div style={{ display: "inline-flex", "float": "right" }}>
                                                                &nbsp;
                                                                <Tooltip title={"Edit"} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                                    <div><EditIcon
                                                                        className="material-Icon"
                                                                        onClick={() => this.onEditClicked(row)}
                                                                        style={{ color: "#e72e89", cursor: "pointer", height: "18px", fontSize: "18px" }} />
                                                                    </div>
                                                                </Tooltip>
                                                                    &nbsp;
                                                                    &nbsp;

                                                                {row.active ?
                                                                    <Tooltip title={"Disable relationship manager"} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                                        <div><DeleteForeverIcon
                                                                            onClick={() => { if (row.active) { this.onDeleteClicked(row) } }}
                                                                            className="material-Icon"
                                                                            style={{ color: "red", cursor: "pointer", height: "18px", fontSize: "18px" }} />
                                                                        </div>
                                                                    </Tooltip>
                                                                    :
                                                                    <Tooltip title={"Enable relationship manager"} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                                        <div><RestoreIcon
                                                                            onClick={() => { if (!row.active) { this.onEnableRMClicked(row) } }}
                                                                            className="material-Icon"
                                                                            style={{ color: "green", cursor: "pointer", height: "18px", fontSize: "18px" }} />
                                                                        </div>
                                                                    </Tooltip>}
                                                            </div>
                                                        </TableCell>

                                                    </TableRow>

                                                );
                                            })}
                                </TableBody>

                            </Table>

                        </div>
                        {isTableDataLoading && <Loader />}
                        {this.state.tableBodyData.length > 0 && searchedText.length === 0 &&
                            <Table>
                                <TableFooter style={{ borderTop: "2px solid #858792", background: "#fafafa" }}>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[25, 50, 100]}
                                            colSpan={6}
                                            count={totalDataCount}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page' },
                                                native: true,
                                            }}
                                            onChangePage={this.handleChangePage.bind(this)}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>}
                        {this.state.tableBodyData.filter(e => {
                            if (e.name.toLowerCase().indexOf(searchedText.toLowerCase()) > -1 ||
                                e.mobile.indexOf(searchedText) > -1 ||
                                e.email.toLowerCase().indexOf(searchedText.toLowerCase()) > -1 ||
                                e.emp_id.toLowerCase().indexOf(searchedText.toLowerCase()) > -1 ||
                                (e.role && e.role !== null && e.role.toLowerCase().indexOf(searchedText.toLowerCase()) > -1) ||
                                (e.zone && e.zone !== null && e.zone.toLowerCase().indexOf(searchedText.toLowerCase()) > -1)
                            ) {
                                return e;
                            } else {
                                return null;
                            }
                        }).length > 0 ? "" :
                            <div className={classes.defaultTemplate}>
                                {searchedText.length > 0 ?
                                    <span className={classes.defaultSpan}>
                                        <i className={classes.defaultIcon + " fa fa-frown-o"} aria-hidden="true"></i>
                                        {"Your search does not match any list"} </span> : !showLoader ? <span className={classes.defaultSpan}>
                                            <i className={classes.defaultIcon + " fa fa-frown-o"} aria-hidden="true"></i>{"No data available"}</span> : ""}
                            </div>}


                    </div>}

                    <div className="updateBtndef">
                        <div className="updateBtnFixed" style={{ display: 'flex' }}
                            onClick={this.handleClickOpen.bind(this)}>
                            <i className="fa fa-plus-circle add-icon" aria-hidden="true"></i>
                            <p style={{
                                fontSize: "14px",
                                fontFamily: "lato",
                                fontWeight: 600
                            }}>Add Relationship Manager</p>
                        </div>
                    </div>

                    {this.state.showAddRMModal &&
                        <AddRelationShipManagerModal
                            openModal={this.state.showAddRMModal}
                            onAddModalCancel={() => this.setState({ showAddRMModal: false })}
                            onRmAddedSuccess={() => { this.setState({ showAddRMModal: false }, () => this.onUpdateMadeRecallApi()); }} />}

                    {this.state.showEditRMmodal &&
                        <EditRelationShipManagerModal
                            openModal={this.state.showEditRMmodal}
                            updatePayload={this.state.editUserData}
                            onEditModalCancel={() => this.setState({ showEditRMmodal: false, editUserData: undefined })}
                            onRmUpdatedSuccess={() => { this.setState({ showEditRMmodal: false, editUserData: undefined }, () => this.onUpdateMadeRecallApi()); }} />}

                    {this.state.showDeleteRmModal &&
                        <DeleteRelationshipManagerModal
                            open={this.state.showDeleteRmModal}
                            rmList={this.state.tableBodyData}
                            deleteDataRow={this.state.editUserData}
                            onDeleteModalCancel={() => this.setState({ showDeleteRmModal: false, editUserData: undefined })}
                            onRmDeleteSuccess={() => { this.setState({ showDeleteRmModal: false, editUserData: undefined }, () => this.onUpdateMadeRecallApi()); }} />}

                    {this.state.showEnableRmModal &&
                        <EnableRelationManagerModal
                            open={this.state.showEnableRmModal}
                            editUserData={this.state.editUserData}
                            onEnableModalCancel={() => this.setState({ showEnableRmModal: false, editUserData: undefined })}
                            onRmEnabledSuccess={() => { this.setState({ showEnableRmModal: false, editUserData: undefined }, () => this.onUpdateMadeRecallApi()); }} />}

                    {this.state.showConfirmDialog ?
                        <ConfirmDialog
                            dialogText={this.state.dialogText}
                            dialogTitle={this.state.dialogTitle}
                            show={this.state.showConfirmDialog}
                            onConfirmed={this.handelConfirmUpdate}
                            onCanceled={this.handelCancelUpdate} /> : ""}

                    {showSweetAlert &&
                        <SweetAlertPage
                            show={true}
                            type={sweetAlertData.type}
                            title={sweetAlertData.title}
                            text={sweetAlertData.text}
                            sweetAlertClose={() => this.handelSweetAlertClosed()}
                        />}
                    {this.state.showEnableLoader && <div style={{
                        zIndex: "999",
                        top: "25%",
                        background: "white",
                        position: "fixed",
                        margin: "auto 24%"
                    }}><Loader /></div>}
                </Paper>
            </MuiThemeProvider>
        );
    }
}

RelationshipTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RelationshipTable);