/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AsyncSelect from 'react-select/lib/Async';
import Loader from '../../common/Loader';
import Utils from '../../../app/common/utils';
import SweetAlertPage from '../../../app/common/SweetAlertPage';
import groupService from '../../../app/groupService/groupService';


const styles = theme => ({
    heading: {
        fontSize: '21px',
        fontWeight: '500',
        marginTop: '0',
        marginBottom: '0',
        fontFamily: 'Montserrat, sans-serif',
    },
    dialogPaper: {
        minWidth: '700px',
        // maxWidth: '700px',
        minHeight: '250px',
        // maxHeight: '500px'
    },
    formAddBtn: {
        width: '90%',
        borderRadius: '10px',
        fontSize: '20px',
        textTransform: 'uppercase',
        backgroundColor: '#4d9fa0 ',
        color: '#fff',
        height: '45px',
        marginBottom: '15px',
        marginTop: "11px",
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    formControl: {
        margin: '0px 5px',
        padding: '0px 10px',
        minWidth: 120,
        color: '#373737 !important',
        borderRight: '1px solid #e6e7e8'
    },
    formRoot: {
        // display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        // marginLeft: '25%',
        border: '1px solid #ccc',
        boxShadow: '2px 2px 6px 0px  rgba(0,0,0,0.3)',
        borderRadius: '4px',
        marginBottom: '20px',
        marginTop: '8%',
        padding: '25px',
        textAlign: 'center'
    },
    input: {
        display: 'none',
    },

});
// const statusOption = ["pending", "settled", "partial_settled"];

const CustomOption = props => {
    const { data, innerRef, innerProps } = props;
    return data && data.is_primary ? (
        <div ref={innerRef} {...innerProps} className='custom_option'>
            <i className="fa fa-check" style={{ color: "#60c1d8" }} aria-hidden="true"></i> &nbsp; {data.label ? data.label : ""}
        </div>
    ) : (
            <div ref={innerRef} {...innerProps} className='custom_option' >
                {data.label ? data.label : ""}
            </div>

        );
};

class AddUserInGroupModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,

            errorFields: {},
            showLoader: false,

            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },

            // new variables
            primaryid: [],
            userid: [],
            payloadData: {
                "credit_limit": this.props.selectedData["group_credit_limit"],
                "group_name": this.props.selectedData["group_name"],
                "id": this.props.selectedData["id"]
            },
            defaultOption:[]
        }
    }


    UNSAFE_componentWillReceiveProps() {
        if (this.props !== this.state) {
            this.setState({ open: this.props.open });
        }
    }
    
    componentDidMount(){
        this.getOptions("userid", "a", (data) => {
            this.setState({ defaultOption: data });
        });
    }

    handleDialogCancel(event) {
        this.props.onAddModalCancel();
    }

    getOptions = async (type, inputValue, callback) => {
        try {
            if (!inputValue) {
                callback([]);
            }
            let data = {};
            data["searchVal"] = inputValue;
            data["role"]= "ca";
            let resp = await groupService.searchUserList(data);
            if (resp.data.status === 1 && resp.data.result) {
                var respData = this.formatDataForDropDown(resp.data.result.data, "fullname", "id");
                callback(respData);
            } else {
                callback([]);
            }
        } catch (err) {
            console.error(err);
            callback([]);
        }
    }

    formatDataForDropDown(data, labelKey, valuekey) {
        var optionsData = [];
        if (data) {
            for (var i = 0; i < data.length; i++) {
                if ((!data[i]['is_primary'] || data[i]['is_primary'] === 'false')) {
                    optionsData.push({
                        rawdata: data[i],
                        is_primary: Utils.checkIfPrimaryKey(data[i]),
                        label: data[i]["fullname"] + ",  " + data[i]["business_name"] + " \n  (" + data[i]["locality"] + " , " + data[i][valuekey] + " )",
                        value: data[i][valuekey]
                    });
                }
            }
        }
        return optionsData;
    }


    getSearchAreaText = (id, event) => {
        try {
            console.log(event);
            let payloadDataVal = this.state.payloadData;
            let errorFields = this.state.errorFields;
            if (event && event.length < 6) {
                //continue selecting
            } else {
                let sweetAlertDataVal = this.state.sweetAlertData;
                sweetAlertDataVal["title"] = "Info";
                sweetAlertDataVal["type"] = "warning";
                sweetAlertDataVal["text"] = "You can add max 5 user at a time";
                this.setState({ sweetAlertData: sweetAlertDataVal, showSweetAlert: true })
                return;
            }
            this.setState({ [id]: event !== null ? event : "" }, () => {
                payloadDataVal["user_data"] = [];
                for (let i = 0; i < event.length; i++) {
                    if (event !== null) {
                        payloadDataVal["user_data"].push(event[i]["value"]);
                    } else {
                        payloadDataVal["user_data"] = [];
                    }
                }
                console.log(payloadDataVal)
                errorFields[id] = false;
                this.setState({ payloadDataVal: payloadDataVal, errorFields: errorFields });
            });
        } catch (err) {
            console.log(err);
        }
    }

    checkForValidPayload(data) {
        let isvalid = true;
        let sweetAlertDataVal = this.state.sweetAlertData;
        sweetAlertDataVal["title"] = "Error";
        sweetAlertDataVal["type"] = "error";
        sweetAlertDataVal["text"] = "";
        let errorFields = {};
        if (this.state.userid && this.state.userid.length > 0) {
            let userName = "";
            for (let i = 0; i < this.state.userid.length; i++) {
                if (this.state.userid[i]["rawdata"] && this.state.userid[i]["rawdata"]["is_connected_to_group"]) {
                    userName = this.state.userid[i]["label"] + (i !== 0 ? (", " + userName) : "")
                }
            }
            if (userName !== "") {
                isvalid = false;
                errorFields["userid"] = true;
                sweetAlertDataVal["text"] = userName + " . This user already exists in another group. So please select other user/users."
            }
        } else {
            errorFields = {};
            isvalid = false;
        }
        if (sweetAlertDataVal["text"] !== "") {
            this.setState({ sweetAlertData: sweetAlertDataVal, showSweetAlert: true })
        }
        this.setState({ errorFields: errorFields });
        return isvalid;
    }

    async handelCreateGroup() {
        try {
            if (this.checkForValidPayload(this.state.payloadData)) {
                this.setState({ showLoader: true });
                let lastIndex = this.state.userid.length - 1;
                for (let i = 0; i < this.state.userid.length; i++) {
                    this.attachUserToGroup(this.state.userid[i]["value"], i === lastIndex);
                }
            }
        } catch (err) {
            console.log(err);
        }
    }


    async attachUserToGroup(userId, isLastIndex) {
        try {
            let payload = {
                "connect_group_id": this.state.payloadData["id"],
                "is_connected_to_group": true
            };
            let resp = await groupService.attacheDetacheUserApi(userId, payload);
            let sweetAlertDataVal = this.state.sweetAlertData;
            if (resp.data.status === 1 && resp.data.result) {
                // 
                sweetAlertDataVal["type"] = "success",
                    sweetAlertDataVal["title"] = "Success",
                    sweetAlertDataVal["text"] = "User attached successfully";
            } else {
                sweetAlertDataVal["type"] = "error",
                    sweetAlertDataVal["title"] = "Error",
                    sweetAlertDataVal["text"] = resp && resp.data && resp.data.message ? resp.data.message : "Oops an error occured while attaching the user in the group";
            }
            if (isLastIndex) {
                this.setState({ sweetAlertData: sweetAlertDataVal, showSweetAlert: true, showLoader: false })
            }

        } catch (err) {
            console.log(err);
            sweetAlertDataVal["type"] = "error",
                sweetAlertDataVal["title"] = "Error",
                sweetAlertDataVal["text"] = "Oops an error occured while attaching the user in the group";
            this.setState({ sweetAlertData: sweetAlertDataVal, showSweetAlert: true, showLoader: false })
        }
    }

    handelSweetAlertClosed() {
        this.setState({ showSweetAlert: false }, () => {
            if (this.state.sweetAlertData["type"] === "success") {
                this.props.onUserAdded();
            }
        });
    }

    render() {
        const { classes } = this.props;
        const { showLoader, primaryid, userid, payloadData, errorFields, showSweetAlert,
            sweetAlertData } = this.state;

        return (<div>
            <Dialog style={{ zIndex: '1' , display : showSweetAlert ? "none":"unset" }}
                open={this.state.open}
                classes={{ paper: classes.dialogPaper }}
                disableBackdropClick={true}
                onClose={this.handleDialogCancel.bind(this)}
                aria-labelledby="form-dialog-title"                >
                {!showLoader ? <div>
                    <DialogTitle
                        style={{ background: '#05073a', textAlign: 'center', height: '50px' }}
                        id="form-dialog-title">
                        <p style={{ color: '#fff', marginTop: '-10px', fontFamily: 'Lato', fontSize: '18px' }}>
                            Add a new Group</p>
                    </DialogTitle>
                    <DialogContent >
                        <div style={{ display: "flex" }} >
                            <TextField
                                margin="dense"
                                id="group_name"
                                label="Group name"
                                type="text"
                                disabled={true}
                                error={errorFields["group_name"] ? true : false}
                                style={{ width: '49%' }}
                                value={payloadData.group_name}
                                // onChange={this.handleInputChange.bind(this)}
                                fullWidth />
                            &nbsp;
                            &nbsp;
                            <TextField
                                margin="dense"
                                id="credit_limit"
                                label="Credit Limit"
                                type="text"
                                disabled={true}
                                error={errorFields["credit_limit"] ? true : false}
                                style={{ width: '49%' }}
                                value={payloadData.credit_limit}
                                // onChange={this.handleInputChange.bind(this)}
                                fullWidth />

                        </div>

                        <div style={{ display: "flex", paddingTop: "10px" }}>
                            <div style={{ borderBottom: errorFields["userid"] ? "2px solid red" : "1px solid gray", width: "98%" }}>
                                <AsyncSelect
                                    isMulti
                                    value={userid}
                                    // menuIsOpen={true}
                                    name={"userid"}
                                    onChange={this.getSearchAreaText.bind(this, "userid")}
                                    isSearchable={true}
                                    isClearable={true}
                                    placeholder={`Select user`}
                                    menuPortalTarget={document.body}
                                    defaultOptions={this.state.defaultOption}
                                    components={{ Option: CustomOption }}
                                    loadOptions={this.getOptions.bind(this, "userid")}
                                />
                            </div>
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button className={classes.formCancelBtn} onClick={this.handelCreateGroup.bind(this)} color="primary">Add</Button>
                        <Button className={classes.formCancelBtn} onClick={this.handleDialogCancel.bind(this)} color="primary">Cancel</Button>
                    </DialogActions>
                </div> :
                    <Loader primaryText="Please wait.." />}
            </Dialog>

            {showSweetAlert &&
                <SweetAlertPage
                    show={true}
                    type={sweetAlertData.type}
                    title={sweetAlertData.title}
                    text={sweetAlertData.text}
                    sweetAlertClose={() => this.handelSweetAlertClosed()}
                />}
        </div>
        );
    }
}

AddUserInGroupModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddUserInGroupModal);