import Api from '../../config/dev';
import Utils from '../common/utils';

let logsService = {
    // https://7t9k622sm0.execute-api.ap-south-1.amazonaws.com/dev/user/getEventList
    getEventList: async function (param) {
        return Api({
            method: 'get',
            headers: {
                "Authorization": Utils.getToken()
            },
            url: '/user/getEventList',
            params : param
        },5);
    },
    getEventDataByEventId : async function (id) {
        // https://7t9k622sm0.execute-api.ap-south-1.amazonaws.com/dev/user/getEventDetail/546
        return Api({
            method: 'get',
            headers: {
                "Authorization": Utils.getToken()
            },
            url: '/user/getEventDetail/'+id
        },5);
    },
    getEventData : async function (param) {
        //  https://7t9k622sm0.execute-api.ap-south-1.amazonaws.com/dev/user/getEventData?type=payment&id=812
        return Api({
            method: 'get',
            headers: {
                "Authorization": Utils.getToken()
            },
            url: '/user/getEventData',
            params : param
        },5);
    },
    getAdminUserList : async function (param) {
        //https://7t9k622sm0.execute-api.ap-south-1.amazonaws.com/dev/user/getUserList
        return Api({
            method: 'get',
            headers: {
                "Authorization": Utils.getToken()
            },
            url: '/user/getUserList',
            params : param
        },5);
    },
};

export default logsService;