import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./app/MainApp";
import ConfigureStore from "./store/ConfigureStore";
import registerServiceWorker from "./registerServiceWorker";
// import Amplify from 'aws-amplify';
import Amplify from '@aws-amplify/core'
import "./assets/css/index.css";
import { getAwsConfig } from "./config/appConfig";

Amplify.configure(getAwsConfig());

const store = ConfigureStore();

ReactDOM.render(
	<Provider store={store}>
	  <Router>
	    <App />
	  </Router>
	</Provider>,
  document.getElementById("root")
);
registerServiceWorker();