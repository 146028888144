/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Tooltip from "@material-ui/core/Tooltip";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import Fab from "@material-ui/core/Fab";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CONSTANTS from "../../utils/constants";
var moment = require("moment");


import CSPostModal from "../dsFeed/common/CSPostModal";

export default class CommonTableTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfoModal: false,
      csPostData: null
    }
  }

  formatDateAndTime = (dateval) => {
    let gmtDateTime = moment.utc(dateval, "YYYY-MM-DD HH:mm")
    let fdate = gmtDateTime.local().format('YYYY-MMM-DD h:mm A')
    return (
      <div style={{ width: "95px", display: "inline-block" }}>
        {" "}
        {fdate.split(" ")[0] +
          " \n" +
          fdate.split(" ")[1] +
          " " +
          fdate.split(" ")[2]}
      </div>
    );
  };

  handlePostIdClick = (event, row) => {
    const csPostData = { ...row };

    // add buyer details
    csPostData['buyerid'] = {
      is_primary: false,
      label: `${csPostData.user_data.first_name}, ${csPostData.user_data.business_name} (${csPostData.user_data.location_area}, ${csPostData.user_data.username})`,
      value: csPostData.user_data.username,
      id: csPostData.user_data.user_id
    }
    this.setState({
      showInfoModal: true,
      csPostData
    })
  }

  onModalCancel(event) {
    this.setState({ showInfoModal: false, csPostData: null })
  }

  onPostUpdated() {
    this.setState({ showInfoModal: false, csPostData: null }, () => this.props.handelRefreshData())
  }

  renderInfoModal = () => {
    const { type } = this.props;
    const { showInfoModal, commodityList, csPostData } = this.state;
    return (
      showInfoModal &&
      <CSPostModal
        edit={true}
        open={showInfoModal}
        commodityList={commodityList}
        csPostData={csPostData}
        onPostUpdated={this.onPostUpdated.bind(this)}
        onAddModalCancel={this.onModalCancel.bind(this)}
        type={type}
      />
    )
  }

  render() {
    const {
      unitsMap,
      tableHeadData,
      tableBodyData,
      rowsPerPage,
      page,
      getTableCellClass,
      showSweetAlert,
      tableHeadDataKey,
      sweetAlertData,
      tableCount,
      showLoader,
      classes,
      handleActionCheckbox,
      action,
      approved,
      handleFollowedUpCheckbox,
      handelRefreshData,
      handleApproveReject,
      onShowSIbImageModal
    } = this.props;
    const leftAlignedIndexs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const centerAlignedIndexs = [0, 12, 13];
    const minWidthIndexs = [13];

    return (
      <div>
        <div className="cs-filter">
          <i
            onClick={handelRefreshData}
            data-toggle="tooltip"
            data-html="true"
            title="Refresh"
            className="fa fa-refresh"
            aria-hidden="true"
          ></i>
          {/* Radio Boxes */}
          <RadioGroup aria-label="filter" name="filters" value={approved} className="radio-group"  onChange={handleFollowedUpCheckbox}>
            <FormControlLabel
              value={CONSTANTS.FILTER_ACTIONS.PENDING}
              control={<Radio />}
              label="Pending" />
            <FormControlLabel
              value={CONSTANTS.FILTER_ACTIONS.APPROVE}
              control={<Radio />}
              label="Approved" />
            <FormControlLabel
              value={CONSTANTS.FILTER_ACTIONS.REJECT}
              control={<Radio />}
              label="Rejected" />
          </RadioGroup>
        </div>
        {!showLoader && (
          <div>
            <div style={{ maxHeight: "70vh", overflowY: "scroll" }}>
              <Table
                className="table-body"
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow style={{ borderBottom: "2px solid #858792" }}>
                    {tableHeadData.map((option, i) => (
                      <TableCell
                        key={option}
                        //onClick={this.onSort(tableHeadDataKey[i])}
                        className={getTableCellClass(classes, i)}
                        style={{
                          minWidth:
                            minWidthIndexs.indexOf(i) > -1 ||
                              i === tableHeadData.length - 1
                              ? i !== 1
                                ? "120px"
                                : "0px"
                              : "0px",
                          textAlign:
                            leftAlignedIndexs.indexOf(i) > -1
                              ? "left"
                              : centerAlignedIndexs.indexOf(i) > -1
                                ? "center"
                                : "",
                        }}
                      >
                        {option}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableBodyData &&
                    (rowsPerPage > 0
                      ? tableBodyData.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      : tableBodyData
                    ).map((row, i) => {
                      return (
                        <TableRow
                          key={"table_" + i}
                          style={{
                            background: i % 2 !== 0 ? "#e8e8e8" : "#fff",
                          }}
                        >
                          <TableCell
                            component="td"
                            scope="row"
                            className={classes.tableCell}
                          >
                            <div
                              onClick={e => this.handlePostIdClick(e, row)}
                              className="text-link">
                              {row.id}
                            </div>
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            style={{ wordBreak: "break-all", wordWrap: "break-word" }}
                            className={getTableCellClass(classes, 3)}
                          >
                            {row.commodity_name}
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            className={getTableCellClass(classes, 3)}
                          >
                            {row.variety ? (
                              row.variety
                            ) : (
                                <div className={"value-unit"}>
                                  <span className={"n-a"}>N/A</span>
                                </div>
                              )}
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            className={getTableCellClass(classes, 3)}
                          >
                            {row.min_price ? (
                              row.min_price
                            ) : (
                                <div className={"value-unit"}>
                                  <span className={"n-a"}>N/A</span>
                                </div>
                              )}
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            className={getTableCellClass(classes, 3)}
                          >
                            {row.max_price ? (
                              row.max_price
                            ) : (
                                <div className={"value-unit"}>
                                  <span className={"n-a"}>N/A</span>
                                </div>
                              )}
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            className={getTableCellClass(classes, 3)}
                          >
                            {unitsMap && unitsMap.get(row.rate_range_id) ? (
                              unitsMap.get(row.rate_range_id)
                            ) : (
                                <div className={"value-unit"}>
                                  <span className={"n-a"}>N/A</span>
                                </div>
                              )}
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            className={getTableCellClass(classes, 3)}
                          >
                            {row.commodity_quantity_value ? (
                              row.commodity_quantity_value +
                              "  " +
                              row.quantity_unit
                            ) : (
                                <div className={"value-unit"}>
                                  <span className={"n-a"}>N/A</span>
                                </div>
                              )}
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            className={getTableCellClass(classes, 3)}
                          >
                            {row.district ? (
                              row.district
                            ) : (
                                <div className={"value-unit"}>
                                  <span className={"n-a"}>N/A</span>
                                </div>
                              )}
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            className={getTableCellClass(classes, 3)}
                          >
                            {row.contact_name + " - " + row.contact_phonenumber}
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            style={{ textAlign: "center" }}
                            className={getTableCellClass(classes, 0)}
                          >
                            {row.images && row.images.length > 0 ? (
                              <React.Fragment>
                                <i
                                  style={{
                                    paddingTop: "11px",
                                    color: "#fd0671",
                                    cursor: "pointer",
                                  }}
                                  data-toggle="tooltip"
                                  data-placement="right"
                                  title="Supporting images"
                                  onClick={onShowSIbImageModal.bind(this, row)}
                                  className={"fa fa-camera " + classes.info}
                                  aria-hidden="true"
                                ></i>
                                <sup>{row.images ? row.images.length : 0}</sup>
                              </React.Fragment>
                            ) : (
                                <div className={"value-unit"}>
                                  <span className={"n-a"}>N/A</span>
                                </div>
                              )}
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            className={getTableCellClass(classes, 3)}
                          >
                            {this.formatDateAndTime(row.created_on)}
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            className={getTableCellClass(classes, 3)}
                          >
                            {this.formatDateAndTime(row.updated_on)}
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "center", fontWeight: "bold" }}
                            component="td"
                            scope="row"
                            className={getTableCellClass(classes, 2)}
                          >
                            {!row.action_done && !row.followed_up ? (
                              <div className={"value-unit status"}>Pending</div>
                            ) : row.followed_up ? (
                              <div className={"value-unit status"} style={{ background: "#009900" }}>Approved</div>
                            ) : (
                                  <div className={"value-unit status"} style={{ background: "#ff0000" }}>Rejected</div>
                                )}
                          </TableCell>
                          {!row.action_done && !row.followed_up ? (
                            <TableCell component="td" scope="row" style={{ textAlign: "center" }}>
                              <React.Fragment>
                                <Fab
                                  variant="extended"
                                  size="small"
                                  aria-label="Approve"
                                  title="Approve"
                                  //disabled={!getAccessAccordingToRole("payViaCredit")}
                                  onClick={(event) =>
                                    handleApproveReject("approve", row.u_id)
                                  }
                                  style={{
                                    background: "#009900",
                                    marginRight: 10,
                                  }}
                                  className={classes.actionButton}
                                >
                                  <CheckIcon className={classes.actionIcon} />{" "}
                                </Fab>
                                <Fab
                                  variant="extended"
                                  size="small"
                                  aria-label="Reject"
                                  title="Reject"
                                  //disabled={!getAccessAccordingToRole("payViaCredit")}
                                  onClick={(event) =>
                                    handleApproveReject("reject", row.u_id)
                                  }
                                  style={{ background: "#ff0000" }}
                                  className={classes.actionButton}
                                >
                                  <ClearIcon className={classes.actionIcon} />{" "}
                                </Fab>
                              </React.Fragment>
                            </TableCell>
                          ) : (
                              <TableCell component="td" scope="row">
                                <div className={"value-unit"} style={{ textAlign: "center" }}>Done</div>
                              </TableCell>
                            )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
            {tableBodyData.length && (
              <Table>
                <TableFooter
                  style={{
                    borderTop: "2px solid #858792",
                    background: "#fafafa",
                  }}
                >
                  <TableRow style={{ textAlign: "center" }}>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      colSpan={5}
                      count={tableCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onChangePage={this.props.handleChangePage.bind(this)}
                      onChangeRowsPerPage={this.props.handleChangeRowsPerPage.bind(
                        this
                      )}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            )}
          </div>
        )}
        {this.renderInfoModal()}

      </div>
    );
  }
}
