import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Utils from '../../../app/common/utils';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import orderService from '../../../app/orderService/orderService';
import Loader from '../../common/Loader';

const styles = theme => ({
    heading: {
        fontSize: '21px',
        fontWeight: '500',
        marginTop: '0',
        marginBottom: '0',
        fontFamily: 'Montserrat, sans-serif',
    },
    dialogPaper: {
        minWidth: '600px',
        // maxWidth: '700px',
        minHeight: '600px',
        maxHeight: '600px'
    },
    formAddBtn: {
        width: '90%',
        borderRadius: '10px',
        fontSize: '20px',
        textTransform: 'uppercase',
        backgroundColor: '#4d9fa0 ',
        color: '#fff',
        height: '45px',
        marginBottom: '15px',
        marginTop: "11px",
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    formRoot: {
        // display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        // marginLeft: '25%',
        border: '1px solid #ccc',
        boxShadow: '2px 2px 6px 0px  rgba(0,0,0,0.3)',
        borderRadius: '4px',
        marginBottom: '20px',
        marginTop: '8%',
        padding: '25px',
        textAlign: 'center'
    }

});

class OrderInfoDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            keyArr: ["supplier_mobile", "buyer_mobile", "source_location", "target_location", "commodity", "qnt",
                "unit", "type", "bijak_amt", "transport_info", "author_name", "author_mobile", "status",
                "remark", "actual_dispatch_date", "actual_arrival_date", "expected_arrival_date", "createdtime",
                "updatedtime", "brokerid", "other_info", "supplierid", "buyerid", "rate", "commission_rate",
                "commission_unit", "rate_unit", "buyer_name", "buyer_businessname", "broker_name", "broker_businessname",
                "supplier_name", "supplier_businessname"],
            dataObj: {},
            showLoader: false
        }

    }
    componentDidMount() {
        if (this.props.LoadDataFromApi) {
            if (this.props.data.linked_order_id) {
                this.setState({ showLoader: true });
                this.getOrderDetailsById(this.props.data.linked_order_id)
            }
        } else {
            if (this.props.data) {
                let data = this.props.data;
                let arr = this.state.keyArr
                for (let i = 0; i < arr.length; i++) {
                    if (data.hasOwnProperty(arr[i]) && (!data[arr[i]] || data[arr[i]] === "null")) {
                        data[arr[i]] = "";
                    }
                }
                this.setState({ dataObj: this.props.data });
            }
        }
    }

    async getOrderDetailsById(id) {
        try {
            let data = {};
            let resp = await orderService.getOrderDataById(id);
            if (resp.data.status === 1 && resp.data.result[0]) {
                data = resp.data.result[0];
            } else {
                data = {};
            }
            let arr = this.state.keyArr
            for (let i = 0; i < arr.length; i++) {
                if (data.hasOwnProperty(arr[i]) && (!data[arr[i]] || data[arr[i]] === "null")) {
                    data[arr[i]] = "";
                }
            }
            this.setState({ dataObj: data, showLoader: false });
        } catch (err) {
            console.log(err);
        }
    }

    handleDialogCancel(event) {
        this.props.onEditModalCancel();
    }


    render() {
        const { classes } = this.props;
        const { showLoader } = this.state;
        return (<div>
            <Dialog style={{ zIndex: '1' }}
                open={this.props.openModal}
                classes={{ paper: classes.dialogPaper }}
                onClose={this.handleDialogCancel.bind(this)}
                aria-labelledby="form-dialog-title"                >
                <DialogTitle style={{ background: '#05073a', textAlign: 'center', height: '60px' }} id="form-dialog-title"><p style={{ color: '#fff', fontFamily: 'Lato', fontSize: '18px' }}>Order Info</p>  </DialogTitle>
                {!showLoader ? <DialogContent>

                    <div style={{ display: 'flex' }}>
                        <TextField
                            margin="dense"
                            id="supplier_mobile"
                            label="Supplier Mobile"
                            type="text"
                            maxLength="10"
                            size="small"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={Utils.maskMobileNumber(this.state.dataObj.supplier_mobile)}
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="buyer_mobile"
                            label="Buyer Mobile"
                            type="text"
                            maxLength="10"
                            disabled={true}
                            size="small"
                            style={{ marginRight: '2%', width: '48%' }}
                            value={Utils.maskMobileNumber(this.state.dataObj.buyer_mobile)}
                            fullWidth
                        />

                    </div>
                    <div style={{ display: 'flex' }}>

                        <TextField
                            margin="dense"
                            id="buyer_name"
                            label="Buyer Name"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.buyer_name}
                            fullWidth
                            size="small"
                        />

                        <TextField
                            margin="dense"
                            id="buyer_businessname"
                            label="Buyer Buisness Name"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.buyer_businessname}
                            fullWidth
                            size="small"
                        />
                    </div>
                    {/* <div style={{ display: 'flex' }}>

                        <TextField
                            margin="dense"
                            id="broker_name"
                            label="Broker Name"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.broker_name}
                            fullWidth
                            size="small"
                        />

                        <TextField
                            margin="dense"
                            id="broker_businessname"
                            label="Broker Buisness Name"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.broker_businessname}
                            fullWidth
                            size="small"
                        />
                    </div> */}
                    <div style={{ display: 'flex' }}>

                        <TextField
                            margin="dense"
                            id="supplier_name"
                            label="Supplier Name"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.supplier_name}
                            fullWidth
                            size="small"
                        />

                        <TextField
                            margin="dense"
                            id="supplier_businessname"
                            label="Supplier Buisness Name"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.supplier_businessname}
                            fullWidth
                            size="small"
                        />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <TextField
                            margin="dense"
                            id="source_location"
                            label="Source Location"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.source_location}
                            fullWidth
                            size="small"
                        />
                        <TextField
                            margin="dense"
                            id="target_location"
                            label="Target Location"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.target_location}
                            fullWidth
                            size="small"
                        />

                    </div>
                    <div style={{ display: 'flex' }}>
                        <TextField
                            margin="dense"
                            id="commodity"
                            label="commodity"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.commodity}
                            fullWidth
                            size="small"
                        />
                        <TextField
                            margin="dense"
                            id="qnt"
                            label="Qunatity"
                            type="text"
                            maxLength="10"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.qnt}
                            fullWidth
                            size="small"
                        />

                    </div>
                    <div style={{ display: 'flex' }}>
                        <TextField
                            margin="dense"
                            id="unit"
                            label="Unit"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.unit}
                            fullWidth
                            size="small"
                        />
                        <TextField
                            margin="dense"
                            id="mobile"
                            label="Mobile"
                            type="text"
                            maxLength="10"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={Utils.maskMobileNumber(this.state.dataObj.mobile)}
                            fullWidth
                            size="small"
                        />

                    </div>
                    <div style={{ display: 'flex' }}>
                        <TextField
                            margin="dense"
                            id="type"
                            label="Type"
                            type="text"
                            maxLength="10"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.type}
                            fullWidth
                            size="small"
                        />
                        <TextField
                            margin="dense"
                            id="amount"
                            label="Amount"
                            type="text"
                            maxLength="10"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.bijak_amt}
                            fullWidth
                            size="small"
                        />

                    </div>
                    <div style={{ display: 'flex' }}>
                        <TextField
                            margin="dense"
                            id="transport_info"
                            label="Transport Info"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.transport_info}
                            fullWidth
                            size="small"
                        />
                        <TextField
                            margin="dense"
                            id="author_name"
                            label="Author Name"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.author_name}
                            fullWidth
                            size="small"
                        />

                    </div>
                    <div style={{ display: 'flex' }}>
                        <TextField
                            margin="dense"
                            id="author_mobile"
                            label="Author Mobile"
                            type="text"
                            maxLength="10"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={Utils.maskMobileNumber(this.state.dataObj.author_mobile)}
                            fullWidth
                            size="small"
                        />
                        <TextField
                            margin="dense"
                            id="status"
                            label="Status"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.status}
                            fullWidth
                            size="small"
                        />

                    </div>
                    <div style={{ display: 'flex' }}>
                        <TextField
                            margin="dense"
                            id="remark"
                            label="Remark"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.remark}
                            fullWidth
                            size="small"
                        />
                        <TextField
                            margin="dense"
                            id="actual_dispatch_date"
                            label="Dispatch Date"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.actual_dispatch_date ? Utils.displayReadableDateTime(this.state.dataObj.actual_dispatch_date) : this.state.dataObj.actual_dispatch_date}
                            fullWidth
                            size="small"
                        />

                    </div>
                    <div style={{ display: 'flex' }}>
                        <TextField
                            margin="dense"
                            id="actual_arrival_date"
                            label="Arrival Date"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.actual_arrival_date}
                            fullWidth
                            size="small"
                        />
                        <TextField
                            margin="dense"
                            id="expected_arrival_date"
                            label="Expected Arrival date"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.expected_arrival_date}
                            fullWidth
                            size="small"
                        />

                    </div>
                    <div style={{ display: 'flex' }}>
                        <TextField
                            margin="dense"
                            id="createdtime"
                            label="Created Time"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.createdtime ? Utils.displayReadableDateTime(this.state.dataObj.createdtime) : this.state.dataObj.createdtime}
                            fullWidth
                            size="small"
                        />
                        <TextField
                            margin="dense"
                            id="updatedtime"
                            label="Updated Time"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.updatedtime ? Utils.displayReadableDateTime(this.state.dataObj.updatedtime) : this.state.dataObj.updatedtime}
                            fullWidth
                            size="small"
                        />

                    </div>
                    {/* <div style={{ display: 'flex' }}>
                        <TextField
                            margin="dense"
                            id="brokerid"
                            label="Broker Id"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.brokerid}
                            fullWidth
                            size="small"
                        />

                        <TextField
                            margin="dense"
                            id="other_info"
                            label="Other Info"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.other_info}
                            fullWidth
                            size="small"
                        />
                    </div> */}
                    <div style={{ display: 'flex' }}>

                        <TextField
                            margin="dense"
                            id="supplierid"
                            label="Supplier Id"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.supplierid}
                            fullWidth
                            size="small"
                        />

                        <TextField
                            margin="dense"
                            id="buyerid"
                            label="Buyer Id"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.buyerid}
                            fullWidth
                            size="small"
                        />
                    </div>

                    {/* <div style={{ display: 'flex' }}>

                           <TextField
                            margin="dense"
                            id="commission_unit"
                            label="Commission unit"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.commission_unit}
                            fullWidth
                            size="small"
                        />
                        <TextField
                            margin="dense"
                            id="commission_rate"
                            label="Commission rate"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.commission_rate}
                            fullWidth
                            size="small"
                        />
                    </div> */}
                    <div style={{ display: 'flex' }}>
                        <TextField
                            margin="dense"
                            id="rate"
                            label="Rate"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.rate}
                            fullWidth
                            size="small"
                        />

                        <TextField
                            margin="dense"
                            id="rate_unit"
                            label="Rate Unit"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.rate_unit}
                            fullWidth
                            size="small"
                        />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <TextField
                            margin="dense"
                            id="unsettled_amount_pltf"
                            label="Unsettled Amount Pltf"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.unsettled_amount_pltf}
                            fullWidth
                            size="small"
                        />

                        <TextField
                            margin="dense"
                            id="creator_role"
                            label="Creater Role"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.creator_role}
                            fullWidth
                            size="small"
                        />
                    </div>
                    {/*--------------- newly Added---------------- */}
                    {/* <div style={{ display: "flex" }} >

                        <TextField
                            margin="dense"
                            id="broker_mobile"
                            label="Broker Mobile"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.broker_mobile}
                            size="small"
                            fullWidth />
                        &nbsp;
                        &nbsp;
                        <TextField
                            margin="dense"
                            id="bijak_total_amount"
                            label="Commerce Value"
                            type="text"
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.bijak_total_amount}
                            disabled={true}
                            size="small"
                            fullWidth />
                    </div> */}

                    <div style={{ display: "flex" }} >

                        <TextField
                            margin="dense"
                            id="invoice_no"
                            label="Invoice No."
                            disabled={true}
                            type="text"
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.invoice_no}
                            size="small"
                            fullWidth />
                        &nbsp;
                        &nbsp;
                <TextField
                            margin="dense"
                            id="old_system_order_id"
                            label="Old System Order id"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            size="small"
                            value={this.state.dataObj.old_system_order_id}
                            fullWidth />
                    </div>

                    <div style={{ display: "flex" }} >

                        <TextField
                            margin="dense"
                            id="pkt"
                            label="Pkt"
                            type="text"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            size="small"
                            value={this.state.dataObj.pkt}
                            fullWidth />
                        &nbsp;
                        &nbsp;
                <TextField
                            margin="dense"
                            id="brokerage"
                            label="brokerage"
                            type="text"
                            size="small"
                            disabled={true}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={this.state.dataObj.brokerage}
                            fullWidth />
                    </div>
                    <div style={{ display: "flex" }} >
                        <TextField
                            margin="dense"
                            id="bijak_total_amount"
                            label="Commerce Value"
                            type="text"
                            style={{ marginRight: '2%', width: '98%' }}
                            value={this.state.dataObj.bijak_total_amount}
                            disabled={true}
                            size="small"
                            fullWidth />
                    </div>

                    {/*--------------- newly Added ends---------------- */}

                    <div style={{ display: "flex" ,  paddingTop: "5px"}} >
                        <Autocomplete
                            multiple
                            id="fixed-demo"
                            disabled={true}
                            options={[]}
                            value={this.state.dataObj.tags ? this.state.dataObj.tags : []}
                            getOptionLabel={e => e}
                            // onChange={this.handelTagsChanges}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip label={option} {...getTagProps({ index })} />
                                ))
                            }
                            style={{ width: "98%" }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Select Tags"
                                    placeholder="Search"
                                    fullWidth
                                />
                            )}
                        />
                    </div>

                    <div style={{ display: "flex", paddingTop: "5px" }} >
                            <Autocomplete
                                id="fixed-demo2"
                                options={[]}
                                disabled={true}
                                value={this.state.dataObj.order_type && this.state.dataObj.order_type !== null ? this.state.dataObj.order_type : ""}
                                getOptionLabel={e => e}
                                // onChange={this.handelOptionTypeChanges}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                style={{ width: "98%" }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Select order type"
                                        placeholder="Search"
                                        fullWidth
                                    />
                                )}
                            />
                        </div>

                    <div style={{ padding: "12px 0px", color: "#9e9e9e" }}>
                        {(this.state.dataObj["supporting_images"] &&
                            this.state.dataObj["supporting_images"] !== null &&
                            this.state.dataObj["supporting_images"].length > 0) ?
                            <div> Supporting Images </div> : <div> No supporting Images added </div>}
                    </div>
                    <div style={{ display: 'flex' }}>
                        {this.state.dataObj["supporting_images"] &&
                            this.state.dataObj["supporting_images"] !== null &&
                            this.state.dataObj["supporting_images"].length > 0 &&

                            this.state.dataObj["supporting_images"].map((imgKey, i) => (
                                <div key={"imhs_" + i} className="transaction-supporting-image">
                                    <img src={imgKey} alt={imgKey} height="150px" width="150px"
                                        onError={(e) => { e.target.onerror = null; e.target.src = "https://bijakteaminternal-userfiles-mobilehub-429986086.s3.ap-south-1.amazonaws.com/public/no_data_found.png" }}
                                    />
                                </div>
                            ))}
                    </div>


                </DialogContent> : <Loader />}
                {!showLoader && <DialogActions>
                    <Button className={classes.formCancelBtn} onClick={this.handleDialogCancel.bind(this)} color="primary">Close</Button>
                </DialogActions>}
            </Dialog>
        </div>
        );
    }
}

OrderInfoDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrderInfoDialog);