import Api from "../../config/dev";
import CONSTANTS from "../../utils/constants";
import Utils from "../common/utils";

let dsFeedService = {
  getDemandList: async function (param) {
    return Api(
      {
        method: CONSTANTS.HTTP_CONSTANTS.GET,
        headers: {
          Authorization: Utils.getToken(),
        },
        url: "demand/internal",
        params: param,
        headers: {
          "Authorization": Utils.getToken()
        },
      },
      7
    );
  },

  postDemand: async function (payload, buyerId) {
    const URL = `${buyerId}/demand/internal`;
    return Api(
      {
        method: CONSTANTS.HTTP_CONSTANTS.POST,
        headers: {
          Authorization: Utils.getToken(),
        },
        url: URL,
        data: payload,
        headers: {
          "Authorization": Utils.getToken()
        },
      },
      7
    );
  },

  putDemand: async function (payload, postId) {
    const URL = `internal/${postId}/demand`;
    return Api(
      {
        method: CONSTANTS.HTTP_CONSTANTS.PUT,
        headers: {
          Authorization: Utils.getToken(),
        },
        url: URL,
        data: payload,
        headers: {
          "Authorization": Utils.getToken()
        },
      },
      7
    );
  },

  updatePost: async function (payload, postId, type) {
    const URL = `internal/${postId}/${type}`;
    return Api(
      {
        method: CONSTANTS.HTTP_CONSTANTS.PUT,
        url: URL,
        data: payload,
        headers: {
          "Authorization": Utils.getToken()
        },
      },
      7
    );
  },

  getMasterData: async function (param) {
    return Api(
      {
        method: CONSTANTS.HTTP_CONSTANTS.GET,
        headers: {
          Authorization: Utils.getToken(),
        },
        url: "fetch/quanity/units",
        params: param,
        headers: {
          "Authorization": Utils.getToken()
        },
      },
      7
    );
  },

  getSupplyList: async function (param) {
    return Api(
      {
        method: "get",
        headers: {
          Authorization: Utils.getToken(),
        },
        url: "supply/internal",
        params: param,
        headers: {
          "Authorization": Utils.getToken()
        },
      },
      7
    );
  },

  putApproveCommon: async function (payload, url) {
    return Api(
      {
        method: CONSTANTS.HTTP_CONSTANTS.PUT,
        headers: {
          Authorization: Utils.getToken(),
        },
        url: url,
        data: payload,
        headers: {
          "Authorization": Utils.getToken()
        },
      },
      7
    );
  },
};

export default dsFeedService;
