import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/core/styles/withStyles';
import Auth from '@aws-amplify/auth'
import '../../assets/css/login.css';
import $ from 'jquery';
import commonService from '../../app/commonService/commonService';

const styles = theme => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            // width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    form: {
        marginTop: theme.spacing.unit,
    }
});

class SignIn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {
                username: '+91',
                password: '',
                loading: false,
                verifyCode: '',
                showVerification: false,
                userdata: null,
                newpassword: '',
                otp: ''
            },
            action: 'sigin',
            disabledLoginBtn: false,

            forgotPasswordViewStep: 1,// 1 for numberInput, 2 for verify
            forgotPasswordData: {
                username_forgot: "+91",
                otp_forgot: "",
                newPassword_forgot: ""
            },
            disabledForgotBtn: false,
            showErrorMsg: false,
            errorMsg: "",

            disabledOtp: false
        }

        this.getViewContent = this.getViewContent.bind(this);
        this.handleVerifyOtp = this.handleVerifyOtp.bind(this);
        this.getSigninFragment = this.getSigninFragment.bind(this);
        this.getChangePasswordFragment = this.getChangePasswordFragment.bind(this);
        // this.handleChangePassword = this.handleChangePassword.bind(this)
        this.handleSigninSubmit = this.handleSigninSubmit.bind(this)
        this.handleNewPassword = this.handleNewPassword.bind(this)

        // forgot password
        this.handleForgotPasswordWithCodeRequest = this.handleForgotPasswordWithCodeRequest.bind(this);
        this.handleForgotPasswordWithtCode = this.handleForgotPasswordWithtCode.bind(this);

    }

    componentDidMount() {
        try {
            $('.input100').each(function () {

                setTimeout(() => {
                    // alert($("#username").val());
                    if ($("#username").val() !== "") {
                        $("#username").addClass('has-val');
                    }
                }, 500);
                // if($('.input100').val().length > 0){
                //     $(this).addClass('has-val');
                // }
                $(this).on('blur', function () {
                    if ($(this).val().trim() !== "") {
                        $(this).addClass('has-val');
                    }
                    else {
                        $(this).removeClass('has-val');
                    }
                })
            })
        } catch (err) {
            console.log(err)
        }

        window.history.pushState(null, null, window.location.href);
        window.addEventListener('popstate', function (event) {
            if (window.location.pathname === "/" || window.location.pathname === "/login") {
                window.history.pushState(null, null, window.location.href);
            }
        });
        this.checkIfAlreadyLoggedIn();
    }

    handelInputFieldLabel() {
        try {
            $('.input100').each(function () {
                setTimeout((function () {
                    if ($('.input100').val().length > 0) {
                        $(this).addClass('has-val');
                    }
                }).bind(this), 300);
            })
        } catch (err) {
            // console.log(err)
        }
    }

    checkIfAlreadyLoggedIn() {
        Auth.currentAuthenticatedUser()
            .then(user => {
                Auth.signOut()
                    .then(data => {
                        window.sessionStorage.removeItem('bijak_token');
                        window.sessionStorage.removeItem('username');
                        this.props.history.push("/");
                    })
                    .catch(err => console.log(err));
            })
            .catch(err => {
                // console.log(err);
                window.sessionStorage.removeItem('bijak_token');
                window.sessionStorage.removeItem('username');
            });
    }

    handleValueChange = event => {
        const user = this.state.user;
        user[event.target.name] = event.target.value;
        this.setState({ user: user, showErrorMsg: false, errorMsg: "" });
    }

    getSigninFragment() {
        return (
            <form className="login100-form validate-form" autoComplete="off">
                <img src="https://static.wixstatic.com/media/3ae3ef_e4ffe8f5fc524099b6a01ad4652b5bed~mv2.png/v1/fill/w_153,h_46,al_c,q_80,usm_1.20_1.00_0.01/Bijak%20Agritech%20Logo.webp" alt="logo" style={{ height: '8vh' }} />
                <span className="login100-form-title p-b-43" style={{ display: 'none', marginBottom: '16px' }}>
                    Bijak
				</span>

                <span style={{ marginBottom: '10px' }}></span>
                <div className="wrap-input100 validate-input">
                    <input className="input100" value={this.state.user.username} autoComplete="off" autofocus
                        onChange={this.handleValueChange} type="text" id="username" name="username" />
                    <span className="focus-input100"></span>
                    <span className="label-input100">Mobile No.</span>
                </div>
                <div className="wrap-input100 validate-input" data-validate="Password is required">
                    <input className="input100" autoComplete="off"
                        onChange={this.handleValueChange}
                        value={this.state.user.password}
                        name="password"
                        type="password"
                        id="password" />
                    <span className="focus-input100"></span>
                    <span className="label-input100">Password</span>
                </div>
                <div className="flex-sb-m w-full p-t-3 p-b-32">
                </div>
                {this.state.showErrorMsg &&
                    <div style={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: "12px",
                        color: "red"
                    }}
                    > {this.state.errorMsg}</div>}
                <div className="container-login100-form-btn">
                    <button className="login100-form-btn"
                        disabled={this.state.disabledLoginBtn}
                        onClick={this.handleSigninSubmit}>
                        Login {this.state.disabledLoginBtn ? <i className="fa fa-spinner fa-spin tableContainer"></i> : ""}
                    </button>
                </div>
                <div className="flex-sb-m w-full p-t-3 p-b-32"
                    style={{
                        paddingTop: "12px",
                        color: "blue",
                        fontWeight: 500,
                        fontSize: "14px",
                        cursor: "pointer"
                    }} onClick={() => this.setState({
                        action: "Forgot_password",
                        showErrorMsg: false,
                        errorMsg: "",
                        forgotPasswordViewStep: 1, user: {
                            username: '+91',
                            password: '',
                            loading: false,
                            verifyCode: '',
                            showVerification: false,
                            userdata: null,
                            newpassword: '',
                            otp: ''
                        }
                    }, () => this.handelInputFieldLabel())}>
                    Forgot Password
                </div>
            </form>)
    }

    getChangePasswordFragment() {
        return (
            <form className="login100-form validate-form">
                <img src='https://static.wixstatic.com/media/3ae3ef_e4ffe8f5fc524099b6a01ad4652b5bed~mv2.png/v1/fill/w_153,h_46,al_c,q_80,usm_1.20_1.00_0.01/Bijak%20Agritech%20Logo.webp' alt="logo" style={{ height: '8vh' }} />
                <span className="login100-form-title p-b-43" style={{ display: 'none' }}>
                    Forgot Password ! we will help you to get a new One
            </span>
                <div className="wrap-input100 validate-input" data-validate="Password is required">
                    <input className="input100"
                        onChange={this.handleValueChange}
                        value={this.state.user.newpassword}
                        type="password"
                        name="newpassword"
                        id="newpassword" />
                    <span className="focus-input100"></span>
                    <span className="label-input100">Enter new password</span>
                </div>
                <div className="flex-sb-m w-full p-t-3 p-b-32">
                </div>
                {this.state.showErrorMsg &&
                    <div style={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: "12px",
                        color: "red"
                    }}
                    > {this.state.errorMsg}</div>}
                <div className="container-login100-form-btn">
                    <button className="login100-form-btn" onClick={this.handleNewPassword}>
                        Login
                    </button>
                </div>
            </form>)
    }

    getMFAOtpFragment() {
        return (
            <form className="login100-form validate-form">
                <img src='https://static.wixstatic.com/media/3ae3ef_e4ffe8f5fc524099b6a01ad4652b5bed~mv2.png/v1/fill/w_153,h_46,al_c,q_80,usm_1.20_1.00_0.01/Bijak%20Agritech%20Logo.webp' alt="logo" style={{ height: '8vh', marginBottom: '10px' }} />
                <span className="login100-form-title p-b-43"
                    style={{ display: 'none' }}>
                    Otp
                </span>
                <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                    <input className={"input100 " + (this.state.user.otp && this.state.user.otp.length > 0 ? "has-val" : "")}
                        name="otp"
                        type="text"
                        id="otp"
                        onChange={this.handleValueChange}
                        value={this.state.user.otp} />
                    <span className="focus-input100"></span>
                    <span className="label-input100">Otp</span>
                </div>

                <div className="flex-sb-m w-full p-t-3 p-b-32">
                </div>
                {this.state.showErrorMsg &&
                    <div style={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: "12px",
                        color: "red"
                    }}
                    > {this.state.errorMsg}</div>}
                <div className="container-login100-form-btn">
                    <button className="login100-form-btn"
                        disabled={this.state.disabledOtp}
                        onClick={this.handleVerifyOtp}>
                        Continue &nbsp; {this.state.disabledOtp ? <i className="fa fa-spinner fa-spin tableContainer"></i> : ""}
                    </button>
                </div>
                <div className="flex-sb-m w-full p-t-3 p-b-32"
                    style={{
                        paddingTop: "12px",
                        color: "blue",
                        fontWeight: 500,
                        fontSize: "14px",
                        cursor: "pointer"
                    }} onClick={() => this.setState({
                        action: "sigin",
                        disabledLoginBtn: false,
                        showErrorMsg: false,
                        errorMsg: "",
                        forgotPasswordViewStep: 1
                    }, () => this.handelInputFieldLabel())}>
                    <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp;  Back
                </div>
            </form>
        )
    }

    handleValueChangeOfForgotPassword = event => {
        const user = this.state.forgotPasswordData;
        user[event.target.name] = event.target.value;
        this.setState({ forgotPasswordData: user, showErrorMsg: false, errorMsg: "" });
    }

    async handleForgotPasswordWithtCode(e) {
        e.preventDefault();
        if (!this.state.forgotPasswordData.username_forgot || this.state.forgotPasswordData.username_forgot.trim() === "") {
            this.setState({ showErrorMsg: true, errorMsg: "Please enter the mobile number" });
            return;
        }
        if (this.state.forgotPasswordData.username_forgot.indexOf("+91") === -1) {
            this.setState({ showErrorMsg: true, errorMsg: "Country code is reqd. Please add +91 with the mobile number" });
            return;
        }

        if (this.state.forgotPasswordData.username_forgot.length < 13) {
            this.setState({ showErrorMsg: true, errorMsg: "Please enter a valid mobile number" });
            return;
        }

        this.setState({ disabledForgotBtn: true });
        try {
            var response = await Auth.forgotPassword(this.state.forgotPasswordData.username_forgot.trim());
            if (response.CodeDeliveryDetails) {
                // console.log("Code send" + JSON.stringify(response));
                // this.state.forgotPasswordData['otp']="";
                // this.state.forgotPasswordData['newPassword']="";
                // this.state.forgotPasswordData['username']="";
                // console.log(this.state.forgotPasswordData);
                //  this.state.forgotPasswordData =  {
                //     username:"",
                //     otp:"",
                //     newPassword:""
                // }

                this.setState({
                    forgotPasswordViewStep: 2,
                    showErrorMsg: false,
                    errorMsg: ""
                }, function () {
                    $('#newPassword_forgot').val('');
                    $('#otp_forgot').val('');
                })

            } else if (response.message) {
                let errorMsgVal = "";
                if (e.code && e.code === "InvalidParameterException") {
                    errorMsgVal = "Enter vaild number"
                } else {
                    errorMsgVal = response.message;
                }
                this.setState({
                    showErrorMsg: true,
                    errorMsg: errorMsgVal
                });
            }
            this.setState({ disabledForgotBtn: false })
        } catch (e) {
            let errorMsgVal = "";
            if (e.code && e.code === "InvalidParameterException") {
                errorMsgVal = "Enter vaild number"
            } else {
                errorMsgVal = e.message;
            }
            this.setState({
                disabledForgotBtn: false,
                showErrorMsg: true,
                errorMsg: errorMsgVal
            });

        }
    }

    async handleForgotPasswordWithCodeRequest(e) {
        e.preventDefault();
        if (!this.state.forgotPasswordData.otp_forgot || this.state.forgotPasswordData.otp_forgot.trim() === "") {
            this.setState({ showErrorMsg: true, errorMsg: "Please enter the otp" });
            return;
        }
        if (!this.state.forgotPasswordData.newPassword_forgot || this.state.forgotPasswordData.newPassword_forgot.trim() === "") {
            this.setState({ showErrorMsg: true, errorMsg: "Please enter the new password" });
            return;
        }
        // if (!this.state.forgotPasswordData.otp_forgot || this.state.forgotPasswordData.otp_forgot.trim() === "") {
        //     this.setState({ showErrorMsg: true, errorMsg: "Please enter the otp" });
        //     return;
        // }
        this.setState({ disabledForgotBtn: true })
        try {
            var response = await Auth.forgotPasswordSubmit(this.state.forgotPasswordData.username_forgot, this.state.forgotPasswordData.otp_forgot.trim(), this.state.forgotPasswordData.newPassword_forgot.trim());
            if (!response) {
                this.setState({
                    action: "sigin",
                    showErrorMsg: false,
                    errorMsg: "",
                    forgotPasswordViewStep: 1
                }, () => this.handelInputFieldLabel());
            } else {

                this.setState({
                    showErrorMsg: true,
                    errorMsg: response.message
                });
            }
            this.setState({ disabledForgotBtn: false })
        } catch (e) {
            let errorMsgVal = "";
            if (e.code && e.code === "InvalidParameterException") {
                errorMsgVal = "Enter vaild input"
            } else {
                errorMsgVal = e.message;
            }
            this.setState({
                disabledForgotBtn: false,
                showErrorMsg: true,
                errorMsg: errorMsgVal
            })
        }
    }


    getForgotPasswordFragment() {
        return (

            (this.state.forgotPasswordViewStep === 1 ?
                <form id="forgotpasswordUser" className="login100-form validate-form" autoComplete="off">
                    <img src='https://static.wixstatic.com/media/3ae3ef_e4ffe8f5fc524099b6a01ad4652b5bed~mv2.png/v1/fill/w_153,h_46,al_c,q_80,usm_1.20_1.00_0.01/Bijak%20Agritech%20Logo.webp' alt="logo" style={{ height: '8vh', marginBottom: '10px' }} />
                    <span className="login100-form-title p-b-43" style={{ fontSize: '16px' }}>
                        Forgot Password
                </span>
                    <div className="wrap-input100 validate-input">
                        <input className="input100" value={this.state.forgotPasswordData.username_forgot}
                            onChange={this.handleValueChangeOfForgotPassword}
                            type="text"
                            autoComplete="new-password"
                            id="username_forgot" name="username_forgot" />
                        <span className="focus-input100"></span>
                        <span className="label-input100">Mobile number</span>
                    </div>

                    <div className="flex-sb-m w-full p-t-3 p-b-32">
                    </div>
                    {this.state.showErrorMsg &&
                        <div style={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: "12px",
                            color: "red"
                        }}
                        > {this.state.errorMsg}</div>}
                    <div className="container-login100-form-btn">
                        <button className="login100-form-btn"
                            disabled={this.state.disabledForgotBtn}
                            onClick={this.handleForgotPasswordWithtCode}>
                            Continue {this.state.disabledForgotBtn ? <i className="fa fa-spinner fa-spin tableContainer"></i> : ""}
                        </button>
                    </div>
                    <div className="flex-sb-m w-full p-t-3 p-b-32"
                        style={{
                            paddingTop: "12px",
                            color: "blue",
                            fontWeight: 500,
                            fontSize: "14px",
                            cursor: "pointer"
                        }} onClick={() => this.setState({
                            action: "sigin",
                            showErrorMsg: false,
                            errorMsg: "",
                            forgotPasswordViewStep: 1
                        }, () => this.handelInputFieldLabel())}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp;  Back to login
                </div>
                </form> :
                <form id="forgotpasswordUserOtpNewPassword" className="login100-form validate-form" autoComplete="off">
                    <img src='https://static.wixstatic.com/media/3ae3ef_e4ffe8f5fc524099b6a01ad4652b5bed~mv2.png/v1/fill/w_153,h_46,al_c,q_80,usm_1.20_1.00_0.01/Bijak%20Agritech%20Logo.webp' alt="logo" style={{ height: '8vh', marginBottom: '10px' }} />
                    <span className="login100-form-title p-b-43" style={{ fontSize: '16px' }}>
                        Forgot Password
                    </span>

                    <div className="wrap-input100 validate-input" >
                        <input className="input100"
                            name="otp_forgot"
                            type="text"
                            id="otp_forgot"
                            // autoComplete="off"
                            autoComplete="new-password"
                            onChange={this.handleValueChangeOfForgotPassword}
                            value={this.state.forgotPasswordData.otp}
                        />
                        <span className="focus-input100"></span>
                        <span className="label-input100">Otp</span>
                    </div>

                    <div className="wrap-input100 validate-input">
                        <input className="input100"
                            name="newPassword_forgot"
                            type="password"
                            id="newPassword_forgot"
                            // autoComplete="off"
                            autoComplete="new-password"
                            onChange={this.handleValueChangeOfForgotPassword}
                            value={this.state.forgotPasswordData.newPassword}
                        />
                        <span className="focus-input100"></span>
                        <span className="label-input100">New password</span>
                    </div>

                    <div className="flex-sb-m w-full p-t-3 p-b-32">
                    </div>
                    {this.state.showErrorMsg &&
                        <div style={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: "12px",
                            color: "red"
                        }}
                        > {this.state.errorMsg}</div>}
                    <div className="container-login100-form-btn">
                        <button className="login100-form-btn"
                            disabled={this.state.disabledForgotBtn}
                            onClick={this.handleForgotPasswordWithCodeRequest}>
                            Verify {this.state.disabledForgotBtn ? <i className="fa fa-spinner fa-spin tableContainer"></i> : ""}
                        </button>
                    </div>
                    <div className="flex-sb-m w-full p-t-3 p-b-32"
                        style={{
                            paddingTop: "12px",
                            color: "blue",
                            fontWeight: 500,
                            fontSize: "14px",
                            cursor: "pointer"
                        }} onClick={() => this.setState({
                            action: "Forgot_password",
                            showErrorMsg: false,
                            errorMsg: "",
                            forgotPasswordViewStep: 1
                        }, () => this.handelInputFieldLabel())}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp;  Back
                </div>

                </form>
            )

        )
    }


    getViewContent = (action) => {

        if (action === 'sigin') {
            return this.getSigninFragment();
        }

        if (action === 'NEW_PASSWORD_REQUIRED') {
            return this.getChangePasswordFragment();
        }

        if (action === 'SMS_MFA') {
            return this.getMFAOtpFragment();
        }

        if (action === 'Forgot_password') {
            return this.getForgotPasswordFragment();
        }

    }

    async handleSigninSubmit(e) {
        e.preventDefault();

        if (this.state.user.username && this.state.user.username.trim() === "" && this.state.user.password && this.state.user.password.trim() === "") {
            this.setState({ showErrorMsg: true, errorMsg: "Please enter the username and password" });
            return;
        } else if (this.state.user.username && this.state.user.username.trim() === "") {
            this.setState({ showErrorMsg: true, errorMsg: "Username cannot be empty" });
            return;
        }

        if (!this.state.user.password || this.state.user.password.trim() === "") {
            this.setState({ showErrorMsg: true, errorMsg: "Password cannot be empty" });
            return;
        }
        this.setState({ disabledLoginBtn: true })
        try {

            var data = await Auth.signIn(this.state.user.username.trim(), this.state.user.password);
            if (data) {
                // console.log(data)
                this.setState({
                    userdata: data
                })
                if (data && data.challengeName === "NEW_PASSWORD_REQUIRED") {
                    let action = data.challengeName;
                    this.setState({
                        action: action,
                        showErrorMsg: false,
                        errorMsg: "",
                    }, () => this.handelInputFieldLabel());
                    return;
                }
                if (data && data.challengeName === 'SMS_MFA') {
                    let action = data.challengeName;
                    this.setState({
                        action: action,
                        showErrorMsg: false,
                        errorMsg: "",
                    }, () => this.handelInputFieldLabel());
                    return;
                }
                // console.log(data);
                var authk = data.signInUserSession.idToken.jwtToken;
                var username = data.signInUserSession.idToken.payload.name;
                var userId = data.username;
                window.sessionStorage.setItem('userId', userId);
                window.sessionStorage.setItem('bijak_token', authk);
                localStorage.setItem("bijak_token_ExpTime", (new Date()).getTime());
                window.sessionStorage.setItem('username', username);
                localStorage.setItem('name', data.attributes.name);
                this.getUserRole();
            }
        } catch (e) {
            // alert(e.message);
            let errorMsgVal = "";
            if (e.code && e.code === "InvalidParameterException") {
                errorMsgVal = "Enter vaild number"
            } else {
                errorMsgVal = e.message;
            }
            this.setState({
                showErrorMsg: true,
                errorMsg: errorMsgVal
            })
        }
        this.setState({ disabledLoginBtn: false });
    }

    async handleNewPassword(e) {
        e.preventDefault();
        this.setState({ showErrorMsg: false, errorMsg: '' });
        try {
            // Auth.completeNewPassword()
            Auth.signIn(this.state.user.username, this.state.user.password)
                .then(user => {
                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        // const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
                        Auth.completeNewPassword(
                            user,               // the Cognito User Object
                            this.state.user.newpassword,       // the new password
                            {
                                // phone_number: this.state.user.username
                            }
                        ).then(user => {
                            // at this time the user is logged in if no MFA required
                            console.log(user);
                            this.setState({
                                userdata: user,
                                action: 'sigin',
                                showErrorMsg: false,
                                errorMsg: "",
                                disabledLoginBtn: false,
                                disabledOtp: false

                            }, () => this.handelInputFieldLabel())

                            // Auth.currentAuthenticatedUser({
                            // }).then(user => {
                            //     var authk = user.signInUserSession.idToken.jwtToken;
                            //     var username = user.signInUserSession.idToken.payload.name;
                            //     window.sessionStorage.setItem('bijak_token', authk)
                            //     localStorage.setItem("bijak_token_ExpTime", (new Date()).getTime());
                            //     window.sessionStorage.setItem('username', username);
                            //     var userId = user.username;
                            //     window.sessionStorage.setItem('userId', userId);
                            //     // this.props.history.push("/" + Utils.getDbName() + "/home/buyer-list");
                            //     this.getUserRole();
                            // })
                            //     .catch(err => console.log(JSON.stringify(err)))

                        }).catch(e => {
                            console.log(e);
                            this.setState({
                                showErrorMsg: true,
                                errorMsg: e.message ? e.message : 'Oops an error occured.',
                                disabledLoginBtn: false,
                                disabledOtp: false
                            });
                        });
                    } else {
                        // other situations
                    }
                }).catch(e => {
                    console.log(e);
                    this.setState({
                        showErrorMsg: true,
                        errorMsg: e.message ? e.message : 'Oops an error occured.',
                        disabledLoginBtn: false,
                        disabledOtp: false
                    });
                });


        } catch (e) {
            // alert(e.message);
            this.setState({
                showErrorMsg: true,
                errorMsg: e.message,
            })
        }
    }

    async getBijakAppVersion() {
        try {
            let resp = await commonService.getBijakVersionOfApp();
            //   console.log(resp);
            //   alert(JSON.stringify(resp));
            if (resp.data.status === 1 && resp.data.result) {
                window.sessionStorage.setItem("app_version", resp.data.result[0].version);
                window.sessionStorage.setItem("app_version_data", resp.data.result[0]);
            } else {
                window.sessionStorage.setItem("app_version", "-");
                window.sessionStorage.setItem("app_version_data", "{}");
            }
        } catch (err) {
            console.log(err);
            window.sessionStorage.setItem("app_version", "-");
            window.sessionStorage.setItem("app_version_data", "{}");
        }
    }

    async getUserRole() {
        try {
            this.setState({ disabledLoginBtn: true, disabledOtp: true });
            await commonService.addLoginEvent(); // Adding Login event move api after permission
            this.getBijakAppVersion();
            let resp = await commonService.getUserSpecificRole(this.state.user.username);
            this.setState({ disabledLoginBtn: false, disabledOtp: false });
            if (resp.data.status === 1 && resp.data.result && resp.data.result.length > 0 &&
                resp.data.result[0].permissions && resp.data.result[0].permissions !== "") {
                sessionStorage.setItem("userRole", resp.data.result[0].permissions);
                sessionStorage.setItem("userName", resp.data.result[0].name);
                sessionStorage.setItem("userMobile", resp.data.result[0].mobile);
                if (resp.data.result[0].permissions.indexOf("BasicUser") > -1 || resp.data.result[0].permissions.indexOf("SuperAdmin") > -1 || resp.data.result[0].permissions.indexOf("super-admin") > -1) {
                    this.props.history.push("/home/user-list");
                } else if (resp.data.result[0].permissions.indexOf("SupportingDataManagement") > -1) {
                    this.props.history.push("/home/mandi-data");
                } else {
                    // default Case 
                    this.props.history.push("/home");
                }

            } else {
                sessionStorage.setItem("userRole", "restricted");
                this.props.history.push("/access-denied");
            }

        } catch (err) {
            console.error(err)
            sessionStorage.setItem("userRole", "restricted");
            this.props.history.push("/access-denied");
        }
    }

    // async handleVerifyOtp(e) {
    //     e.preventDefault()
    //     try {
    //         var data = await Auth.confirmSignIn(this.state.userdata, this.state.user.otp, this.state.action);
    //         if (data) {
    //             this.setState({
    //                 userdata: data
    //             })
    //             var authk = data.signInUserSession.idToken.jwtToken;
    //             var username = data.signInUserSession.idToken.payload.name;
    //             cookie.save('bijak_token', authk, { path: '/' })
    //             localStorage.setItem("bijak_token_ExpTime", (new Date()).getTime());
    //             cookie.save('username', username, { path: '/' });
    //             var userId = data.username;
    //             cookie.save('userId', userId, { path: '/' });
    //             this.getUserRole();
    //         } else {
    //             // alert("failed in login");
    //             this.setState({
    //                 showErrorMsg: true,
    //                 errorMsg: "failed in login",
    //             });
    //         }
    //     } catch (e) {
    //         // alert(e.message);
    //         this.setState({
    //             showErrorMsg: true,
    //             errorMsg: e.message,
    //         });

    //     }
    // }

    async handleVerifyOtp(e) {
        e.preventDefault();
        let userOtp =  this.state.user.otp;
        if ( !userOtp || userOtp === "" || userOtp.trim() === "") {
            this.setState({ showErrorMsg: true, errorMsg: "Please enter the otp" });
            return;
        } 
        this.setState({ disabledOtp: true });

        try {
            var data = await Auth.confirmSignIn(this.state.userdata, this.state.user.otp, this.state.action);
            if (data) {
                this.setState({
                    userdata: data
                })

                Auth.currentAuthenticatedUser({

                }).then(user => {
                    user.deviceKey = user.getCachedDeviceKeyAndPassword();
                    user.setDeviceStatusRemembered({
                        onSuccess: function (result) {

                        },
                        onFailure: function (err) {
                            // console.log(JSON.stringify(err));
                        }
                    });
                })
                    .catch(err => console.log("_^_" + JSON.stringify(err)))
                var authk = data.signInUserSession.idToken.jwtToken;
                var username = data.signInUserSession.idToken.payload.name;
                window.sessionStorage.setItem('bijak_token', authk);
                localStorage.setItem("bijak_token_ExpTime", (new Date()).getTime());
                window.sessionStorage.setItem('username', username);
                var userId = data.username;
                window.sessionStorage.setItem('userId', userId);

                this.setState({ disabledOtp: false });

                // this.props.history.push("/" + Utils.getDbName() + "/home/buyer-list");
                this.getUserRole();
                // this.props.history.push("/home/buyer-list");

            } else {
                // alert("failed in login");
                this.setState({
                    showErrorMsg: true,
                    errorMsg: "failed in login",
                    disabledOtp: false
                });
            }
        } catch (e) {
            // alert(e.message);
            this.setState({
                showErrorMsg: true,
                errorMsg: e.message,
                disabledOtp: false
            });
        }
    }

    render() {
        if (window.sessionStorage.getItem('bijak_token', null)) {
            // return <Redirect to={"/" + Utils.getDbName() + "/home/buyer-list"} />;

            // return <Redirect to={"/home/buyer-list"} />;

        }

        return (
            <React.Fragment>
                <CssBaseline />
                <main className={this.props.classes.layout}>
                    <div className="limiter">
                        <div className="container-login100">
                            <div className="wrap-login100">
                                {this.getViewContent(this.state.action)}
                                <div className="login100-more" >
                                    {/* this div is for the backgroud image */}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment >
        );
    }
}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SignIn);