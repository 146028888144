
import Sockette from "sockette";
import Utils from '../../app/common/utils';
import { getEnviornmentType } from "../../config/appConfig";
import $ from 'jquery';

let ws = null;
let intervalForReconnet = null;


function initiateSocket() {
  try {
    let webSocketUrl = "";
    let env = getEnviornmentType();
    if (env === 'prod') {
      webSocketUrl = "wss://lzue5ee8c8.execute-api.ap-south-1.amazonaws.com/prod?token="; // prod url not provided yet
    } else if (env === 'staging') {
      webSocketUrl = "wss://rki2jwrogj.execute-api.ap-south-1.amazonaws.com/staging?token=";
    } else {
      // handeling dev here
      webSocketUrl = "wss://eo9nxbvkwa.execute-api.ap-south-1.amazonaws.com/dev?token=";
    }

    ws = new Sockette(webSocketUrl + Utils.getToken(),
      {
        timeout: 5e3,
        maxAttempts: 1,
        onopen: e => onConnectionOpen(e),
        onmessage: e => handelOnNotificationRecieved(e),
        onreconnect: e => onConnectionCloseOrError(e),
        // onmaximum: e =>  console.log( e ),
        onclose: e => onConnectionCloseOrError(e),
        onerror: e => onConnectionCloseOrError(e)
      }
    );
  } catch (err) {
    console.log(err);
    onConnectionCloseOrError();
  }
}

// // handeling Sockket open here 
const onConnectionOpen = (e) => {
  if (intervalForReconnet && intervalForReconnet !== null) {
    clearTimeout(intervalForReconnet);
    intervalForReconnet = null;
  }
}

//Handeling socket reconnect and close here 
const onConnectionCloseOrError = (e) => {
  ws = null;
  checkIfInternetAvailable();
}


function checkIfInternetAvailable() {
  try {
    // if (!ws || ws.readyState == WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
    intervalForReconnet = setInterval(function () {
      if (navigator.onLine) {
        if (!ws) {
          initiateSocket();
        }
      } else {
        // don't retry
      }
    }, 3000);
  } catch (e) {
    console.log(e);
    initiateSocket();
  }
};

// function closeConnection( ){
//   ws.close(); // graceful shutdown
// }

function SendNotification(message) {
  //    let mess =  {
  //         author: isMe,
  //         type,
  //         data: messageData
  //       }
  //     const newMessage = { ...message, author: "bijak" };
  ws.json({
    action: "sendMessage",
    data: "payment"
  });
};

const handelOnNotificationRecieved = ({data}) => {
  try {
    let msgData = Utils.getNotificationData();
    let pData = JSON.parse(data);
    msgData.unshift(pData);
    window.notificationdata = pData;
    Utils.setNotificationData(msgData);


    //This part is for notification badge
    let lengthOfNotf = Utils.getNotificationDataLength();
    if (lengthOfNotf < 100) {
      $("#notification_badge").show().html(lengthOfNotf);
    } else {
      if ($("#notification_badge").text() === "99+" && lengthOfNotf >= 99) {
        // don't update the span 
      } else {
        $("#notification_badge").show().html('99+');
      }
    }

    //this is for pushing the data in list
    if ($("#emptyView") && $("#emptyView").length > 0) {
      $("#emptyView").remove();
      $("#clearAllFooter").show();
    }

    let typeIcon = Utils.getIconOnTypeText(pData.type);
    $('#notificationsBody').prepend(
      `<div class="notf_list ">
      <div style='padding-left:12px;'>
        ${typeIcon}
      </div>
      <div class="notf">
        <div class="d-flex justify-content-between">
          <h5 class="notf_title">${pData.type ? pData.type : ''}</h5>
        </div>
        <span class="notf_message">
          ${pData.message ? pData.message : ''}
        </span>
      </div>
    </div>`);

    // this part is for Component Wise Showing Update
    let idToHighLight = pData['type'] + "_" + pData['id'];
    if ($("#" + idToHighLight).length > 0) {
      $("#" + idToHighLight).addClass("highlightCssEffect");
    }

  } catch (err) {
    console.log(err);
  }

}

const websocket = {

  initiateSocket,
  SendNotification,
  checkIfInternetAvailable,
  // closeConnection

}


export default websocket;