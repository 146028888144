import React from 'react';
import PropTypes from 'prop-types';
import '../../assets/css/app.css';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Loader from '../common/Loader';
import commonService from '../../app/commonService/commonService';

const styles = theme => ({
    root: {
        width: '100%',
        // overflow: 'auto',
        fontFamily: 'Lato !important',
        maxWidth: '1200px',
        marginLeft:'4%',
        marginTop: "6%"
    },
    card: {
        maxWidth: '100%',
        marginTop: '15px',
        height: '97%',
    },
    heading: {
        marginTop: '15px',
        fontSize: '20px',
        alignTtems: 'center',
        display: '-webkit-inline-box'
    },
    pointsClass: {
        fontSize: "15px",
        fontWeight: 500,
        color: "#02071d",
        fontFamily: "lato"
    }
});



class AppVersionContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            appVersionData: [],
            showLoader: false
        };
    }

    componentDidMount() {
        this.setState({
            showLoader: true
        }, () => {
            this.getBijakAppVersion();
        })

    }

    async getBijakAppVersion() {
        try {
            let resp = await commonService.getBijakVersionOfApp();
            // alert(JSON.stringify(resp));
            if (resp.data.status === 1 && resp.data.result) {
                // window.sessionStorage.setItem("app_version" , resp.data.result.version );
                // window.sessionStorage.setItem("app_version_data" , resp.data.result );
                // let data = resp.data.result;
                // data = data.concat(data)
                // data = data.concat(data)
                // data = data.concat(data)
                this.setState({
                    showLoader: false,
                    appVersionData:  resp.data.result
                })
            } else {
                // window.sessionStorage.setItem("app_version", "-");
                // window.sessionStorage.setItem("app_version_data", "{}");
                this.setState({
                    showLoader: false
                })
            }
        } catch (err) {
            console.log(err);
            window.sessionStorage.setItem("app_version", "-");
            window.sessionStorage.setItem("app_version_data", "{}");
        }
    }


    render() {
        const { classes } = this.props;
        const { appVersionData } = this.state;
        return (
            <div className={classes.root}>
                {this.state.showLoader && <Loader />}

                <Card className={classes.card} style={{ display: this.state.showLoader ? "none" : "unset" }}>
                    {/* <div style={{ textAlign: "left", fontSize: "20px" }}>
                        Current updated app version  : {appVersionData.version}
                    </div>
                    <div style={{ paddingLeft: "9%", fontSize: "20px" }}>
                        {appVersionData["details"] && appVersionData["details"].length > 0 &&
                            appVersionData["details"].map((pnts, index) =>
                                <div key={index + "_points"} style={{ textAlign: "left", paddingLeft: "10%" }}>
                                    - {pnts}
                                </div>
                            )
                        } */}
                    {appVersionData && appVersionData.length > 0 && appVersionData.map(( obj, i)=>
                    <div key={i+"_appVersion"}>
                        <h3 style={{ color: (i === 0 ? "green":'#001688'), fontSize: "16px" , textAlign: "left"}}>{i === 0 ? 'Current app version' : 'App version'} : {obj.version}</h3>
                        <ul style={{ listStyle: "none", textAlign: "left", marginLeft: '3%',padding:'7px 0px 19px 0px' }}>
                            {obj["details"] && obj["details"].length > 0 &&
                                typeof(obj["details"]) ==='object' && obj["details"].map((pnts, index) =>
                                    <li key={index+"_details"} className={classes.pointsClass}>
                                        <i className="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp;{pnts}</li>
                                        )}

                            {/* {obj["details"] && obj["details"].length > 0 &&
                                typeof(obj["details"]) ==='string' && 
                                    <li  className={classes.pointsClass}>
                                        <i className="fa fa-hand-o-right" aria-hidden="true"></i> &nbsp;{obj["details"]}
                                    </li>} */}
                                        
                        </ul>
                    </div>)}
                    {/* </div> */}
                </Card>
            </div>
        );
    }
}

AppVersionContainer.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(AppVersionContainer);