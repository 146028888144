/* eslint-disable */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Fab from '@material-ui/core/Fab';
import { createMuiTheme } from "@material-ui/core/styles";
import 'date-fns';
import Utils from './../../app/common/utils';
import tickIcon from "../../assets/images/icons/check.svg";
import faqIconReddish from "../../assets/images/icons/faq_redish.svg";
import failedIcon from "../../assets/images/icons/failed.svg";
import razorpay_skipped from "../../assets/images/razorpay_skipped.png";
import approvedIcon from "../../assets/images/icons/approved.svg";
import hourglassIcon from "../../assets/images/icons/hourglass.svg";
import cancelledIcon from "../../assets/images/icons/cancelled.svg";
import payment_InitatedIcon from "../../assets/images/icons/payment_Initated.svg";
import payment_failureIcon from "../../assets/images/icons/payment_failure.svg";
import AccountBalanceWalletSharpIcon from '@material-ui/icons/AccountBalanceWalletSharp';
import Tooltip from '@material-ui/core/Tooltip';
import Loader from './Loader';
import orderService from '../../app/orderService/orderService';


var moment = require('moment');

const theme = createMuiTheme({
    overrides: {
        MuiFormLabel: {
            root: {
                color: "White"
            }
        },
        MuiInput: {
            underline: {
                borderBottom: "1px solid rgb(255, 255, 255)"
            }
        },
        MuiIconButton: {
            root: {
                color: "#fff"
            }
        },
        MuiInputBase: {
            input: {
                color: "White"
            }
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                color: "#000"
            }
        }
    }
});

const styles = theme => ({
    dialogPaper: {
        minWidth: '84%',
        maxWidth: '85%',
        // minHeight: '700px',
        // maxHeight: '500px'
    },
    appBar: {
        position: 'relative',
        background: "#05073a",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        fontSize: "20px !important",
        fontFamily: "lato !important",
        fontWeight: 500
    },
    closeBtn: {
        fontSize: "15px !important",
        fontFamily: "lato !important",
        fontWeight: 500
    },
    tableCell: {
        paddingLeft: '4px',
        paddingRight: '4px',
        textAlign: 'center',
        maxWidth: '200px'
    },
    formControl: {
        color: "#fff"
    },
    dataHeader: {
        width: "20%"
    },
    lightTooltip: {
        fontSize: '15px',
        fontWeight:500,
        maxWidth: 'none',
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// const statusOption = ["approved", "failed"];


class OrderPaymentList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            allTransactionsData: [],
            tableHeadData: ["status","id","APP ID", "Supplier Name", "Supplier Bussiness Name", "Created Time",  "Payment mode","Payment type","Amount"],
            tableHeadDataKeys: ["","id","pay_id","supplier_fullname","supplier_business_name","createdtime","payment_mode","transaction_type","amount",""],
            expanded: "",
            invoiceModalData: [],
            showImageInvoiceModal: false,
            supplierNameMapping: {},
            buyerInfo: this.props.buyerInfo,
            showLoader: false,

            // transDate: this.props.transDate,
            selectedTab: "all",

            editableData: undefined,
            showEditTransactionModal: false,
            isDataUpdated: false,
            
            rowsPerPage: 50,
            page: 0,

            params: {
                limit: 1000, // total amount of data 
                offset: 0 // data from which data needs to be get
            },
            totalDataCount: 0,
            isTableDataLoading: false,

            statusAnchorEl:null,
            statusdropActionOpen: "",
            confirmDialogData:{ 
                                "text": "Are you sure to update the status of this payment?",
                                "title":"Are you sure to update the status of this payment?",
                            },
            showConfirmStatusDialoge: false,
            statusUpdateObj:{},
            showStatusChangeModal: false,

            showPayoutModal: false,
            payoutData: undefined,

            showTransactionInfoDialog: false,
            transactionInfoData : undefined,

            sortKeys: {},
            sortParams:{},
            sortKeysOrder: [],
            sortingEnabled: ["id", "createdtime", "amount"],
            orderData : this.props.orderData

        }
    }

    componentDidMount() {
        
        if( this.props.orderData["id"] && this.props.orderData["id"] !== null){
            this.setState({showLoader : true})
            this.getPaymentHistory( this.props.orderData["id"] , this.state.params);
        }
    }

    getPaymentHistory = async (id , params) => {
        try {
            let updatedParams = { ...params };
            if(Object.keys(this.state.sortParams).length > 0){
                updatedParams["sortkey"] = this.state.sortParams["sortkey"];
                updatedParams["sortorder"] = this.state.sortParams["sortorder"];
            }
            let resp = await orderService.getOrderDataByLinkedOrderId(id, updatedParams);
            if (resp.data.status === 1 && resp.data.result) {
                var respData = resp.data.result;
                this.setState({
                    allTransactionsData: respData["allTransactions"],
                    buyerInfo: respData["metainfo"],
                    isTableDataLoading: false ,
                    totalDataCount: resp.data.result.totalCount && resp.data.result.totalCount[0] && resp.data.result.totalCount[0]["count"] ? parseInt(resp.data.result.totalCount[0]["count"], 10) : 0,
                    showLoader: false
                });
            } else {
                this.setState({
                    allTransactionsData: [],
                    isTableDataLoading: false ,
                    totalDataCount:  0,
                    showLoader: false
                });
            }
        } catch (err) {
            console.error(err);
        }
    }

    handelModalClose(event) {
        this.setState({ open: false }, function () {
                this.props.onPaymentHistoryClosed();
        })
    }

    getTableCellClass(classes, index) {
        return classes.tableCell;
    }

    handelTransactionInvoiceModal(row, event) {
        this.setState({ invoiceModalData: row["images"] }, function () {
            this.setState({ showImageInvoiceModal: true })
        })
    }

    getTransactionTypeColor(transaction_type) {
        if (transaction_type === "b_out") {
            return "rgb(212, 58, 58)"; // red

        } else if (transaction_type === "b_in") {
            return "rgb(56, 122, 57)"; // green

        } else {
            return "rgba(0, 0, 0, 0.87)" // default black color
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
        if (this.state.allTransactionsData.length === (newPage * this.state.rowsPerPage)) {
            this.resetOffsetAndGetData();
        }
      };
    
      handleChangeRowsPerPage = event => {
        this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
      };

    //   handelStatusOptionClick( row, event ){
    //       if(getAccessAccordingToRole("editPayment")){
    //         this.setState({ showStatusChangeModal : true, statusUpdateObj: row });
    //     }
    //   }


      getStatusOption( event , row ){
          if( (row["transaction_type"] === "b_out" && row["payment_mode"] === "bijak") ||  (row["transaction_type"] === "b_in" && row["payment_mode"] === "bank") ){
            
            if(  row["status"] === "transaction_failed" ){
                return(<span 
                    style={{ paddingLeft: "15%"}}  
                    data-toggle="tooltip" 
                    data-placement="center" 
                    title={row["status"] }>
                    <img src={ payment_failureIcon } alt={row["status"]} style={{ height: "22px",width: "22px"}}/>
                </span> );
               }else if(  row["status"] === "transaction_initiated" ){
            return(<span 
                style={{ paddingLeft: "15%"}}  
                data-toggle="tooltip" 
                data-placement="center" 
                title={row["status"] }>
                <img src={ payment_InitatedIcon } alt={row["status"]} style={{ height: "22px",width: "22px"}}/>
            </span> );
           }else if(  row["status"] === "payout_reversed" ||
                row["status"] === "payout_cancelled" || 
                row["status"] === "payout_rejected" ){
                    return( <span>
                         <Tooltip title={row["status"] + (row["failure_reason"] ? (":"+ row["failure_reason"]) : "")} placement="top" classes={{ tooltip: this.props.classes.lightTooltip }}>
                        <span 
                        style={{paddingLeft: "6px"}}  
                        data-toggle="tooltip" 
                        data-placement="center" 
                        title={row["status"] }>
                        <img src={ cancelledIcon } alt={row["status"]} style={{ height: "20px",width: "20px"}}/>
                    </span> 
                    </Tooltip>
                    <span 
                        style={{ fontSize: "20px",paddingLeft: "25px", cursor:"pointer"}}  
                        data-toggle="tooltip" 
                        data-placement="center" 
                        // onClick={( event )=> { if( getAccessAccordingToRole("makePayout") ){this.setState({ showPayoutModal : true, payoutData : row })}}}
                        title={row["status"] }>
                         <i className="fa fa-refresh" aria-hidden="true" style={{color :"gray" }} ></i>
                    </span> </span>);
                    } else if(row["status"] === "payout_processed"){
                return(<span 
                    style={{ paddingLeft: "15%"}}  
                    data-toggle="tooltip" 
                    data-placement="center" 
                    title={row["status"] }>
                    <img src={ approvedIcon } alt={row["status"]} style={{ height: "22px",width: "22px"}}/>
                </span> );
            } else if(
                row["status"] === "payout_initiated" || 
                row["status"] === "payout_queued" || 
                row["status"] === "payout_pending" || 
                row["status"] === "payout_processing"){
                return(<span 
                    style={{ paddingLeft: "15%"}}  
                    data-toggle="tooltip" 
                    data-placement="center" 
                    title={row["status"] }>
                    <img src={ hourglassIcon } alt={row["status"]} style={{ height: "22px",width: "22px"}}/>
                </span> );
            } else if(row["transaction_type"] === "b_out" && row["status"] === "approved"){
                return( <Fab
                    variant="extended"
                    size="small"
                    aria-label="PAYOUT" 
                    disabled={ true }
                    style={{ textTransform: "none", background: "gray", color: "#ffffff", padding: "0 15px" }}
                >
                   PAYOUT
            </Fab>);
            }else if(row["transaction_type"] === "b_in" && row["status"].indexOf("validated") > -1 ){
                return( <Fab
                    variant="extended"
                    size="small"
                    disabled={true}
                    aria-label="PAYOUT" 
                    // onClick={( event )=> {if(getAccessAccordingToRole("makePayout")){this.setState({ showPayoutModal : true, payoutData : row })}}}
                    style={{ textTransform: "none", background: "gray", color: "#ffffff", padding: "0 15px" }}
                >
                   PAYOUT
            </Fab>);
            }else if(row["status"] === "pending" || row["status"] === "pending_approved" || row["status"] === null ){
               return(
                   this.getActionAbleIcon( event , row )
                );
            }else if(row["status"] === "failed" ){
                return(<span 
                        style={{ paddingLeft: "15%"}}  
                        data-toggle="tooltip" 
                        data-placement="center" 
                        title={row["status"].toUpperCase() +(row["reason"] ? "\nReason : "+ row["reason"]: "")  }>
                            <img src={row["status"] === "failed" ?  failedIcon : "" } alt="failedIcon" 
                                style={{ height: "22px",width: "22px"}}/>
                    </span>)
            }
        }else if( row["transaction_type"] === "b_out" && row["payment_mode"] !== "bijak") {
            if(  row["status"] === "transaction_failed" ){
                return(<span 
                    style={{ paddingLeft: "15%"}}  
                    data-toggle="tooltip" 
                    data-placement="center" 
                    title={row["status"] }>
                    <img src={ payment_failureIcon } alt={row["status"]} style={{ height: "22px",width: "22px"}}/>
                </span> );
               }else if(  row["status"] === "transaction_initiated" ){
            return(<span 
                style={{ paddingLeft: "15%"}}  
                data-toggle="tooltip" 
                data-placement="center" 
                title={row["status"] }>
                <img src={ payment_InitatedIcon } alt={row["status"]} style={{ height: "22px",width: "22px"}}/>
            </span> );
           }else if(  row["status"] === "payout_reversed" ||
                row["status"] === "payout_cancelled" || 
                row["status"] === "payout_rejected" ){
                    return( <span>
                        <span 
                        style={{paddingLeft: "6px"}}  
                        data-toggle="tooltip" 
                        data-placement="center" 
                        title={row["status"] }>
                        <img src={ cancelledIcon } alt={row["status"]} style={{ height: "20px",width: "20px"}}/>
                       
                    </span> 
                    {/* <span 
                        style={{ fontSize: "20px",paddingLeft: "25px", cursor:"pointer"}}  
                        data-toggle="tooltip" 
                        data-placement="center" 
                        onClick={( event )=> { if( getAccessAccordingToRole("makePayout") ){this.setState({ showPayoutModal : true, payoutData : row })}}}
                        title={row["status"] }>
                         <i className="fa fa-refresh" aria-hidden="true" style={{color : (!getAccessAccordingToRole("makePayout") ? "gray" :"#0c6523"  )}} ></i>
                    </span>  */}
                    </span>);
                    } else if(row["status"] === "payout_processed"){
                return(<span 
                    style={{ paddingLeft: "15%"}}  
                    data-toggle="tooltip" 
                    data-placement="center" 
                    title={row["status"] }>
                    <img src={ approvedIcon } alt={row["status"]} style={{ height: "22px",width: "22px"}}/>
                </span> );
            } else if(
                row["status"] === "payout_initiated" || 
                row["status"] === "payout_queued" || 
                row["status"] === "payout_pending" || 
                row["status"] === "payout_processing"){
                return(<span 
                    style={{ paddingLeft: "15%"}}  
                    data-toggle="tooltip" 
                    data-placement="center" 
                    title={row["status"] }>
                    <img src={ hourglassIcon } alt={row["status"]} style={{ height: "22px",width: "22px"}}/>
                </span> );
            } else  if(row["status"] === "failed" ){
                return(<span 
                        style={{ paddingLeft: "15%"}}  
                        data-toggle="tooltip" 
                        data-placement="center" 
                        title={row["status"].toUpperCase() +(row["reason"] ? "\nReason : "+ row["reason"]: "")  }>
                            <img src={row["status"] === "failed" ?  failedIcon : "" } alt="failedIcon" 
                                style={{ height: "22px",width: "22px"}}/>
                    </span>)
            }
        }else{
            return ( <AccountBalanceWalletSharpIcon style={{color:"gray", marginLeft:"15%"}}/>);
        }
      }

      getActionAbleIcon( event , row ){
        return(
        <span style={{ width: "40px", height: "20px", paddingLeft:"15%"}}>
        <IconButton
            style={{ padding: "4px"}}
            data-toggle="tooltip" data-placement="center" title={row["status"] === "pending" || row["status"] === "pending_approved" || row["status"] === null ? "pending_approved" : row["status"] }
            aria-label="more"
            aria-controls={"long-menu"+row["id"] }
            disabled={true}
            aria-haspopup="true"
            // onClick={this.handelStatusOptionClick.bind( event, row )}
        >
        <img src={row["status"] === "pending" || row["status"] === "pending_approved" || row["status"] === null ?  faqIconReddish : tickIcon } alt="statusIcon" 
            style={{ height: "22px",width: "22px"}}/>
        </IconButton>
      </span>)
      }

      checkIfAccountInfoAvaialble( data ){
        if( (data["transaction_type"] === "b_out" && data["payment_mode"] === "bijak") ||
          (data["transaction_type"] === "b_in" && data["payment_mode"] === "bank") ){
        if(  data &&
             data["bank_details"] &&
             (data["status"] === "payout_processed" || 
             data["status"] === "transaction_initiated" || 
             data["status"] === "payout_initiated" || 
             data["status"] === "payout_queued" || 
             data["status"] === "payout_pending" || 
             data["status"] === "payout_processing"
            ) &&
             data["bank_details"] !== "-" && 
             data["bank_details"]["bank_account_number"] &&
             data["bank_details"]["bank_ifsc_code"] && 
             data["bank_details"]["bank_account_holder_name"]){
            return true;
        }else{
            return false;
        }
    }else{
        return false;
    }
      }

      formatDateAndTime = (dateval) => {
        var fdate = moment.utc(new Date(dateval)).format('DD-MMM-YYYY HH:mm A')
        return <div style={{ width: "95px", display: "inline-block" }}> {fdate.split(" ")[0] + " \n" + fdate.split(" ")[1] + " " + fdate.split(" ")[2]}</div>
        // return <div style={{ width: "95px", display: "inline-block" }}> {fdate.split(" ")[0]}</div>
    }

        // -------------------------------------------------- 
        resetOffsetAndGetData() {
            let paramsval = this.state.params;
            paramsval["offset"] = paramsval["offset"] + 1000;
            this.setState({ params: paramsval, isTableDataLoading: true }, function () {
                this.getPaymentHistory( this.props.orderData["id"]  , this.state.params );
            })
        }
    
        handelRefreshModal() {
            let param = {};
            param["offset"] = 0;
            param["limit"] = 1000;
            this.setState({
                groupedTransactionData: {},
                allTransactionsData: [],
                page: 0, 
                totalDataCount: 0, 
                sortKeys: {},
                sortParams:{},
                sortKeysOrder: [],
                showLoader: true }, () =>
            this.getPaymentHistory( this.props.orderData["id"] , param )
            )
        }

        addKeysToSortList = (keys) => {
            if (this.state.sortingEnabled.indexOf(keys) > -1) {
                let srtkys = Object.assign({}, this.state.sortKeys);
                let srtKeyOrders = this.state.sortKeysOrder;
                if (srtKeyOrders.indexOf(keys) === -1) {
                    srtKeyOrders.splice(0, 0, keys);
                } else {
                    srtKeyOrders.splice(srtKeyOrders.indexOf(keys), 1);
                    srtKeyOrders.splice(0, 0, keys);
                }   
                if (srtkys.hasOwnProperty(keys)) {
                    if (srtkys[keys] === "asc") {
                        srtkys[keys] = "desc";
                    } else {
                        srtkys[keys] = "asc";
                    }
                } else {
                    srtkys[keys] = "asc";
                }
                this.setState({ sortKeys: srtkys, sortKeysOrder: srtKeyOrders });
                let params = {
                    sortkey: [],
                    sortorder: []
                }
                for (let i = 0; i < srtKeyOrders.length; i++) {
                    params["sortkey"].push(srtKeyOrders[i]);
                    params["sortorder"].push(srtkys[srtKeyOrders[i]]);
                }
                params["sortkey"] = params["sortkey"].toString();
                params["sortorder"] = params["sortorder"].toString();
                    this.setState({
                        sortParams : params,
                        allTransactionsData : [],
                        groupedTransactionData : [],
                        page: 0,
                        showLoader:true
                    },()=>{
                        this.getPaymentHistory( this.props.orderData["id"] , this.state.params);
                    }) 
            }
        }
    
    render() {
        const { classes } = this.props;
        const { allTransactionsData,
              isTableDataLoading, tableHeadDataKeys, sortingEnabled
         } = this.state;
        const { rowsPerPage, page } = this.state;
        const leftAlignedIndexs = [0,1, 2,3];
        const rightAlignedIndexs = [8];
        return (
            <div>
                <Dialog 
                fullScreen={false}
                open={true} 
                classes={{ paper: classes.dialogPaper }}
                onClose={(event) => { this.handelModalClose(event) }} 
                TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Typography
                                variant="h6"
                                className={classes.title}>
                                Order Id : { this.props.orderData["id"] }
                            </Typography>
                           
                            <Button autoFocus className={classes.closeBtn} color="inherit" onClick={(event) => { this.handelModalClose(event) }}>
                                Close
                            </Button>
                        </Toolbar>
                    </AppBar>
                    {allTransactionsData ? 
                    <div style={{ margin: 20 }}>
                            {allTransactionsData && allTransactionsData.length > 0 &&
                                <Table className='table-body'>
                                    <TableHead style={{ borderLeft: "4px solid #05073a", borderRight: "4px solid #05073a" }}>
                                        <TableRow  style={{borderBottom: "2px solid #858792"}} >
                                            {this.state.tableHeadData.map((option, i) => (
                                                <TableCell 
                                                key={option} 
                                                // onClick={() => this.addKeysToSortList(tableHeadDataKeys[i])}
                                                className={this.getTableCellClass(classes, i)} 
                                                style={{ minWidth: '120px', paddingLeft: i === 0 ? '22px' : '',
                                                cursor: sortingEnabled.indexOf(tableHeadDataKeys[i]) > -1 ? "pointer" : "unset",
                                                textAlign: leftAlignedIndexs.indexOf(i) > -1 ? "left" : rightAlignedIndexs.indexOf(i) > -1 ? "right" : ""
                                            }}>{option}
                                            {/* {sortingEnabled.indexOf(tableHeadDataKeys[i]) > -1 &&
                                                        <i className={Utils.getArrowIconType(sortKeys, tableHeadDataKeys[i])} aria-hidden="true"  style={{ color : sortKeys.hasOwnProperty(tableHeadDataKeys[i]) ? '#e72e89':'unset'}}></i>} */}
                                            </TableCell>
                                            ))}
                                             <TableCell className={this.getTableCellClass(classes, 0)} style={{ paddingLeft:  '' }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {!isTableDataLoading && <TableBody>
                                        {
                                          (rowsPerPage > 0
                                            ? allTransactionsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : allTransactionsData
                                          ).map((row, i) => {
                                            return (
                                                //tableHeadData:["id","Supplier Name","Supplier Bussiness Name","Created Time","Amount","Payment mode","Invoice images"],
                                                <TableRow key={'table_' + i} style={{ background: (i % 2 === 0 ? "#e5e8ec" : "#fff"), borderLeft: `4px solid ${this.getTransactionTypeColor(row.transaction_type)}`, borderRight: `4px solid ${this.getTransactionTypeColor(row.transaction_type)}` }}>

                                                    <TableCell component="th" scope="row" className={this.getTableCellClass(classes, 0)}  style={{textAlign: "left"}} >

                                                    {/* {this.checkIfAccountInfoAvaialble( row ) ? 
                                                    <i className="fa fa-info-circle" aria-hidden="true" 
                                                        onClick={(event )=> this.setState({ showTransactionInfoDialog : true , transactionInfoData : row  })}
                                                        style={{ color: "#e72e89",marginLeft:"2px", marginRight:"8px", cursor: "pointer", height: "18px", fontSize:"22px" }} />
                                                    : ""} */}

                                                    {this.getStatusOption(this, row)}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" className={this.getTableCellClass(classes, 0)} style={{textAlign: "left"}}>                                                   
                                                    { !row.active && 
                                                                   <i className="fa fa-circle"
                                                                      data-toggle="tooltip" title={row.active ? "Enabled" : "Disabled"} 
                                                                      style={{ color: "#776969", fontSize:"17px" , cursor:"pointer", marginLeft: "-12%"}} 
                                                                      aria-hidden="true"></i>} &nbsp;
                                                                    
                                                        {row.id ? row.id : "-"}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" className={this.getTableCellClass(classes, 0)} style={{textAlign:"left"}}>
                                                                    {row.pay_id ? row.pay_id : "-"}
                                                                    </TableCell>
                                                    <TableCell component="th" scope="row" className={this.getTableCellClass(classes, 0)} style={{textAlign: "left"}}>
                                                        {row.supplier_fullname ? row.supplier_fullname : "-"}
                                                    </TableCell>
                                                    
                                                    <TableCell className={this.getTableCellClass(classes, 2)} style={{textAlign: "left"}}>
                                                        <div className="text-ellpses">
                                                            {row.supplier_business_name ? row.supplier_business_name : "-"}
                                                        </div>
                                                    </TableCell>
                                                    
                                                    <TableCell className={this.getTableCellClass(classes, 3)}>
                                                        <div className="text-ellpses">
                                                            {row.createdtime ? this.formatDateAndTime(row.createdtime): "-"}
                                                        </div>
                                                    </TableCell>
                                                    
                                                    <TableCell className={this.getTableCellClass(classes, 4)}>
                                                    {row.razx_skipped ? <img width="30px" height="30px" src={razorpay_skipped} alt=""/> : ""} {row.payment_mode ? row.payment_mode : "-"}
                                                        {/* <span id="livetransactionId"> <img className="livetransaction" src={transactionIcon} alt="transacionIcon"/></span> */}
                                                    </TableCell>
                                                    <TableCell className={this.getTableCellClass(classes, 4)}>
                                                                        {row.transaction_type ? row.transaction_type : "-"}
                                                                    </TableCell>
                                                    <TableCell className={this.getTableCellClass(classes, 4)} style={{ color: this.getTransactionTypeColor(row.transaction_type) , textAlign: "right"}}>
                                                    ₹ {row.amount ? Utils.formatNumberWithComma(row.amount) : "-"}
                                                    </TableCell>
                                                    {/* <TableCell className={this.getTableCellClass(classes, 4)}>

                                                        <Fab
                                                            variant="extended"
                                                            size="small"
                                                            aria-label="add"
                                                            onClick={this.handelTransactionInvoiceModal.bind(this, row)}
                                                            style={{ textTransform: "none", background: "#05073a", color: "#ffffff", padding: "0 35px" }}
                                                        >
                                                            View
                                                </Fab>

                                                    </TableCell> */}
                                                    <TableCell className={this.getTableCellClass(classes, 4)}>
                                                        {/* { getAccessAccordingToRole("editPayment") &&  <EditIcon
                                                            className="material-Icon"
                                                            onClick={() => this.handelEditModalOpen(row)}
                                                            style={{ color: "#e72e89", cursor: "pointer", height: "18px", fontSize: "18px"  }} />} */}
                                                    </TableCell>

                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>}
                            </Table>}
                                
                            {allTransactionsData && allTransactionsData.length > 0 && !this.state.showLoader  ? 
                            "":
                                <div className={classes.defaultTemplate}
                                    style={{
                                        margin: "10%",
                                        textAlign: "center",
                                        fontSize: "24px"
                                    }}>
                                    {this.state.showLoader ?  <Loader/> :
                                     <span style={{display : "grid"}}>
                                        <i style={{ fontSize : "44px"}} className={classes.defaultIcon + " fa fa-frown-o"} aria-hidden="true"></i>
                                        {"No data available"}
                                        </span>}
                                </div>}
                            {/* {!allTransactionsData && <Loader />} */}
                        </div> :
                         <Loader />}


                </Dialog>
                {/* {this.state.showImageInvoiceModal &&
                    <TransactionInvoiceModal
                        openModal={this.state.showImageInvoiceModal}
                        onInvoiceModalClose={() => { this.setState({ showImageInvoiceModal: false, invoiceModalData: [] }) }}
                        invoiceUrlData={this.state.invoiceModalData} />} */}
            </div>);

    }
}

OrderPaymentList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrderPaymentList);