import React from "react";
import { Route, Switch} from "react-router-dom";
import AppliedRoute from "./containers/AppliedRoute";
import Home from "./components/common/HomePage";
import Signin from "./components/auth/SigninPage";
import NotFound from "./components/common/NotFoundPage";
import AccessDeniedContainer from "./components/auth/AccessDeniedContainer";
import { getStatusOfRole } from "./config/appConfig";
import CONSTANTS from './utils/constants';


export default () =>
  <Switch>
    <Route path="/" exact component={Signin}  />
    <Route path="/login" exact component={Signin}  />
    <Route path="/access-denied" exact component={AccessDeniedContainer}  />
    <AppliedRoute path="/home" exact component={Home}  />
    
    {getStatusOfRole("BasicUser") && <AppliedRoute path="/home/user-list" exact component={Home}  />}
    {getStatusOfRole("BasicUser") && <AppliedRoute path="/home/buyer-list" exact component={Home}  />}
    {getStatusOfRole("BasicUser") && <AppliedRoute path="/home/broker-list" exact component={Home}  />}
    {getStatusOfRole("BasicUser") && <AppliedRoute path="/home/supplier-list" exact component={Home}  />}
    {getStatusOfRole("BasicUser") && <AppliedRoute path="/home/rate-list" exact component={Home}  />}
    {getStatusOfRole("BasicUser") && <AppliedRoute path="/home/orders-list" exact component={Home}  />}
    {getStatusOfRole("BasicUser") && <AppliedRoute path="/home/payment" exact component={Home}  />}
    {getStatusOfRole("BasicUser") && <AppliedRoute path="/home/payment-details" exact component={Home}  />}
    {getStatusOfRole("BasicUser") && <AppliedRoute path="/home/dsfeed-demand-list" exact component={Home}  />}
    {getStatusOfRole(CONSTANTS.ROLE_CONSTANTS.CSUSER) && <AppliedRoute path="/home/supply-list" exact component={Home}  />}
    {getStatusOfRole(CONSTANTS.ROLE_CONSTANTS.CSUSER) && <AppliedRoute path="/home/demand-list" exact component={Home}  />}
    {/* {getStatusOfRole("ShiftingMergingOrderPayment") && <AppliedRoute path="/home/merge-users" exact component={Home}  />} */}
    {getStatusOfRole("ShiftingMergingOrderPayment") && <AppliedRoute path="/home/group-user" exact component={Home}  />}
    {getStatusOfRole("RelationshipManagerRole") && <AppliedRoute path="/home/rm" exact component={Home}  />}
    {/* {getStatusOfRole("BasicUser") && <AppliedRoute path="/home/todays-payment" exact component={Home}  />} */}
    {getStatusOfRole("BasicUser") && <AppliedRoute path="/home/add-bank-account" exact component={Home}  />}
    {getStatusOfRole("DownloadData") && <AppliedRoute path='/home/downlaod-net' exact  component={Home}  />}
    {/* supporting Data tab routes */}
    {getStatusOfRole("SupportingDataManagement") && <AppliedRoute path="/home/comodity-list" exact component={Home}  />}
    {getStatusOfRole("SupportingDataManagement") && <AppliedRoute path="/home/mandi-data" exact component={Home}  />}
    {getStatusOfRole("SupportingDataManagement") && <AppliedRoute path="/home/mandi-rates" exact component={Home}  />}

    {getStatusOfRole("SuperAdmin") && <AppliedRoute path="/home/role-permission" exact component={Home}  />}
    {getStatusOfRole("SuperAdmin") && <AppliedRoute path="/home/app-version" exact component={Home}  />}
    {getStatusOfRole("SuperAdmin") && <AppliedRoute path="/home/app-logs" exact component={Home}  />}

    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;