// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.
// const awsmobile_dev = {
//     'aws_cognito_identity_pool_id': 'ap-south-1:539a4404-f5f2-4f16-b519-f91c7fa651f8',
//     'aws_cognito_region': 'ap-south-1',
//     'aws_project_id': '94b2e410-af26-4b0f-88af-42c744f674c1',
//     'aws_project_name': 'dev-bijak-team-internal',
//     'aws_project_region': 'ap-south-1',
//     'aws_resource_name_prefix': 'devbijakteaminternal-mobilehub-235425636',
//     'aws_sign_in_enabled': 'enable',
//     'aws_user_pools_id': 'ap-south-1_uK2L2UAmX',
//     'aws_user_pools_web_client_id': '5sum9sk0h9hghm6mr9329t7dc0',
//     'aws_user_files': 'enable',
//     'aws_user_files_s3_bucket': 'devbijakteaminternal-userfiles-mobilehub-235425636',
//     'aws_user_files_s3_bucket_region': 'ap-south-1'
// }

// AWS Mobile Hub Project Constants
const awsmobile_staging = {
    // 'aws_app_analytics': 'enable',
    'aws_cognito_identity_pool_id': 'ap-south-1:70e942ec-8a16-4d86-979b-2656e1f362f0',
    'aws_cognito_region': 'ap-south-1',
    'aws_project_id': 'c2438ef2-22d0-428d-ab0b-b7795b154272',
    'aws_project_name': 'staging-bijak-team-internal',
    'aws_project_region': 'ap-south-1',
    'aws_resource_name_prefix': 'stagingbijakteaminte-mobilehub-1084387757',
    'aws_sign_in_enabled': 'enable',

    'aws_user_pools_id': 'ap-south-1_MxYNEsVe3',
    'aws_user_pools_web_client_id': '7evp71sgrehhnrriqdiiqaqa8v',
    'aws_user_files': 'enable',
    'aws_user_files_s3_bucket': 'stagingbijakteaminte-userfiles-mobilehub-1084387757',
    'aws_user_files_s3_bucket_region': 'ap-south-1'    
}

export default awsmobile_staging;