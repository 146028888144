import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Utils from '../../../app/common/utils';
import SweetAlertPage from '../../../app/common/SweetAlertPage';
import DialogActions from '@material-ui/core/DialogActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Loader from '../../common/Loader';
import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
import commonService from '../../../app/commonService/commonService';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const theme = createMuiTheme({
    overrides: {
        head: {
            color: '#2e3247',
            fontWeight: 600,
            fontSize: '13px !important',
            fontFamily: 'lato !important',
            textTransform: 'uppercase',
            lineHeight: "1em"

        },
        body: {
            color: 'rgba(0, 0, 0, 0.87)',
            fontWeight: 500,
            fontSize: '14px !important',
            fontFamily: 'lato !important',
            // lineHeight: '1.5em',
        }, MuiTablePagination: {
            toolbar: {
                paddingRight: '200px'
            }
        },
        MuiButton:{
            root:{
                '&:hover':{
                    
                }
            }
        }
    }
});

const styles = theme => ({
    dialogPaper: {
        minWidth: '70%',
        // maxWidth: '700px',
        minHeight: '590px',
        // maxHeight: '500px'
    },
    profile: {
        marginLeft: '30%',
        background: 'red',
        width: '40px',
        borderRadius: '10px'
    },
    formSubmitBtn: {
        backgroundColor: "#127c27",
        color: '#fff',
        '&:hover':{
            backgroundColor: "#127c27 !important",     
        }
    },
    formCancelBtn: {
        backgroundColor: "#dd1515",
        color: '#fff',
        '&:hover':{
            backgroundColor: "#dd1515 !important",      
        }
    },
    actcardtext: {
        fontSize: "15px",
        fontFamily: "lato"
    }

});

class ConfirmAndPayoutModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            transactionData: {},
            tableHeadData: ["Details", "Payout Type", "Transaction Details"],
            payoutBulkData: this.props.payoutBulkData,
            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },
            showLoader: false,
            reviewChecked: false
        }
        // console.log(this.props.payoutBulkData);
    }

    handelSweetAlertClosed() {
        this.setState({ showSweetAlert: false }, () => {
            if (this.state.sweetAlertData.type !== "error") {
                this.props.onConfirmPayoutSuccess();
            }
        });
    }

    onModalCancel(event) {
        this.setState({ open: false }, () => {
            this.props.onModalCancel();
        });
    }

    getUserRowData(classes, row) {
        return (
            <span>
                <div style={{ display: 'flex' }} className={classes.actcardtext} ><span style={{ width: '25%' }}>ID </span>:&nbsp;<span>{row.id ? row.id : "-"}</span></div>
                <div style={{ display: 'flex' }} className={classes.actcardtext} ><span style={{ width: '25%' }}>Buyer</span>:&nbsp;<span>{row.buyer_name ? row.buyer_name : '-'}</span></div>
                <div style={{ display: 'flex' }} className={classes.actcardtext}><span style={{ width: '25%' }}>Supplier</span>:&nbsp;<span>{row.name ? row.name : '-'}</span></div>
            </span>
        );
    }

    getDataDetails(classes, obj) {
        try {
            if (obj.hasOwnProperty('skipRazorpayX') && obj['skipRazorpayX']) {
                return (this.getSkipRazorPayData(classes, obj));
            } else {
                return (this.getRazorPayTrans(classes, obj));
            }
        } catch (err) {
            console.log(err);
        }
    }

    getSkipRazorPayData(classes, obj) {
        return (<div>
            <div style={{ display: "flex", paddingBottom: "5px", paddingTop: '5px' }}>
                <span className={classes.actcardtext} > Amount  : &nbsp;<strong style={{ color: "red" }}> ₹ {Utils.formatNumberWithComma(obj.amount)}  </strong></span>
            </div>
            <div>
            <span>
                <div style={{ display: 'flex' }} className={classes.actcardtext}><span style={{ width: '45%' }}>Transaction mode </span>:&nbsp;&nbsp;<span>{obj.mode}</span></div>
                <div style={{ display: 'flex' }} className={classes.actcardtext}><span style={{ width: '45%' }}>Bank id</span>:&nbsp;&nbsp;<span>{obj.bank_id}</span></div>
                <div style={{ display: 'flex' }} className={classes.actcardtext}><span style={{ width: '45%' }}>UTR</span>:&nbsp;&nbsp;<span>{obj.utr}</span></div>
                <div style={{ display: 'flex' }} className={classes.actcardtext}><span style={{ width: '45%' }}>Remark</span>:&nbsp;&nbsp;<span>{obj.remarks}</span></div>
               
            </span>
                {/* <TextField
                    margin="dense"
                    id="bank_id"
                    label="Bank id"
                    type="text"
                    style={{ width: '100%' }}
                    value={obj.bank_id}
                    disabled={true}
                    fullWidth />

                <TextField
                    margin="dense"
                    id="utr"
                    label="UTR"
                    type="text"
                    style={{ width: '100%', textTransform: "uppercase" }}
                    value={obj.utr}
                    disabled={true}
                    fullWidth />

                <TextField
                    margin="dense"
                    id="remarks"
                    label="Remark"
                    type="text"
                    style={{ width: '100%' }}
                    value={obj.remarks}
                    disabled={true}
                    fullWidth /> */}
            </div>

        </div>);
    }

    getRazorPayTrans(classes, obj) {
        return (<span>
            <div style={{ display: "flex", paddingBottom: "5px", paddingTop: '5px' }}>
                <span className={classes.actcardtext} > Amount  : &nbsp;<strong style={{ color: "red" }}> ₹ {Utils.formatNumberWithComma(obj.amount)}  </strong></span>
            </div>
            <div>

            <span>
                <div style={{ display: 'flex' }} className={classes.actcardtext}><span style={{ width: '45%' }}>Transaction mode </span>:&nbsp;&nbsp;<span>{obj.mode}</span></div>
                <div style={{ display: 'flex' }} className={classes.actcardtext}><span style={{ width: '45%' }}>Narration</span>:&nbsp;&nbsp;<span>{obj.narration}</span></div>
            </span>
{/* 
                <TextField
                    margin="dense"
                    id="narration"
                    success={true}
                    disabled={true}
                    label="Transaction mode"
                    type="text"
                    style={{ width: '100%' }}
                    value={obj.mode}
                    fullWidth />
                <TextField
                    margin="dense"
                    id="narration"
                    success={true}
                    disabled={true}
                    label="Narration"
                    type="text"
                    style={{ width: '100%' }}
                    value={obj.narration}
                    fullWidth /> */}

            </div>
        </span>
        );
    }

    async onConfirmPayout(event) {
        try {
            this.setState({ showLoader: true });
            let resp = await commonService.submitBulkPayout(this.state.payoutBulkData);    
            this.setState({ showLoader: false });
            let sweetAlrtData = this.state.sweetAlertData;
            if (resp.data.status === 1) {
                sweetAlrtData["type"] = "success";
                sweetAlrtData["title"] = "Success";
                sweetAlrtData["text"] = "Payout done successfully";
            } else {
                sweetAlrtData["type"] = "error";
                sweetAlrtData["title"] = "Error";
                sweetAlrtData["text"] = resp && resp.data && resp.data.message ? resp.data.message : "Oops! There was a error while payout.";
            }
            this.setState({
                showLoader: false,
                showSweetAlert: true,
                sweetAlertData: sweetAlrtData
            });
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        const { classes } = this.props;
        const { showSweetAlert, sweetAlertData, tableHeadData, reviewChecked, payoutBulkData ,showLoader} = this.state;
        const leftAlignedIndexs = [];
        const rightAlignedIndexs = [];

        return (<MuiThemeProvider theme={theme}>
            <div>
                <Dialog style={{ zIndex: '1305' }}
                    open={this.state.open}
                    classes={{ paper: classes.dialogPaper }}
                    onClose={this.onModalCancel.bind(this)}
                    aria-labelledby="form-dialog-title"                >
                    <DialogTitle
                        style={{ background: '#05073a', textAlign: 'center', height: '60px' }}
                        id="form-dialog-title">
                        <div style={{ color: '#fff', fontFamily: 'Lato', fontSize: '20px' }}>
                            Confirm Bulk Payout
                    </div>
                    </DialogTitle>
                    <DialogContent style={{ width: '100%', padding: '0' }}>
                        {!showLoader ? <Table className='table-body'>
                            <TableHead style={{}}>
                                <TableRow style={{ borderBottom: "2px solid #858792" }} >
                                    {tableHeadData.map((option, i) => (
                                        <TableCell
                                            key={option}
                                            className={classes.tableCell}
                                            style={{
                                                minWidth: '120px', paddingLeft: i === 0 ? '22px' : '',
                                                textAlign: leftAlignedIndexs.indexOf(i) > -1 ? "left" : rightAlignedIndexs.indexOf(i) > -1 ? "right" : ""
                                            }}>
                                            {option}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {<TableBody>
                                {payoutBulkData && payoutBulkData.map((obj, i) => {
                                    return (
                                        <TableRow key={'table_' + i} style={{ background: (i % 2 === 0 ? "#e5e8ec" : "#fff") }}>
                                            <TableCell component="th" scope="row" className={classes.tableCell}  >
                                                {this.getUserRowData(classes, obj)}
                                            </TableCell>
                                            <TableCell component="th" scope="row" className={classes.tableCell} >
                                                <div>{obj.hasOwnProperty("skipRazorpayX") && obj['skipRazorpayX'] ? 'skipRazorpayX' : 'RazorPayX'}</div>
                                            </TableCell>
                                            <TableCell component="th" scope="row" className={classes.tableCell} style={{ maxWidth: '200px' }}>
                                                {this.getDataDetails(classes, obj)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>}
                        </Table>: <Loader/>}

                    </DialogContent>
                   {!showLoader &&  <FormControlLabel style={{ padding: '0px 4%' }} control={
                        <Checkbox checked={reviewChecked} onChange={(event) => this.setState({ reviewChecked: event.target.checked })} name="checkedB" color="primary" />}
                        label="I have reviewed all the data above" />}
                    {!showLoader && <DialogActions>
                        {reviewChecked &&
                        <Button classes={{root : classes.formSubmitBtn }} className={classes.formSubmitBtn} onClick={this.onConfirmPayout.bind(this)} >Submit</Button>}
                        <Button classes={{root : classes.formCancelBtn }} className={classes.formCancelBtn} onClick={this.onModalCancel.bind(this)}>Cancel</Button>
                    </DialogActions>}
                    {showSweetAlert &&
                        <SweetAlertPage
                            show={true}
                            type={sweetAlertData.type}
                            title={sweetAlertData.title}
                            text={sweetAlertData.text}
                            sweetAlertClose={() => this.handelSweetAlertClosed()}
                        />}

                </Dialog>

            </div>
        </MuiThemeProvider>
        );
    }
}

ConfirmAndPayoutModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ConfirmAndPayoutModal);