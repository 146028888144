import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import '../../assets/css/app.css';
import ListItems from '../../containers/SidebarComponent';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import { Route } from "react-router-dom";
// import { Auth } from "aws-amplify";
import Auth from '@aws-amplify/auth';
// import cookie from "react-cookies";
import { withRouter } from 'react-router-dom';
import MandiDataContainer from '../mandiData/MandiDataContainer';
import UserDataContainer from '../userData/UserDataContainer';
import BrokerContainer from '../broker/brokerContainer';
import BuyerContainer from '../buyer/buyerContainer';
import SupplierContainer from '../supplier/supplierContainer';
import PriceContainer from '../price/priceContainer';
import mandiDataService from '../../app/mandiDataService/mandiDataService';
import CommodityContainer from '../commodityList/commodityContainer';
import OrdersContainer from '../orders/OrdersContainer';
import PaymentContainer from '../payment/PaymentContainer';
// import TodaysPaymentContainer from '../todaysPayments/TodaysPaymentContainer';
import Utils from '../../app/common/utils';
import MandiRateContainer from '../mandiRates/MandiRateContainer';
import ChangePasswordPage from '../auth/ChangePasswordPage';
import Icon from '@material-ui/core/Icon';
import AddBankAccountContainer from '../addBankAccount/AddBankAccountContainer';
import commonService from '../../app/commonService/commonService';
import RolePermissionContainer from '../role-permission/RolePermissionContainer';
import { getStatusOfRole, checkIfAppVersionMatches } from '../../config/appConfig';
import PaymentDetailsContainer from '../paymentDetails/PaymentDetailsContainer';
import DownloadNetContainer from '../downloadData/DownloadNetContainer';
import SweetAlertPage from '../../app/common/SweetAlertPage';
import AppVersionContainer from '../appVersion/AppVersionContainer';
import NotificationsIcon from '@material-ui/icons/Notifications';
import bellNoft from "../../assets/images/notification_version.svg";
import $ from 'jquery';
import AppLogsContainer from '../applogs/AppLogsContainer';
import Websocket from '../auth/Websocket';
import GroupUserContainer from '../groupUser/GroupUserContainer';
import RelationshipManagerContainer from '../relationshipManager/RelationshipManagerContainer';
import DsFeedContainer from '../dsFeed/dsFeedContainer';
import DsFeedSupplyContainer from '../dsFeed/dsFeedSupplyContainer';
import DsFeedDemandContainer from '../dsFeed/dsFeedDemandContainer';
import CONSTANTS from '../../utils/constants';

// import MergeUserContainer from '../mergeUser/MergeUserContainer';
const drawerWidth = 250;

const styles = theme => ({
  root: {
    flexGrow: 1,
    // height: "100vh",
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  toolbarRoot: {
    flexGrow: 1

  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#f5f3f3',
    textColor: "#50a3b4",
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  barBrandName: {
    marginLeft: '10px',
    marginTop: '20px',
    fontSize: '16px',
    color: '#ffffff',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'fixed',
    whiteSpace: 'nowrap',
    // backgroundColor: '#f5f5fa',
    backgroundColor: '#2e3247',
    borderRight: "0 !important",
    backgroundImage: "#2e3247 !important",
    // backgroundImage: "linear-gradient(to left, #3c3f3f, #333535, #292c2c, #212323, #181a1a)",
    // backgroundImage: "linear-gradient(to right bottom, #478e89, #25828b, #00748c, #00668c, #005687)",
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 7,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 8px',
    background: 'white',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: "#fff",
    padding: theme.spacing.unit * 3,
    // paddingLeft: theme.spacing.unit * 3,
    // paddingRight: theme.spacing.unit * 3,
    marginTop: '30px',
  },
  Icon: {
    // float: 'left',
    marginTop: '15px',
    width: '32%',
    float: 'right',
    height: '20px',
    display: "flex"
  },
  IconFirstDiv: {
    width: '33%',
    float: 'left'
  },
  IconSecondDiv: {
    width: '33%',
    float: 'left'
  },
  badgeNotify: {
    color: '#fff',
    background: 'red',
    position: 'relative',
    top: '-40px',
    marginLeft: '16px',
    width: '30px',
    textAlign: 'center',
    borderRadius: '5px',
    fontSize: '12px'

  },
  lightTooltip: {
    fontSize: '14px',
  },
  muimenuPaper: {
    left: 'unset !important',
    right: '2%',
    maxHeight: '500px'
  }
});

class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: true,
      auth: true,
      anchorEl: null,
      anchorElNotf: null,
      dbImgUrl: "",
      isSetGlobal: false,
      showChangePasswordView: false,
      currentTabName: "",
      showVersionDialog: false,
      notificationDataArray: []
    };
    this.logoutUser = this.logoutUser.bind(this)
    this.changePasswordViewClick = this.changePasswordViewClick.bind(this);
  }


  UNSAFE_componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

    // setTimeout(() => {
    //   $("#payment_29").addClass("highlightCssEffect");
    // }, 5000);
  }

  async componentDidMount() {
    try {

      $("#notification_badge").hide();
      $("#clearAllFooter").hide();


      $('#notificationsBody').html(`<div id="emptyView" style="display:grid; text-align:center;padding:10%">
          <span><i class="fa fa-bell-o" style="font-size: 50px;color: #e74a52;" aria-hidden="true"></i></span>
          <span style="color:#230202; font-family:lato; font-size:15px;">No notification available</span>
        </div>`)

      window.notificationdata = "";
      Websocket.initiateSocket();
      // Websocket.initiateSocket(({ data }) => {
      //   // console.log(data)
      //   //         // let msgData = ref.state.notificationDataArray;
      //   //         // let pData = JSON.parse(data);
      //   //         // msgData.unshift(pData);
      //   //         // window.notificationdata = pData;
      //   //         // ref.setState({ notificationDataArray: msgData });

      //   let msgData = Utils.getNotificationData();
      //   let pData = JSON.parse(data);
      //   msgData.unshift(pData);
      //   window.notificationdata = pData;
      //   Utils.setNotificationData(msgData);


      //   //This part is for notification badge
      //   let lengthOfNotf = Utils.getNotificationDataLength();
      //   if (lengthOfNotf < 100) {
      //     $("#notification_badge").show().html(lengthOfNotf);
      //   } else {
      //     if ($("#notification_badge").text() === "99+" && lengthOfNotf >= 99) {
      //       // don't update the span 
      //     } else {
      //       $("#notification_badge").show().html('99+');
      //     }
      //   }

      //   //this is for pushing the data in list
      //   if ($("#emptyView") && $("#emptyView").length > 0) {
      //     $("#emptyView").remove();
      //     $("#clearAllFooter").show();
      //   }

      //   let typeIcon = Utils.getIconOnTypeText(pData.type);
      //   $('#notificationsBody').prepend(
      //     `<div class="notf_list ">
      //     <div style='padding-left:12px;'>
      //       ${typeIcon}
      //     </div>
      //     <div class="notf">
      //       <div class="d-flex justify-content-between">
      //         <h5 class="notf_title">${pData.type ? pData.type : ''}</h5>
      //       </div>
      //       <span class="notf_message">
      //         ${pData.message ? pData.message : ''}
      //       </span>
      //     </div>
      //   </div>`);

      //   // this part is for Component Wise Showing Update
      //   let idToHighLight = pData['type'] + "_" + pData['id'];
      //   if ($("#" + idToHighLight).length > 0) {
      //     $("#" + idToHighLight).addClass("highlightCssEffect");
      //   }
      // });
    } catch (err) {
      console.log(err);
    }



    Auth.currentAuthenticatedUser()
      .then(user => {
        this.getUserRole(user.attributes.phone_number);
      })
      .catch(err => {
        console.log(err);
      });

    this.checkIfVersionMatched();
    this.handelNotificationPopUp();
  }

  handelNotificationPopUp() {

    $(document).ready(function () {
      $(".notification_header").click(function () {
        $("#notificationContainer").fadeToggle(300);
        $("#notification_count").fadeOut("slow");
        return false;
      });

      //Document Click hiding the popup 
      $(document).click(function () {
        $("#notificationContainer").hide();
      });

      //Popup on click
      $("#notificationContainer").click(function () {
        return false;
      });

      $("#clearAllFooter").click(function () {
        Utils.setNotificationData([]);
        $("#notification_badge").show().html('0');
        $("#notification_badge").hide();
        $('#notificationsBody').empty();
        $('#notificationsBody').html(`<div id="emptyView" style="display:grid; text-align:center;padding:10%">
        <span><i class="fa fa-bell-o" style="font-size: 50px;color: #e74a52;" aria-hidden="true"></i></span>
        <span style="color:#230202; font-family:lato; font-size:15px;">No notification available</span>
        </div>`)
        $("#clearAllFooter").hide();
      });

    });

  }

  async getData() {
    let resp = await mandiDataService.getDistrictList();
    if (resp.data.status === 1 && resp.data.result) {
      Utils.setDistrictData(resp.data.result.data)
    } else {
      let sweetAlrtData = this.state.sweetAlertData;
      sweetAlrtData["type"] = "error";
      sweetAlrtData["title"] = "Error";
      sweetAlrtData["text"] = resp && resp.data && resp.data.message ? resp.data.message : "Oops an error occured while getting the district list";
      this.setState({
        showSweetAlert: true,
        sweetAlertData: sweetAlrtData
      });
    }
  }

  async getUserRole(mobile) {
    try {
      let resp = await commonService.getUserSpecificRole(mobile);
      if (resp.data.status === 1 && resp.data.result && resp.data.result.length > 0 &&
        resp.data.result[0].permissions && resp.data.result[0].permissions !== "") {
        sessionStorage.setItem("userRole", resp.data.result[0].permissions);
        sessionStorage.setItem("userName", resp.data.result[0].name);
        sessionStorage.setItem("userMobile", resp.data.result[0].mobile);
        this.getData();
      } else {
        sessionStorage.setItem("userRole", "restricted");
        this.props.history.push("/access-denied");
      }

    } catch (err) {
      console.error(err)
      sessionStorage.setItem("userRole", "restricted");
      this.props.history.push("/access-denied");
    }
    // Websocket.SendNotification("Hello How are you ?")
  }



  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleNotfMenu = event => {
    // this.setState({ anchorElNotf: event.currentTarget });
    $("#notificationContainer").fadeToggle(300);
    $("#notification_count").fadeOut("slow");
  };

  handleNotfClose = () => {
    this.setState({ anchorElNotf: null });
  };


  logoutUser = () => {
    try {

      Auth.currentAuthenticatedUser({
      }).then(user => {
        user.deviceKey = user.getCachedDeviceKeyAndPassword();
        user.forgetDevice({
          onSuccess: function (result) {
          },
          onFailure: function (err) {
            console.log(err);
          }
        });
      });


      Auth.signOut()
        .then(data => {
          window.sessionStorage.removeItem('bijak_token')
          window.sessionStorage.removeItem('username');
          this.props.history.push("/");
        })
        .catch(err => console.log(err));

    } catch (err) {
      console.log(err)
    }
  }

  changePasswordViewClick = () => {
    try {
      this.setState({ showChangePasswordView: true, anchorEl: null })

    } catch (err) {
      console.log(err)
    }
  }

  handelSweetAlertClosed() {
    this.setState({ showSweetAlert: false }, () => {
      if (this.state.sweetAlertData.type !== "error") {
        // this.handelGetData();
      }
    });
  }

  checkIfVersionMatched() {
    if (!checkIfAppVersionMatches()) {
      let isAlreadyPopUpShown = window.localStorage.getItem("reloaload_version_shown" + window.sessionStorage.getItem("app_version"))
      if (isAlreadyPopUpShown !== "true") {
        this.setState({ showVersionDialog: true });
      }
    }
  }

  closeVersionMismatchDialog() {
    this.setState({ showVersionDialog: false }, () => {
      try {
        (function () {
          var rep = /.*\?.*/,
            links = document.getElementsByTagName('link'),
            scripts = document.getElementsByTagName('script'),
            process_scripts = false;
          for (var i = 0; i < links.length; i++) {
            var link = links[i],
              href = link.href;
            if (rep.test(href)) {
              link.href = href + '&' + Date.now();
            }
            else {
              link.href = href + '?' + Date.now();
            }

          }
          if (process_scripts) {
            for (var j = 0; j < scripts.length; j++) {
              var script = scripts[j],
                src = script.src;
              if (rep.test(src)) {
                script.src = src + '&' + Date.now();
              }
              else {
                script.src = src + '?' + Date.now();
              }

            }
          }
        })();
        window.localStorage.setItem("reloaload_version_shown" + window.sessionStorage.getItem("app_version"), "true")
        window.location.reload();
      } catch (err) {
        console.log(err)
      }
    });
  }

  getNotificationDataList() {
    let notifData = Utils.getNotificationData();
    return (<div id="notificationsBody" className="notifications">
      {notifData && notifData.length > 0 ? (notifData.map((obj, i) =>
        <div className="notf_list">
          <div style={{ paddingLeft: '12px' }}>
            {Utils.getIconOnType(obj.type)}
          </div>
          <div className="notf">
            <div className="d-flex justify-content-between">
              <h5 className="notf_title">{obj.type ? obj.type : ''}</h5>
            </div>
            <span className="notf_message">
              {obj.message ? obj.message : ''}
            </span>
          </div>
        </div>
      )) :
        <div style={{ display: 'grid', textAlign: 'center', padding: '10%' }}>
          <span><NotificationsIcon style={{ color: "#ed4b53", fontSize: '4.5rem' }} /></span>
          <span style={{ color: '#230202', fontFamily: 'lato', fontSize: '15px' }}>No notification available</span>
        </div>
      }
    </div>
    )
  }

  render() {

    const { classes } = this.props;
    const { anchorEl, showChangePasswordView, showSweetAlert,
      sweetAlertData, showVersionDialog } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div className={classes.root}>
        <AppBar position="fixed" style={{ background: "#2e3247", boxShadow: 'none' }}
          className={classNames(classes.appBar, this.state.open && classes.appBarShift)}>
          <Toolbar>

            <div className={this.props.classes.toolbarRoot}>
              <Grid container spacing={8}>
                <Grid item xs={6} sm={1}>

                  {
                    this.state.open ?
                      <IconButton onClick={this.handleDrawerClose}>
                        <MenuIcon style={{ color: "#fff" }} />
                      </IconButton> :
                      <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={this.handleDrawerOpen}
                        className={classNames(classes.menuButton, this.state.open && classes.hide)}
                      >
                        {/* <img src={logo} className="App-logo2" alt="logo2" /> */}
                        <MenuIcon style={{ color: "#fff" }} />
                      </IconButton>
                  }
                </Grid>
                {/* <Grid style={{marginLeft:'5px',padding: "47px 0px"}} > */}
                <div style={{ color: "white", margin: "auto", display: "flex", width: "22%", height: "25px" }}>
                  <Icon style={{ color: "#50a1cf", fontSize: "24px" }}>play_arrow</Icon>
                  <span style={{ lineHeight: "26px" }}> {this.state.currentTabName}</span>
                </div>
                {/* </Grid> */}
                {!checkIfAppVersionMatches() && <div style={{ color: "white", margin: "auto", display: "flex", width: "22%", height: "25px" }}>
                  <img src={bellNoft} width="55px" height="32px" alt="bell Notification" />
                  <span style={{ lineHeight: "30px", color: "#fff", fontWeight: "bold" }}> {"Version Mismatch"}</span>
                </div>}
                <Grid style={{ marginLeft: (!checkIfAppVersionMatches() ? '4%' : "34%") }} item xs={3} sm={4}>
                  {!window.sessionStorage.getItem('bijak_token', null)

                    ? <div></div>
                    : <div style={{ textAlign: "right", display: "flex", float: "right" }}>

                      <div style={{ display: "grid" }}>
                        <span style={{ fontSize: "17px", fontWeight: 500, fontFamily: "lato" }} > {sessionStorage.getItem("userName") ? sessionStorage.getItem("userName") : ""}</span>
                        <span style={{ fontSize: "13px", fontWeight: 500, fontFamily: "lato" }}>  {sessionStorage.getItem("userMobile") ? sessionStorage.getItem("userMobile") : ""}</span>
                      </div>
                      <IconButton
                        aria-owns={open ? 'menu-appbar' : null}
                        aria-haspopup="true"
                        onClick={this.handleMenu}
                      >
                        <AccountCircle style={{ color: "#ed4b53" }} />
                        {/* {!checkIfAppVersionMatches() && <i className="fa fa-bell" style={{ fontSize: "10px", color: "yellow" }} aria-hidden="true"></i>} */}
                      </IconButton>
                      <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={open}
                        onClose={this.handleClose}
                      >
                        {/* {!checkIfAppVersionMatches() &&
                          <MenuItem style={{ fontSize: "14px", fontWeight: 500, fontFamily: "lato" }}
                            onClick={() => window.location.href = window.location.href}><i className="fa fa-bell" style={{ fontSize: "10px", color: "red " }} aria-hidden="true"></i> &nbsp;&nbsp; Reload App
                        </MenuItem>} */}
                        <MenuItem style={{ fontSize: "14px", fontWeight: 500, fontFamily: "lato" }}
                          onClick={this.changePasswordViewClick}>Change Password
                        </MenuItem>
                        <MenuItem style={{ fontSize: "14px", fontWeight: 500, fontFamily: "lato" }}
                          onClick={this.logoutUser}>Logout</MenuItem>
                      </Menu>
                      {/* <IconButton
                        aria-owns={notfOpen ? 'menu-appbar_a' : null}
                        aria-haspopup="true"
                        style={{ padding: '12px 12px 12px 0px' }}
                        onClick={this.handleNotfMenu}> */}
                      {/* <Badge badgeContent={ 10 } id="XXYYZZ" color="secondary"> */}
                      <div href="#" className="notification_header">
                        <NotificationsIcon style={{ color: "#ed4b53" }} />
                        <span id="notification_badge" className="badge_css"></span>
                      </div>
                      {/* </Badge> */}
                      {/* </IconButton> */}
                      <div id="notificationContainer">
                        <div id="notificationTitle">Notifications</div>
                        <div id="notificationsBody" className="notifications"></div>
                        {/* <div id="notificationsBody" className="notifications">
                          {<div className="notf_list">
                            <div style={{ paddingLeft: '12px' }}>
                              {Utils.getIconOnType('user')}
                            </div>
                            <div className="notf">
                              <div className="d-flex justify-content-between">
                                <h5 className="notf_title">Rose Mic</h5>
                              </div>
                              <span className="notf_message">Notification’s most important elements.It easy to scan.
                              </span>
                            </div>
                          </div>}
                        </div> */}
                        {/* {this.getNotificationDataList()} */}
                        <div id="notificationFooter">
                          <span id="clearAllFooter" style={{ color: "#000", cursor: 'pointer' }} >Clear all</span>
                        </div>
                      </div>
                      {/* {Utils.getNotificationDataLength() > 0 &&
                        <Menu
                          id="menu-appbar_a"
                          anchorEl={anchorElNotf}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                          open={notfOpen}
                          classes={{ paper: classes.muimenuPaper }}
                          onClose={this.handleNotfClose}>

                          {Utils.getNotificationData().map((obj, nfi) =>
                            <MenuItem key={"-" + nfi} style={{ fontSize: "14px", fontWeight: 500, fontFamily: "lato" }}>
                              <div>
                                <div style={{ fontFamily: "lato", fontWeight: "600", textTransform: "uppercase" }}>{Utils.getIconOnType(obj.type)} &nbsp;&nbsp; {obj.type ? obj.type : ''}</div>
                                <div>{obj.message ? obj.message : ''}</div>
                              </div>
                            </MenuItem>)}

                          
                        </Menu>} */}
                    </div>
                  }

                </Grid>
              </Grid>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
          }}

          open={this.state.open}

        >

          {/* <Divider /> */}
          <List id="binod" style={{ padding: '0' }}>
            <ListItems
              globalModeOption={this.state.isSetGlobal}
              isdrawerOpen={this.state.open}
              dbImageUrl={this.state.dbImgUrl}
              onRouteClicked={(rname) => this.setState({ currentTabName: rname })}
              labname={this.state.labname} />
          </List>
        </Drawer>
        <main className={classes.content} style={this.state.open ? { marginLeft: '250px' } : { marginLeft: '56px' }}>

          {getStatusOfRole("BasicUser") && <Route path='/home/user-list' exact component={UserDataContainer} />}
          {getStatusOfRole("BasicUser") && <Route path='/home/buyer-list' exact component={BuyerContainer} />}
          {getStatusOfRole("BasicUser") && <Route path='/home/broker-list' exact component={BrokerContainer} />}
          {getStatusOfRole("BasicUser") && <Route path='/home/supplier-list' exact component={SupplierContainer} />}
          {getStatusOfRole("BasicUser") && <Route path='/home/rate-list' exact component={PriceContainer} />}
          {getStatusOfRole("BasicUser") && <Route path='/home/orders-list' exact component={OrdersContainer} />}
          {getStatusOfRole("BasicUser") && <Route path='/home/dsfeed-demand-list' exact component={DsFeedContainer} />}
          {getStatusOfRole("BasicUser") && <Route path='/home/payment' exact component={PaymentContainer} />}
          {getStatusOfRole("BasicUser") && <Route path='/home/payment-details' exact component={PaymentDetailsContainer} />}
          {getStatusOfRole(CONSTANTS.ROLE_CONSTANTS.CSUSER) && <Route path='/home/supply-list' exact component={DsFeedSupplyContainer} />}
          {getStatusOfRole(CONSTANTS.ROLE_CONSTANTS.CSUSER) && <Route path='/home/demand-list' exact component={DsFeedDemandContainer} />}
          {/* {getStatusOfRole("ShiftingMergingOrderPayment") && <Route path='/home/merge-users' exact component={MergeUserContainer} />} */}
          {getStatusOfRole("ShiftingMergingOrderPayment") && <Route path='/home/group-user' exact component={GroupUserContainer} />}
          {getStatusOfRole("RelationshipManagerRole") && <Route path='/home/rm' exact component={RelationshipManagerContainer} />}

          {/* {getStatusOfRole("BasicUser") && <Route path='/home/todays-payment' exact component={TodaysPaymentContainer} />} */}
          {getStatusOfRole("BasicUser") && <Route path='/home/add-bank-account' exact component={AddBankAccountContainer} />}
          {getStatusOfRole("DownloadData") && <Route path='/home/downlaod-net' exact component={DownloadNetContainer} />}

          {getStatusOfRole("SupportingDataManagement") && <Route path='/home/comodity-list' exact component={CommodityContainer} />}
          {getStatusOfRole("SupportingDataManagement") && <Route path='/home/mandi-data' exact component={MandiDataContainer} />}
          {getStatusOfRole("SupportingDataManagement") && <Route path='/home/mandi-rates' exact component={MandiRateContainer} />}

          {getStatusOfRole("SuperAdmin") &&
            <Route path='/home/role-permission' exact component={RolePermissionContainer} />}
          {getStatusOfRole("SuperAdmin") &&
            <Route path='/home/app-version' exact component={AppVersionContainer} />}
          {getStatusOfRole("SuperAdmin") &&
            <Route path='/home/app-logs' exact component={AppLogsContainer} />}
        </main>

        {
          showChangePasswordView &&
          <ChangePasswordPage
            openModal={this.state.showChangePasswordView}
            onModalClose={() => this.setState({ showChangePasswordView: false })} />
        }

        {
          showSweetAlert &&
          <SweetAlertPage
            show={true}
            type={sweetAlertData.type}
            title={sweetAlertData.title}
            text={sweetAlertData.text}
            sweetAlertClose={() => this.handelSweetAlertClosed()}
          />
        }
        {
          showVersionDialog &&
          <SweetAlertPage
            show={true}
            type={"warning"}
            title={"App Updated"}
            text={"Since the app version is Updated. Kindly Press ok or Hard refresh( ctrl+shift+R )"}
            sweetAlertClose={() => this.closeVersionMismatchDialog()}
          />
        }
      </div >
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles, { withTheme: true })(Home));