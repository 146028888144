/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Loader from '../../common/Loader';
import Utils from '../../../app/common/utils';
import SweetAlertPage from '../../../app/common/SweetAlertPage';
import groupService from '../../../app/groupService/groupService';


const styles = theme => ({
    heading: {
        fontSize: '21px',
        fontWeight: '500',
        marginTop: '0',
        marginBottom: '0',
        fontFamily: 'Montserrat, sans-serif',
    },
    dialogPaper: {
        minWidth: '700px',
        // maxWidth: '700px',
        minHeight: '200px',
        // maxHeight: '500px'
    },
    formAddBtn: {
        width: '90%',
        borderRadius: '10px',
        fontSize: '20px',
        textTransform: 'uppercase',
        backgroundColor: '#4d9fa0 ',
        color: '#fff',
        height: '45px',
        marginBottom: '15px',
        marginTop: "11px",
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    formControl: {
        margin: '0px 5px',
        padding: '0px 10px',
        minWidth: 120,
        color: '#373737 !important',
        borderRight: '1px solid #e6e7e8'
    },
    formRoot: {
        // display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        // marginLeft: '25%',
        border: '1px solid #ccc',
        boxShadow: '2px 2px 6px 0px  rgba(0,0,0,0.3)',
        borderRadius: '4px',
        marginBottom: '20px',
        marginTop: '8%',
        padding: '25px',
        textAlign: 'center'
    },
    input: {
        display: 'none',
    },

});
// const statusOption = ["pending", "settled", "partial_settled"];

const CustomOption = props => {
    const { data, innerRef, innerProps } = props;
    return data && data.is_primary ? (
        <div ref={innerRef} {...innerProps} className='custom_option'>
            <i className="fa fa-check" style={{ color: "#60c1d8" }} aria-hidden="true"></i> &nbsp; {data.label ? data.label : ""}
        </div>
    ) : (
            <div ref={innerRef} {...innerProps} className='custom_option' >
                {data.label ? data.label : ""}
            </div>

        );
};

class CreateGroupModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            addOrderPayload: {
                "buyerid": "",
                "supplierid": "",
                "brokerid": "",
                "commodity": "",
                "rate": "",
                "qnt": "",
                "unit": "",
                "type": "bilti",
                "bijak_amt": "",
                "supporting_images": [],
                "actual_dispatch_date": new Date(),
                "transport_info": "",
                "author_name": "",
                // "author_mobile": "",
                "creator_role": "",
                "status": "pending",
                "remark": "",
                "other_info": "",
                "buyer_mobile": "",
                "supplier_mobile": "",
                "commission_rate": "",
                "commission_unit": "",
                "target_location": "",
                "source_location": "",
                "rate_unit": "",

                "broker_mobile": "",
                "bijak_total_amount": "",
                "invoice_no": "",
                "old_system_order_id": "",
                "pkt": "",
                "brokerage": "",
                "tags": []
            },

            buyerid: "",
            supplierid: "",
            brokerid: "",
            tempVar: {},
            errorFields: {},
            attachmentArray: [],
            commodityList: { options: [], optionN_E: {}, optionE_N: {} },
            showLoader: false,
            subId: "",

            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },
            showFormError: false,

            tagsOptions: [],


            // new variables
            primaryid: [],
            userid: [],
            payloadData: {
                "credit_limit": this.props.selectedData["group_credit_limit"] || "",
                "group_name": this.props.selectedData["group_name"] || "",
            }
        }
        console.log(this.props.selectedData)
    }


    UNSAFE_componentWillReceiveProps() {
        if (this.props !== this.state) {
            this.setState({ open: this.props.open });
        }
    }


    handleInputChange(event) {
        let payloadDataVal = this.state.payloadData;
        var floatIds = ["credit_limit"]; // this values need to be float
        var errors = this.state.errorFields;
        var id = event.target.id;
        if (!id && id === undefined) {
            id = event.target.name;
        }
        var val = event.target.value;
        if (floatIds.indexOf(id) > -1) {
            if (val === "" || !isNaN(val)) { //max 1000000000
                // if (val === "" || val.match(/^(\d+\.?\d{0,9}|\.\d{1,9})$/)) {
                let amount = val && val !== "" ? Number(val) : 0;
                if (amount <= 1000000000) {
                    payloadDataVal[id] = (val && val !== "" ? Number(val) : "");
                }
            }
        } else {
            if (val.length <= 50) {
                payloadDataVal[id] = val;
            }
        }
        if (errors.hasOwnProperty(id)) {
            delete errors[id];
        }
        this.setState({
            payloadData: payloadDataVal,
            errorFields: errors,
            showFormError: false
        })
    }

    handleDialogCancel(event) {
        this.props.onAddModalCancel();
    }

    getOptions = async (type, inputValue, callback) => {
        try {
            if (!inputValue) {
                callback([]);
            }
            let data = {};
            data["searchVal"] = inputValue;
            data["role"]= "ca";
            let resp = await groupService.searchUserList(data);
            if (resp.data.status === 1 && resp.data.result) {
                var respData = this.formatDataForDropDown(resp.data.result.data, "fullname", "id", type);
                callback(respData);
            } else {
                callback([]);
            }
        } catch (err) {
            console.error(err);
            callback([]);
        }
    }

    formatDataForDropDown(data, labelKey, valuekey, type) {

        var optionsData = [];
        if (data) {
            for (var i = 0; i < data.length; i++) {
                // optionsData.push({ label: data[i][labelKey] + " (" + data[i][valuekey] + " )", value: data[i][valuekey] });
                if (type === "primaryid" && data[i].hasOwnProperty('is_primary') && data[i]['is_primary'] && data[i]['is_primary'] !== 'false') {
                    optionsData.push({
                        rawdata: data[i],
                        is_primary: Utils.checkIfPrimaryKey(data[i]),
                        label: data[i]["fullname"] + ",  " + data[i]["business_name"] + " \n  (" + data[i]["locality"] + " , " + data[i][valuekey] + " )",
                        value: data[i][valuekey]
                    });
                } else if (type !== "primaryid" && (!data[i]['is_primary'] || data[i]['is_primary'] === 'false')) {
                    optionsData.push({
                        rawdata: data[i],
                        is_primary: Utils.checkIfPrimaryKey(data[i]),
                        label: data[i]["fullname"] + ",  " + data[i]["business_name"] + " \n  (" + data[i]["locality"] + " , " + data[i][valuekey] + " )",
                        value: data[i][valuekey]
                    });
                }
            }
        }
        return optionsData;
    }

    checkForValidPayload(data) {
        let isvalid = true;
        let errorFields = {};

        if (!data["group_name"] || (data["group_name"] && data["group_name"].trim() === "")) {
            isvalid = false;
            errorFields["group_name"] = true;
        }
        if (!data["credit_limit"] || data["credit_limit"] === "" || data["credit_limit"] === 0) {
            isvalid = false;
            errorFields["credit_limit"] = true;
        }
        this.setState({ errorFields: errorFields });
        return isvalid;
    }

    async handelCreateGroup() {
        try {

            if (this.checkForValidPayload(this.state.payloadData)) {
                this.setState({ showLoader: true });
                let id = this.props.selectedData["id"];
                let payload = this.state.payloadData;
                // payload["active"]= true;
                // payload["group_name"]= this.state.payloadData["group_name"];
                // payload["credit_limit"]= this.state.payloadData["credit_limit"];

                let resp = await groupService.updateGroupMetaInfo(id, payload);
                console.log(resp);
                let sweetAlertDataVal = this.state.sweetAlertData;
                if (resp.data.status === 1 && resp.data.result) {
                    // 
                    sweetAlertDataVal["type"] = "success",
                        sweetAlertDataVal["title"] = "Success",
                        sweetAlertDataVal["text"] = "Group updated successfully";
                } else {
                    sweetAlertDataVal["type"] = "error",
                        sweetAlertDataVal["title"] = "Error",
                        sweetAlertDataVal["text"] = resp && resp.data && resp.data.message ? resp.data.message : "Oops an error occured while updating the group";
                }
                this.setState({ sweetAlertData: sweetAlertDataVal, showSweetAlert: true, showLoader: false })
            }
        } catch (err) {
            console.log(err);
        }
    }

    handelSweetAlertClosed() {
        this.setState({ showSweetAlert: false }, () => {
            if (this.state.sweetAlertData["type"] === "success") {
                this.props.onGroupEdited();
            }
        });
    }

    render() {
        const { classes } = this.props;
        const { showLoader, payloadData, errorFields, showSweetAlert, sweetAlertData } = this.state;

        return (<div>
            <Dialog style={{ zIndex: '1' , display : showSweetAlert ? "none":"unset" }}
                open={this.state.open}
                classes={{ paper: classes.dialogPaper }}
                disableBackdropClick={true}
                onClose={this.handleDialogCancel.bind(this)}
                aria-labelledby="form-dialog-title"                >
                {!showLoader ? <div>
                    <DialogTitle
                        style={{ background: '#05073a', textAlign: 'center', height: '50px' }}
                        id="form-dialog-title">
                        <p style={{ color: '#fff', marginTop: '-10px', fontFamily: 'Lato', fontSize: '18px' }}>
                            Edit Group</p>
                    </DialogTitle>
                    <DialogContent >
                        <div style={{ display: "flex" }} >
                            <TextField
                                margin="dense"
                                id="group_name"
                                label="Group name"
                                type="text"
                                error={errorFields["group_name"] ? true : false}
                                style={{ width: '49%' }}
                                value={payloadData.group_name}
                                onChange={this.handleInputChange.bind(this)}
                                fullWidth />
                            &nbsp;
                            &nbsp;
                            <TextField
                                margin="dense"
                                id="credit_limit"
                                label="Credit Limit"
                                type="text"
                                error={errorFields["credit_limit"] ? true : false}
                                style={{ width: '49%' }}
                                value={payloadData.credit_limit}
                                onChange={this.handleInputChange.bind(this)}
                                fullWidth />
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button className={classes.formCancelBtn} onClick={this.handelCreateGroup.bind(this)} color="primary">Update</Button>
                        <Button className={classes.formCancelBtn} onClick={this.handleDialogCancel.bind(this)} color="primary">Cancel</Button>
                    </DialogActions>
                </div> :
                    <Loader primaryText="Please wait.." />}
            </Dialog>

            {showSweetAlert &&
                <SweetAlertPage
                    show={true}
                    type={sweetAlertData.type}
                    title={sweetAlertData.title}
                    text={sweetAlertData.text}
                    sweetAlertClose={() => this.handelSweetAlertClosed()}
                />}
        </div>
        );
    }
}

CreateGroupModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateGroupModal);