/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Label from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AsyncSelect from 'react-select/lib/Async';
import buyerService from '../../../app/buyerService/buyerService';
import supplierService from '../../../app/supplierService/supplierService';
import brokerService from '../../../app/brokerService/brokerService';
// import { Storage } from 'aws-amplify';
import commodityService from '../../../app/commodityService/commodityService';

import Loader from '../../common/Loader';
import Utils from '../../../app/common/utils';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
// import { Auth } from 'aws-amplify';
import Auth from '@aws-amplify/auth';
import SweetAlertPage from '../../../app/common/SweetAlertPage';
import ConfirmDialog from "../../../app/common/ConfirmDialog";
//
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import dsFeedService from '../../../app/dsFeedService/dsFeedService';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CONSTANTS from '../../../utils/constants';
import CommonUtils from '../../../utils/helper';
import NoDataAvailable from "../../common/NoDataAvailable";



const styles = theme => ({
  heading: {
    fontSize: '21px',
    fontWeight: '500',
    marginTop: '0',
    marginBottom: '0',
    fontFamily: 'Montserrat, sans-serif',
  },
  dialogPaper: {
    minWidth: '700px',
    // maxWidth: '700px',
    minHeight: '400px',
    // maxHeight: '500px'
  },
  formAddBtn: {
    width: '90%',
    borderRadius: '10px',
    fontSize: '20px',
    textTransform: 'uppercase',
    backgroundColor: '#4d9fa0 ',
    color: '#fff',
    height: '45px',
    marginBottom: '15px',
    marginTop: "11px",
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  formRoot: {
    // display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    // marginLeft: '25%',
    border: '1px solid #ccc',
    boxShadow: '2px 2px 6px 0px  rgba(0,0,0,0.3)',
    borderRadius: '4px',
    marginBottom: '20px',
    marginTop: '8%',
    padding: '25px',
    textAlign: 'center'
  },
  input: {
    display: 'none',
  }
});
// const statusOption = ["pending", "settled", "partial_settled"];

const CustomOption = props => {
  const { data, innerRef, innerProps } = props;
  return data && data.is_primary ? (
    <div ref={innerRef} {...innerProps} className='custom_option'>
      <i className="fa fa-check" style={{ color: "#60c1d8" }} aria-hidden="true"></i> &nbsp; {data.label ? data.label : ""}
    </div>
  ) : (
      <div ref={innerRef} {...innerProps} className='custom_option' >
        {data.label ? data.label : ""}
      </div>

    );
};

class CSPostModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      csPostData: {
        buyerid: null,
        buyer_mobile: null,
        max_price: null,
        min_price: null,
        rate_range_id: null,
        variety: null,
        commodity_id: null,
        commodity_name: null,
        contact_name: null,
        contact_phonenumber: null,
        contact_organisation: null,
        commodity_quantity_value: null,
        commodity_quantity_id: null,
        is_expired: false,
        district_id: null,
        images: null
      },

      buyerid: null,
      tempVar: {},
      errorFields: {},
      attachmentArray: [],
      commodityList: { options: [], optionN_E: {}, optionE_N: {} },
      showLoader: false,
      subId: "",
      showConfirmDialog: false,
      showSweetAlert: false,
      sweetAlertData: {
        "type": "",
        "title": "",
        "text": ""
      },
      showFormError: false,
      errorMessages: {},
      masterData: {},
      english: true,
      hindi: false,
      fixedRate: false,
      dialogTitle: " Confirmation!",
      dialogText: "",
      imageIndex: null
    }
  }


  componentDidMount() {
    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => this.setState({ subId: user.attributes.sub }))
      .catch(err => console.error(err));

    if (this.props.userdata && this.props.userdata.role === "ca") {
      this.state.csPostData.buyerid = this.props.userdata.id;
      this.state.csPostData.buyer_mobile = this.props.userdata.mobile;
    }

    this.getMasterData();
    this.setInitialData();
    this.getCommodityNames();
    this.handelAutoCompleteChange = this.handelAutoCompleteChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps() {
    if (this.props !== this.state) {
      this.setState({ open: this.props.open });
    }

    this.setInitialData();
  }

  setInitialData = () => {
    if (this.props.csPostData !== null) {
      const csPostData = { ...this.props.csPostData };
      // csPostData.other_details = this.getEmptyOtherDetails();

      // copy buyer details aside
      const buyerid = { ...csPostData.buyerid };
      csPostData.buyerid = buyerid.id;

      this.setState({ csPostData, buyerid });
    }
  }

  async getCommodityNames() {
    try {
      let resp = await commodityService.getCommodityTable();
      // console.error(resp)
      if (resp.data.status === 1 && resp.data.result) {
        this.setState({ commodityList: Utils.getCommodityNamesArrayKeysMap(resp.data.result.data) });
      } else {
        this.setState({ commodityList: { options: [], optionN_E: {}, optionE_N: {} } });
      }
    } catch (err) {
      // console.error(err)
      this.setState({ commodityList: { options: [], optionN_E: {}, optionE_N: {} } });
    }
  }

  async getMasterData() {
    try {
      const params = { lang: CONSTANTS.COMMON_CONSTANTS.EN };
      let resp = await dsFeedService.getMasterData(params);
      // console.log(resp);
      if (resp.data.data) {
        this.setState({ masterData: resp.data.data });
      }
    } catch (err) {
      // console.error(err);
    }
  }

  getCommodityNamesArray(data) {
    try {
      var listData = [];
      if (data) {
        for (var i = 0; i < data.length; i++) {
          if (data[i]["name"]) {
            listData.push(data[i]["name"])
          }
        }
      }
      // console.log('listdata', listData);
      return listData;
    } catch (err) {
      // console.log(err);
      return [];
    }
  }

  getQuantityUnitsArray() {
    let arr = [];
    const { masterData } = this.state;
    if (masterData.quantity_units) {
      arr = [...masterData.quantity_units]
    }
    return arr;
  }

  handleInputChange(event) {
    var floatIds = ["min_price", "max_price", "commodity_quantity_value"]; // this values need to be float
    var { errorFields, errorMessages } = this.state;
    var id = event.target.id;
    if (!id && id === undefined) {
      id = event.target.name;
    }
    var val = event.target.value;
    var csPostDataVal = { ...this.state.csPostData };
    if (floatIds.indexOf(id) > -1) {
      // if (val === "" || !isNaN(val)) {
      if (val === "" || val.match(/^(\d+\.?\d{0,9}|\.\d{1,9})$/)) {
        csPostDataVal[id] = val;
      }
    } else {
      csPostDataVal[id] = val;
    }

    if (errorFields.hasOwnProperty(id)) {
      delete errorFields[id];
    }

    errorMessages = {};

    this.setState({
      csPostData: csPostDataVal,
      errorFields,
      showFormError: false,
      errorMessages
    })
  }

  handelAutoCompleteChange = (event, values) => {

    var errors = this.state.errorFields;
    var id = "commodity";
    var csPostDataVal = this.state.csPostData;

    let commoditylist = [];
    // let data = this.state.dataObj;
    if (values && values.length > 0) {
      for (var i = 0; i < values.length; i++) {
        commoditylist.push(this.state.commodityList["optionE_N"][values[i]]);
      }
    }

    if (values && values !== null) {
      csPostDataVal[id] = commoditylist.toString();
    } else {
      csPostDataVal[id] = "";
    }

    if (errors.hasOwnProperty(id)) {
      delete errors[id];
    }
    this.setState({
      csPostData: csPostDataVal,
      errorFields: errors
    })
  }

  handleDialogCancel(event) {
    this.props.onAddModalCancel();
  }

  getOptions = async (type, inputValue, callback) => {
    try {
      if (!inputValue) {
        callback([]);
      }
      let resp = {};
      let data = {};
      data["searchVal"] = inputValue;
      if (type === "buyerid") {

        data['role'] = 'ca';
        resp = await buyerService.serchUser(data);
        // Check is_ib true
        if (resp.data.result.data) {
          const newData = resp.data.result.data.filter(d => d.is_ib);
          resp.data.result.data = newData;
        }
      }

      if (type === "supplierid") {
        data['role'] = 'la';
        resp = await supplierService.serchUser(data);
      }

      if (type === "brokerid") {
        data['role'] = 'broker';
        resp = await brokerService.serchUser(data);
      }

      if (resp.data.status === 1 && resp.data.result) {
        var respData = [];
        if (type === "brokerid") {
          respData = this.formatDataForDropDown(resp.data.result.data, "fullname", "id");
          this.setTempArray(resp.data.result.data, "id");
        } else {
          respData = this.formatDataForDropDown(resp.data.result.data, "fullname", "mobile");
          this.setTempArray(resp.data.result.data, "mobile");
        }

        callback(respData);
      } else {
        callback([]);
      }
    } catch (err) {
      // console.error(err);
      callback([]);
    }
  }

  setTempArray(data, type) {
    var tempVarVal = this.state.tempVar;
    for (var i = 0; i < data.length; i++) {
      tempVarVal[data[i][type]] = data[i];
    }
    this.setState({ tempVar: tempVarVal });
  }

  formatDataForDropDown(data, labelKey, valuekey) {

    var optionsData = [];
    if (data) {
      for (var i = 0; i < data.length; i++) {
        // optionsData.push({ label: data[i][labelKey] + " (" + data[i][valuekey] + " )", value: data[i][valuekey] });
        optionsData.push({
          is_primary: Utils.checkIfPrimaryKey(data[i]),
          label: data[i]["fullname"] + ",  " + data[i]["business_name"] + " \n  (" + data[i]["locality"] + " , " + data[i][valuekey] + " )",
          value: data[i][valuekey]
        });
      }
    }
    return optionsData;
  }

  formateDateForApi(data) {
    if (data && data !== "") {
      var dateVal = new Date(data);
      dateVal = dateVal.getFullYear() + "-" + ((dateVal.getMonth() + 1) < 10 ? "0" + (dateVal.getMonth() + 1) : dateVal.getMonth() + 1) + "-" + (dateVal.getDate() < 10 ? "0" + dateVal.getDate() : dateVal.getDate());
      return dateVal;
    } else {
      return "";
    }
  }

  getCommodityMappedName(data) {
    if (data) {
      return this.state.commodityList["optionE_N"][data]
    }
    return data;
  }

  preparePayload = () => {
    const { csPostData } = this.state;
    const payload = {
      commodity_id: null,
      commodity_name: csPostData.commodity_name,
      images: csPostData.images
    };
    return payload;
  }

  async submitDsData(event) {
    try {
      const { csPostData } = this.state;
      const { repost, edit, expire, type } = this.props;
      if (this.checkForInvalidFields(csPostData)) {
        this.setState({ showLoader: true });

        // let resp = { data: { status: 1, message: "custom Mas" ,result:[]} }
        var resp = await dsFeedService.updatePost(this.preparePayload(), csPostData.u_id, type);

        let sweetAlrtData = { ...this.state.sweetAlertData };
        this.setState({ showLoader: false });
        if (resp.data.data && (resp.data.data.post_id || resp.data.data.id)) {
          // alert("Successfully added this order ");
          sweetAlrtData["type"] = "success";
          sweetAlrtData["title"] = "Success";
          sweetAlrtData["text"] = `Post data saved successfully`;
        } else {
          // alert("There was an error while adding this order");
          // alert(resp && resp.data && resp.data.message ? resp.data.message : "There was an error while adding this order");
          sweetAlrtData = CommonUtils.getErrorDataForSweetAlert(resp);
        }
        this.setState({
          showSweetAlert: true,
          sweetAlertData: sweetAlrtData
        });
      } else {
        // alert("please fill the mandatory fields highlighted");
        this.setState({ showFormError: true });
      }
    } catch (err) {
      let sweetAlrtData = CommonUtils.getErrorDataForSweetAlert(resp);

      this.setState({
        showLoader: false,
        showSweetAlert: true,
        sweetAlertData: sweetAlrtData
      });
    }
  }

  checkForInvalidFields(data) {
    let { fixedRate, errorMessages, csPostData } = this.state;
    var isValid = true;
    var error = {};

    if (CommonUtils.isEmpty(csPostData.commodity_name)) {
      error.commodity_name = true;
      isValid = false;
    }

    this.setState({ errorFields: error, errorMessages });
    return isValid;
  }

  handelSweetAlertClosed() {
    this.setState({ showSweetAlert: false }, () => {
      if (this.state.sweetAlertData.type !== 'warning') {
        this.props.onPostUpdated();
      }
    })
  }

  handelCommoditySelect = (event, value) => {
    let csPostDataVal = { ...this.state.csPostData };
    csPostDataVal.commodity_name = value;
    this.setState({ csPostData: csPostDataVal });
  }

  getLabelForMinField = () => {
    let label = 'Min Rate(₹)';
    const { fixedRate } = this.state;
    if (fixedRate) {
      label = 'Fixed Rate(₹)'
    }
    return label;
  }

  getLabelForButton = () => {
    let label = 'Add';
    const { edit, repost, expire } = this.props;
    if (edit) {
      label = 'Save changes';
    } else if (repost) {
      label = 'Repost';
    } else if (expire) {
      label = 'Force Expire';
    }
    return label;
  }

  renderFormErrors = () => {
    const { errorMessages, errorFields } = this.state;
    return (
      <div className="form-errors">
        <p>Please fill the fields highlighted above</p>
        {
          Object.keys(errorMessages).map(key => <p key={`error-${key}`}>{errorMessages[key]}</p>)
        }
      </div>
    )

  }

  handelCancelUpdate = () => {
    this.setState({ showConfirmDialog: false, imageIndex: null });
  };

  removeImageHandler = (item, index) => {
    this.setState({
      imageIndex: index,
      showConfirmDialog: true,
      dialogText: `Do you want to remove this image?`
    });

  }

  handelConfirmUpdate = () => {
    const { csPostData, imageIndex } = this.state;
    let imageArray = csPostData.images.filter((url, index) => index !== imageIndex);
    csPostData.images = imageArray;
    this.setState({ csPostData: csPostData, showConfirmDialog: false })
  }

  checkIfUrlsIsValid(url) {
    return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
  }

  render() {
    const { classes, userdata, edit, expire, type } = this.props;
    const { showLoader, csPostData, buyerid,
      commodityList, tempVar, errorFields,
      showSweetAlert, sweetAlertData, showFormError,
      fixedRate } = this.state;
    const imageList = csPostData.images;
    return (
      <div>
        <Dialog style={{ zIndex: '1' }}
          open={this.state.open}
          classes={{ paper: classes.dialogPaper }}
          // disableBackdropClick={true}
          onClose={this.handleDialogCancel.bind(this)}
          aria-labelledby="form-dialog-title">
          {!showLoader ? <div>
            <DialogTitle
              style={{ background: '#05073a', textAlign: 'center', height: '50px' }}
              id="form-dialog-title">
              <p style={{ color: '#fff', marginTop: '-10px', fontFamily: 'Lato', fontSize: '18px' }}>
                Post Details</p>
            </DialogTitle>
            <DialogContent>
              <div style={{ display: "flex" }}>

                {userdata && userdata.role === "ca" ?
                  <TextField
                    required
                    margin="dense"
                    id="buyerid"
                    label="Buyer"
                    disabled={true}
                    required
                    // error={errorFields["amount"] ? true : false}
                    type="text"
                    style={{ width: '100%' }}
                    value={this.props.userdata.fullname}
                    // onChange={this.handleInputChange.bind(this)}
                    fullWidth />
                  : <div style={{ borderBottom: errorFields["buyerid"] ? "2px solid red" : "1px solid gray", width: "100%" }}>
                    <AsyncSelect
                      cacheOptions
                      isDisabled={expire || edit}
                      value={this.props.buyerid || buyerid}
                      id={"reactSelectCustom"}
                      name={"buyerid"}
                      // onChange={( item )=>{ this.setState({ buyerid : item  })}}
                      onChange={(item) => {
                        // console.log(tempVar);
                        this.setState({ buyerid: item }, function () {
                          var data = csPostData;
                          if (errorFields["buyerid"]) {
                            delete errorFields["buyerid"];
                          }
                          if (item && item !== null) {
                            data["buyerid"] = tempVar[item["value"]]["app_id"];
                            data["buyer_mobile"] = tempVar[item["value"]]["mobile"];
                            data["target_location"] = tempVar[item["value"]]["locality"];
                            data["contact_organisation"] = tempVar[item["value"]]["business_name"];
                            data["district_id"] = tempVar[item["value"]]["district_id"];
                          } else {
                            data["buyerid"] = "";
                            data["buyer_mobile"] = "";
                          }
                          this.setState({ csPostData: data, errorFields: errorFields })
                        })
                      }}
                      isSearchable={true}
                      isClearable={true}
                      placeholder={`Select buyer..`}
                      defaultOptions={[]}
                      components={{ Option: CustomOption }}
                      loadOptions={this.getOptions.bind(this, "buyerid")}
                    />
                  </div>}
              </div>
              <div style={{ display: "flex", marginTop: 5 }}>
                <TextField
                  margin="dense"
                  id="contact_name"
                  label="Contact Person"
                  type="text"
                  disabled={edit}
                  style={{ width: '49%' }}
                  value={csPostData.contact_name}
                  onChange={this.handleInputChange.bind(this)}
                  fullWidth />

                                &nbsp;
                                &nbsp;
                <TextField
                  margin="dense"
                  id="contact_phonenumber"
                  label="Contact Phone Number"
                  type="number"
                  disabled={edit}
                  // error={this.state.errorFields["contact_phonenumber"]}
                  onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10) }}
                  // disabled={this.state.isUpdate}
                  style={{ width: '49%' }}
                  value={csPostData.contact_phonenumber}
                  onChange={this.handleInputChange.bind(this)}
                  fullWidth
                />

              </div>
              <div style={{ display: "flex", marginTop: 5 }}>
                <Autocomplete
                  id="commodity_name"
                  name="commodity_name"
                  disabled={csPostData.action_done}
                  error={errorFields["commodity_name"] ? true : false}
                  options={commodityList["options"].sort()}
                  value={csPostData.commodity_name}
                  getOptionLabel={e => e}
                  onChange={this.handelCommoditySelect}
                  required
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option} {...getTagProps({ index })} />
                    ))
                  }
                  style={{ width: "49%", marginTop: '5px' }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      error={errorFields["commodity_name"] ? true : false}
                      label="Select Commodity"
                      placeholder="Commodity"
                      fullWidth
                      required
                    />
                  )}
                />
                            &nbsp;
                            &nbsp;
              <TextField
                  margin="dense"
                  id="variety"
                  label="Add Variety"
                  error={errorFields["variety"] ? true : false}
                  type="text"
                  disabled={edit}
                  style={{ width: '49%' }}
                  value={csPostData.variety}
                  onChange={this.handleInputChange.bind(this)}
                  fullWidth />

              </div>
              <div style={{ display: "flex", marginTop: 5 }}>
                <TextField
                  margin="dense"
                  id="min_price"
                  required
                  label={this.getLabelForMinField()}
                  type="number"
                  disabled={edit}
                  error={errorFields["min_price"] ? true : false}
                  style={{ width: '22%' }}
                  value={csPostData.min_price}
                  onChange={this.handleInputChange.bind(this)}
                  fullWidth />
              &nbsp;
              &nbsp;
              {!this.state.fixedRate &&
                  <TextField
                    margin="dense"
                    id="max_price"
                    label="Max Rate(₹)"
                    type="number"
                    disabled={edit}
                    error={errorFields["max_price"] ? true : false}
                    style={{ width: '20%' }}
                    value={csPostData.max_price}
                    required
                    onChange={this.handleInputChange.bind(this)}
                    fullWidth />
                }
              &nbsp;
              &nbsp;
              <TextField
                  select
                  id="rate_range_id"
                  name="rate_range_id"
                  label="Rate Range Unit"
                  required
                  disabled={edit}
                  error={errorFields["rate_range_id"] ? true : false}
                  type="text"
                  style={{ width: '33%', marginTop: '5px' }}
                  value={csPostData.rate_range_id}
                  onChange={this.handleInputChange.bind(this)}>
                  {this.getQuantityUnitsArray().map((unit, i) => (
                    <MenuItem key={unit.id} value={unit.id} selected={true}>
                      {unit.value}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div style={{ display: "flex", marginTop: 5 }} >
                <TextField
                  required
                  margin="dense"
                  id="commodity_quantity_value"
                  label="Quantity Required (per day)"
                  type="number"
                  disabled={edit}
                  error={errorFields["commodity_quantity_value"] ? true : false}
                  style={{ width: '49%' }}
                  value={csPostData.commodity_quantity_value}
                  onChange={this.handleInputChange.bind(this)}
                  fullWidth />

                            &nbsp;
                              &nbsp;
            <TextField
                  select
                  required
                  id="commodity_quantity_id"
                  error={errorFields["commodity_quantity_id"] ? true : false}
                  name="commodity_quantity_id"
                  label="Quantity Required Unit"
                  type="text"
                  disabled={edit}
                  style={{ width: '49%', marginTop: '5px' }}
                  value={csPostData.commodity_quantity_id}
                  onChange={this.handleInputChange.bind(this)}>
                  {this.getQuantityUnitsArray().map((unit, i) => (
                    <MenuItem key={unit.id} value={unit.id} selected={true}>
                      {unit.value}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              {/* Images */}
              {type === CONSTANTS.DSFEED_CONSTANTS.SUPPLY && imageList && imageList.length ?
                <div className="images-box">
                  <h4>Images:</h4>
                  <div className="image-thumbnails">
                    {imageList.map((item, index) => {
                      return (
                        <div key={index} className="image-item">
                          {this.checkIfUrlsIsValid(item) ?
                            <img src={item} alt="CS Image" />
                            :
                            <img src={CONSTANTS.COMMON_CONSTANTS.IMAGE_NOT_FOUND_URL} alt="no_data_found" />
                          }
                          {!csPostData.action_done &&
                            <DeleteForeverIcon onClick={() => this.removeImageHandler(item, index)} />
                          }
                        </div>
                      )
                    }
                    )}
                  </div>
                </div>
                :
                null
              }

              {showFormError &&
                this.renderFormErrors()
              }
            </DialogContent>
            <DialogActions>
              {!csPostData.action_done &&
                <Button disabled={csPostData.action_done} className={classes.formCancelBtn} onClick={this.submitDsData.bind(this)} color="primary" style={{ color: expire ? "#cc5500" : "#3f51b5" }}>{this.getLabelForButton()}</Button>
              }
              <Button className={classes.formCancelBtn} onClick={this.handleDialogCancel.bind(this)} color="primary">Cancel</Button>
            </DialogActions>
          </div> :
            <Loader primaryText="Please wait.." />}
        </Dialog>

        {showSweetAlert &&
          <SweetAlertPage
            show={true}
            type={sweetAlertData.type}
            title={sweetAlertData.title}
            text={sweetAlertData.text}
            sweetAlertClose={() => this.handelSweetAlertClosed()}
          />}

        {this.state.showConfirmDialog ? (
          <ConfirmDialog
            dialogText={this.state.dialogText}
            dialogTitle={this.state.dialogTitle}
            show={this.state.showConfirmDialog}
            onConfirmed={this.handelConfirmUpdate}
            onCanceled={this.handelCancelUpdate}
          />
        ) : (
            ""
          )}
      </div>
    );
  }
}

CSPostModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CSPostModal);