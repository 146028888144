/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import relationshipManagerService from '../../../app/relationshipManagerService/relationshipManagerService';
import SweetAlertPage from '../../../app/common/SweetAlertPage';
import Loader from '../../common/Loader';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Utils from '../../../app/common/utils';

const theme = createMuiTheme({
    overrides: {

        MuiInputBase: {
            input: {
                color: "#000"
            }
        }
    }
});
const styles = theme => ({
    heading: {
        fontSize: '21px',
        fontWeight: '500',
        marginTop: '0',
        marginBottom: '0',
        fontFamily: 'Montserrat, sans-serif',
    },
    dialogPaper: {
        minWidth: '700px',
        // maxWidth: '700px',
        minHeight: '300px',
        // maxHeight: '500px'
    },
    profile: {
        marginLeft: '30%',
        background: 'red',
        width: '40px',
        borderRadius: '10px'
    }

});

class AddRelationShipManagerModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.openModal,
            showLoader: false,
            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },
            showErrorMsg: false,
            requiredKey: ['emp_id', 'name', 'email', 'mobile'],
            addPayload: {
                "emp_id": "",
                "name": "",
                "email": "",
                "mobile": "",
                "role": "",
                "zone": ""
            },
            errorFields: {},
            errorMsg: "Please check all required field"
        }
    }

    handleChange = event => {
        let data = this.state.addPayload;
        let errorF = this.state.errorFields;
        let id = event.target.id;
        let val = event.target.value;
        let lengthValidation={
            "emp_id": 30,
            "name": 70,
            "email": 50,
            "mobile": 10,
            "role": 30,
            "zone": 30
        }
        if (id === "mobile") {
            if (val.length <= 10) {
                if (val === "" || !isNaN(val)) {
                    data[id] = val;
                }
            }
        } else  {
            if (val.length <= lengthValidation[id]) {
                data[id] = val;
            }
        }
        errorF[id] = false;
        this.setState({ addPayload: data, showErrorMsg: false, errorFields: errorF });
    }

    onAddClicked = async () => {
        let obj = this.state.addPayload;
        if (this.checkIfFieldsValid(obj)) {
            if (!obj.role || obj.role === "" || obj.role.trim() === "") {
                delete obj.role;
            }
            if (!obj.zone || obj.zone === "" || obj.zone.trim() === "") {
                delete obj.zone;
            }
            this.setState({ showLoader: true });
            let resp = await relationshipManagerService.createRMUser(obj);
            this.setState({ showLoader: false });
            let sweetAlrtData = this.state.sweetAlertData;
            if (resp.data.status === 1) {
                sweetAlrtData["type"] = "success";
                sweetAlrtData["title"] = "Success";
                sweetAlrtData["text"] = "Relationship manager added successfully";
            } else {
                sweetAlrtData["type"] = "error";
                sweetAlrtData["title"] = "Error";
                sweetAlrtData["text"] = resp && resp.data && resp.data.message ? resp.data.message : "Oops there was an error, while adding";
            }
            this.setState({
                showSweetAlert: true,
                sweetAlertData: sweetAlrtData
            });
        }
    }

    handleDialogCancel(event) {
        this.props.onAddModalCancel();
    }



    checkIfFieldsValid(data) {
        let isValid = true;
        let reqArr = this.state.requiredKey;
        let errF = {};
        let errorMsgval = "Please check all required field";
        for (let i = 0; i < reqArr.length; i++) {
            if (!Utils.validateEmail(data.email)) {
                errF["email"] = true;
                isValid = false;
                errorMsgval = "Please enter a valid email";
            }

            if (data.mobile && data.mobile.length < 10) {
                errF["mobile"] = true;
                isValid = false;
                errorMsgval = "Please enter a valid mobile number";
            }

            if (!data[reqArr[i]] && data[reqArr[i]] === "") {
                errF[reqArr[i]] = true;
                isValid = false;
                errorMsgval = "Please check all required field";
            }
        }

        if (!isValid) {
            this.setState({ showErrorMsg: true, errorFields: errF, errorMsg: errorMsgval });
        }
        return isValid;
    }


    handelSweetAlertClosed() {
        this.setState({ showSweetAlert: false }, () => {
            if (this.state.sweetAlertData["type"] !== "error") {
                this.props.onRmAddedSuccess();
            }
        })
    }

    render() {
        const { classes } = this.props;
        const { showLoader, showSweetAlert, sweetAlertData } = this.state;
        return (
            <Dialog style={{ zIndex: '1' }}
                open={this.state.open}
                classes={{ paper: classes.dialogPaper }}
                // disableBackdropClick={true}
                onClose={this.handleDialogCancel.bind(this)}
                aria-labelledby="form-dialog-title"                >
                {!showLoader ? <div>
                    <DialogTitle
                        style={{ background: '#05073a', textAlign: 'center', height: '50px' }}
                        id="form-dialog-title">
                        <p style={{ color: '#fff', marginTop: '-10px', fontFamily: 'Lato', fontSize: '18px' }}>
                            Add relationship manager</p>
                    </DialogTitle>
                    <DialogContent>
                        <MuiThemeProvider theme={theme}><div style={{ width: '100%', padding: '8px 24px' }}>
                            {!showLoader ? <div>
                                <div style={{ display: 'flex' }}>
                                    <TextField
                                        margin="dense"
                                        id="emp_id"
                                        label="Emp id"
                                        size="small"
                                        autoComplete="off"
                                        type="text"
                                        error={this.state.errorFields.emp_id}
                                        style={{ marginRight: '2%', width: '48%', fontSize: "14px" }}
                                        value={this.state.addPayload.emp_id}
                                        onChange={this.handleChange.bind(this)}
                                        required
                                        fullWidth
                                    />

                                    <TextField
                                        margin="dense"
                                        id="name"
                                        label="Name"
                                        size="small"
                                        type="text"
                                        autoComplete="off"
                                        error={this.state.errorFields.name}
                                        style={{ marginRight: '2%', width: '48%', fontSize: "14px" }}
                                        value={this.state.addPayload.name}
                                        onChange={this.handleChange.bind(this)}
                                        required
                                        fullWidth
                                    />
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <TextField
                                        margin="dense"
                                        id="mobile"
                                        label="Mobile no."
                                        type="text"
                                        autoComplete="off"
                                        error={this.state.errorFields.mobile}
                                        style={{ marginRight: '2%', width: "48%" }}
                                        value={this.state.addPayload.mobile}
                                        onChange={this.handleChange.bind(this)}
                                        required
                                        fullWidth
                                    />

                                    <TextField
                                        margin="dense"
                                        id="email"
                                        label="Email id"
                                        type="text"
                                        autoComplete="off"
                                        error={this.state.errorFields.email}
                                        style={{ marginRight: '2%', width: '48%' }}
                                        value={this.state.addPayload.email}
                                        onChange={this.handleChange.bind(this)}
                                        required
                                        fullWidth
                                    />
                                </div>


                                <div style={{ display: 'flex' }}>
                                    <TextField
                                        margin="dense"
                                        id="role"
                                        label="Role"
                                        type="text"
                                        autoComplete="off"
                                        error={this.state.errorFields.role}
                                        style={{ marginRight: '2%', width: "48%" }}
                                        value={this.state.addPayload.role}
                                        onChange={this.handleChange.bind(this)}
                                        fullWidth
                                    />

                                    <TextField
                                        margin="dense"
                                        id="zone"
                                        label="Zone"
                                        error={this.state.errorFields.zone}
                                        type="text"
                                        autoComplete="off"
                                        style={{ marginRight: '2%', width: '48%' }}
                                        value={this.state.addPayload.zone}
                                        onChange={this.handleChange.bind(this)}
                                        fullWidth
                                    />
                                </div>

                                {this.state.showErrorMsg &&
                                    <div style={{
                                        fontFamily: 'Montserrat, sans-serif',
                                        fontSize: "12px",
                                        color: "red",
                                        textAlign: "right",
                                        paddingRight: "10px"
                                    }}
                                    >{this.state.errorMsg}</div>}
                            </div> :
                                <Loader primaryText="Please wait.." />}

                            {showSweetAlert &&
                                <SweetAlertPage
                                    show={true}
                                    type={sweetAlertData.type}
                                    title={sweetAlertData.title}
                                    text={sweetAlertData.text}
                                    sweetAlertClose={() => this.handelSweetAlertClosed()}
                                />}
                        </div>
                        </MuiThemeProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button className={classes.formCancelBtn} onClick={() => this.onAddClicked(this)} color="primary">Create</Button>
                        <Button className={classes.formCancelBtn} onClick={this.handleDialogCancel.bind(this)} color="primary">Cancel</Button>
                    </DialogActions></div> :
                    <Loader primaryText="Please wait.." />}
            </Dialog>
        );
    }
}

AddRelationShipManagerModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddRelationShipManagerModal); 