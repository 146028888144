import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Tooltip from '@material-ui/core/Tooltip';
import groupService from '../../../app/groupService/groupService';
import SweetAlertPage from '../../../app/common/SweetAlertPage';
import ConfirmDialogWithLoader from '../common/ConfirmDialogWithLoader';
import Loader from '../../common/Loader';
import BusinessInfoDialog from '../../common/BusinessInfoDialog';

const theme = createMuiTheme({
    overrides: {
        MuiTableCell: {
            head: {
                color: '#2e3247',
                fontWeight: 600,
                fontSize: '13px !important',
                fontFamily: 'lato !important',
                textTransform: 'uppercase',
                lineHeight: "1em"

            },
            body: {
                color: 'rgba(0, 0, 0, 0.87)',
                fontWeight: 500,
                fontSize: '13px !important',
                fontFamily: 'lato !important',
            },
            root: {
                padding: '10px'
            }
        },
        MuiTablePagination: {
            toolbar: {
                paddingRight: '250px'
            }
        },
    }
});

const styles = theme => ({

    tableCell: {
        paddingLeft: '4px',
        paddingRight: '1px',
        // textAlign: 'center',
        maxWidth: '200px',
        fontSize: '13px !important'
    },
    root: {
        width: '100%',
        minHeight: '80vh',
        padding: "1%"
    },
    lightTooltip: {
        fontSize: '15px',
        maxWidth: 'none',
    },
    textEllpses: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: "110px",
        lineHeight: "18px",
        display: "block"
    },
    textEllipseForAppid: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        direction: 'rtl',
        textAlign: 'left',
        maxWidth: '75px',
        textOverflow: 'ellipsis'
    }
});


class GroupSubUserTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableHeadData: ["id", "fullname (business name)", "mobile", "role", "Credit Limit", "is Connected", "Group id/ name ", "Action"],
            tableHeadDataKeys: ["id", "fullname", "mobile", "role", "user_bijak_credit_limit", "is_connected", "groupName", ""],
            tableBodyData: this.props.tableData,
            totalDataCount: this.props.totalDataCount || 0,
            currentOffset: this.props.currentOffset || 0,
            searchedText: "",
            editableData: {},
            showServerDialog: false,
            showOptionModal: false,
            anchorEl: null,
            showUserModal: false,
            userData: {},
            // userId: null,
            payload: null,
            showAddModal: false,
            infoData: null,
            open: false,

            rowsPerPage: 50,
            page: 0,

            showSupportingInvoiceModal: false,
            invoiceModalData: [],

            showAddOrderModal: false,

            showEditDataModal: false,
            commodityList: { options: [], optionN_E: {}, optionE_N: {} },
            showUploader: false,

            showPayoutModal: false,
            payoutData: undefined,

            showUserInfo: false,
            userInfoData: undefined,
            isLimitUpdate: false,
            userId: undefined,

            sortKeys: this.props.sortKeys,
            sortKeysOrder: this.props.sortKeysOrder,
            sortingEnabled: ["id", "unsettled_amount_pltf", "createdtime", "bijak_amt"],
            showPaymentsOfOrderModal: false,
            orderInfo: undefined,


            // new decelartion from here 
            showCreateModal: false,
            dataForAttachDettach: undefined,
            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },
            showConfirmLoader: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.tableBodyData !== nextProps.tableData) {
            this.setState({ tableBodyData: nextProps.tableData });
        }
    }

    onAttachedClicked(row) {
        this.setState({ dataForAttachDettach: row });
    }

    onDetachedClicked(row) {
        this.setState({
            dialogTitle: "Are you sure to remove this user from the group ?",
            showConfirmLoader: false,
            dialogText:
                <div style={{ display: "block" }}><div style={{ fontSize: "15px", fontFamily: 'Lato' }}>{`${row["user_fullname"]} will be removed from the group ${row['group_name']}`}</div>
                    <div style={{ fontSize: "13px", fontFamily: 'Lato', marginTop: "10px" }}>{"Note : You can latter on attach this user to any group"}</div></div>,
            showConfirmDialog: true,
            dataForAttachDettach: row
        });
    }

    handelCancelUpdate(event) {
        this.setState({ showConfirmDialog: false, showConfirmLoader: false, dataForAttachDettach: undefined });
    }

    async handelConfirmBtnClicked() {
        let sweetAlrtData = this.state.sweetAlertData;
        try {

            this.setState({ showConfirmLoader: true });
            let payload = {
                "is_connected_to_group": false
            };
            let idOfUser = this.state.dataForAttachDettach["user_id"];
            // if( this.state.dataForAttachDettach && this.state.dataForAttachDettach[""]){

            // }
            let resp = await groupService.attacheDetacheUserApi(idOfUser, payload);
            console.log(resp);
            if (resp.data.status === 1) {
                sweetAlrtData["type"] = "success";
                sweetAlrtData["title"] = "Success";
                sweetAlrtData["text"] = "User account successfully deleted";
                let tableBodyDataVal = this.state.tableBodyData;
                for (let i = 0; i < tableBodyDataVal.length; i++) {
                    if (tableBodyDataVal[i]["user_id"] === idOfUser) {
                        delete tableBodyDataVal[i];
                    }
                }
                this.setState({
                    tableBodyData: tableBodyDataVal,
                    showSweetAlert: true,
                    sweetAlertData: sweetAlrtData
                });
            } else {
                sweetAlrtData["type"] = "error";
                sweetAlrtData["title"] = "Error";
                sweetAlrtData["text"] = "Oops an error occured";
                this.setState({
                    showSweetAlert: true,
                    sweetAlertData: sweetAlrtData
                });
            }
        } catch (err) {
            console.log(err);
            sweetAlrtData["type"] = "error";
            sweetAlrtData["title"] = "Error";
            sweetAlrtData["text"] = err
            this.setState({
                showSweetAlert: true,
                sweetAlertData: sweetAlrtData
            });
        }
    }

    handelSweetAlertClosed() {
        this.setState({ showSweetAlert: false, showConfirmDialog: false }, () => {
            if (this.state.sweetAlertData.type !== "error") {
                this.props.onUserDettached();
            }
        });
    }

    getRole(row) {
        let role = "NA";
        let isGlobal = false;
        if (row.user_role === "ca") {
            role = "Buyer";
        } else if (row.user_role === 'la') {
            role = "Supplier";
        } else if (row.user_role === 'broker') {
            role = "Broker";
        } else {
            role = "NA";
        }

        if (row.hasOwnProperty("global_ca") && row.global_ca) {
            isGlobal = true;
        }
        if (row.hasOwnProperty("global_la") && row.global_la) {
            isGlobal = true;
        }

        return (<span style={{
            color: "white",
            background: this.getBackgroundColor(row),
            padding: "4px 12px",
            borderRadius: "13px"
        }}> {role} {isGlobal ? <i className="fa fa-globe" aria-hidden="true"></i> : ""}</span>)
    }

    getBackgroundColor(obj) {
        if (obj.user_role === "ca") {
            return "#f94141";
        } else if (obj.user_role === 'la') {
            return "#00a700";
        } else if (obj.user_role === 'broker') {
            return "#7070fd";
        } else {
            return "#757575";
        }
    }

    onUserInfoClicked = (info, type, event) => {
        console.log(info)
        let id = "";
        if (info.role === "ca") {
            id = info["user_mobile"];
        } else {
            id = info["user_mobile"];
        }
        this.setState({ userId: id, showUserInfo: true, userInfoData: JSON.parse(JSON.stringify(info)) });
    }

    handleUserInfoClose(event) {
        this.setState({ showUserInfo: false });
    }

    changeLimitSucces(event) {
        this.setState({ isLimitUpdate: true });
    }

    onUserInfoModalCancel(event) {
        this.setState({ showUserInfo: false });
        if (this.state.isLimitUpdate) {
            this.props.onUserDettached();
        }
    }

    render() {
        const { classes, showLoader } = this.props;
        const { tableHeadDataKeys, sortingEnabled, showSweetAlert, sweetAlertData } = this.state;
        const leftAlignedIndexs = [0, 1];
        const rightAlignedIndexs = [4];
        const minWidthIndexs = [0, 2, 3, 5, 6, 8];
        return (
            <MuiThemeProvider theme={theme}>
                {!showLoader ? <Paper className={classes.root} >
                    {this.state.tableBodyData && <div style={{ maxHeight: "65vh", overflowY: "scroll" }}>
                        <Table className='table-body' stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow style={{ borderBottom: "2px solid #858792" }} >
                                    {this.state.tableHeadData.map((option, i) => (
                                        <TableCell
                                            key={option}
                                            // onClick={() => this.addKeysToSortList(tableHeadDataKeys[i])}
                                            className={classes.tableCell}
                                            style={{
                                                paddingLeft: i === 0 ? '22px' : '',
                                                minWidth: i === 0 ? "85px" : (minWidthIndexs.indexOf(i) > -1 || i === (this.state.tableHeadData.length - 1)) ? (i !== 1 ? "100px" : "80px") : '120px',
                                                textAlign: leftAlignedIndexs.indexOf(i) > -1 ? "left" : rightAlignedIndexs.indexOf(i) > -1 ? "right" : "center",
                                                cursor: sortingEnabled.indexOf(tableHeadDataKeys[i]) > -1 ? "pointer" : "unset"
                                            }}>{option}
                                            {/* {sortingEnabled.indexOf(tableHeadDataKeys[i]) > -1 &&
                                                        <i className={Utils.getArrowIconType(sortKeys, tableHeadDataKeys[i])} aria-hidden="true"  style={{ color : sortKeys.hasOwnProperty(tableHeadDataKeys[i]) ? '#e72e89':'unset'}}></i>} */}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.tableBodyData &&
                                    this.state.tableBodyData
                                        .map((row, i) => {
                                            return (
                                                <TableRow key={'table_' + i} id={"order_" + row.id} style={{ background: i % 2 !== 0 ? "#e8e8e8" : "#fff" }}>
                                                    <TableCell component="th" scope="row" className={classes.tableCell} >
                                                        {row.primary_user_id && row.user_id && row.primary_user_id === row.user_id ?
                                                            <Tooltip title={"Primary user"} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                                <i className="fa fa-check" style={{ color: "#60c1d8" }} aria-hidden="true"></i>
                                                            </Tooltip> :   <i className="fa fa-chec" style={{ color: "#60c1d8", width:"14px" }} aria-hidden="true"></i>
                                                        }

                                                        <div style={{ display: "inline-table" }}>
                                                            <Tooltip title={row.hasOwnProperty("active") ? (row.active ? "Enabled" : "Disabled"):""} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                            <div style={{
                                                                color: "#fff",
                                                                background: row.hasOwnProperty("active") ? (row.active ? "green" : "red") :"#a59292",
                                                                padding: "4px 12px",
                                                                width: 'fit-content',
                                                                marginLeft: '2%',
                                                                borderRadius: "13px"
                                                            }}> {row.user_id}</div>
                                                            </Tooltip>
                                                        </div>

                                                    </TableCell>

                                                    <TableCell className={classes.tableCell}>
                                                    <Tooltip title={row.user_fullname ? row.user_fullname : ""} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                        <div className=" name-span" style={{ display: "grid", textAlign: "left", textTransform: "capitalize", cursor: "pointer" }}
                                                            onClick={this.onUserInfoClicked.bind(this, row, "buyer_name")}
                                                        >
                                                            <span className="text-ellpses">{row.user_fullname ? row.user_fullname : ""} </span>
                                                            <span className="text-ellpses" style={{ fontSize: "12px" }}>{row.user_business_name ? row.user_business_name : " "}</span>
                                                        </div>
                                                        </Tooltip>
                                                    </TableCell>

                                                    <TableCell component="th" scope="row" className={classes.tableCell} style={{ textAlign: "center" }}>
                                                        {row.user_mobile ? row.user_mobile : ""}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" className={classes.tableCell} style={{ textAlign: "center" }}>
                                                        {this.getRole(row)}
                                                    </TableCell>

                                                    <TableCell component="th" scope="row" className={classes.tableCell} style={{ textAlign: "right" }} >
                                                        ₹  {row.user_bijak_credit_limit ? row.user_bijak_credit_limit : "0"}
                                                    </TableCell>

                                                    <TableCell component="th" scope="row" className={classes.tableCell} style={{ textAlign: "center" }}>
                                                        <i className="fa fa-check" style={{ color: "green" }} aria-hidden="true"></i>
                                                    </TableCell>

                                                    <TableCell className={classes.tableCell} style={{ textAlign: "center" }}>

                                                        <div style={{ display: "grid", textAlign: "center" }}>
                                                            <span className="text-ellpses" >{row.id ? row.id : "-"} </span>
                                                            <span className="text-ellpses" style={{ fontSize: "12px" }}>{row.group_name ? row.group_name : " "}</span>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell} style={{ textAlign: "center" }}>
                                                        {row.primary_user_id && row.user_id && row.primary_user_id === row.user_id ?
                                                            <span style={{ marginLeft: "10px" }}>
                                                                <i className="fa fa-caret-left translabelIcon" style={{ marginRight: "-0.75px", color: "red", display: "contents" }} aria-hidden="true"></i>
                                                                <span
                                                                    className=" translabeltag  labeltag"
                                                                    style={{ cursor: "no-drop", color: "#fff", background: "red", paddingTop: "2px", paddingBottom: "4px", fontSize: "12px" }}>
                                                                    primary user
                                                        </span>
                                                            </span>

                                                            : <Tooltip interactive title={"Remove this user from this group"} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                                <div>
                                                                    {/* {row.is_connected_to_group ? */}
                                                                    <RemoveCircleOutlineIcon
                                                                        className="material-Icon"
                                                                        onClick={() => this.onDetachedClicked(row)}
                                                                        style={{ color: "#e72e89", cursor: "pointer", height: "18px", fontSize: "18px" }} />
                                                                    {/* :
                                                                    <AddCircleOutlineIcon
                                                                        className="material-Icon"
                                                                        onClick={() => this.onAttachedClicked(row)}
                                                                        style={{ color: "#e72e89", cursor: "pointer", height: "18px", fontSize: "18px" }} />
                                                                        } */}
                                                                </div>
                                                            </Tooltip>}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                            </TableBody>
                        </Table>
                    </div>}

                    {this.state.showConfirmDialog ?
                        <ConfirmDialogWithLoader
                            dialogText={this.state.dialogText}
                            dialogTitle={this.state.dialogTitle}
                            show={this.state.showConfirmDialog}
                            showSweetAlertShown={showSweetAlert}
                            showConfirmLoader={this.state.showConfirmLoader}
                            onConfirmed={() => this.handelConfirmBtnClicked()}
                            onCanceled={() => this.handelCancelUpdate()} /> : ""}

                    {showSweetAlert &&
                        <SweetAlertPage
                            show={true}
                            type={sweetAlertData.type}
                            title={sweetAlertData.title}
                            text={sweetAlertData.text}
                            sweetAlertClose={() => this.handelSweetAlertClosed()}
                        />}

                </Paper> : <Loader />}

                {this.state.showUserInfo ?
                    <BusinessInfoDialog
                        openModal={this.state.showUserInfo}
                        onEditModalClosed={this.handleUserInfoClose.bind(this)}
                        data={this.state.userInfoData}
                        isInfo={true}
                        userId={this.state.userId}
                        onLimitUpdate={this.changeLimitSucces.bind(this)}
                        onEditModalCancel={this.onUserInfoModalCancel.bind(this)} /> : ""}

            </MuiThemeProvider>
        );
    }
}

GroupSubUserTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GroupSubUserTable);