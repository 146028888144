// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
// const awsmobile = {
  
//     'aws_app_analytics': 'disable',
//     'aws_cognito_identity_pool_id': 'ap-south-1:ae662a2a-b766-4f83-973c-2148d6c7f2ad',
//     'aws_cognito_region': 'ap-south-1',
//     'aws_project_id': 'e5f45a7c-b34e-4d52-82e3-838ccdbf3f25',
//     'aws_project_name': 'bijak-team-internal',
//     'aws_project_region': 'ap-south-1',
//     'aws_resource_name_prefix': 'bijakteaminternal-mobilehub-429986086',
//     'aws_sign_in_enabled': 'enable',
//     'aws_user_pools': 'enable',
//     'aws_user_pools_id': 'ap-south-1_T8U2iy16A',
//     'aws_user_pools_web_client_id': '27203rhop0it4v4171hp1a26eh'
// }

const awsmobile = {
    'aws_cognito_identity_pool_id': 'ap-south-1:ae662a2a-b766-4f83-973c-2148d6c7f2ad',
    'aws_cognito_region': 'ap-south-1',
    'aws_project_id': 'e5f45a7c-b34e-4d52-82e3-838ccdbf3f25',
    'aws_project_name': 'bijak-team-internal',
    'aws_project_region': 'ap-south-1',
    'aws_resource_name_prefix': 'bijakteaminternal-mobilehub-429986086',
    'aws_user_files': 'enable',
    'aws_user_files_s3_bucket': 'bijakteaminternal-userfiles-mobilehub-429986086',
    'aws_user_files_s3_bucket_region': 'ap-south-1',
    'aws_sign_in_enabled': 'enable',
    'aws_user_pools_id': 'ap-south-1_T8U2iy16A',
    'aws_user_pools_web_client_id': '27203rhop0it4v4171hp1a26eh'
}


export default awsmobile;