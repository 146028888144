import React, { Component } from "react";
import Routes from "../routes";
import "../assets/css/app.css";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import { Auth } from "aws-amplify";
import Auth from '@aws-amplify/auth';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMicroscope } from '@fortawesome/free-solid-svg-icons'
// import "../assets/css/cardcss.scss";
import userAuth from "../config/authActions";
import commonService from "./commonService/commonService";

library.add(faMicroscope)

const styles = {
  root: {
    flexGrow: 1,
  },
  bar: {
    backgroundColor: '#50a3b4',
  },
  flex: {
    flexGrow: 1,
    marginLeft: 5,
    color: '#fff'
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      user: '',
      componentName: '',
      providerName:
        window.location.href
          .replace("https://", "")
          .replace("http://", "")
          .replace(window.location.hostname + "/", "")
          .split("/")[0] === 'localhost:3000' ? window.location.href
            .replace("https://", "")
            .replace("http://", "")
            .replace(window.location.hostname + "/", "")
            .split("/")[1] : window.location.href
              .replace("https://", "")
              .replace("http://", "")
              .replace(window.location.hostname + "/", "")
              .split("/")[0]
    };

  }

  userHasAuthenticated = (props) => {
    this.setState({ isAuthenticated: props.isLoggedIn, user: props.email, componentName: props.view });
  }

  async componentDidMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    userAuth.startTokenGenaerationAfter45Min();
    userAuth.setup();

  }
  UNSAFE_componentWillMount() {
    // Utils.setDbName(this.state.providerName);
    Auth.currentAuthenticatedUser()
      .then(user => {
        var authk = user.signInUserSession.idToken.jwtToken;
        var username = user.signInUserSession.idToken.payload.name;
        window.sessionStorage.setItem('bijak_token', authk);
        window.sessionStorage.setItem('username', username);
        this.getBijakAppVersion();
      })
      .catch(err => {
        console.log(err);
        window.sessionStorage.removeItem('bijak_token');
        window.sessionStorage.removeItem('username');
      });
  }

  async getBijakAppVersion() {
    try {
      let resp = await commonService.getBijakVersionOfApp();
      if (resp.data.status === 1 && resp.data.result) {
        window.sessionStorage.setItem("app_version" , resp.data.result[0].version );
        window.sessionStorage.setItem("app_version_data" , resp.data.result[0] );
      } else {
        window.sessionStorage.setItem("app_version" , "-" );
        window.sessionStorage.setItem("app_version_data" , "{}" );
      }
    } catch (err) {
      console.log(err);
      window.sessionStorage.setItem("app_version" , "-" );
      window.sessionStorage.setItem("app_version_data" , "{}" );
    }
  }

  render() {
    return (
      <div className="outerContainer">
        <Routes />
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);