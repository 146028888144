import React from 'react';
import PropTypes from 'prop-types';
// import '../../assets/css/app.css';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../common/Loader';
import Paper from '@material-ui/core/Paper';
import DemandListTable from "./components/DemandListTable";
// import brokerService from './../../app/brokerService/brokerService';
import buyerService from './../../app/buyerService/buyerService';
import Utils from '../../app/common/utils';
import dsFeedService from '../../app/dsFeedService/dsFeedService';
import CommonUtils from '../../utils/helper';
import CONSTANTS from '../../utils/constants';
// import sampleFile from '../sampleDownloadFiles/bulk-add-order-data-sample.csv';

const styles = theme => ({
  root: {
    width: '100%',
    overflow: 'auto',
    fontFamily: 'Lato !important',
    maxWidth: '1200px',
    minHeight: '80vh',
  },
  card: {
    maxWidth: '100%',
    marginTop: '15px',
    height: '97%',
  },
  heading: {
    marginTop: '15px',
    fontSize: '20px',
    alignTtems: 'center',
    display: '-webkit-inline-box'
  }
});



class DsFeedContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      searchedText: "",
      buyersList: [],
      demandListData: [],
      showLoader: false,
      datePayloads: { "startDate": "", "endDate": "" },
      totalDataCount: 0,
      params: { ...CONSTANTS.COMMON_CONSTANTS.OFFSET_LIMIT_500 },
      resetPageNumber: false,
      isTableDataLoading: false,
      filterParams: {},

      sortKeys: {},
      sortParams: {},
      sortKeysOrder: [],

      resetFilter: false,

      currentRef: this,
      notificationData: "",
      lastUpdatedKeys: {
        unsettled_amount_pltf: '',
        bijak_amt: '',
        id: ''
      }

    }
    this.ismounted = true;
    this.timer = 0;

  }

  componentDidMount() {
    this.getBuyersList();
    this.getDemandListData();
    this.getMasterData();
  }

  async getBuyersList() {
    try {
      let param = { role: 'ca', "limit": 1000, "offset": 0 };
      let resp = await buyerService.serchUser(param);
      if (this.ismounted) {
        if (resp.data.status === 1 && resp.data.result) {
          this.setState({ buyersList: this.formatDataForDropDown(resp.data.result.data, "fullname", "mobile") });
        }
      }
    } catch (err) {
      // console.error(err);
    }
  }

  formatDataForDropDown(data, labelKey, valuekey) {

    var optionsData = [];
    if (data) {
      for (var i = 0; i < data.length; i++) {
        // optionsData.push({ label: data[i][labelKey] +" ("+data[i][valuekey]+" )", value: data[i][valuekey] });
        optionsData.push({
          is_primary: Utils.checkIfPrimaryKey(data[i]),
          label: data[i]["fullname"] + ",  " + data[i]["business_name"] + " \n  (" + data[i]["locality"] + " , " + data[i][valuekey] + " )",
          value: data[i][valuekey]
        });
      }
    }
    return optionsData;
  }

  async getMasterData() {
    try {
      const params = { lang: CONSTANTS.COMMON_CONSTANTS.EN };
      let resp = await dsFeedService.getMasterData(params);
      // console.log(resp);
      if (resp.data.data.quantity_units) {
        const unitsMap = new Map();
        resp.data.data.quantity_units.forEach(unit => {
          unitsMap.set(unit.id, unit.value);
        })
        this.setState({ unitsMap });
      }
    } catch (err) {
      // console.error(err);
    }
  }

  async getDemandListData() {
    this.setState({
      showLoader: true,
      isTableDataLoading: true
    });
    let params = { lang: CONSTANTS.COMMON_CONSTANTS.EN, is_ib: 1 };
    const { searchedText } = this.state;
    if (!CommonUtils.isEmpty(searchedText)) {
      params['search_value'] = searchedText;
    }
    if (!params.hasOwnProperty("limit")) {
      params["limit"] = this.state.params["limit"];
    }
    if (!params.hasOwnProperty("offset")) {
      params["offset"] = this.state.params["offset"];
    }

    // if (this.state.datePayloads["startDate"] !== "") {
    //   params["startDate"] = this.state.datePayloads["startDate"];
    // }
    // if (this.state.datePayloads["endDate"] !== "") {
    //   params["endDate"] = this.state.datePayloads["endDate"];
    // }


    if (Object.keys(this.state.sortParams).length > 0 && this.state.sortParams["sortkey"] !== "") {
      params["sortkey"] = this.state.sortParams["sortkey"];
      params["sortorder"] = this.state.sortParams["sortorder"];
    } else {
      delete params["sortkey"];
      delete params["sortorder"];
    }

    let updatedParams = { ...params, ...this.state.filterParams }
    try {
      let resp = await dsFeedService.getDemandList(updatedParams);

      if (this.ismounted) {
        if (resp.data.data.results) {
          // console.log(resp)
          this.setState({
            demandListData: [...resp.data.data.results],
            totalDataCount: resp.data.data.count,
            showLoader: false,
            isTableDataLoading: false
          });
        }
      }

    } catch (err) {
      // console.error(err);
    } finally {
      if (this.ismounted) { this.setState({ showLoader: false, isTableDataLoading: false }); }
    }
  }

  componentWillUnmount() {
    this.ismounted = false;
  }

  // this function brings default selected date from the DateRangeSelection and call the Api 
  //when first Landed on this page
  onDefaultDateFromDateRangeShown(data) {
    this.setState({ datePayloads: data, showLoader: true }, function () {
      this.getSearchedOrderListData();
    });
  }

  onDateChaged(data) {
    this.setState({ datePayloads: data, showLoader: true }, function () {
      this.getSearchedOrderListData();
    });
  }

  handelRefreshData() {
    this.setState({
      showLoader: true,
      sortParams: {},
      sortKeysOrder: [],
      demandListData: [],
      filterParams: {},
      sortKeys: {},
      resetFilter: true,
      params: { ...CONSTANTS.COMMON_CONSTANTS.OFFSET_LIMIT_500 }
    }, function () {
      this.setState({
        resetFilter: false
      }, () => this.getDemandListData())

    })
  }

  resetOffsetAndGetData() {
    let paramsval = this.state.params;
    paramsval["offset"] = paramsval["offset"] + 500;
    this.setState({ params: paramsval, isTableDataLoading: true }, function () {
      this.getSearchedOrderListData();
    })
  }

  handleGetDemandData(param) {
    param = { ...CONSTANTS.COMMON_CONSTANTS.OFFSET_LIMIT_500 };

    this.setState({
      demandListData: [],
      showLoader: true,
      resetPageNumber: true,
      filterParams: param,
      params: { ...CONSTANTS.COMMON_CONSTANTS.OFFSET_LIMIT_500 }
      // sortParams: {}, sortKeysOrder: [],
      // sortKeys: {}
    }, () =>
      this.getDemandListData()
    )
  }

  onTableSorted(sortParamsData) {
    // let param = this.state.filterParams;
    // param["offset"] = 0;
    // param["limit"] = 1000;
    this.setState({
      sortParams: sortParamsData["params"],
      sortKeysOrder: sortParamsData["order"],
      sortKeys: sortParamsData["sortKeys"],
      demandListData: [],
      showLoader: true,
      resetPageNumber: true,
      params: { limit: 1000, offset: 0 }
    }, () =>
      this.getDemandListData()
    );
  }

  handleFilter(event) {
    let searchedText = event.target.value;
    var ref = this;
    this.setState({ searchedText: searchedText });
    clearTimeout(this.timer);
    this.timer = setTimeout(function (event) {
      ref.setState({
        showLoader: true
      }, function () {
        ref.setState({
          resetFilter: false
        }, () => this.getDemandListData())
      })
    }, 1000);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Paper className={classes.card} >
          <div style={{ display: "flex" }}>
            <i onClick={(event) => {
              if (!this.state.showLoader) {
                this.handelRefreshData(event)
              }
            }}
              style={{ padding: "18px", fontSize: "18px", color: "#50a1cf", cursor: "pointer" }} data-toggle="tooltip" data-html="true" title="Refresh" className="fa fa-refresh" aria-hidden="true">
            </i>
            <input
              type="text"
              placeholder="Search Business name/Commodity name/Contact details"
              className="search-input"
              style={{ width: "50%", marginLeft: 50, marginBottom: 15 }}
              value={this.state.searchedText}
              onChange={this.handleFilter.bind(this)} />
            <i style={{ marginTop: 22, marginLeft: "-36px" }} className="fa fa-search"></i>
            {/* <DateRangeSelector
                            onDateChanged={this.onDateChaged.bind(this)}
                            onDefaultDateFromDateRangeShown={this.onDefaultDateFromDateRangeShown.bind(this)} /> */}
          </div>
          {this.state.showLoader &&
            <Loader />}
          {<DemandListTable
            unitsMap={this.state.unitsMap}
            tableData={this.state.demandListData}
            resetOffsetAndGetData={() => this.resetOffsetAndGetData()}
            currentOffset={this.state.params["offset"]}
            resetPageNumber={this.state.resetPageNumber}
            showLoader={this.state.showLoader}
            sortKeys={this.state.sortKeys}
            sortKeysOrder={this.state.sortKeysOrder}
            onSortingChanged={(sortParams) => this.onTableSorted(sortParams)}
            setPageNumber={() => this.setState({ resetPageNumber: false })}
            totalDataCount={this.state.totalDataCount}
            isTableDataLoading={this.state.isTableDataLoading}
            // tagsOption={this.state.tagsOption}
            // orderTypeOptions={this.state.orderTypeOptions}
            onDemandPostCreated={() => this.handleGetDemandData(this.state.filterParams)} />
          }

        </Paper>
      </div>
    );
  }
}
DsFeedContainer.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(DsFeedContainer);