import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import ReactDOM from 'react-dom';
import FormControl from '@material-ui/core/FormControl';
import AsyncSelect from 'react-select/lib/Async';
import Utils from "../../../app/common/utils";
import groupService from "../../../app/groupService/groupService";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: '0px 5px',
        padding: '0px 10px',
        minWidth: 120,
        color: '#373737 !important',
        // borderRight: '1px solid #e6e7e8'
    },
    'input': {
        '&::placeholder': {
            color: 'blue'
        }
    },
    bgColor: {
        "& > *": {
            // display: "inline-block",
            fontWeight: "600",
            fontFamily: "Montserrat",
            fontSize: "12px",
            color: "#3e5569",
        }
    }
});

const CustomOption = props => {
    const { data, innerRef, innerProps } = props;
    return data && data.is_primary ? (
        <div ref={innerRef} {...innerProps} className='custom_option'>
            <i className="fa fa-check" style={{ color: "#60c1d8" }} aria-hidden="true"></i> &nbsp; {data.label ? data.label : ""}
        </div>
    ) : (
            <div ref={innerRef} {...innerProps} className='custom_option' >
                {data.label ? data.label : ""}
            </div>

        );
};

class FilterUserComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userid: [],
            selectedUserData: {},
            labelWidth: 0,
            inputValue: "",
            date: new Date(),
            filterOptionData: {},
            selectedId: { "name": "", "id": "", "mobile": "" },
            isUserDetailsEdited: false,
            defaultOption: []
        }
    }

    componentDidMount() {



        if (this.props.selectedUser && Object.keys(this.props.selectedUser).length > 0) {
            this.setState({
                userid: [{
                    rawdata: this.props.selectedUser,
                    is_primary: Utils.checkIfPrimaryKey(this.props.selectedUser),
                    label: this.props.selectedUser["fullname"] + ",  " + this.props.selectedUser["business_name"] + " \n  (" + this.props.selectedUser["locality"] + " , " + this.props.selectedUser['mobile'] + " )",
                    value: this.props.selectedUser['mobile']
                }],
                selectedId: { "name": this.props.selectedUser["fullname"], "id": this.props.selectedUser["id"], "mobile": this.props.selectedUser['mobile'] },
            }, () => {
                this.getOptions("userid", this.state.selectedId["name"], (data) => {
                    if (data && data.length > 0) {
                        let updatedSelectedData = null;
                        for (let i = 0; i < data.length; i++) {
                            if (data[i]["value"] === this.state.selectedId["mobile"]) {
                                updatedSelectedData = data[i];
                                break;
                            }
                        }
                        this.getSearchAreaText("userid", updatedSelectedData);
                    }
                });
            })
        }
        if (this.state.defaultOption && this.state.defaultOption.length === 0) {
            this.getOptions("userid", "a", (data) => {
                this.setState({ defaultOption: data });
            });
        }

        if (this.InputLabelRef) {
            this.setState({
                labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isUserDetailsEdited !== this.state.isUserDetailsEdited) {
            this.setState({ isUserDetailsEdited: nextProps.isUserDetailsEdited }, () => {
                if (this.state.selectedId && this.state.selectedId["id"] &&
                    this.state.selectedId["name"] && this.state.selectedId["mobile"] && this.state.selectedId["id"] !== "") {
                    this.getOptions("userid", this.state.selectedId["name"], (data) => {
                        if (data && data.length > 0) {
                            let updatedSelectedData = null;
                            for (let i = 0; i < data.length; i++) {
                                if (data[i]["value"] === this.state.selectedId["mobile"]) {
                                    updatedSelectedData = data[i];
                                    break;
                                }
                            }
                            this.getSearchAreaText("userid", updatedSelectedData);
                        }
                    });
                }
            });
        }
    }

    getSearchAreaText = (id, event) => {
        try {
            console.log(event)
            let selectedIdVal = "";
            this.setState({ [id]: event !== null ? event : "" }, () => {
                let sData = {};
                if (event !== null) {
                    sData = event["rawdata"];
                    selectedIdVal = { "name": event["rawdata"]["fullname"], "id": event["rawdata"]["id"], mobile: event["value"] };
                }
                console.log(selectedIdVal)

                this.setState({ selectedUserData: sData, selectedId: selectedIdVal }, () => {
                    this.props.onUserSelected(sData);
                });
            });
        } catch (err) {
            console.log(err);
        }
    }

    getOptions = async (type, inputValue, callback) => {
        try {

            console.log(type, inputValue)
            if (!inputValue) {
                callback([]);
            }
            // let resp = {};
            let data = {};
            data["searchVal"] = inputValue;
            data["role"] = "ca";
            let resp = await groupService.searchUserList(data);

            if (resp.data.status === 1 && resp.data.result) {
                var respData = this.formatDataForDropDown(resp.data.result.data, "fullname", "mobile");
                callback(respData);
            } else {
                callback([]);
            }
        } catch (err) {
            console.error(err);
            callback([]);
        }
    }

    formatDataForDropDown(data, labelKey, valuekey) {

        var optionsData = [];
        if (data) {
            for (var i = 0; i < data.length; i++) {
                optionsData.push({
                    rawdata: data[i],
                    is_primary: Utils.checkIfPrimaryKey(data[i]),
                    label: data[i]["fullname"] + ",  " + data[i]["business_name"] + " \n  (" + data[i]["locality"] + " , " + data[i][valuekey] + " )",
                    value: data[i][valuekey]
                });
            }
        }
        return optionsData;
    }

    render() {
        const { classes } = this.props;
        const { userid } = this.state;
        return (
            <div>
                <Grid container direction="row" alignItems="stretch">
                    <Grid item xs={12} sm={12} md={12}>
                        <form className={classes.root} autoComplete="off" style={{ padding: '15px 0px', backgroundColor: "#fff", color: "#000", borderRadius: "4px" }}>
                            <FormControl variant="outlined" className={classes.formControl}
                                style={{ flex: 1, zIndex: "999" }}>
                                <AsyncSelect
                                    cacheOptions
                                    value={userid}
                                    name={"userid"}
                                    onChange={this.getSearchAreaText.bind(this, "userid")}
                                    isSearchable={true}
                                    isClearable={true}
                                    placeholder={`Select user`}
                                    defaultOptions={this.state.defaultOption}
                                    components={{ Option: CustomOption }}
                                    loadOptions={this.getOptions.bind(this, "userid")}
                                />
                            </FormControl>
                        </form>
                    </Grid>
                </Grid>
            </div >
        );
    }
}

FilterUserComponent.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FilterUserComponent);
