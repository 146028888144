import Api from '../../config/dev';
import Utils from '../common/utils';

let groupService = {

    // https://nvy4z4oj1m.execute-api.ap-south-1.amazonaws.com/dev/user/list
    searchUserList: async function (param) {
        return Api({
            method: 'get',
            headers: { "Authorization": Utils.getToken() },
            url: '/user/list',
            params: param
        }, 6);
    },

    searchGroupList: async function ( param ) {
        // https://nvy4z4oj1m.execute-api.ap-south-1.amazonaws.com/dev/group/list?searchVal=dev_test_3
        //connect_group_id=22
        return Api({
            method: 'get',
            headers: { "Authorization": Utils.getToken() },
            url: '/group/list',
            params: param
        }, 6);
    },

    createGroup: async function (payload) {
        // https://nvy4z4oj1m.execute-api.ap-south-1.amazonaws.com/dev/users/creategroup
        // {
        //     "group_data":{
        //         "credit_limit":1,
        //         "group_name":"dev_test_3",
        //         "primary_user_id":2971,
        //         "primary_user_mobile":"8826536055"
        //     },
        //     "user_data":[ 2971, 3296 ]
        // }

        return Api({
            method: 'post',
            headers: { "Authorization": Utils.getToken() },
            url: '/users/creategroup',
            data: payload
        }, 6);
    },

    updateGroupMetaInfo: async function (id, payload) {
        // https://nvy4z4oj1m.execute-api.ap-south-1.amazonaws.com/dev/users/updategroup/22
        // {
        //     "credit_limit": 10,
        //         "group_name": "dev_test_3",
        //             "primary_user_id": 2971,
        //                 "primary_user_mobile": "8826536055"
        //     //active: false
        // }


        return Api({
            method: 'post',
            headers: { "Authorization": Utils.getToken() },
            url: '/users/updategroup/'+id,
            data: payload
        }, 6);
    },

    attacheDetacheUserApi: async function (userId , payload) {
        // https://nvy4z4oj1m.execute-api.ap-south-1.amazonaws.com/dev/users/group/update/3296
        // To attach
        // {
        //     "connect_group_id": 20,
        //         "is_connected_to_group": true
        // }
        // // To detach
        // {
        //     "connect_group_id": 20,
        //         "is_connected_to_group": false
        // }


        return Api({
            method: 'post',
            headers: { "Authorization": Utils.getToken() },
            url: '/users/group/update/'+userId,
            data: payload
        }, 6);
    }
}

export default groupService;