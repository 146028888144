/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import userListService from '../../app/userListService/userListService';
import ConfirmDialog from '../../app/common/ConfirmDialog';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Utils from '../../app/common/utils';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
// import commodityService from '../../app/commodityService/commodityService';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Loader from './Loader';
import { getAccessAccordingToRole } from '../../config/appConfig';
// import { Auth } from 'aws-amplify';
import Auth from '@aws-amplify/auth';
import Place from './google_place'
import SweetAlertPage from '../../app/common/SweetAlertPage';
import relationshipManagerService from '../../app/relationshipManagerService/relationshipManagerService';
// 
import Storage from '@aws-amplify/storage';
import { getBucketName } from '../../config/appConfig';
//
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CircularProgress from '@material-ui/core/CircularProgress';
import CommonUtils from '../../utils/helper';

const theme = createMuiTheme({
  overrides: {

    MuiInputBase: {
      input: {
        color: "#000"
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: "14px",
        fontFamily: "lato"
      }
    },
    // MuiFormControl: {
    //     marginDense: {
    //         marginBottom: "unset"
    //     }
    // }
  }
});
const styles = theme => ({

  profile: {
    marginLeft: '30%',
    background: 'red',
    width: '40px',
    borderRadius: '10px'
  }

});

class EditUser extends Component {

  constructor(props) {
    super(props);
    this.state = {
      commodityList: { options: [], optionN_E: {}, optionE_N: {} },
      open: this.props.openModal,
      dataObj: this.props.data,
      requiredKey: ['fullname', 'mobile', 'role'],
      roleList: ['la', 'ca', 'broker'],
      isUpdate: false,
      isInfo: false,
      payload: {},
      stateList: Utils.getStateData(),
      "districtMap": Utils.getDistrictData(),
      "districtList": [],
      showLoader: false,
      subId: "",

      showSweetAlert: false,
      sweetAlertData: {
        "type": "",
        "title": "",
        "text": ""
      },
      showErrorMsg: false,
      rmOption: { dropDownOption: [], dropdownOptionMap: {} },
      errorFields: {},
      isFileLoading: false
    }
    this.handelAutoCompleteChange = this.handelAutoCompleteChange.bind(this);
    // this.handelAutoCompleteChangeForRm = this.handelAutoCompleteChangeForRm.bind(this);
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => this.setState({ subId: user.attributes.sub }))
      .catch(err => console.log(err));

    this.getRelationShipList();

    if (this.props.data) {
      let data = this.props.data;
      let arr = ['state', 'district', 'locality', 'business_name', 'business_name_hindi', 'fullname_hindi']
      for (let i = 0; i < arr.length; i++) {
        if (data.hasOwnProperty(arr[i]) && (!data[arr[i]] || data[arr[i]] === "null")) {
          data[arr[i]] = "";
        }
      }
      let list = [];
      let val = data['state'];
      if (this.state.districtMap.hasOwnProperty(val.toLowerCase())) {
        list = this.state.districtMap[val.toLowerCase()];
      }
      this.setState({ dataObj: this.props.data, districtList: list, isUpdate: true, isInfo: this.props.isInfo, commodityList: this.props.commodityList });
    }

  }

  handleChange = event => {
    let data = this.state.dataObj;
    let id = event.target.id;
    let val = event.target.value;
    let namelengthValidationarray = ["fullname", "fullname_hindi", "business_name", "business_name_hindi", "partner_names"]
    if (id === "mobile" || id === "sec_mobile" || id === "third_mobile") {
      if (val.length <= 10) {
        data[id] = val;
      }
    } else if (id === "default_commodity" || id === "partner_names") {
      data[id] = val.split(',');
    } else {
      data[id] = val;
    }
    if (id === "bijak_credit_limit" && val > 1000000000) { //checkfor credit 100cr
      return;
    }

    if (namelengthValidationarray.indexOf(id) > -1 && val && val.length > 100) {
      return;
    }

    if (id === "rating" && val > 5) {
      return;
    }

    this.setState({ dataObj: data, showErrorMsg: false, errorFields: {} });
  }

  handelAutoCompleteChange = (event, values) => {
    var commoditylist = [];
    let data = this.state.dataObj;
    if (values && values.length > 0) {
      for (var i = 0; i < values.length; i++) {
        commoditylist.push(this.props.commodityList["optionE_N"][values[i]]);
      }
    }
    data["default_commodity"] = commoditylist;
    this.setState({ dataObj: data })
  }

  removeRmCorrespondingKeys(obj) {
    let updateObj = Object.assign({}, obj);
    for (let key in updateObj) {
      if (key.indexOf("rm_") > -1) {
        if (key !== "rm_id") {
          delete updateObj[key];
        }
      }
    }
    return updateObj;
  }

  handelConfirmUpdate = async () => {
    let id = this.state.dataObj.id;
    let obj = this.state.dataObj;
    let reqObj = {}
    if (this.state.isUpdate) {
      // delete obj.mobile;
      delete obj.createdtime;
      delete obj.updatedtime;
      reqObj = { 'data': this.removeRmCorrespondingKeys(obj) };
    } else {
      id = null;
      reqObj['data'] = [];
      reqObj['data'][0] = obj;
    }
    const google_locality = document.getElementById("google-map-demo").value;
    const district_id = document.getElementById("place_id");
    if (google_locality) {
      const locality_list = google_locality.split(",");
      reqObj["data"]["locality"] = google_locality;
      let length = locality_list.length;
      if (length == 3) {
        reqObj["data"]["district"] = locality_list[0];
        reqObj["data"]["state"] = locality_list[1];
      }

      if (length == 4) {
        reqObj["data"]["district"] = locality_list[1];
        reqObj["data"]["state"] = locality_list[2];
      }

      if (length == 2 || length == 1) {
        reqObj["data"]["district"] = locality_list[0];
        reqObj["data"]["state"] = locality_list[0];
      }
    }
    if (district_id) {
      reqObj["data"]["district_id"] = district_id.value
    }
    // let resp = { data :{ status : 1, message : "cust msg"}}; 
    // console.log(reqObj);
    this.setState({ showLoader: true, showConfirmDialog: false });
    let resp = await userListService.addUserData(this.state.subId, this.state.isUpdate, id, reqObj);
    this.setState({ showLoader: false });
    let sweetAlrtData = this.state.sweetAlertData;
    if (resp.data.status === 1) {
      this.props.onDataEdited();
      sweetAlrtData["type"] = "success";
      sweetAlrtData["title"] = "Success";
      sweetAlrtData["text"] = "User details updated successfully";

    } else {
      sweetAlrtData["type"] = "error";
      sweetAlrtData["title"] = "Error";
      sweetAlrtData["text"] = resp && resp.data && resp.data.message ? resp.data.message : "Oops there was an error, while adding";
    }
    this.setState({
      alertData: {},
      showSweetAlert: true,
      sweetAlertData: sweetAlrtData
    });
  }

  handelCancelUpdate = () => {
    this.setState({ showConfirmDialog: false, alertData: {} });
  }

  handleDialogCancel(event) {
    this.props.onEditModalCancel();
  }

  handleAddClick(event) {
    let data = this.state.dataObj;
    let inputElement = document.getElementById("place_id");
    if (inputElement && inputElement.value) {
      data.district_id = inputElement.value;
    } else {
      if (CommonUtils.isEmpty(data.district_id)) {
        data.district_id = '';
      }
    }

    let reqArr = this.state.requiredKey;
    if (this.state.dataObj.is_ib) {
      reqArr = [...reqArr, 'business_name', 'district_id'];
    }
    let errors = {};
    let isValid = true;
    errors.district_id = false;
    for (let i = 0; i < reqArr.length; i++) {
      if (!data[reqArr[i]] && data[reqArr[i]] === "") {
        errors[reqArr[i]] = true;
        isValid = false;
      }
    }
    if (!isValid) {
      this.setState({ showErrorMsg: true, errorFields: errors });
      return;
    }

    let dialogText = this.state.isUpdate ? "Are you sure  to update ?" : "Are you sure to add ?";
    this.setState({ dialogText: dialogText, dialogTitle: "Alert", showConfirmDialog: true, payload: data });

  }

  handleCheckbox(id, event) {
    let obj = this.state.dataObj;
    if (id === "is_primary" && obj["is_connected_to_group"]) {
      let sweetAlrtData = this.state.sweetAlertData;
      sweetAlrtData["type"] = "error";
      sweetAlrtData["title"] = "Info";
      sweetAlrtData["text"] = "You cannot enable/disable the primary user, as its linked with some Active Group";
      this.setState({ sweetAlertData: sweetAlrtData, showSweetAlert: true });
      return;
    }
    obj[id] = !obj[id];
    this.setState({ QueryObj: obj });
  }

  handleStateChange = (id, event) => {
    let data = { ...this.state.dataObj };
    data[id] = event.target.value;
    if (data[id] !== "ca") {
      data['is_ib'] = false;
      data['image_url'] = null;
    }
    this.setState({ dataObj: data });
    if (id === "state") {
      let val = event.target.value;
      this.state.dataObj.district = "";
      if (this.state.districtMap.hasOwnProperty(val.toLowerCase())) {
        let list = this.state.districtMap[val.toLowerCase()];
        this.setState({ districtList: list });
      }
    }

  };

  handelSweetAlertClosed() {
    this.setState({ showSweetAlert: false }, () => {
      if (this.state.sweetAlertData["type"] !== "error") {
        // this.props.onEditModalClosed();
      }
    })
  }

  getCommodityArray(data) {
    let cList = [];
    for (let i = 0; i < data.length; i++) {
      if (this.props.commodityList["optionN_E"].hasOwnProperty(data[i])) {
        cList.push(this.props.commodityList["optionN_E"][data[i]]);
      } else {
        cList.push(data[i])
      }
    }
    return cList;
  }

  async getRelationShipList() {
    try {
      let resp = await relationshipManagerService.getRMList();
      if (resp.data.status === 1 && resp.data.result) {
        let respData = resp.data.result;
        this.setState({
          rmOption: this.getFormattedRmList(respData)
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  getFormattedRmList(resp) {
    // console.log(resp.data)
    let options = { dropDownOption: [], dropdownOptionMap: {} };
    for (let i = 0; i < resp.data.length; i++) {
      options.dropdownOptionMap[resp.data[i]["name"]] = resp.data[i]["id"];
      if (resp.data[i]["active"]) {
        options.dropDownOption.push(resp.data[i]["name"]);
      }
    }
    return options;
  }

  handelAutoCompleteChangeForRm(event, value) {
    let data = this.state.dataObj;
    if (value !== null) {
      data["rm_name"] = value;
      data["rm_id"] = this.state.rmOption["dropdownOptionMap"][value];
    } else {
      data["rm_name"] = value;
      data["rm_id"] = -1;
    }
    this.setState({ dataObj: data })
  }

  fileChangedHandler = (event) => {
    let { selectedFileName, isFileLoading, file } = this.state;
    file = event.target.files[0];
    if (Utils.checkIfValidImageFile(file.name)) {
      selectedFileName = file ? file.name : null;
      isFileLoading = !file ? false : true;
      this.setState({ selectedFileName, isFileLoading, file });
      let updatedFileName = Utils.getImageName(file.name);
      Storage.configure({
        level: 'public',
        AWSS3: {
          bucket: getBucketName(),//Your bucket name;
          region: 'ap-south-1'//Specify the region your bucket was created in;
        }
      });
      Storage.put("buyer/" + updatedFileName, file, {
        // key: "UBIL-Register-Online.png"
        contentType: 'image/png'
      }).then(result => {
        let attachmentObj = {
          bucket: getBucketName(),
          filename: updatedFileName,
          key: result.key
        }

        Storage.get("buyer/" + updatedFileName)
          .then(result => {
            const image_url = result.split("?")[0];
            const dataObj = { ...this.state.dataObj };
            dataObj.image_url = image_url;
            this.setState({
              isFileLoading: false,
              dataObj
            });
          })
          .catch(err => console.log(err));
      }
      ).catch(err => {
        this.setState({ isFileLoading: false });
        console.log(err);
      });
    } else {
      let sweetAlrtData = this.state.sweetAlertData;
      sweetAlrtData["type"] = "warning";
      sweetAlrtData["title"] = "Info";
      sweetAlrtData["text"] = "Please select a valid image file.";
      this.setState({ showSweetAlert: true, sweetAlertData: sweetAlrtData });
    }
  }

  deleteImageHandler = () => {
    const dataObj = { ...this.state.dataObj };
    dataObj.image_url = null;
    this.setState({ dataObj });
  }

  render() {
    const { classes } = this.props;
    const { showLoader, showSweetAlert, sweetAlertData, dataObj, errorFields } = this.state;
    const is_primary = Utils.checkIfPrimaryKey(dataObj);
    if (!dataObj.is_ib) {
      let data = dataObj;
      data.image_url = null;
    }
    return (
      <MuiThemeProvider theme={theme}><div style={{ width: '100%', padding: '8px 24px', marginTop: '50px' }}>
        {!showLoader ? <div>
          <div style={{ display: 'flex' }}>
            <TextField
              margin="dense"
              id="mobile"
              label="Mobile"
              size="small"
              type="text"
              maxLength="10"
              disabled={true}
              style={{ marginRight: '2%', width: '48%', fontSize: "14px" }}
              value={Utils.maskMobileNumber(dataObj.mobile)}
              // onChange={this.handleChange.bind(this)}
              required
              fullWidth
            />
            <TextField
              select
              id="role"
              label="Role"
              disabled={this.state.isInfo}
              type="text"
              style={{ marginRight: '2%', width: '48%', marginTop: '5px' }}
              value={dataObj.role}
              onChange={this.handleStateChange.bind(this, 'role')}

            >

              {this.state.roleList.map((option, i) => (
                <MenuItem key={i} value={option} selected={true}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

          </div>
          <div style={{ display: 'flex' }}>
            <TextField
              margin="dense"
              id="fullname"
              label="Fullname"
              type="text"
              error={this.state.errorFields["fullname"]}
              disabled={this.state.isInfo}
              style={{ marginRight: '2%', width: this.state.isUpdate ? '48%' : "98%" }}
              value={dataObj.fullname}
              onChange={this.handleChange.bind(this)}
              required
              fullWidth
            />
            {this.state.isUpdate && <TextField
              margin="dense"
              id="fullname_hindi"
              disabled={this.state.isInfo}
              label="Fullname (Hindi)"
              type="text"
              style={{ marginRight: '2%', width: '48%' }}
              value={dataObj.fullname_hindi}
              onChange={this.handleChange.bind(this)}
              fullWidth
            />}
          </div>


          <div style={{ display: 'flex' }}>
            <Place
              props={dataObj.locality}
              is_ib={dataObj.is_ib}
              error={errorFields.district_id} />
            {/* <TextField
                            select
                            margin="dense"
                            id="state"
                            label="State"
                            type="text"
                            disabled={this.state.isInfo}
                            style={{ marginRight: '2%', width: '48%' }}
                            value={dataObj.state}
                            onChange={this.handleStateChange.bind(this, 'state')}
                            fullWidth>

                            {this.state.stateList.map((option, i) => (
                                <MenuItem key={i} value={option.toLowerCase()} selected={true}>
                                    {option.toLowerCase()}
                                </MenuItem>
                            ))}
                        </TextField> */}
            {/* <TextField
                            select
                            id="district"
                            label="District"
                            type="text"
                            disabled={this.state.isInfo}
                            style={{ marginRight: '2%', marginTop: '5px', width: '48%' }}
                            value={dataObj.district}
                            onChange={this.handleStateChange.bind(this, 'district')}

                        >

                            {this.state.districtList.map((option, i) => (
                                <MenuItem key={i} value={option.district_name} selected={true}>
                                    {option.district_name}
                                </MenuItem>
                            ))}
                        </TextField> */}
          </div>

          <div style={{ display: 'flex' }}>
            {/* <TextField 
                        margin="dense" 
                        id="default_commodity" 
                        label="Default Commodity" 
                        type="text" 
                        style={{ marginRight: '2%' }} 
                        value={dataObj.default_commodity} 
                        onChange={this.handleChange.bind(this)} 
                        fullWidth 
                    /> */}

            <Autocomplete
              multiple
              id="fixed-tags-demo"
              disabled={this.state.isInfo}
              options={this.props.commodityList["options"]}
              getOptionLabel={e => e}
              defaultValue={this.getCommodityArray(dataObj.default_commodity)}
              onChange={this.handelAutoCompleteChange}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip label={option} {...getTagProps({ index })} />
                ))
              }
              style={{ width: "98%" }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Default Commodity"
                  placeholder="Search"
                  fullWidth
                />
              )}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <TextField
              margin="dense"
              id="business_name"
              label="Buisness Name"
              type="text"
              error={this.state.errorFields["business_name"]}
              disabled={this.state.isInfo}
              style={{ marginRight: '2%', width: this.state.isUpdate ? '48%' : "98%" }}
              value={dataObj.business_name}
              onChange={this.handleChange.bind(this)}
              fullWidth
              required={dataObj.is_ib}
            />

            {this.state.isUpdate && <TextField
              margin="dense"
              id="business_name_hindi"
              label="Buisness Name (Hindi)"
              type="text"
              disabled={this.state.isInfo}
              style={{ marginRight: '2%', width: '48%' }}
              value={dataObj.business_name_hindi}
              onChange={this.handleChange.bind(this)}
              fullWidth
            />}

          </div>
          <div style={{ display: 'flex' }}>
            <TextField
              margin="dense"
              id="sec_mobile"
              label="Second Mobile"
              type="text"
              maxLength="10"
              disabled={this.state.isInfo}
              // disabled={this.state.isUpdate} 
              style={{ marginRight: '2%', width: '25%' }}
              value={dataObj.sec_mobile}
              onChange={this.handleChange.bind(this)}
              fullWidth
            />
            <TextField
              margin="dense"
              id="third_mobile"
              label="Third Mobile"
              type="text"
              maxLength="10"
              disabled={this.state.isInfo}
              // disabled={this.state.isUpdate} 
              style={{ marginRight: '2%', width: '25%' }}
              value={dataObj.third_mobile}
              onChange={this.handleChange.bind(this)}
              fullWidth
            />
            {/* </div>
                    <div style={{ display: 'flex' }}> */}
            <TextField
              margin="dense"
              id="bijak_credit_limit"
              label="bijak Credit Limit"
              type="number"
              maxLength="10"
              disabled={true}
              // disabled={this.state.isUpdate} 
              style={{ marginRight: '2%', width: '25%' }}
              value={dataObj.bijak_credit_limit}
              onChange={this.handleChange.bind(this)}
              fullWidth
            />
            <TextField
              margin="dense"
              id="exposure_cutoff_limit"
              label="Cutoff Limit"
              type="number"
              disabled={this.state.isInfo}
              style={{ marginRight: '2%', width: '23%' }}
              value={dataObj.exposure_cutoff_limit}
              onChange={this.handleChange.bind(this)}
              fullWidth
            />

          </div>
          <div style={{ display: 'flex' }}>

            {/* <TextField
                            margin="dense"
                            id="locality"
                            label="Locality"
                            disabled={this.state.isInfo}
                            type="text"
                            style={{ marginRight: '2%', width: '48%' }}
                            value={dataObj.locality}
                            onChange={this.handleChange.bind(this)}
                            fullWidth
                        /> */}

            <TextField
              margin="dense"
              id="rating"
              label="Rating"
              type="number"
              disabled={this.state.isInfo}
              style={{ marginRight: '2%', width: '10%' }}
              value={dataObj.rating}
              onChange={this.handleChange.bind(this)}
              fullWidth
            />
            <TextField
              margin="dense"
              id="partner_names"
              label="Partner Name"
              type="text"
              disabled={this.state.isInfo}
              style={{ marginRight: '2%', width: '38%' }}
              value={dataObj.partner_names}
              onChange={this.handleChange.bind(this)}
              fullWidth
            />

            {/* </div>

                    <div style={{ display: 'flex' }}> */}

            <Autocomplete
              id="fixed-tags-demo"
              options={this.state.rmOption.dropDownOption}
              getOptionLabel={e => e}
              defaultValue={dataObj.rm_name}
              onChange={(event, value) => this.handelAutoCompleteChangeForRm(event, value)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip label={option} {...getTagProps({ index })} />
                ))
              }
              style={{ width: "48%", marginTop: ".7%" }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Relationship manager"
                  placeholder="Search"
                  fullWidth
                />
              )}
            />
          </div>


          <div style={{ display: 'flex', marginTop: '12px' }}>
            <div style={{ marginRight: '2%', width: '38%' }}>
              <Checkbox
                style={{ height: 24, width: 34 }}
                disabled={this.state.isInfo}
                checked={dataObj.bijak_verified}
                onClick={this.handleCheckbox.bind(this, "bijak_verified")}
                tabIndex={-1}
                disableRipple
              />Is Bijak Verified</div>

            <div style={{ marginRight: '2%', width: '38%' }}>
              <Checkbox
                style={{ height: 24, width: 34 }}
                disabled={this.state.isInfo}
                checked={dataObj.bijak_assured}
                onClick={this.handleCheckbox.bind(this, "bijak_assured")}
                tabIndex={-1}
                disableRipple
              />Is Bijak Assured</div>
            <div style={{ marginRight: '2%', width: '38%' }}>
              <Checkbox
                style={{ height: 24, width: 34 }}
                checked={dataObj.active}
                disabled={this.state.isInfo}
                onClick={this.handleCheckbox.bind(this, "active")}
                tabIndex={-1}
                disableRipple
              />Is User Enabled</div>
          </div>

          <div style={{ display: 'flex' }}>

            {this.props.role === "ca" || dataObj.role === "ca" ?
              <div style={{ marginTop: '10px', width: '38%' }}>
                <Checkbox
                  style={{ height: 24, width: 34 }}
                  checked={dataObj.global_ca}
                  onClick={this.handleCheckbox.bind(this, "global_ca")}
                  tabIndex={-1}
                  // disabled={this.state.isMobileRequired}
                  disableRipple
                />Is Global CA</div> : ""}

            {this.props.role === "la" || dataObj.role === "la" ?
              <div style={{ marginTop: '10px', width: '38%' }}>
                <Checkbox
                  style={{ height: 24, width: 34 }}
                  checked={dataObj.global_la}
                  onClick={this.handleCheckbox.bind(this, "global_la")}
                  tabIndex={-1}
                  // disabled={this.state.isMobileRequired}
                  disableRipple
                />Is Global LA</div> : ""}
            <div style={{ marginTop: '10px', width: '38%' }}>
              <Checkbox
                style={{ height: 24, width: 34 }}
                checked={is_primary}
                onClick={this.handleCheckbox.bind(this, "is_primary")}
                tabIndex={-1}
                // disabled={this.state.isMobileRequired}
                disableRipple
              />Is Primary</div>
            {/* IB Checked */}
            {dataObj.role === "ca" &&
              <div style={{ marginRight: '2%', marginTop: '10px', width: '38%' }}>
                <Checkbox
                  style={{ height: 24, width: 34 }}
                  checked={dataObj.is_ib}
                  onClick={this.handleCheckbox.bind(this, "is_ib")}
                  tabIndex={-1}
                  disabled={this.state.isInfo}
                  disableRipple
                />Is Institutional Buyer</div>
            }
          </div>
          {/* IB Image */}
          {dataObj.is_ib && dataObj.role === "ca" &&
            <Grid item style={{ textAlign: 'left', display: "inline-block", position: "relative", margin: "20px 0px 5px 0px", marginBottom: 5 }}>
              <input
                style={{ position: "absolute", visibility: "hidden" }}
                className={classes.input}
                id="flat-button2-file"
                type="file"
                onClick={(event) => {
                  event.target.value = null
                }}
                accept="image/*"
                onChange={this.fileChangedHandler.bind(this)}
              />
              <label htmlFor="flat-button2-file">
                {this.state.isFileLoading ?
                  <div style={{ color: "#0c0", fontSize: "12px", fontWeight: "bold" }}>
                    <CircularProgress style={{ color: "#0c0", width: "20px", height: "20px", fontSize: "12px", fontWeight: "bold" }} />
                                        Uploading file
                                    </div>
                  : []}
                {dataObj.image_url && !this.state.isFileLoading ?
                  <Button component="span" style={{ position: 'relative', border: '1px solid #d5d2d2', padding: '5px 10px', fontSize: 12, backgroundColor: '#dbdbdb' }}  >
                    <img src={dataObj.image_url} alt="Profile Image" style={{ maxWidth: "150px", maxHeight: "150px", minWidth: "150px", minHeight: "150px" }} />
                    <EditIcon style={{ position: "absolute", top: "4px", right: "33px", width: "24px", height: "24px", textAlign: "center", border: "solid 1px #000", borderRadius: "4px", color: "#000", fontSize: "18px" }} />
                  </Button>
                  : (
                    !this.state.isFileLoading ?
                      <Button component="span" style={{ border: '1px solid #d5d2d2', padding: '5px 10px', fontSize: 12, backgroundColor: '#dbdbdb' }}  >
                        Choose IB image
                                  </Button> : []
                  )
                }
              </label>
              {dataObj.image_url && !this.state.isFileLoading &&
                <DeleteForeverIcon onClick={this.deleteImageHandler} style={{ position: "absolute", cursor: "pointer", top: "5px", right: "5px", width: "24px", height: "24px", textAlign: "center", border: "solid 1px #c00", borderRadius: "4px", color: "#c00", fontSize: "18px" }} />
              }
            </Grid>
          }
          {this.state.showErrorMsg &&
            <div style={{
              fontFamily: 'Montserrat, sans-serif',
              fontSize: "12px",
              color: "red",
              textAlign: "right",
              paddingRight: "10px"
            }}
            > Please check all required field</div>}
          <div style={{ textAlign: 'end', marginRight: '4%', marginTop: '2%' }}>

            {getAccessAccordingToRole("editUser") &&
              <Button className={classes.formCancelBtn} onClick={this.handleAddClick.bind(this)} color="primary" disabled={this.state.isFileLoading}>Sumbit</Button>}
            <Button className={classes.formCancelBtn} onClick={this.handleDialogCancel.bind(this)} color="primary">Cancel</Button>
          </div>
        </div> :
          <Loader primaryText="Please wait.." />}

        {this.state.showConfirmDialog ?
          <ConfirmDialog
            dialogText={this.state.dialogText}
            dialogTitle={this.state.dialogTitle}
            show={this.state.showConfirmDialog}
            onConfirmed={this.handelConfirmUpdate}
            onCanceled={this.handelCancelUpdate} /> : ""}

        {showSweetAlert &&
          <SweetAlertPage
            show={true}
            type={sweetAlertData.type}
            title={sweetAlertData.title}
            text={sweetAlertData.text}
            sweetAlertClose={() => this.handelSweetAlertClosed()}
          />}
      </div>
      </MuiThemeProvider>
    );
  }
}

EditUser.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EditUser); 