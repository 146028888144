/* eslint-disable */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Fab from '@material-ui/core/Fab';
import Loader from '../../common/Loader';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Utils from '../../../app/common/utils';
import tickIcon from "../../../assets/images/icons/check.svg";
import faqIconReddish from "../../../assets/images/icons/faq_redish.svg";
import failedIcon from "../../../assets/images/icons/failed.svg";
import approvedIcon from "../../../assets/images/icons/approved.svg";
import razorpay_skipped from "../../../assets/images/razorpay_skipped.png";
import hourglassIcon from "../../../assets/images/icons/hourglass.svg";
import cancelledIcon from "../../../assets/images/icons/cancelled.svg";
import payment_InitatedIcon from "../../../assets/images/icons/payment_Initated.svg";
import payment_failureIcon from "../../../assets/images/icons/payment_failure.svg";
import AccountBalanceWalletSharpIcon from '@material-ui/icons/AccountBalanceWalletSharp';
import SelectTransactionTypeModal from '../../payment/common/SelectTransactionTypeModal';
import EditTransactionModal from '../../payment/common/EditTransactionModal';
import PayoutModal from '../../payment/common/PayoutModal';
import TransactionInvoiceModal from '../../payment/components/TransactionInvoiceModal';
import BusinessInfoDialog from '../../common/BusinessInfoDialog';
import TransactionIfoModal from '../../payment/common/TransactionIfoModal';
import { getAccessAccordingToRole } from '../../../config/appConfig';
import TransactionIdInfoModal from '../../common/TransactionIdInfoModal';
import DownloadModalPayment from '../../common/DownloadModalPayment';
import Tooltip from '@material-ui/core/Tooltip';
import OrderInfoDialog from '../../orders/components/OrderInfoDialog';
import Checkbox from '@material-ui/core/Checkbox';
import SweetAlertPage from '../../../app/common/SweetAlertPage';
import BulkPayoutModal from './BulkPayoutModal';


var moment = require('moment');

const theme = createMuiTheme({
    overrides: {
        MuiTableCell: {
            head: {
                color: '#2e3247',
                fontWeight: 600,
                fontSize: '13px !important',
                fontFamily: 'lato !important',
                textTransform: 'uppercase',
                lineHeight: "1em"

            },
            body: {
                color: 'rgba(0, 0, 0, 0.87)',
                fontWeight: 500,
                fontSize: '14px !important',
                fontFamily: 'lato !important',
            }
        },
        MuiTablePagination: {
            toolbar: {
                paddingRight: '270px'
            }
        },
        MuiTypography:{
            body1:{
                fontSize: '14px !important',
                fontFamily : 'lato !important'
            }
        },
        MuiFormLabel:{
            root:{
                fontSize: '15px !important',
                fontFamily : 'lato !important',
                color:'rgb(0, 0, 0) !important'
            }
        }
    }
});

const styles = theme => ({
    appBar: {
        position: 'relative',
        background: "#05073a",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        fontSize: "20px !important",
        fontFamily: "lato !important",
        fontWeight: 500
    },
    closeBtn: {
        fontSize: "15px !important",
        fontFamily: "lato !important",
        fontWeight: 500
    },
    tableCell: {
        padding:"8px",
        paddingLeft: '4px',
        paddingRight: '4px',
        textAlign: 'center',
        maxWidth: '200px'
    },
    formControl: {
        color: "#fff"
    },
    dataHeader: {
        width: "20%"
    },
    lightTooltip: {
        fontSize: '15px',
        fontWeight:500,
        maxWidth: 'none',
    },
    inline: {
        display: 'inline',
    },
    detailHeadmain: {
        padding: "4px 0px",
    },
    defaultTemplate: { 
        height: '30vh', 
        paddingTop: '10vh'
     },
});


class BulkPaymentPaymentDetailsTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allTransactionsData: this.getOnlyPayoutData(this.props.allTransactionsData),
            paymentMetaInfo : this.props.paymentMetaInfo,
            tableHeadData: ["status","Lnked Order Id", "APP ID", "Buyer Name/ Bussiness Name", "Supplier Name/ Bussiness Name", "Created Time",  "Payment mode/ Payment type","Amount"],
            tableHeadDataKeys: ["","","linked_order_id","pay_id","buyer_fullname","supplier_fullname","createdtime","payment_mode","amount"],
            invoiceModalData: [],
            showImageInvoiceModal: false,
            editableData: undefined,
            showEditTransactionModal: false,
            isDataUpdated: false,
            
            rowsPerPage: 50,
            page: 0,

            statusUpdateObj:{},
            showStatusChangeModal: false,

            showPayoutModal: false,
            payoutData: undefined,

            showUserInfo: false,
            userInfoData : undefined,
            isLimitUpdate: false,

            showTransactionInfoDialog: false,
            transactionInfoData : undefined,
            datePayloads: { "startDate": "" },

            userId: undefined,

            showTransactionIDInfoDialog: false,
            transactionIDInfoData: undefined,
            
            showDownloadModal: false,

            totalDataCount: this.props.totalDataCount || 0,
            currentOffset : this.props.currentOffset || 0,
            isTableDataLoading : this.props.isTableDataLoading,

            
            sortKeys: this.props.sortKeys || {},
            sortKeysOrder :this.props.sortKeysOrder || [],
            sortingEnabled: ["id","linked_order_id","createdtime","amount"],
            
            showOrderInfo: false,
            orderInfo: undefined,
            maxPayoutLimit: 15,
            selectedPayoutIds:{},
            isSelectedAll : false,
            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },
            showBulkPayoutModal: false
        }
    }

    getOnlyPayoutData( rawData ){
        try{
            if( rawData ){
                return rawData.filter(e => {
                    if ((e["transaction_type"] === "b_out" && e["status"] === "approved") || (e["transaction_type"] === "b_in" && e["status"].indexOf("validated") > -1 && e["payment_mode"] === "bank")) {
                        return e;
                    }
                })
            }else{
                return [];
            }

        }catch( err ){
            console.log( err );
            return [];
        }
    }

    UNSAFE_componentWillReceiveProps( nextProps ){
        
        if (this.state.paymentMetaInfo !== nextProps.paymentMetaInfo) {
            this.setState({ paymentMetaInfo: nextProps.paymentMetaInfo });
        }

        if (this.state.allTransactionsData !== nextProps.allTransactionsData) {
            this.setState({ allTransactionsData: this.getOnlyPayoutData(nextProps.allTransactionsData) , 
                selectedPayoutIds:{}, isSelectedAll : false });
        }

        if (this.state.totalDataCount !== nextProps.totalDataCount) {
            this.setState({ totalDataCount: nextProps.totalDataCount });
        }
        
        if (this.state.currentOffset !== nextProps.currentOffset) {
            this.setState({ currentOffset: nextProps.currentOffset });
        }

        if (this.state.isTableDataLoading !== nextProps.isTableDataLoading) {
            this.setState({ isTableDataLoading: nextProps.isTableDataLoading });
        }
        if (this.state.sortKeys !== nextProps.sortKeys) {
            this.setState({ sortKeys: nextProps.sortKeys });
        }
        if (this.state.sortKeysOrder !== nextProps.sortKeysOrder) {
            this.setState({ sortKeysOrder: nextProps.sortKeysOrder });
        }

        if (nextProps.resetPageNumber) {
            this.setState({ page : 0 },()=>
            this.props.setPageNumber());
        }
    }

    handelTransactionInvoiceModal(row, event) {
        this.setState({ invoiceModalData: row["images"] }, function () {
            this.setState({ showImageInvoiceModal: true })
        })
    }

    getTransactionTypeColor(transaction_type) {
        if (transaction_type === "b_out") {
            return "rgb(212, 58, 58)"; // red

        } else if (transaction_type === "b_in") {
            return "rgb(56, 122, 57)"; // green

        } else {
            return "rgba(0, 0, 0, 0.87)" // default black color
        }
    }

    //edit option
    handelEditModalOpen(data) {
        this.setState({ editableData:Object.assign({},data) , showEditTransactionModal: true });
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
        if(  this.state.allTransactionsData.length === (newPage *this.state.rowsPerPage ) ){
            this.props.resetOffsetAndGetData();
        }
      };
    
      handleChangeRowsPerPage = event => {
        this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
      };

      handelRefreshModal( event ){  
        this.props.OnPaymentUpdated();
      }
    

      handelStatusOptionClick( row, event ){
            this.setState({ showStatusChangeModal : true, statusUpdateObj: row });
      }


      getStatusOption( event , row ){
          if( (row["transaction_type"] === "b_out" && row["payment_mode"] === "bijak") ||  (row["transaction_type"] === "b_in" && row["payment_mode"] === "bank") ){
            
            if(  row["status"] === "transaction_failed" ){
                return(<span 
                    style={{ paddingLeft: "15%"}}  
                    data-toggle="tooltip" 
                    data-placement="center" 
                    title={row["status"] }>
                    <img src={ payment_failureIcon } alt={row["status"]} style={{ height: "22px",width: "22px"}}/>
                </span> );
               }else if(  row["status"] === "transaction_initiated" ){
            return(<span 
                style={{ paddingLeft: "15%"}}  
                data-toggle="tooltip" 
                data-placement="center" 
                title={row["status"] }>
                <img src={ payment_InitatedIcon } alt={row["status"]} style={{ height: "22px",width: "22px"}}/>
            </span> );
           }else if(  row["status"] === "payout_reversed" ||
                row["status"] === "payout_cancelled" || 
                row["status"] === "payout_rejected" ){
                    return( <span>
                        <Tooltip title={row["status"] +":"+ row["failure_reason"]} placement="top" classes={{ tooltip: this.props.classes.lightTooltip }}>
                        <span 
                        style={{paddingLeft: "6px"}}  
                        data-toggle="tooltip" 
                        data-placement="center" 
                        title={row["status"] }>
                        <img src={ cancelledIcon } alt={row["status"]} style={{ height: "20px",width: "20px"}}/>
                    </span> 
                    </Tooltip>
                    <span 
                        style={{ fontSize: "20px",paddingLeft: "25px", cursor:"pointer"}}  
                        data-toggle="tooltip" 
                        data-placement="center" 
                        onClick={( event )=> { if( getAccessAccordingToRole("makePayout") ){this.setState({ showPayoutModal : true, payoutData : row })}}}
                        title={row["status"] }>
                         <i className="fa fa-refresh" aria-hidden="true" style={{color : (!getAccessAccordingToRole("makePayout") ? "gray" :"#0c6523"  )}} ></i>
                    </span> </span>);
                    } else if(row["status"] === "payout_processed"){
                return(<span 
                    style={{ paddingLeft: "15%"}}  
                    data-toggle="tooltip" 
                    data-placement="center" 
                    title={row["status"] }>
                    <img src={ approvedIcon } alt={row["status"]} style={{ height: "22px",width: "22px"}}/>
                </span> );
            } else if(
                row["status"] === "payout_initiated" || 
                row["status"] === "payout_queued" || 
                row["status"] === "payout_pending" || 
                row["status"] === "payout_processing"){
                return(<span 
                    style={{ paddingLeft: "15%"}}  
                    data-toggle="tooltip" 
                    data-placement="center" 
                    title={row["status"] }>
                    <img src={ hourglassIcon } alt={row["status"]} style={{ height: "22px",width: "22px"}}/>
                </span> );
            } else if(row["transaction_type"] === "b_out" && row["status"] === "approved"){
                return( <Fab
                    variant="extended"
                    size="small"
                    disabled={!getAccessAccordingToRole("makePayout")}
                    aria-label="PAYOUT"
                    onClick={( event )=> this.setState({ showPayoutModal : true, payoutData : row })}
                    style={{ textTransform: "none", background: (!getAccessAccordingToRole("makePayout") ? "gray" :"#0c6523"  ), color: "#ffffff", padding: "5px 10px", fontSize:"13px",height: 'fit-content' }}
                >
                   PAYOUT
            </Fab>); 
            }else if(row["transaction_type"] === "b_in" && row["status"].indexOf("validated") > -1){
                return( <Fab
                    variant="extended"
                    size="small"
                    disabled={!getAccessAccordingToRole("makePayout")}
                    aria-label="PAYOUT"
                    onClick={( event )=> this.setState({ showPayoutModal : true, payoutData : row })}
                    style={{ textTransform: "none", background: (!getAccessAccordingToRole("makePayout") ? "gray" :"#0c6523"  ), color: "#ffffff", padding: "5px 10px", fontSize:"13px",height: 'fit-content' }}
                >
                   PAYOUT
            </Fab>);
            }else if(row["status"] === "pending" || row["status"] === "pending_approved" || row["status"] === null ){
               return(
                   this.getActionAbleIcon( event , row )
                );
            }else if(row["status"] === "failed" ){
                return(<span 
                        style={{ paddingLeft: "15%"}}  
                        data-toggle="tooltip" 
                        data-placement="center" 
                        title={row["status"].toUpperCase() +(row["reason"] ? "\nReason : "+ row["reason"]: "")  }>
                            <img src={row["status"] === "failed" ?  failedIcon : "" } alt="failedIcon" 
                                style={{ height: "22px",width: "22px"}}/>
                    </span>)
            }
        }else  if( row["transaction_type"] === "b_out" && row["payment_mode"] !== "bijak") {
            
            if(  row["status"] === "transaction_failed" ){
                return(<span 
                    style={{ paddingLeft: "15%"}}  
                    data-toggle="tooltip" 
                    data-placement="center" 
                    title={row["status"] }>
                    <img src={ payment_failureIcon } alt={row["status"]} style={{ height: "22px",width: "22px"}}/>
                </span> );
               }else if(  row["status"] === "transaction_initiated" ){
            return(<span 
                style={{ paddingLeft: "15%"}}  
                data-toggle="tooltip" 
                data-placement="center" 
                title={row["status"] }>
                <img src={ payment_InitatedIcon } alt={row["status"]} style={{ height: "22px",width: "22px"}}/>
            </span> );
           }else if(  row["status"] === "payout_reversed" ||
                row["status"] === "payout_cancelled" || 
                row["status"] === "payout_rejected" ){
                    return( <span>
                        <span 
                        style={{paddingLeft: "6px"}}  
                        data-toggle="tooltip" 
                        data-placement="center" 
                        title={row["status"] }>
                        <img src={ cancelledIcon } alt={row["status"]} style={{ height: "20px",width: "20px"}}/>
                       
                    </span> 
                    {/* <span 
                        style={{ fontSize: "20px",paddingLeft: "25px", cursor:"pointer"}}  
                        data-toggle="tooltip" 
                        data-placement="center" 
                        onClick={( event )=> { if( getAccessAccordingToRole("makePayout") ){this.setState({ showPayoutModal : true, payoutData : row })}}}
                        title={row["status"] }>
                         <i className="fa fa-refresh" aria-hidden="true" style={{color : (!getAccessAccordingToRole("makePayout") ? "gray" :"#0c6523"  )}} ></i>
                    </span> */}
                     </span>);
                    } else if(row["status"] === "payout_processed"){
                return(<span 
                    style={{ paddingLeft: "15%"}}  
                    data-toggle="tooltip" 
                    data-placement="center" 
                    title={row["status"] }>
                    <img src={ approvedIcon } alt={row["status"]} style={{ height: "22px",width: "22px"}}/>
                </span> );
            } else if(
                row["status"] === "payout_initiated" || 
                row["status"] === "payout_queued" || 
                row["status"] === "payout_pending" || 
                row["status"] === "payout_processing"){
                return(<span 
                    style={{ paddingLeft: "15%"}}  
                    data-toggle="tooltip" 
                    data-placement="center" 
                    title={row["status"] }>
                    <img src={ hourglassIcon } alt={row["status"]} style={{ height: "22px",width: "22px"}}/>
                </span> );
            } else if(row["status"] === "failed" ){
                return(<span 
                        style={{ paddingLeft: "15%"}}  
                        data-toggle="tooltip" 
                        data-placement="center" 
                        title={row["status"].toUpperCase() +(row["reason"] ? "\nReason : "+ row["reason"]: "")  }>
                            <img src={row["status"] === "failed" ?  failedIcon : "" } alt="failedIcon" 
                                style={{ height: "22px",width: "22px"}}/>
                    </span>)
            }
        }else{
            return ( <AccountBalanceWalletSharpIcon style={{color:"gray", marginLeft:"15%"}}/>);
        }
      }

      getActionAbleIcon( event , row ){
        return(
        <span style={{ width: "40px", height: "20px", paddingLeft:"15%"}}>
        <IconButton
            style={{ padding: "4px"}}
            data-toggle="tooltip" data-placement="center" title={row["status"] === "pending" || row["status"] === "pending_approved" || row["status"] === null ? "pending_approved" : row["status"] }
            aria-label="more"
            aria-controls={"long-menu"+row["id"] }
            disabled={!getAccessAccordingToRole("makePayout")}
            aria-haspopup="true"
            onClick={this.handelStatusOptionClick.bind( event, row )}
        >
        <img src={row["status"] === "pending" || row["status"] === "pending_approved" || row["status"] === null ?  faqIconReddish : tickIcon } alt="statusIcon" 
            style={{ height: "22px",width: "22px"}}/>
        </IconButton>
      </span>)
      }

    getPaymentInOutInfo(type, key) {
  if(this.state.paymentMetaInfo){
        let arr = this.state.paymentMetaInfo
        for (let i = 0; i < arr.length; i++) {
            let obj = arr[i];
            if (type === obj['transaction_type']) {
                return Utils.formatNumberWithComma(obj[key]);
            }
        }
    }
        return "0";
    }

    checkIfOmittedStatusKeys(row) {
        let isValid = true;
        let statusKeysToOmit = ["failed", "rejected", "reversed", "cancelled", "transaction_initiated"]
        if (row && row["status"]) {
            for (let i in statusKeysToOmit) {
                if (row["status"].indexOf(statusKeysToOmit[i]) > -1) {
                    isValid =  false;
                    break;
                }
            }
        } 
        return isValid;
    }

    onUserInfoModalCancel(event) {
        this.setState({ showUserInfo : false,  isInfo: false });
        if(this.state.isLimitUpdate){
            this.props.OnPaymentUpdated();
        }
    }

    changeLimitSucces(event){
        if( event ){
            let obj = this.state.userInfoData;
            obj['bijak_credit_limit'] = event;
            this.setState({ userInfoData:obj, isLimitUpdate:true });
        }else{
            this.setState({ isLimitUpdate: true });
        }
    }

    handleUserInfoClose(event) {
        this.setState({ showUserInfo: false, isInfo: false });
    }

    onUserInfoClicked = ( info, type , event) => {
        let id = "";
        if( type === "supplier_name"){
            id = info["supplier_mobile"];
        }else{
            id = info["buyer_mobile"];
        }
        this.setState({ userId :id, showUserInfo : true, userInfoData : JSON.parse(JSON.stringify(info)), isInfo: true });
    }

    checkIfAccountInfoAvaialble( data ){
        if( 
            (data["transaction_type"] === "b_out" && data["payment_mode"] === "bijak") ||
            // (data["transaction_type"] === "b_out" && data["payment_mode"] === "bank") ||
            (data["transaction_type"] === "b_in" && data["payment_mode"] === "bank") 
            ){
        if(  data &&
             data["bank_details"] &&
             (data["status"] === "payout_processed" || 
             data["status"] === "transaction_initiated" || 
             data["status"] === "payout_initiated" || 
             data["status"] === "payout_queued" || 
             data["status"] === "payout_pending" || 
             data["status"] === "payout_processing") &&
             data["bank_details"] !== "-" && 
             data["bank_details"]["bank_account_number"] &&
             data["bank_details"]["bank_ifsc_code"] && 
             data["bank_details"]["bank_account_holder_name"]){
            return true;
        }else{
            return false;
        }
    }else{
        return false;
    }
      }

     formatDateAndTime = (dateval) => {
        var fdate = moment.utc(new Date(dateval)).format('DD-MMM-YYYY HH:mm A')
        // return <div style={{ width: "95px", display: "inline-block" }}> {fdate.split(" ")[0]}</div>
        return <div style={{ width: "95px", display: "inline-block" }}> {fdate.split(" ")[0] + " \n" + fdate.split(" ")[1] + " " + fdate.split(" ")[2]}</div>
    }

    // handelDownloadClicked = () => {
    //     Utils.downloadDataInCSV(this.state.allTransactionsData,"payments-detail")
    // }

    
    addKeysToSortList = (keys) => {
        if (this.state.sortingEnabled.indexOf(keys) > -1) {
            let srtkys = Object.assign({}, this.state.sortKeys);
            let srtKeyOrders = this.state.sortKeysOrder;
            if(srtKeyOrders.indexOf(keys) === -1){
                srtKeyOrders.splice(0, 0, keys);
            }else{
                srtKeyOrders.splice(srtKeyOrders.indexOf(keys), 1);
                srtKeyOrders.splice(0, 0, keys);
            }

            if (srtkys.hasOwnProperty(keys)) {
                if (srtkys[keys] === "asc") {
                    srtkys[keys] = "desc";
                } else {
                    srtkys[keys] = "asc";
                }
            } else {
                srtkys[keys] = "asc";
            }
            this.setState({ sortKeys: srtkys, sortKeysOrder : srtKeyOrders });
            // ?sortkey=id,fullname,rating&sortorder=asc,asc,asc&limit=2000&offset=0
            let params = {
                sortkey: [],
                sortorder: []
            }
            for( let i = 0 ; i < srtKeyOrders.length ; i++ ){
                params["sortkey"].push( srtKeyOrders[i] ) ;
                params["sortorder"].push(srtkys[srtKeyOrders[i]] );
            }
            params["sortkey"] = params["sortkey"].toString();
            params["sortorder"] = params["sortorder"].toString();
            let SortpropsData ={ "params" : params,"order": srtKeyOrders , sortKeys: srtkys};
            this.props.onSortingChanged(SortpropsData);
        }
    }

    onInfoClick = (info) => {
        this.setState({
            orderInfo: info, showOrderInfo: true
        })
    }

    onOrderInfoModalClose = () => {
        this.setState({
            orderInfo: undefined, showOrderInfo: false
        })
    }
    
    getGroupTransctionBackground( type ){
        if(type === "dashboard_credit"){
            return "rgb(73, 128, 234)";
        }else if(type === "direct_payment"){
            return "#06651f";
        }else if(type === "direct_payment_payout"){
            return "#06651f";
        }else if(type === "to_bijak"){
            return "#ed9549";
        }
    }

    onSelectAllClicked( event ){
        try{            
            let selectedPayoutIdsVal = Object.assign({}, this.state.selectedPayoutIds);
            if( this.state.allTransactionsData.length > 0 ){
                if( event.target.checked ){
                    for(let i =  0 ; i < this.state.allTransactionsData.length; i++){
                        if( i < this.state.maxPayoutLimit ){
                            let pyObj = this.state.allTransactionsData[i];
                            selectedPayoutIdsVal[ pyObj['id'] ] = pyObj;
                        }
                    }
                    this.setState({ selectedPayoutIds : selectedPayoutIdsVal, isSelectedAll : true});

                    if(this.state.allTransactionsData.length > this.state.maxPayoutLimit ){
                        let sweetAlrtData = this.state.sweetAlertData;
                                sweetAlrtData["type"] = "info";
                                sweetAlrtData["title"] = "Info";
                                sweetAlrtData["text"] = "Max "+ this.state.maxPayoutLimit+" payout is allowed at once.\nSo First "+ this.state.maxPayoutLimit+ " is selected" ;
                            this.setState({
                                showSweetAlert: true,
                                sweetAlertData: sweetAlrtData
                            });
                    }
                }else{
                    this.setState({ selectedPayoutIds : {}, isSelectedAll : false });
                }
            }
        }catch( e ){
            console.log( e );
        }
    }

    onRowSelected( event, row ){
        try{
            let selectedPayoutIdsVal = this.state.selectedPayoutIds;
            if( event.target.checked ){
                if(Object.keys( selectedPayoutIdsVal ).length < this.state.maxPayoutLimit ){
                    selectedPayoutIdsVal[ row['id'] ] = row;
                    this.setState({ selectedPayoutIds : selectedPayoutIdsVal});
                }else{
                    let sweetAlrtData = this.state.sweetAlertData;
                    sweetAlrtData["type"] = "info";
                    sweetAlrtData["title"] = "Info";
                    sweetAlrtData["text"] = "Max "+ this.state.maxPayoutLimit+" payout is allowed at once";
                    this.setState({ showSweetAlert: true, sweetAlertData: sweetAlrtData });
                }
            }else{
                if( selectedPayoutIdsVal.hasOwnProperty(row['id'])){
                    delete selectedPayoutIdsVal[row['id']];
                }
                let isAllSelected = this.state.isSelectedAll;
                if( Object.keys( selectedPayoutIdsVal ).length === 0 ){
                    isAllSelected = false;
                }
                this.setState({ selectedPayoutIds : selectedPayoutIdsVal , isSelectedAll : isAllSelected });
            }
        }catch( e ){
            console.log( e );
        }
    }

    handelSweetAlertClosed() {
        this.setState({ showSweetAlert: false }, () => {
            // if (this.state.sweetAlertData.type !== "error") {
              
            // }
        });
    }

    onBulkPayoutBtnClicked( event ){
        event.preventDefault();
        if( Object.keys(this.state.selectedPayoutIds).length > 0 ){
            this.setState({ showBulkPayoutModal : true });
        }else{
            let sweetAlrtData = this.state.sweetAlertData;
            sweetAlrtData["type"] = "warning";
            sweetAlrtData["title"] = "Info";
            sweetAlrtData["text"] = "Please select atleast one transaction to make the payout";
            this.setState({ showSweetAlert: true, sweetAlertData: sweetAlrtData });
        }
    }

    render() {
        const { classes , showLoader} = this.props;
        const { allTransactionsData,showEditTransactionModal, showSweetAlert, sweetAlertData,
                showTransactionIDInfoDialog,transactionIDInfoData ,showDownloadModal, isSelectedAll,
                isTableDataLoading, tableHeadDataKeys,sortKeys,sortingEnabled, selectedPayoutIds } = this.state;
        const leftAlignedIndexs = [0, 2,3,4,5];
        const rightAlignedIndexs = [8];
        console.log(allTransactionsData)
        return (
            
            <div>
                <MuiThemeProvider theme={theme}>
                    {!showLoader && <div>
                        {allTransactionsData ? <div style={{ marginTop : 14,maxHeight: "65vh", overflowY: "scroll" }}>
                            {allTransactionsData && allTransactionsData.length > 0 &&
                                <Table  className='table-body' stickyHeader aria-label="sticky table">
                                    <TableHead style={{ borderLeft: "4px solid #05073a", borderRight: "4px solid #05073a" }}>
                                        <TableRow  style={{borderBottom: "2px solid #858792"}} >
                                        <TableCell style={{ width :'150px', paddingLeft: '4px'}}>
                                            <Checkbox
                                                checked={isSelectedAll}
                                                onChange={( event )=>this.onSelectAllClicked(event)}
                                                inputProps={{ 'aria-label': 'select all desserts' }}
                                            />
                                            Select All
                                            </TableCell>
                                            {this.state.tableHeadData.map((option, i) => (
                                                <TableCell 
                                                key={option} 
                                                onClick={() => this.addKeysToSortList(tableHeadDataKeys[i])}
                                                className={classes.tableCell} 
                                                style={{ width:( option === "Lnked Order Id" ||option === "APP ID" ? "70px":""),
                                                 minWidth: ( option === "Lnked Order Id" ||option === "APP ID"? "70px":(option === 'status' || option === ''  ?'100px':"120px")), 
                                                 cursor: sortingEnabled.indexOf(tableHeadDataKeys[i]) > -1 ? "pointer" : "unset",
                                                textAlign: leftAlignedIndexs.indexOf(i) > -1 ? "left" : rightAlignedIndexs.indexOf(i) > -1 ? "right" : ""
                                            }}>{option}
                                            {sortingEnabled.indexOf(tableHeadDataKeys[i]) > -1 &&
                                                        <i className={Utils.getArrowIconType(sortKeys, tableHeadDataKeys[i])} aria-hidden="true"  style={{ color : sortKeys.hasOwnProperty(tableHeadDataKeys[i]) ? '#e72e89':'unset'}}></i>}
                                            </TableCell>
                                            ))}
                                             <TableCell className={classes.tableCell} style={{ paddingLeft:  '' }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { !isTableDataLoading &&
                                          (
                                            // rowsPerPage > 0
                                            // ? allTransactionsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            // : allTransactionsData
                                            allTransactionsData
                                          ).map((row, i) => {
                                            return (
                                             
                                                //tableHeadData:["id","Supplier Name","Supplier Bussiness Name","Created Time","Amount","Payment mode","Invoice images"],
                                                <TableRow key={'table_' + i} id={"payment_"+row.id} style={{ background: (i % 2 === 0 ? "#e5e8ec" : "#fff"), borderLeft: `4px solid ${this.getTransactionTypeColor(row.transaction_type)}`, borderRight: `4px solid ${this.getTransactionTypeColor(row.transaction_type)}` }}>
                                                    <TableCell style={{ width :'150px', paddingLeft: '4px'}}>
                                                        <Checkbox
                                                        checked={selectedPayoutIds[row.id] ? true : false}
                                                        onChange={(event)=>this.onRowSelected( event,  row)}
                                                        inputProps={{ 'aria-labelledby': "labelId" }}
                                                        />
                                                        { !row.active && 
                                                                   <i className="fa fa-circle"
                                                                      data-toggle="tooltip" title={row.active ? "Enabled" : "Disabled"} 
                                                                      style={{ color: "#776969", fontSize:"17px" , cursor:"pointer", marginLeft: "-12%"}} 
                                                                      aria-hidden="true"></i>} &nbsp;
                                                                    
                                                        {/* {row.id ? row.id : "-"} */}
                                                        <span 
                                                      onClick={( event )=> this.setState({ showTransactionIDInfoDialog : true, transactionIDInfoData : row })}
                                                      className=" name-span" style={{ cursor: "pointer"}} > 
                                                       {row.id ? row.id : "-"}
                                                        </span>
                                                       { !row.is_added_by_platform && <i style ={{fontSize:"24px",marginLeft:"4px",color:"#50aa35"}} className="fa fa-mobile" aria-hidden="true"></i>}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" className={classes.tableCell}  style={{textAlign: "left"}} >
                                                   <div>
                                                        {this.checkIfAccountInfoAvaialble( row ) ? <i className="fa fa-info-circle" aria-hidden="true" 
                                                             onClick={(event )=> this.setState({ showTransactionInfoDialog : true , transactionInfoData : row  })}
                                                                            style={{ color: "#e72e89",marginLeft:"2px", marginRight:"8px", cursor: "pointer", height: "18px", fontSize:"22px" }} /> : ""}
                                                    {this.getStatusOption(this, row)}
                                                    </div>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell} style={{textAlign: "left"}}>
                                                        <span
                                                            data-toggle="tooltip" data-placement="center" title="info"
                                                            onClick={()=> { if(row.linked_order_id){this.onInfoClick( row)}}}
                                                            style={{ color: row.linked_order_id ? "#3f51b5" : "", borderBottom: row.linked_order_id ? "2px solid #3f51b5" : "", padding: "0px 2px", cursor:  row.linked_order_id ?"pointer": "" }}>
                                                            {row.linked_order_id ? row.linked_order_id : "-"} 
                                                    </span>
                                                        
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell} style={{textAlign: "left"}}>
                                                        {row.pay_id ? row.pay_id : "-"}
                                                    </TableCell>
                                                   
                                                    <TableCell className={classes.tableCell} style={{textAlign: "left"}}>
                                                        <div className="text-ellpses">
                                                        <div className=" name-span" style={{ display: "grid", textAlign: "left", textTransform: "capitalize" , cursor: "pointer"}}
                                                            onClick={this.onUserInfoClicked.bind(this, row, "buyer_name")}>
                                                            <span className="text-ellpses">{row.buyer_fullname ? row.buyer_fullname : ""} </span>
                                                            <span className="text-ellpses" style={{ fontSize: "12px" }}>{row.buyer_business_name ? row.buyer_business_name : ""} </span>
                                                        </div>
                                                        </div>
                                                    </TableCell>
                                                    
                                                    <TableCell component="th" scope="row" className={classes.tableCell} style={{textAlign: "left", cursor: "pointer"}}>
                                                        <div className=" name-span" style={{ display: "grid", textAlign: "left", textTransform: "capitalize" , cursor: "pointer"}}
                                                            onClick={this.onUserInfoClicked.bind(this, row, "supplier_name")}>
                                                        <span className="text-ellpses">{row.supplier_fullname ? row.supplier_fullname : ""} </span>
                                                        <span className="text-ellpses" style={{ fontSize: "12px" }}>{row.supplier_business_name ? row.supplier_business_name : ""} </span>
                                                    </div>
                                                    </TableCell>

                                                    <TableCell className={classes.tableCell}>
                                                        <div className="text-ellpses">
                                                            {/* {row.createdtime ? Utils.formatDateData(row.createdtime.split("T")[0]) : "-"} */}
                                                            {row.createdtime ? this.formatDateAndTime(row.createdtime): "-"}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <div style={{display: "grid"}}>
                                                            <span>
                                                                {row.razx_skipped ? <img width="30px" height="30px" src={razorpay_skipped} alt=""/> : ""}    {row.payment_mode ? row.payment_mode : "-"} / <span style={{fontWeight: "bold"}}>{row.transaction_type ? row.transaction_type : "-"} </span>
                                                            </span>
                                                            {(row.group_type && row.group_type !== "other_payments") && <span style={{ marginLeft: "10px" }}>
                                                                <i className="fa fa-caret-left translabelIcon" style={{ marginRight: "-0.75px", color: this.getGroupTransctionBackground( row.group_type ) ,display: "contents"}} aria-hidden="true"></i>
                                                                <span
                                                                    // onClick={() => this.setState({ selectedTab: "all" })}
                                                                    className=" translabeltag  labeltag"
                                                                    style={{ cursor: "pointer", color: "#fff", background: this.getGroupTransctionBackground( row.group_type ) ,paddingTop: "2px", paddingBottom: "4px",fontSize: "12px"}}>
                                                                    {row.group_type}
                                                                </span>
                                                            </span>}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell} style={{ color: this.getTransactionTypeColor(row.transaction_type) , textAlign: "right"}}>
                                                    ₹ {row.amount || row.amount === 0 ? Utils.formatNumberWithComma(row.amount) : "-"}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>}
                                {isTableDataLoading && <div><Loader/> </div>}
                            {allTransactionsData && allTransactionsData.length > 0 ? "" :
                                <div className={classes.defaultTemplate}
                                    style={{
                                        marginTop: "10%",
                                        textAlign: "center",
                                        fontSize: "24px"
                                    }}>
                                    {<span style={{ display:"grid"}}>
                                        <i className={"fa fa-frown-o"} style={{fontSize: "44px"}} aria-hidden="true"></i>
                                        {"No data available"}
                                        </span>}
                                </div>}
                        </div> : <Loader />}
                        {/* {allTransactionsData && allTransactionsData.length > 0 &&
                                <Table>  
                                    <TableFooter style={{ borderTop: "2px solid #858792" }}>
                                        <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[25, 50, 100]}
                                            colSpan={1}
                                            count={ -1 }
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                            }}
                                            onChangePage={this.handleChangePage.bind(this)}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                                        />
                                        </TableRow>
                                    </TableFooter>
                                </Table>} */}
                    </div>}
                                

                {this.state.showImageInvoiceModal &&
                    <TransactionInvoiceModal
                        openModal={this.state.showImageInvoiceModal}
                        onInvoiceModalClose={() => { this.setState({ showImageInvoiceModal: false, invoiceModalData: [] }) }}
                        invoiceUrlData={this.state.invoiceModalData} />}

                {showEditTransactionModal && this.state.editableData &&
                    <EditTransactionModal
                        open={showEditTransactionModal}
                        editableTransactionData={this.state.editableData}
                        onTransactionUpdated={(event) => this.setState({ showEditTransactionModal: false, isDataUpdated: true }, function () {
                            this.props.OnPaymentUpdated();
                        })}
                        onEditModalCancel={(event) => this.setState({ editableData : undefined, showEditTransactionModal: false })}
                    />}


                    {/* for status change and reason add */}
                    {this.state.showStatusChangeModal &&
                    <SelectTransactionTypeModal
                        openModal={this.state.showStatusChangeModal}
                        rowDataObj={ this.state.statusUpdateObj }
                        onUpdateSuccessFull={ (event) => {this.setState({ showStatusChangeModal: false, statusUpdateObj: {} }); this.handelRefreshModal() }}
                        onStatusUpdateObjClose={() => { this.setState({ showStatusChangeModal: false, statusUpdateObj: {} }) }}
                         />}

                    {this.state.showPayoutModal && this.state.payoutData && 
                        <PayoutModal
                            openPayoutModal={this.state.showPayoutModal}
                            onPayoutModalClose={() => { this.setState({ showPayoutModal: false, payoutData: undefined }) }}
                            onPayoutSuccessfull={(event) => this.setState({ showPayoutModal: false, payoutData: undefined, allTransactionsData : undefined }, function () {
                                this.props.OnPaymentUpdated();
                            })}
                            payoutData={this.state.payoutData} />}

                    {this.state.showUserInfo ? 
                        <BusinessInfoDialog 
                            openModal={this.state.showUserInfo}
                            onEditModalClosed={this.handleUserInfoClose.bind(this)}
                            data={this.state.userInfoData}
                            isInfo={true}
                            // userId={ this.state.userInfoData["supplier_mobile"]}
                            userId={ this.state.userId}
                            onLimitUpdate= {this.changeLimitSucces.bind(this)}
                            onEditModalCancel={this.onUserInfoModalCancel.bind(this)}
                            currentRoute={"todays-payment"} /> : ""}

                    {this.state.showTransactionInfoDialog && 
                        <TransactionIfoModal
                            open={ this.state.showTransactionInfoDialog }
                            onTransactionInfoModalClose = {()=> this.setState({ showTransactionInfoDialog : false , transactionInfoData : undefined  })}
                            transactionInfoData={this.state.transactionInfoData}
                        />}

                    {allTransactionsData && allTransactionsData.length > 0 &&
                    <div className="updateBtndef">
                        {getAccessAccordingToRole("addPayment") &&
                            <div
                                className="updateBtnFixed"
                                style={{ display: 'flex', background:"#e72e89" }}
                                onClick={(event) => this.onBulkPayoutBtnClicked( event )}>
                                <i className="fa fa-plus-circle add-icon" aria-hidden="true"></i>
                                <p>Proceed Bulk Payout</p></div>}
                    </div>}

                    {showTransactionIDInfoDialog && 
                        <TransactionIdInfoModal
                            open={showTransactionIDInfoDialog }
                            onTransactionIDInfoModalClose = {()=> this.setState({ showTransactionIDInfoDialog : false , transactionIDInfoData : undefined  })}
                            transactionInfoData={transactionIDInfoData}
                        />}

                                            {/* DownloadModalPayment */}
                    {showDownloadModal &&
                        <DownloadModalPayment
                            open={showDownloadModal}
                            downloadFilename={"Payment_details"}
                            onDownLoadModalCancelled={() => this.setState({ showDownloadModal: false })}
                            allTransactionsData={ allTransactionsData } />}

                    {this.state.showOrderInfo &&
                        <OrderInfoDialog
                            LoadDataFromApi={true}
                            openModal={this.state.showOrderInfo}
                            data={this.state.orderInfo}
                            onEditModalCancel={this.onOrderInfoModalClose.bind(this)} />}

                    {showSweetAlert &&
                        <SweetAlertPage
                            show={true}
                            type={sweetAlertData.type}
                            title={sweetAlertData.title}
                            text={sweetAlertData.text}
                            sweetAlertClose={() => this.handelSweetAlertClosed()}
                        />}

                        {this.state.showBulkPayoutModal && 
                        <BulkPayoutModal
                            open={this.state.showBulkPayoutModal}
                            onBulkPayOutClosed = {()=>this.setState({showBulkPayoutModal : false })}
                            onBulkPayoutSuccess= {()=>this.setState({showBulkPayoutModal : false, selectedPayoutIds:{}, isAllSelected : false},()=>{
                                this.props.OnPaymentUpdated();
                            })}
                            selectedPayoutIds = { selectedPayoutIds }
                        />
                        }

                        </MuiThemeProvider>
            </div>);

    }
}

BulkPaymentPaymentDetailsTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BulkPaymentPaymentDetailsTable);