import React from 'react';
import PropTypes from 'prop-types';
import '../../assets/css/app.css';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import UserTable from '../common/UserTable';
import InfoDialog from '../common/InfoDialog';
// import sampleFile from '../sampleDownloadFiles/bulk-add-supplier-data-sample.csv';
import FileUploader from '../common/fileUploader';
import userListService from './../../app/userListService/userListService';
import { getAccessAccordingToRole } from '../../config/appConfig';
import Utils from '../../app/common/utils';
import SweetAlertPage from '../../app/common/SweetAlertPage';

const styles = theme => ({
    root: {
        width: '100%',
        // marginTop: '30px',
        // height: '88vh',
        overflow: 'auto',
        fontFamily: 'Lato !important',
        maxWidth: '1200px'
    },
    card: {
        maxWidth: '100%',
        marginTop: '15px',
        height: '97%',
    },
    heading: {
        marginTop: '15px',
        fontSize: '20px',
        alignTtems: 'center',
        display: '-webkit-inline-box'
    }
});

class SupplierContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            showAddModal: false,
            dataList: [],
            showUploader: false,
            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },
            refreshData: false 
        };
    }

    handleClose(event) {
        this.setState({ open: false, showAddModal: false, refreshData: true }, () => {
            this.setState({ refreshData: false });
        });
    }
    onModalCancel(event) {
        this.setState({ open: false, showAddModal: false, showUploader: false });
    }

    handleClickOpen(event) {
        this.setState({ showAddModal: true, open: true });
    }

    async handleFileUploader(event) {
        try {
            let resp = await userListService.uploadData(event);
            if (resp.data.status === 1 && resp.data.result) {
                let sweetAlrtData = this.state.sweetAlertData;
                sweetAlrtData["type"] = "success";
                sweetAlrtData["title"] = "Success";
                sweetAlrtData["text"] = "Data successfully uploaded";
                this.setState({
                    showSweetAlert: true,
                    sweetAlertData: sweetAlrtData
                });
            }

        } catch (err) {
            console.error(err)
            // this.handelGetData();
            this.setState({ refreshData: true }, () => {
                this.setState({ refreshData: false });
            });
        }
    }


    handleUploaderClick(event) {
        this.setState({ showUploader: true });
    }

    handelDownloadClicked = () => {
        let fHeader = {
            "id": "LA ID",
            "fullname": "LA Name",
            "business_name": "Firm Name",
            "default_commodity": "Commodity",
            "mobile": "Phone",
            "locality": "Locality",
            "bijak_verified": "KYC",
            "createdtime": "Date",
            "state": "State",
            "district": "District"
        }
        // map the download from the user table not from here 
        Utils.downloadFormattedDataInCSV(this.state.dataList, "LA Data (supplier)", fHeader)
    }

    handelSweetAlertClosed() {
        this.setState({ showSweetAlert: false }, () => {
            if (this.state.sweetAlertData.type !== "error") {
                // this.handelGetData(); //@To Do
                this.setState({ refreshData: true }, () => {
                    this.setState({ refreshData: false });
                });
            }
        });
    }

    render() {
        const { classes } = this.props;
        const { showSweetAlert, sweetAlertData, refreshData } = this.state;
        return (
            <div className={classes.root}>
                <Card className={classes.card} >
                    {!refreshData && <UserTable
                        role="la"
                        userRole={"la"}
                        downloadAbleFileName="supplier_list_data" />}

                    {getAccessAccordingToRole("addUser") && <div className="updateBtndef">
                        <div className="updateBtnFixed" style={{ display: 'flex', right: "-5px" }} onClick={this.handleClickOpen.bind(this)}>
                            <i className="fa fa-plus-circle add-icon" aria-hidden="true"></i>
                            <p style={{
                                fontSize: "14px",
                                fontFamily: "lato",
                                fontWeight: 600
                            }}>ADD SUPPLIER</p></div>
                    </div>}

                    {/* <div className="fixedLeftBtnContainer">
                    <a download={"bulk-add-supplier-data-sample.csv"} href={sampleFile} title="sampleFile">
                        <div className="fixedLeftBtn" style={{ display: 'flex' }}
                            // onClick={() => { window.open(sampleFile, 'Download'); }}
                            >
                            <i className="fa fa-cloud-download add-icon" aria-hidden="true"></i>
                            <p style={{
                                fontSize: "14px",
                                fontFamily: "lato",
                                fontWeight: 600
                            }}>Download sample</p></div>
                            </a>
                    </div>

                     <div className="fixedLeftBtnContainer">
                        <div className="fixedLeftBtn" style={{ display: 'flex', left:"16%", background:"#4da443" }}
                            onClick={this.handleUploaderClick.bind(this)}
                            >
                            <i className="fa fa-cloud-upload add-icon" aria-hidden="true"></i>
                            <p style={{
                                fontSize: "14px",
                                fontFamily: "lato",
                                fontWeight: 600
                            }}>Upload file</p></div>
                    </div> */}


                    {/* {getAccessAccordingToRole("allowDownload") && <div className="updateBtndef" style={{ right: "160px" }} data-toggle="tooltip" data-html="true" title="Download">
                        <div className="updateBtnFixed" style={{ display: 'flex', background: "#e72e89", borderRadius: "6px" }} onClick={this.handelDownloadClicked.bind(this)}>
                            <i className="fa fa-cloud-download add-icon" style={{ marginRight: 0, color: "white" }} aria-hidden="true"></i>
                        </div>
                    </div>} */}

                </Card>

                {this.state.showAddModal ?
                    <InfoDialog openModal={this.state.open}
                        onEditModalClosed={this.handleClose.bind(this)}
                        role="la"
                        onEditModalCancel={this.onModalCancel.bind(this)} /> : ""}

                {this.state.showUploader ?
                    <FileUploader openModal={this.state.showUploader}
                        onEditModalClosed={this.handleFileUploader.bind(this)}
                        //  commodityList={ this.state.commodityList}
                        onEditModalCancel={this.onModalCancel.bind(this)}
                    />
                    : ""}
                    {showSweetAlert &&
                    <SweetAlertPage
                        show={true}
                        type={sweetAlertData.type}
                        title={sweetAlertData.title}
                        text={sweetAlertData.text}
                        sweetAlertClose={() => this.handelSweetAlertClosed()}
                    />}
            </div>
        );
    }
}

SupplierContainer.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(SupplierContainer);