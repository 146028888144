import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import userListService from '../../app/userListService/userListService';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import PersonIcon from '@material-ui/icons/Person';
import ConfirmDialog from '../../app/common/ConfirmDialog';
import Tooltip from '@material-ui/core/Tooltip';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import UserDialog from './UserInfo';
import StarIcon from '@material-ui/icons/Star';
import OrderTable from './OrderTable';
// import HowToRegIcon from '@material-ui/icons/HowToReg';
import Utils from '../../app/common/utils';
import UserFilterDataView from './UserFilterDataView';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Loader from './Loader';
// import { Auth } from 'aws-amplify';
import Auth from '@aws-amplify/auth';
import SweetAlertPage from '../../app/common/SweetAlertPage';
// 
import ViewIbImageModal from '../userData/ViewIbImageModal';


const theme = createMuiTheme({
    overrides: {
        MuiTableCell: {
            head: {
                color: '#2e3247',
                fontWeight: 600,
                fontSize: '12px !important',
                fontFamily: 'lato !important',
                textTransform: 'uppercase',
                lineHeight: "1em"

            },
            body: {
                color: 'rgba(0, 0, 0, 0.87)',
                fontWeight: 500,
                fontSize: '14px !important',
                fontFamily: 'lato !important',
            }
        },
        MuiTablePagination: {
            toolbar: {
                paddingRight: '250px'
            }
        },
    }
});

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        verticalAlign: "middle",
        marginRight: theme.spacing.unit,
        fontSize: 10,
    },
    tableCell: {
        paddingLeft: '4px',
        paddingRight: '4px',
        textAlign: 'center',
        maxWidth: '180px',
        padding: '12px',
        maxHeight: '40px',
        whiteSpace: "unset",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    titleText: { width: '50%', textAlign: 'left', paddingLeft: '15px', paddingTop: '7px', fontFamily: 'lato !important', },
    defaultTemplate: { height: '30vh', paddingTop: '10vh', },
    defaultSpan: { display: 'grid', fontSize: '25px' },
    defaultIcon: { fontSize: '65px', color: "#384952" },
    editIcon: { fontSize: '20px', color: "#1e459c", paddingLeft: 3, cursor: 'pointer', marginRight: '2px', float: 'left' },
    infoIcon: { color: '#d46262', fontSize: '18px', cursor: 'pointer' },
    cellDiv: {
        maxWidth: '180px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    root: {
        width: '100%',
        minHeight: '80vh'
    },
    lightTooltip: {
        fontSize: '18px',
        maxWidth: '800px',
    },
    info: {
        fontSize: '18px',
        marginRight: '8px',
        color: '#000000',
        cursor: 'pointer',
        float: 'right'
    },
    name: {
        fontWeight: '600',
        fontSize: '15px',
        color: '#242529'
    },
    container: {
        maxHeight: 440,
    },
    commodityDataClass: {
        display: "-webkit-box",
        "-webkit-line-clamp": "3",
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        fontSize: "12px",
        lineHeight: "unset",
        // fontWeight: "bold"
    },
    commodityitem: {
        fontSize: "11px",
    },
    roleCss: {
        height: "28px",
        width: "28px",
        display: "table-cell",
        textAlign: "center",
        color: "#fff",
        verticalAlign: "middle",
        borderRadius: "50%",
        background: "yellow"
    },
    elipseClass:{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    }

});

const colorArray = [
    '#f32379', '#f90496', '#476096', '#f8ba03', '#ba53c0',
    '#2b439a', '#db2929', '#a3b419', '#ffe912', '#d96f06',
    '#437800', '#c92828', '#223999'
]
class UserListTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userRole: this.props.userRole || "",
            tableHeadData: ["id", "fullname(business name)", "mobile", "order", "payment", "rating", "locality/district", "commodity", "role", "status"],
            tableHeadDataKeys: ["id", "fullname", "mobile", "ordercount", "paymentcount", "rating", "", "default_commodity", "role", "profile_completed"],
            tableBodyData: [],
            rawTableBodyData: [],
            searchedText: "",
            editableData: {},
            showServerDialog: false,
            showOptionModal: false,
            anchorEl: null,
            showUserModal: false,
            open: false,
            userData: {},
            userId: null,
            payload: null,
            showOrderModal: false,
            isInfo: false,
            stateList: this.getStateData(),


            rowsPerPage: 50,
            page: 0,
            isLimitUpdate: false,


            totalDataCount: 0,
            isTableDataLoading: false,
            subId: "",

            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },

            filterParams: {},

            //sorting Enabled
            sortKeys: {},
            sortKeysOrder: [],
            sortParams: {},
            sortingEnabled: ["id", "fullname", "rating", "ordercount", "paymentcount"],
            params: {
                limit: 1000, // total amount of data 
                offset: 0 // data from which data needs to be get
            },
            // commodityList:["dd"],
            imageModalData: [],
            showIbImageModal: false,

        }
    }


    componentDidMount() {
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => this.setState({ subId: user.attributes.sub }))
            .catch(err => console.log(err));

        this.handelFilter({});

    }

    getStateData() {
        let data = Utils.getStateData();
        var optionsData = [];
        if (data) {
            for (var i = 0; i < data.length; i++) {
                optionsData.push({ label: data[i].toLowerCase(), value: data[i].toLowerCase() });
            }
        }
        return optionsData;
    }

    async handelFilter(data) {

        this.setState({ isTableDataLoading: true, filterParams: data });
        let rows = this.state.tableBodyData;
        let respData = {};
        // data["limit"] = 2000;
        // data["offset"] = 0;
        data["limit"] = this.state.params["limit"];
        data["offset"] = this.state.params["offset"];
        if (this.props.userRole !== "all") {
            data["role"] = this.props.userRole;
        }

        if (Object.keys(this.state.sortParams).length > 0 && this.state.sortParams["sortkey"] !== "") {
            data["sortkey"] = this.state.sortParams["sortkey"];
            data["sortorder"] = this.state.sortParams["sortorder"];
        } else {
            delete data["sortkey"];
            delete data["sortorder"];
        }

        let resp = await userListService.serchUser(data);
        // console.log(resp.data);
        if (resp.data.status === 1 && resp.data.result) {
            respData = resp.data.result;
            rows = rows.concat(resp.data.result.data);
        } else {
            // alert(resp && resp.data && resp.data.message ? resp.data.message : "Oops an error occured while getting the data");
            let sweetAlrtData = this.state.sweetAlertData;
            sweetAlrtData["type"] = "error";
            sweetAlrtData["title"] = "Error";
            sweetAlrtData["text"] = resp && resp.data && resp.data.message ? resp.data.message : "Oops an error occured while getting the data";
            this.setState({
                showSweetAlert: true,
                sweetAlertData: sweetAlrtData
            });
        }
        this.setState({
            tableBodyData: rows,
            totalDataCount: respData.totalCount && respData.totalCount[0] && respData.totalCount[0]["count"] ? parseInt(respData.totalCount[0]["count"], 10) : 0,
            // page: 0,
            isTableDataLoading: false
        });
    }


    getInfoSTring(obj) {
        return obj.locality ? obj.locality : "-/" + obj.district ? obj.district : "-";
    }

    handleClose(event) {
        this.setState({ open: false, showUserModal: false, showOrderModal: false, isInfo: false }, () =>
            this.resetFilterAndParamsData()
        );
    }

    resetFilterAndParamsData() {
        this.setState({
            tableBodyData: [],
            showLoader: true,
            filterParams: {},
            page: 0,
            sortParams: {},
            sortKeys: {},
            sortKeysOrder: [],
            params: { limit: 1000, offset: 0 },
        }, () =>
            this.handelFilter({})
        );
    }

    onHeaderFilterChanged(filterParams) {
        this.setState({
            tableBodyData: [],
            showLoader: true,
            params: { limit: 1000, offset: 0 },
        }, () =>
            this.handelFilter(filterParams)
        );
    }

    onModalCancel(event) {
        this.setState({ open: false, showUserModal: false, showOrderModal: false, isInfo: false });
        if (this.state.isLimitUpdate) {
            this.setState({
                tableBodyData: [],
                showLoader: true,
                params: { limit: 1000, offset: 0 },
            }, () =>
                this.handelFilter(this.state.filterParams))

        }

    }


    handelConfirmUpdate = async () => {

        try {
            let resp = await userListService.addUserData(this.state.subId, this.state.userId, this.state.payload);
            this.setState({ showConfirmDialog: false, alertData: {} });
            let sweetAlrtData = this.state.sweetAlertData;
            if (resp.data.status === 1) {
                sweetAlrtData["type"] = "success";
                sweetAlrtData["title"] = "Success";
                sweetAlrtData["text"] = "Successfully submitted";
            } else {
                sweetAlrtData["type"] = "error";
                sweetAlrtData["title"] = "Error";
                sweetAlrtData["text"] = resp && resp.data && resp.data.message ? resp.data.message : "Oops there was an error, while adding";
            }
            this.setState({
                showSweetAlert: true,
                sweetAlertData: sweetAlrtData
            });

        } catch (err) {
            // console.log(err);
        }
    }

    handelCancelUpdate = () => {
        this.setState({ showConfirmDialog: false, alertData: {} });
    }

    getRole(row) {

        let role = "NA";
        let isGlobal = false;

        if (row.role === "ca") {
            role = "Buyer";
        } else if (row.role === 'la') {
            role = "Supplier";
        } else if (row.role === 'broker') {
            role = "Broker";
        } else {
            role = "NA";
        }

        if (row.hasOwnProperty("global_ca") && row.global_ca) {
            isGlobal = true;
        }
        if (row.hasOwnProperty("global_la") && row.global_la) {
            isGlobal = true;
        }

        return (<span style={{
            color: "white",
            background: this.getBackgroundColor(row),
            padding: "4px 12px",
            borderRadius: "13px"
        }}> {role} {isGlobal ? <i className="fa fa-globe" aria-hidden="true"></i> : ""}</span>)
    }
    getBackgroundColor(obj) {
        if (obj.role === "ca") {
            return "#f94141";
        } else if (obj.role === 'la') {
            return "#00a700";
        } else if (obj.role === 'broker') {
            return "#7070fd";
        } else {
            return "#757575";
        }
    }

    getOrderNoBackgroundColor(obj) {
        if (obj.ordercount === "0" || obj.paymentcount === "0") {
            return "#757575";
        } else {
            return "#377c3b";
        }
    }

    onInfoClick = (info, event) => {
        this.setState({ showUserModal: true, open: true, userData: JSON.parse(JSON.stringify(info)), isInfo: true });
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
        if (this.state.tableBodyData.length === (newPage * this.state.rowsPerPage)) {
            this.resetOffsetAndGetData();
        }
    };

    resetOffsetAndGetData() {
        let paramsval = this.state.params;
        paramsval["offset"] = paramsval["offset"] + 1000;
        this.setState({ params: paramsval, isTableDataLoading: true }, function () {
            this.handelFilter(this.state.filterParams);
        })
    }

    handleChangeRowsPerPage = event => {
        this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
    };

    getNameStr(row) {
        return row.fullname + "(" + row.business_name + ")"
    }

    handelDownloadClicked = () => {
        Utils.downloadDataInCSV(this.state.tableBodyData, this.props.downloadAbleFileName)
    }

    getCommmodityList2(commoditCellList) {
        var formatedCommdityText = "";
        if (commoditCellList) {
            for (var i = 0; i < commoditCellList.length; i++) {
                if (i === 0) {
                    formatedCommdityText = commoditCellList[i].charAt(0).toUpperCase() + commoditCellList[i].slice(1);
                } else {
                    formatedCommdityText = formatedCommdityText + ", " + commoditCellList[i].charAt(0).toUpperCase() + commoditCellList[i].slice(1);
                }
            }
        }
        return formatedCommdityText;
    }

    getCommmodityList(classes, commoditCellList, isTooltip) {
        if (commoditCellList && typeof (commoditCellList) === "object") {
            return (
                <p className={classes.commodityDataClass} style={{ fontSize: isTooltip ? "15px" : "12px" }}>
                    {commoditCellList.map((commodity, i) =>
                        (commodity && commodity !== null && <span key={i + "_commodity"} style={{ fontSize: isTooltip ? "15px" : "12px", fontFamily: "lato" }}>
                            <strong style={{ fontSize: isTooltip ? "14px" : "13px", fontFamily: "lato", color: isTooltip ? "#ff" : (colorArray[i < colorArray.length ? i : i % colorArray.length]) }}>
                                {commodity.charAt(0).toUpperCase()}</strong>{commodity.slice(1) + (i !== commoditCellList.length - 1 ? ", " : "")}
                        </span>)
                    )}
                </p>
            )
        } else {
            return ""
        }
    }

    changeLimitSucces(event) {
        // alert(event);
        if (event) {
            let obj = this.state.userData;
            obj['bijak_credit_limit'] = event;
            this.setState({ userData: obj, isLimitUpdate: true });
        } else {
            this.setState({ isLimitUpdate: true });
        }
    }

    handelSweetAlertClosed() {
        this.setState({ showSweetAlert: false }, () =>
            this.resetFilterAndParamsData()
        )
    }

    addKeysToSortList = (keys) => {
        if (this.state.sortingEnabled.indexOf(keys) > -1) {
            let srtkys = Object.assign({}, this.state.sortKeys);
            let srtKeyOrders = this.state.sortKeysOrder;
            if (srtKeyOrders.indexOf(keys) === -1) {
                srtKeyOrders.splice(0, 0, keys);
            } else {
                srtKeyOrders.splice(srtKeyOrders.indexOf(keys), 1);
                srtKeyOrders.splice(0, 0, keys);
            }

            if (srtkys.hasOwnProperty(keys)) {
                if (srtkys[keys] === "asc") {
                    srtkys[keys] = "desc";
                } else {
                    srtkys[keys] = "asc";
                }
            } else {
                srtkys[keys] = "asc";
            }
            this.setState({ sortKeys: srtkys, sortKeysOrder: srtKeyOrders });
            // ?sortkey=id,fullname,rating&sortorder=asc,asc,asc&limit=2000&offset=0
            let params = {
                sortkey: [],
                sortorder: []
            }
            for (let i = 0; i < srtKeyOrders.length; i++) {
                params["sortkey"].push(srtKeyOrders[i]);
                params["sortorder"].push(srtkys[srtKeyOrders[i]]);
            }
            params["sortkey"] = params["sortkey"].toString();
            params["sortorder"] = params["sortorder"].toString();

            this.setState({
                sortParams: params
            }, () =>
                this.onHeaderFilterChanged(this.state.filterParams)
            )
            // this.props.onSortingChanged(params);

        }
    }

    onFilterChanged(params) {
        this.setState({
            tableBodyData: [],
            showLoader: true,
            page: 0,
            // sortKeys: {},
            // sortKeysOrder: [],
            params: { limit: 1000, offset: 0 },
        }, () =>
            this.handelFilter(params)
        );
    }

    // Image modal
    onShowSIbImageModal = (info, event) => {
        this.setState({
            imageModalData: info["image_url"], showIbImageModal: true
        })
    }

    render() {
        const { classes, showLoader } = this.props;
        const { rowsPerPage, page, totalDataCount, isTableDataLoading, sortKeys,
            showSweetAlert, sweetAlertData, tableHeadDataKeys, sortingEnabled } = this.state;
            // console.log('this.state.tableBodyData', this.state.tableBodyData)
            // console.log('showIbImageModal', this.state.showIbImageModal)
            // console.log('imageModalData', this.state.imageModalData)
        return (
            <MuiThemeProvider theme={theme}>
                <Paper className={classes.root} >
                    {/* <div style={{  textAlign: 'center', paddingLeft: '15px', paddingTop: '10px', fontSize: '20px',height:'50px' }}> Total Mandi ({this.state.dataList.length})  </div> */}
                    <div style={{ display: 'flex' }}>
                        <UserFilterDataView
                            stateList={this.state.stateList}
                            role={this.props.role}
                            onRefreshButtonClicked={(event) => this.resetFilterAndParamsData(event)}
                            //   districtList={this.state.districtList}
                            //   districtData={Utils.getDistrictData()}
                            showLoader={isTableDataLoading}
                            onHeaderFilterChange={this.onFilterChanged.bind(this)}
                        />
                        {/* <div style={{ width: '40%', marginLeft: '58%' }}>
                            <input
                                type="text"
                                placeholder="Search..."
                                className="search-input"
                                onChange={this.handelFilter.bind(this)} /><i className="fa fa-search"></i>
                        </div> */}
                    </div>
                    {!showLoader && <div>
                        <div style={{ maxHeight: "70vh", overflowY: "scroll" }}>
                            <Table className='table-body' stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow style={{ borderBottom: "2px solid #858792" }} >
                                        {this.state.tableHeadData.map((option, i) => (
                                            <TableCell
                                                key={i + "_"}
                                                onClick={() => this.addKeysToSortList(tableHeadDataKeys[i])}
                                                className={classes.tableCell}
                                                style={{
                                                    textAlign: i === 1 ? "left" : "center", minWidth: i === 5 ? '50px' : '100px',
                                                    paddingLeft: i === 0 ? '22px' : '', color: i === 5 ? "goldenrod" : "",
                                                    cursor: sortingEnabled.indexOf(tableHeadDataKeys[i]) > -1 ? "pointer" : "unset"
                                                }}>
                                                {i === 5 ? <StarIcon /> : option}
                                                {sortingEnabled.indexOf(tableHeadDataKeys[i]) > -1 &&
                                                    <i className={Utils.getArrowIconType(sortKeys, tableHeadDataKeys[i])} aria-hidden="true" style={{ color: sortKeys.hasOwnProperty(tableHeadDataKeys[i]) ? '#e72e89' : 'unset' }}></i>}
                                            </TableCell>
                                            // <TableCell key="star" className={classes.tableCell} style={{ minWidth: '50px', color: "goldenrod" }}>  </TableCell>
                                        ))}

                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {!isTableDataLoading && this.state.tableBodyData && this.state.tableBodyData &&
                                        (rowsPerPage > 0
                                            ? this.state.tableBodyData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : this.state.tableBodyData
                                        )
                                            .map((row, i) => {
                                                return (

                                                    <TableRow key={'table_' + i} id={"user_" + row.id} style={{ background: i % 2 !== 0 ? "#e8e8e8" : "#fff" }}>
                                                        <TableCell component="th" scope="row" className={classes.tableCell}>
                                                            {Utils.checkIfPrimaryKey(row) &&
                                                                <Tooltip title={"Primary"} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                                    <i className="fa fa-check" style={{ color: "#60c1d8" }} aria-hidden="true"></i>
                                                                </Tooltip>
                                                            }
                                                            <div style={{ display: "inline-table" }}>
                                                                {/* <div className={classes.roleCss} style={{ background: this.getBackgroundColor(row) }}>
                                                                    {this.getRole(row)}
                                                                </div> */}

                                                                <Tooltip title={row.active ? "Enabled" : "Disabled"} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                                    <div style={{
                                                                        color: "#fff",
                                                                        background: row.active ? "green" : "red",
                                                                        padding: "4px 12px",
                                                                        width: 'fit-content',
                                                                        marginLeft: '2%',
                                                                        borderRadius: "13px"
                                                                    }}> {row.id}</div>
                                                                </Tooltip>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" className={classes.tableCell} style={row.image_url ? {position:'relative', paddingRight:30} : {}}>
                                                            <Tooltip title={row.fullname ? row.fullname : ""} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                                <div style={{ textAlign: "left", cursor: 'pointer', maxWidth: "140px" }} className=" name-span" onClick={this.onInfoClick.bind(this, row)}>
                                                                    <div className={classes.elipseClass}>{row.fullname}</div>
                                                                    <div className={classes.elipseClass} style={{ fontSize: "12px" }}>{"( " + row.business_name + " )"}</div>
                                                                    
                                                                </div>
                                                                {/* <div onClick={this.onInfoClick.bind(this, row)} className="text-ellpses name-span" style={{cursor:'pointer',color:'#2e3247'}}><span className={classes.name}>{row.fullname}</span>{"("+row.business_name+")"}</div> */}
                                                            </Tooltip>
                                                            {row.image_url &&
                                                                <i style={{ margin:'-9px 0 0', position:'absolute', top:'50%', right:5, color:"#fd0671" }}
                                                                data-toggle="tooltip" data-placement="right" title="IB images"
                                                                onClick={this.onShowSIbImageModal.bind(this, row)}
                                                                className={"fa fa-camera " + classes.info} aria-hidden="true"></i>
                                                            }
                                                        </TableCell>
                                                        <TableCell
                                                            style={{ maxWidth: "140px" }}
                                                            className={classes.tableCell}>
                                                            {Utils.maskMobileNumber(row.mobile)}
                                                        </TableCell>

                                                        <TableCell className={classes.tableCell}>
                                                            <Tooltip title={row.ordercount} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                                <div className="text-ellpses" style={{
                                                                    color: "white",
                                                                    background: this.getOrderNoBackgroundColor(row),
                                                                    padding: "4px 12px", width: 'fit-content', marginLeft: '20%',
                                                                    borderRadius: "13px"
                                                                }}>{row.ordercount}</div>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <Tooltip title={row.paymentcount} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                                <div className="text-ellpses" style={{
                                                                    color: "white",
                                                                    background: this.getOrderNoBackgroundColor(row),
                                                                    padding: "4px 12px", width: 'fit-content', marginLeft: '20%',
                                                                    borderRadius: "13px"
                                                                }}>{row.paymentcount}</div>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell style={{ width: "90px" }} className={classes.tableCell} >{row.rating}

                                                        </TableCell>
                                                        <TableCell style={{ width: "90px", maxWidth: "135px" }} className={classes.tableCell} >{this.getInfoSTring(row)}

                                                        </TableCell>
                                                        <TableCell className={classes.tableCell} style={{ padding: "0px", lineHeight: "100%", width: "160px" }} >
                                                            <Tooltip title={row.default_commodity ? this.getCommmodityList(classes, row.default_commodity, true) : ""} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                                <div className="text-ellpses " style={{ lineHeight: "unset", maxHeight: "100%" }}>{row.default_commodity ?
                                                                    this.getCommmodityList(classes, row.default_commodity, false)
                                                                    : ""}</div>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell} >
                                                            {this.getRole(row)}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell} >
                                                            <Tooltip title={row.profile_completed ? "Profile Completed : YES" : "Profile Completed : NO"} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                                <PersonIcon className="material-Icon" style={{ color: row.profile_completed ? '' : '#0000008a', height: "18px", fontSize: "18px" }} />
                                                            </Tooltip>
                                                            <Tooltip title={row.bijak_verified ? "Bijak Verified : YES" : "Bijak Verified : NO"} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                                <DoneAllIcon className="material-Icon" style={{ color: row.bijak_verified ? '' : 'red', height: "18px", fontSize: "18px" }} />
                                                            </Tooltip>
                                                            <Tooltip title={row.bijak_assured ? "Bijak Assured : YES" : "Bijak Assured : NO"} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                                <BeenhereIcon className="material-Icon" style={{ color: row.bijak_assured ? '#507705' : '#0000008a', height: "18px", fontSize: "18px" }} />
                                                            </Tooltip>
                                                            {/* <Tooltip title={row.kyc_completed ? "Kyc Completed: YES" : "Kyc Completed : NO"} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                                                <HowToRegIcon className="material-Icon" style={{ color: row.kyc_completed ? '#507705' : '#0000008a', height: "18px", fontSize: "18px" }} />
                                                            </Tooltip> */}
                                                        </TableCell>

                                                    </TableRow>

                                                );
                                            })}
                                </TableBody>

                            </Table>

                        </div>
                        {isTableDataLoading && <Loader />}
                        {this.state.tableBodyData.length > 0 && <Table>
                            <TableFooter style={{ borderTop: "2px solid #858792", background: "#fafafa" }}>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[25, 50, 100]}
                                        colSpan={6}
                                        count={totalDataCount}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                        }}
                                        onChangePage={this.handleChangePage.bind(this)}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>}
                        {this.state.tableBodyData.length > 0 ? "" :
                            <div className={classes.defaultTemplate}>
                                {this.state.searchedText.length > 0 ?
                                    <span className={classes.defaultSpan}>
                                        <i className={classes.defaultIcon + " fa fa-frown-o"} aria-hidden="true"></i>
                                        {"Your search does not match any list"} </span> : !showLoader ? <span className={classes.defaultSpan}>
                                            <i className={classes.defaultIcon + " fa fa-frown-o"} aria-hidden="true"></i>{"No data available"}</span> : ""}
                            </div>}

                    </div>}
                    {this.state.showUserModal ?
                        <UserDialog openModal={this.state.open}
                            onEditModalClosed={this.handleClose.bind(this)}
                            data={this.state.userData}
                            isInfo={this.state.isInfo}
                            onLimitUpdate={this.changeLimitSucces.bind(this)}
                            commodityList={this.props.commodityList}
                            onEditModalCancel={this.onModalCancel.bind(this)} /> : ""}

                    {this.state.showConfirmDialog ?
                        <ConfirmDialog
                            dialogText={this.state.dialogText}
                            dialogTitle={this.state.dialogTitle}
                            show={this.state.showConfirmDialog}
                            onConfirmed={this.handelConfirmUpdate}
                            onCanceled={this.handelCancelUpdate} /> : ""}
                    {this.state.showOrderModal ? <OrderTable openModal={this.state.open}
                        onEditModalClosed={this.handleClose.bind(this)}
                        data={this.state.userData}
                        onEditModalCancel={this.onModalCancel.bind(this)} /> : ""}

                    {/* download */}
                    {/* <div className="updateBtndef" style={{ right: "160px" }} data-toggle="tooltip" data-html="true" title="Download">
                        <div className="updateBtnFixed" style={{ display: 'flex', background: "#e72e89", borderRadius: "6px" }} onClick={this.handelDownloadClicked.bind(this)}>
                            <i className="fa fa-cloud-download add-icon" style={{ marginRight: 0, color: "white" }} aria-hidden="true"></i>
                        </div>
                    </div> */}

                    {showSweetAlert &&
                        <SweetAlertPage
                            show={true}
                            type={sweetAlertData.type}
                            title={sweetAlertData.title}
                            text={sweetAlertData.text}
                            sweetAlertClose={() => this.handelSweetAlertClosed()}
                        />}

                </Paper>
                {/* Image modal */}
                {this.state.showIbImageModal &&
                    <ViewIbImageModal
                        openModal={this.state.showIbImageModal}
                        onInvoiceModalClose={() => { this.setState({ showIbImageModal: false, imageModalData: [] }) }}
                        imageUrlData={this.state.imageModalData} />}
            </MuiThemeProvider>

        );
    }
}

UserListTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserListTable);