import React from "react";
import { Route, Redirect } from "react-router-dom";
// import cookie from 'react-cookies';

export default ({ component: Component, ...rest }) => 
    <Route {...rest} render={(props) => (window.sessionStorage.getItem('bijak_token') ? (
        <Component {...props} />
    ) : (
            <Redirect to={{
                pathname: `/`,
                state: { referrer: props.location }
            }} />
        )
    )} />;