import React from 'react';
import PropTypes from 'prop-types';
// import '../../assets/css/app.css';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../common/Loader';
import Paper from '@material-ui/core/Paper';
import FilterListComponent from "./components/FilterListComponent";
// import brokerService from './../../app/brokerService/brokerService';
import buyerService from './../../app/buyerService/buyerService';
import supplierService from './../../app/supplierService/supplierService';
import orderService from './../../app/orderService/orderService';
import OrderListTable from "./components/OrderListTable";
import DateRangeSelector from "./components/DateRangeSelector";
import commonService from '../../app/commonService/commonService';
import Utils from '../../app/common/utils';
// import sampleFile from '../sampleDownloadFiles/bulk-add-order-data-sample.csv';

const styles = theme => ({
    root: {
        width: '100%',
        overflow: 'auto',
        fontFamily: 'Lato !important',
        maxWidth: '1200px',
        minHeight: '80vh',
    },
    card: {
        maxWidth: '100%',
        marginTop: '15px',
        height: '97%',
    },
    heading: {
        marginTop: '15px',
        fontSize: '20px',
        alignTtems: 'center',
        display: '-webkit-inline-box'
    }
});



class OrdersContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            buyersList: [],
            brokersList: [],
            suppliersList: [],
            orderedListData: [],
            showLoader: false,
            datePayloads: { "startDate": "", "endDate": "" },
            totalDataCount: 0,
            params: {
                limit: 1000, // total amount of data 
                offset: 0 // data from which data needs to be get
            },
            resetPageNumber: false,
            isTableDataLoading: false,
            filterParams: {},

            sortKeys: {},
            sortParams: {},
            sortKeysOrder: [],

            resetFilter: false,
            tagsOption: [],
            orderTypeOptions:[],

            currentRef: this,
            notificationData: "",
            lastUpdatedKeys: {
                unsettled_amount_pltf: '',
                bijak_amt: '',
                id : ''
            }

        }
        this.ismounted = true;
    }

    componentDidMount() {
        this.getBuyersList();
        this.getTagsData();
        this.getSuppliersList();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (window.notificationdata && window.notificationdata !== "" &&
            window.notificationdata.hasOwnProperty("type") && window.notificationdata["type"] === 'order' &&
            prevState.notificationData !== window.notificationdata) {
            prevState.currentRef.updateDataOnNotificationRcvd(window.notificationdata);
            return null;
        } else {
            return null;
        }
    }

    updateDataOnNotificationRcvd(notfData) {
        try {
            // console.log(notfData)
            if (notfData && notfData['type'] && notfData['type'] === 'order') {
                if (!this.checkIfLastUpdatedKeysAreSame(notfData['raw_data']['unsettled_amount_pltf'], notfData['raw_data']['bijak_amt'], notfData['id'])) {
                    this.setState({
                        lastUpdatedKeys: {
                            'unsettled_amount_pltf': notfData['raw_data']['unsettled_amount_pltf'],
                            'bijak_amt': notfData['raw_data']['bijak_amt'],
                            'id':notfData['id']
                        }
                    }, () => {
                        let orderedListData = this.state.orderedListData;
                        if (orderedListData && orderedListData.length > 0) {
                            if (Utils.ifDataHasId(this.state.orderedListData, notfData['id'])) {
                                let index = Utils.getIndexOfTheID(this.state.orderedListData, notfData['id']);
                                orderedListData[index]['unsettled_amount_pltf'] = notfData['raw_data']['unsettled_amount_pltf'];
                                orderedListData[index]['bijak_amt'] = notfData['raw_data']['bijak_amt'];
                                this.setState({
                                    'orderedListData': orderedListData,
                                }, () => {
                                    window.notificationdata = "";
                                })
                            }
                        }
                    })

                }
            } else {
                window.notificationdata = "";
            }
        } catch (err) {
            console.log(err);
        }
    }

    checkIfLastUpdatedKeysAreSame(unsettled_amount_pltf, bijak_amt, id) {
        let lastUpdatedKeysVal = this.state.lastUpdatedKeys;
        if (lastUpdatedKeysVal['unsettled_amount_pltf'] === unsettled_amount_pltf &&
         lastUpdatedKeysVal['bijak_amt'] === bijak_amt && lastUpdatedKeysVal['id'] === id ) {
            return true;
        } else {
            return false;;
        }
    }

    async getBuyersList() {
        try {
            let param = { role: 'ca', "limit": 1000, "offset": 0 };
            let resp = await buyerService.serchUser(param);
            if (this.ismounted) {
                if (resp.data.status === 1 && resp.data.result) {
                    this.setState({ buyersList: this.formatDataForDropDown(resp.data.result.data, "fullname", "mobile") });
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    async getTagsData() {
        try {
            let tagsData = [];
            let orderTypeOptionsVal = [];
            let resp = await commonService.getTagsData("orders");
            if (resp.data.status === 1 && resp.data.order_type) {
                orderTypeOptionsVal = resp.data.order_type;
            } 
            if (resp.data.status === 1 && resp.data.result) {
                tagsData = resp.data.result;
            } else {
                tagsData = [];
            }
            this.setState({ tagsOption: tagsData, orderTypeOptions : orderTypeOptionsVal  });
        } catch (err) {
            console.log(err);
        }
    }

    async getSuppliersList() {
        try {
            let param = { role: 'la', "limit": 1000, "offset": 0 };
            let resp = await supplierService.serchUser(param);
            if (this.ismounted) {
                if (resp.data.status === 1 && resp.data.result) {
                    this.setState({ suppliersList: this.formatDataForDropDown(resp.data.result.data, "fullname", "mobile") });
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    formatDataForDropDown(data, labelKey, valuekey) {

        var optionsData = [];
        if (data) {
            for (var i = 0; i < data.length; i++) {
                // optionsData.push({ label: data[i][labelKey] +" ("+data[i][valuekey]+" )", value: data[i][valuekey] });
                optionsData.push({ 
                    is_primary :  Utils.checkIfPrimaryKey( data[i] ),
                    label: data[i]["fullname"] + ",  " + data[i]["business_name"] + " \n  (" + data[i]["locality"] + " , " + data[i][valuekey] + " )", 
                    value: data[i][valuekey] });
            }
        }
        return optionsData;
    }

    async getSearchedOrderListData() {

        let params = {};

        if (!params.hasOwnProperty("limit")) {
            params["limit"] = this.state.params["limit"];
        }
        if (!params.hasOwnProperty("offset")) {
            params["offset"] = this.state.params["offset"];
        }

        if (this.state.datePayloads["startDate"] !== "") {
            params["startDate"] = this.state.datePayloads["startDate"];
        }
        if (this.state.datePayloads["endDate"] !== "") {
            params["endDate"] = this.state.datePayloads["endDate"];
        }

        if (Object.keys(this.state.sortParams).length > 0 && this.state.sortParams["sortkey"] !== "") {
            params["sortkey"] = this.state.sortParams["sortkey"];
            params["sortorder"] = this.state.sortParams["sortorder"];
        } else {
            delete params["sortkey"];
            delete params["sortorder"];
        }

        let updatedParams = { ...params, ...this.state.filterParams }
        try {
            let resp = await orderService.getOrderListData(updatedParams);
            if (this.ismounted) {
                if (resp.data.status === 1 && resp.data.result) {
                    this.setState({
                        orderedListData: this.state.orderedListData.concat(resp.data.result.data),
                        totalDataCount: resp.data.result.totalCount && resp.data.result.totalCount[0] && resp.data.result.totalCount[0]["count"] ? parseInt(resp.data.result.totalCount[0]["count"], 10) : 0,
                        showLoader: false,
                        isTableDataLoading: false
                    });
                } else {
                    this.setState({ orderedListData: [], totalDataCount: 0, showLoader: false, isTableDataLoading: false });
                }
            }

        } catch (err) {
            console.error(err);
            if (this.ismounted) { this.setState({ orderedListData: [], totalDataCount: 0, showLoader: false, isTableDataLoading: false }); }
        }
    }

    componentWillUnmount() {
        this.ismounted = false;
    }

    // this function brings default selected date from the DateRangeSelection and call the Api 
    //when first Landed on this page
    onDefaultDateFromDateRangeShown(data) {
        this.setState({ datePayloads: data, showLoader: true }, function () {
            this.getSearchedOrderListData();
        });
    }

    onDateChaged(data) {
        this.setState({ datePayloads: data, showLoader: true }, function () {
            this.getSearchedOrderListData();
        });
    }

    handelRefreshData() {
        this.setState({
            showLoader: true,
            sortParams: {},
            sortKeysOrder: [],
            orderedListData: [],
            filterParams: {},
            sortKeys: {},
            resetFilter: true,
            params: { limit: 1000, offset: 0 }
        }, function () {
            this.setState({
                resetFilter: false
            }, () => this.getSearchedOrderListData())

        })
    }

    resetOffsetAndGetData() {
        let paramsval = this.state.params;
        paramsval["offset"] = paramsval["offset"] + 1000;
        this.setState({ params: paramsval, isTableDataLoading: true }, function () {
            this.getSearchedOrderListData();
        })
    }

    handelGetOrderData(param) {
        param["offset"] = 0;
        param["limit"] = 1000;

        this.setState({
            orderedListData: [],
            showLoader: true,
            resetPageNumber: true,
            filterParams: param,
            params: { limit: 1000, offset: 0 }
            // sortParams: {}, sortKeysOrder: [],
            // sortKeys: {}
        }, () =>
            this.getSearchedOrderListData()
        )
    }

    onTableSorted(sortParamsData) {
        // let param = this.state.filterParams;
        // param["offset"] = 0;
        // param["limit"] = 1000;
        this.setState({
            sortParams: sortParamsData["params"],
            sortKeysOrder: sortParamsData["order"],
            sortKeys: sortParamsData["sortKeys"],
            orderedListData: [],
            showLoader: true,
            resetPageNumber: true,
            params: { limit: 1000, offset: 0 }
        }, () =>
            this.getSearchedOrderListData()
        );
    }


    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Paper className={classes.card} >
                    <div style={{ display: "flex" }}>
                        <i onClick={(event) => {
                            if (!this.state.showLoader) {
                                this.handelRefreshData(event)
                            }
                        }}
                            style={{ padding: "18px", fontSize: "18px", color: "#50a1cf", cursor: "pointer" }} data-toggle="tooltip" data-html="true" title="Refresh" className="fa fa-refresh" aria-hidden="true"></i>
                        <DateRangeSelector
                            onDateChanged={this.onDateChaged.bind(this)}
                            onDefaultDateFromDateRangeShown={this.onDefaultDateFromDateRangeShown.bind(this)} />
                    </div>
                    {!this.state.resetFilter && <FilterListComponent
                        buyersList={this.state.buyersList}
                        // brokersList={this.state.brokersList}
                        // filterParams={ this.state.filterParams }
                        tagsOption={this.state.tagsOption}
                        orderTypeOptions={this.state.orderTypeOptions}
                        showLoader={this.state.showLoader}
                        suppliersList={this.state.suppliersList}
                        getSearchedOrderListData={this.handelGetOrderData.bind(this)} />}


                    {/* <div className="fixedLeftBtnContainer">
                    <a download={"bulk-add-order-data-sample.csv"} href={sampleFile} title="sampleFile">
                        <div className="fixedLeftBtn" style={{ display: 'flex' }}
                            // onClick={() => { window.open(sampleFile, 'Download'); }}
                            >
                            <i className="fa fa-cloud-download add-icon" aria-hidden="true"></i>
                            <p style={{
                                fontSize: "14px",
                                fontFamily: "lato",
                                fontWeight: 600
                            }}>Download sample</p></div>
                            </a>
                    </div> */}



                    {this.state.showLoader &&
                        <Loader />}
                    <OrderListTable
                        tableData={this.state.orderedListData}
                        resetOffsetAndGetData={() => this.resetOffsetAndGetData()}
                        currentOffset={this.state.params["offset"]}
                        resetPageNumber={this.state.resetPageNumber}
                        showLoader={this.state.showLoader}
                        sortKeys={this.state.sortKeys}
                        sortKeysOrder={this.state.sortKeysOrder}
                        onSortingChanged={(sortParams) => this.onTableSorted(sortParams)}
                        setPageNumber={() => this.setState({ resetPageNumber: false })}
                        totalDataCount={this.state.totalDataCount}
                        isTableDataLoading={this.state.isTableDataLoading}
                        tagsOption={this.state.tagsOption}
                        orderTypeOptions={this.state.orderTypeOptions}
                        onOrderAdded={() => this.handelGetOrderData(this.state.filterParams)} />
                    {/* } */}


                </Paper>
            </div>
        );
    }
}
OrdersContainer.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(OrdersContainer);