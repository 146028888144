import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Utils from '../../app/common/utils';
import Tooltip from '@material-ui/core/Tooltip';
import GroupDetailModal from './GroupDetailModal';
import commonService from '../../app/commonService/commonService';
var moment = require('moment');

const styles = theme => ({
    root: {
        width: '100%',
        fontFamily: 'lato !important',
        marginTop: '15px',
        padding: '8px 24px'

    },
    head: {
        fontSize: '15px',
        fontWeight: 'bold',
        color: '#848383',
        width: "45%",
        padding: "2px 0px"
    },
    value: {
        fontSize: '15px',
        marginLeft: '6px',
        lineHieght: '2',
        width: "45%"
        // padding: '3px'
    },
    row: {
        display: 'flex',
        // width: '50%'
    },
    mainrow: {
        display: 'flex',
        height: '30px',
        padding: '2px'
    },
    label: {
        background: 'red',
        borderRadius: '8px',
        padding: '0 10px 0 10px',
        fontSize: '13px',
        color: '#fff',
        height: '18px',
        marginTop: '4px'
    },
    lightTooltip: {
        fontSize: '16px',
    },
    cellDiv: {
        maxWidth: '180px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
});

class UserDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showGroupDetails: false,
            selectedGroupId: undefined,
            connectedBankActCount: ""
        }
        // console.log(this.props.data.role)
    }

    componentDidMount(){
        this.getBankDetails( this.props.data );
    }

    getBankDetails = async (userData) => { 
        try { 
            let param = { "mobile": userData["mobile"] };
            let resp = await commonService.getbankDetail(param);
            if (resp.data.status === 1) {
                let actCount = [];
                if (resp.data.result && resp.data.result.length > 0) {
                    for(let i = 0 ; i < resp.data.result.length; i++){
                        if( resp.data.result[i]["status"] === "active"){
                            actCount.push( resp.data.result[i] )
                        }
                    }
                    this.setState({ connectedBankActCount : actCount.length || 0 });
                } else {
                    this.setState({ connectedBankActCount : 0 })
                }
            } else {
                this.setState({ connectedBankActCount : "" })
            }
        } catch (err) {
            console.error(err);
            this.setState({ connectedBankActCount : "" })
        }
    }

    formatDateAndTime = (dateval) => {
        var fdate = moment.utc(new Date(dateval)).format('DD-MMM-YYYY HH:mm A')
        return <div style={{ width: "95px", display: "inline-block" }}> {fdate.split(" ")[0] + " " + fdate.split(" ")[1] + " " + fdate.split(" ")[2]}</div>
        // return <div style={{ width: "95px", display: "inline-block" }}> {fdate.split(" ")[0]}</div>
    }

    getCommodityNames(data, classes) {
        if (data) {
            let cName = data.join(", ");
            if (cName.length > 45) {
                return (
                    <Tooltip title={cName} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                        <p
                            className={classes.value} style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                            }} >: &nbsp; {cName} </p>
                    </Tooltip>
                )
            } else {
                return (<p className={classes.value}>: &nbsp; {cName} </p>)
            }
        } else {
            return (<p className={classes.value}>: &nbsp; {"-"} </p>)
        }
    }

    getRole(row) {

        let role = "NA";
        let isGlobal = false;
        if (row.role === "ca") {
            role = "Buyer";
        } else if (row.role === 'la') {
            role = "Supplier";
        } else if (row.role === 'broker') {
            role = "Broker";
        } else {
            role = "NA";
        }
        if (row.hasOwnProperty("global_ca") && row.global_ca) {
            isGlobal = true;
        }
        if (row.hasOwnProperty("global_la") && row.global_la) {
            isGlobal = true;
        }
        return (<span style={{
            color: "white",
            background: this.getBackgroundColor(row),
            padding: "4px 12px",
            borderRadius: "13px"
        }}> {role} {isGlobal ? <i className="fa fa-globe" aria-hidden="true"></i> : ""}</span>)
    }

    getBackgroundColor(obj) {
        if (obj.role === "ca") {
            return "#f94141";
        } else if (obj.role === 'la') {
            return "#00a700";
        } else if (obj.role === 'broker') {
            return "#7070fd";
        } else {
            return "#757575";
        }
    }

    render() {
        const { classes, creditLimitData } = this.props;
        console.log(this.props.data)
        return (
            <div className={classes.root}>
                <div style={{ width: "100%", display: "flex", marginTop: "5%" }}>
                    <div style={{ width: "49%" }}>

                        <div className={classes.row}>
                            <p className={classes.head}>Full Name  </p>
                            {/* <p className={classes.value}
                                style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}>: &nbsp; {this.props.data.fullname}</p> */}
                            <p className={classes.value}>
                                {this.props.data.fullname && this.props.data.fullname.length > 25 ?
                                    <Tooltip title={this.props.data.fullname ? this.props.data.fullname : ""} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                        <div className="text-ellpses" style={{ whiteSpace: "nowrap" }}>:&nbsp;{this.props.data.fullname ? this.props.data.fullname : ""}</div>
                                    </Tooltip>
                                    :
                                    <span>: &nbsp; {this.props.data.fullname ? this.props.data.fullname : ""}</span>}
                            </p>
                        </div>

                        <div className={classes.row}>
                            <p className={classes.head}>Business Name  </p>
                            {/* <p className={classes.value}>: &nbsp; {this.props.data.business_name}</p> */}
                            <p className={classes.value}>
                                {this.props.data.business_name && this.props.data.business_name.length > 25 ?
                                    <Tooltip title={this.props.data.business_name ? this.props.data.business_name : ""} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                        <div className="text-ellpses" style={{ whiteSpace: "nowrap" }}>:&nbsp;{this.props.data.business_name ? this.props.data.business_name : ""}</div>
                                    </Tooltip>
                                    :
                                    <span>: &nbsp; {this.props.data.business_name ? this.props.data.business_name : ""}</span>}
                            </p>

                        </div>

                        <div className={classes.row}>
                            <p className={classes.head}>Mobile No. </p>
                            <p className={classes.value}>: &nbsp; {Utils.maskMobileNumber(this.props.data.mobile)}</p>
                        </div>
                        <div className={classes.row}>
                            <p className={classes.head}>State </p>
                            <p className={classes.value} style={{textTransform: "capitalize"}}>: &nbsp; {this.props.data.state ? this.props.data.state : ""}</p>
                        </div>

                        <div className={classes.row}>
                            <p className={classes.head}>District </p>
                            <p className={classes.value} style={{textTransform: "capitalize"}}>: &nbsp; {this.props.data.district ? this.props.data.district : ""}</p>
                        </div>

                        <div className={classes.row}>
                            <p className={classes.head}>Locality </p>
                            <p className={classes.value} style={{textTransform: "capitalize"}}>: &nbsp; {this.props.data.locality ? this.props.data.locality : ""}</p>
                        </div>

                        <div className={classes.row}>
                            <p className={classes.head}>Commodity </p>
                            {this.getCommodityNames(this.props.data.default_commodity, classes)}
                            {/* <p className={classes.value}>: &nbsp; {this.props.data.default_commodity ? this.getCommodityName(this.props.data.default_commodity) : "-"} </p> */}
                        </div>

                        <div className={classes.row}>
                            <p className={classes.head}>Second Mobile no. </p>
                            <p className={classes.value}>: &nbsp; {this.props.data.sec_mobile ? this.props.data.sec_mobile : ""}</p>
                        </div>

                        <div className={classes.row}>
                            <p className={classes.head}>Third Mobile no. </p>
                            <p className={classes.value}>: &nbsp; {this.props.data.third_mobile ? this.props.data.third_mobile : ""}</p>
                        </div>

                        <div className={classes.row}>
                            <p className={classes.head}> Partner name </p>
                            {/* <p className={classes.value}>: &nbsp; {this.props.data.partner_names ? this.props.data.partner_names : ""}</p> */}
                            <p className={classes.value}>
                                {this.props.data.partner_names && this.props.data.partner_names.toString().length > 25 ?
                                    <Tooltip title={this.props.data.partner_names ? this.props.data.partner_names : ""} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                        <div className="text-ellpses" style={{ whiteSpace: "nowrap" }}>:&nbsp;{this.props.data.partner_names ? this.props.data.partner_names : ""}</div>
                                    </Tooltip>
                                    :
                                    <span>:&nbsp;{this.props.data.partner_names ? this.props.data.partner_names : ""}</span>}
                            </p>
                        </div>

                        <div className={classes.row}>
                            <p className={classes.head}> Role </p>
                            <p className={classes.value}>: &nbsp; {this.getRole(this.props.data)}</p>
                        </div>

                        <div className={classes.row}>
                            <p className={classes.head}>Connected to group</p>
                            <p className={classes.value}>: &nbsp; <span style={{
                                    background: (this.props.data.is_connected_to_group ? "#5bbc9b" : "#e63232"),
                                    padding: "4px",
                                    borderRadius: "3px",
                                    color: "#fff"
                                }}>
                                    {this.props.data.is_connected_to_group ? "Yes" : "No"}</span></p>
                        </div>

                        {/* <div className={classes.row}>
                            <p className={classes.head}> Group name </p>
                            <p className={classes.value}>
                                {this.props.data.group_name && this.props.data.group_name.toString().length > 25 ?
                                    <Tooltip title={this.props.data.group_name ? this.props.data.group_name : ""} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                        <div className="text-ellpses" style={{ whiteSpace: "nowrap" }}>:&nbsp;{this.props.data.group_name ? this.props.data.group_name : ""}</div>
                                    </Tooltip>
                                    :
                                    <span>:&nbsp;{this.props.data.group_name ? this.props.data.group_name : ""}</span>}
                            </p>
                        </div> */}

                    </div>
                    {/* ------------------------------------------------------------------------------------- */}
                    <div style={{ width: "2%" }}></div>
                    {/* ------------------------------------------------------------------------------------- */}
                    <div style={{ width: "49%" }}>
                        <div className={classes.row}>
                            <p className={classes.head}> Bijak credit limit  </p>
                            <p className={classes.value}>: &nbsp; {this.props.data.bijak_credit_limit ? this.props.data.bijak_credit_limit : ""}</p>
                        </div>

                        <div className={classes.row}>
                            <p className={classes.head}> Available Credit Limit  </p>
                            <p className={classes.value}>: &nbsp; {creditLimitData ? creditLimitData : ""}</p>
                        </div>

                        <div className={classes.row}>
                            <p className={classes.head}>Cutoff Limit </p>
                            <p className={classes.value}>: &nbsp; {this.props.data.exposure_cutoff_limit ? this.props.data.exposure_cutoff_limit : ""}</p>
                        </div>

                        <div className={classes.row}>
                            <p className={classes.head}>Rating </p>
                            <p className={classes.value}>: &nbsp; {this.props.data.rating || this.props.data.rating === 0 ? this.props.data.rating : ""}</p>
                        </div>

                        <div className={classes.row}>
                            <p className={classes.head}>Managed Client</p>
                            <p className={classes.value}>: &nbsp;
                            <span style={{
                                    background: (this.props.data.is_ib ? "#5bbc9b" : "#e63232"),
                                    padding: "4px",
                                    borderRadius: "3px",
                                    color: "#fff"
                                }}>
                                    {this.props.data.is_ib ? "Yes" : "No"}</span></p>
                        </div>

                        <div className={classes.row}>
                            <p className={classes.head}>Is Bijak Verified </p>
                            <p className={classes.value}>: &nbsp;
                            <span style={{
                                    background: (this.props.data.bijak_verified ? "#5bbc9b" : "#e63232"),
                                    padding: "4px",
                                    borderRadius: "3px",
                                    color: "#fff"
                                }}>
                                    {this.props.data.bijak_verified ? "Yes" : "No"}</span></p>
                        </div>

                        <div className={classes.row}>
                            <p className={classes.head}>Is Bijak Assured </p>
                            <p className={classes.value}>: &nbsp;
                            <span style={{
                                    background: (this.props.data.bijak_assured ? "#5bbc9b" : "#e63232"),
                                    padding: "4px",
                                    borderRadius: "3px",
                                    color: "#fff"
                                }}>
                                    {this.props.data.bijak_assured ? "Yes" : "No"}</span></p>
                        </div>

                        <div className={classes.row}>
                            <p className={classes.head}> Is User Enabled </p>
                            <p className={classes.value}>: &nbsp;
                            <span style={{
                                    background: (this.props.data.active ? "#5bbc9b" : "#e63232"),
                                    padding: "4px",
                                    borderRadius: "3px",
                                    color: "#fff"
                                }}>
                                    {this.props.data.active ? "Yes" : "No"}</span></p>
                        </div>

                        <div className={classes.row}>
                            <p className={classes.head}> Total Orders </p>
                            <p className={classes.value}>: &nbsp; {this.props.data.ordercount ? this.props.data.ordercount : ""}</p>
                        </div>

                        <div className={classes.row}>
                            <p className={classes.head}> Total Payments </p>
                            <p className={classes.value}>: &nbsp; {this.props.data.paymentcount ? this.props.data.paymentcount : ""}</p>
                        </div>

                        <div className={classes.row}>
                            <p className={classes.head}> Bank Account Connected </p>
                            <p className={classes.value}>: &nbsp; {this.state.connectedBankActCount}</p>
                        </div>
                        <div className={classes.row}>
                            <p className={classes.head}> Relationship manager </p>
                            <p className={classes.value}>

                                {this.props.data.rm_name && this.props.data.rm_name.length > 25 ?
                                    <Tooltip title={this.props.data.rm_name ? this.props.data.rm_name : ""} placement="top" classes={{ tooltip: classes.lightTooltip }}>
                                        <div className="text-ellpses" style={{ whiteSpace: "nowrap" }}>:&nbsp;{this.props.data.rm_name ? this.props.data.rm_name : ""}</div>
                                    </Tooltip>
                                    :
                                    <span>:&nbsp;{this.props.data.rm_name ? this.props.data.rm_name : ""}</span>}

                                {/* { this.props.data.rm_name ? this.props.data.rm_name : "" } */}
                            </p>
                        </div>
                        <div className={classes.row}>
                            <p className={classes.head}>Group id</p>
                            <p className={classes.value+ (this.props.data.connect_group_id ? " name-span":"")} style={{ cursor : this.props.data.connect_group_id ? "pointer":"unset", color:"blue"}}
                                onClick={() => { if (this.props.data.connect_group_id) { this.setState({ showGroupDetails: true, selectedGroupId: this.props.data.connect_group_id }) } }}
                            >: &nbsp; {this.props.data.connect_group_id ? this.props.data.connect_group_id : ""} </p>
                        </div>
                    </div>

                </div>

                <hr style={{ margin: "10px 0px 10px 0px" }} />
                <div style={{ width: "100%", display: "flex" }}>
                    <div style={{ width: "49%" }}>

                        <div className={classes.row}>
                            <p className={classes.head}>Created date  </p>
                            <p className={classes.value}>: &nbsp; {this.props.data.createdtime ? Utils.formatDateData(this.props.data.createdtime) : ""}</p>
                        </div>

                        <div className={classes.row}>
                            <p className={classes.head}>Last updated  </p>
                            {/* <p className={classes.value}>: &nbsp; {this.props.data.updatedtime ?this.formatDateAndTime(this.props.data.updatedtime) : ""}</p> */}
                            <p className={classes.value}>: &nbsp; {this.props.data.updatedtime ? Utils.formatDateData(this.props.data.updatedtime) : ""}</p>
                        </div>

                    </div>
                    {/* ------------------------------------------------------------------------------------- */}
                    <div style={{ width: "2%" }}></div>
                    {/* ------------------------------------------------------------------------------------- */}
                    <div style={{ width: "49%" }}>
                        <div className={classes.row}>
                            <p className={classes.head}> Last Login  </p>
                            <p className={classes.value}>: &nbsp; {""}</p>
                        </div>

                        <div className={classes.row}>
                            <p className={classes.head}> Available Credit Limit  </p>
                            <p className={classes.value}>: &nbsp; {creditLimitData ? creditLimitData : ""}</p>
                        </div>
                    </div>
                </div>

                {this.state.showGroupDetails &&
                    <GroupDetailModal
                        open={this.state.showGroupDetails}
                        selectedGroupId={this.state.selectedGroupId}
                        onAddModalCancel={(event) => this.setState({ showGroupDetails: false, selectedGroupId: undefined })}
                    />}

            </div>
        )
    }
}

UserDetail.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserDetail);

