class CommonUtils {
  static isEmpty = (value) => {
    if (value === undefined || value === null || new String(value).length === 0) {
      return true;
    }
    return false;
  }

  static getErrorDataForSweetAlert = (resp) => {
    const sweetAlrtData = {};
    sweetAlrtData["type"] = "error";
    sweetAlrtData["title"] = "Error";
    sweetAlrtData["text"] = resp && resp.data && resp.data.message ? resp.data.message : "There was an error peforming the requested operation";
    return { ...sweetAlrtData };
  }
}

export default CommonUtils;