import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Loader from "../common/Loader";
import AllLogsViewTable from './components/AllLogsViewTable';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import logsService from '../../app/logsService/logsService';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import OrderLogsViewTable from './components/OrderLogsViewTable';
import PaymentLogsViewTable from './components/PaymentLogsViewTable';
import UsersLogsViewTable from './components/UsersLogsViewTable';
import Button from '@material-ui/core/Button';
import AdminUserLogsTable from './components/AdminUserLogsTable';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import clsx from 'clsx';
import Input from '@material-ui/core/Input';
// import AdminSearchTableView from './components/AdminSearchTableView';

const styles = theme => ({
    root: {
        width: '100%',
        overflow: 'auto',
        fontFamily: 'Lato !important',
        maxWidth: '1200px',
        minHeight: '80vh',
    },
    card: {
        maxWidth: '100%',
        marginTop: '15px',
        height: '97%',
    },
    heading: {
        marginTop: '15px',
        fontSize: '20px',
        alignTtems: 'center',
        display: '-webkit-inline-box'
    },
    formControl: {
        // margin: '0px 5px',
        // padding: '0px 10px',
        minWidth: 120,
        color: '#373737 !important',
        borderRight: '1px solid #e6e7e8'
    },
});



class AppLogsContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            logsData: [],
            selectedLogType: "all",
            showLoader: false,

            params: {
                limit: 1000, // total amount of data 
                offset: 0 // data from which data needs to be get
            },
            totalDataCount: 0,
            isTableDataLoading: false,

            searchedId: "",
            isSearchActive: false,
            serachedState: {
                pSerachedId: '',
                datachanged: false,
                lastVisitedTab: 'all',
                params: {
                    limit: 1000, // total amount of data 
                    offset: 0 // data from which data needs to be get
                },
                pagenumber: 0,
                sortParams: {}
            },
            datePayloads: {
                startDate: this.getPreviousDate(90),
                endDate: new Date()
            },
            sortParams: {},
            srtkys: {},
            srtKeyOrders: []
        }
        this.ismounted = true;
    }

    componentDidMount() {
        this.setState({ showLoader: true }, () =>
            this.getAllEventList()
        );
    }

    getPreviousDate(PreviousnoOfDays) {
        var date = new Date();
        return (new Date(date.setDate(date.getDate() - PreviousnoOfDays)));
    }

    formateDateForApi(data) {
        if (data && data !== "") {
            var dateVal = new Date(data);
            dateVal = dateVal.getFullYear() + "-" + ((dateVal.getMonth() + 1) < 10 ? "0" + (dateVal.getMonth() + 1) : dateVal.getMonth() + 1) + "-" + (dateVal.getDate() < 10 ? "0" + dateVal.getDate() : dateVal.getDate());
            return dateVal;
        } else {
            return "";
        }
    }

    async getAllEventList() {
        try {
            let params = this.state.params;
            params["startDate"] = this.formateDateForApi(this.state.datePayloads.startDate);
            params["endDate"] = this.formateDateForApi(this.state.datePayloads.endDate);

            if (this.state.sortParams && Object.keys(this.state.sortParams).length > 0) {
                params = { ...params, ...this.state.sortParams }
            }

            if (params.sortkey) {
                if (params.sortkey.indexOf("event_id") > -1) {
                    params.sortkey = params.sortkey.replace("event_id", "id");
                }

            }

            let resp = await logsService.getEventList(params);
            if (this.ismounted) {
                if (resp.data.status === 1 && resp.data.result && resp.data.result.data) {
                    this.setState({
                        logsData: this.state.logsData.concat(resp.data.result.data),
                        totalDataCount: resp.data.result.totalCount && resp.data.result.totalCount["count"] ? parseInt(resp.data.result.totalCount["count"], 10) : 0
                    });
                }
                this.setState({ showLoader: false, isTableDataLoading: false });
            }
        } catch (err) {
            console.log(err);
            if (this.ismounted) {
                this.setState({ showLoader: false, isTableDataLoading: false });
            }
        }
    }

    async getEventData(param) {
        try {
            // if (!param.hasOwnProperty("id")) {
            param["startDate"] = this.formateDateForApi(this.state.datePayloads.startDate);
            param["endDate"] = this.formateDateForApi(this.state.datePayloads.endDate);
            // }
            if (!param.hasOwnProperty("id")) {
                if (this.state.sortParams && Object.keys(this.state.sortParams).length > 0) {
                    param = { ...param, ...this.state.sortParams }
                }
            }
            let resp = await logsService.getEventData(param);
            if (this.ismounted) {
                if (resp.data.status === 1 && resp.data.result.data) {
                    this.setState({
                        logsData: this.state.logsData.concat(resp.data.result.data),
                        totalDataCount: resp.data.result.totalCount && resp.data.result.totalCount["count"] ? parseInt(resp.data.result.totalCount["count"], 10) : 0
                    });
                }
                let serachedStateVal = this.state.serachedState;
                serachedStateVal["pSerachedId"] = param["id"] ? param["id"] : '';
                serachedStateVal["datachanged"] = true;
                this.setState({
                    showLoader: false, isTableDataLoading: false, serachedState: serachedStateVal
                });
            }
        } catch (err) {
            console.log(err);
            if (this.ismounted) {
                this.setState({ showLoader: false, isTableDataLoading: false });
            }
        }
    }

    async getAminUserLogs(param) {
        try {

            let resp = await logsService.getAdminUserList(param);
            if (this.ismounted) {
                if (resp.data.status === 1 && resp.data.result) {
                    this.setState({
                        logsData: resp.data.result,
                        totalDataCount: resp.data.result.length //resp.data.result.totalCount && resp.data.result.totalCount[0] && resp.data.result.totalCount[0]["count"] ? parseInt(resp.data.result.totalCount[0]["count"], 10) : 0
                    });
                }
                this.setState({ showLoader: false, isTableDataLoading: false });
            }
        } catch (err) {
            console.log(err);
            if (this.ismounted) {
                this.setState({ showLoader: false, isTableDataLoading: false });
            }
        }
    }

    handelLogsTypeChanged(event) {
        let type = event.target.value;
        let serachedStateVal = this.state.serachedState;
        serachedStateVal["lastVisitedTab"] = this.state.selectedLogType;
        serachedStateVal["pSerachedId"] = '';
        this.setState({
            selectedLogType: type,
            logsData: [],
            totalDataCount: 0,
            showLoader: true,
            params: { limit: 1000, offset: 0 },
            serachedState: serachedStateVal,
            searchedId: "",
            sortParams: {},
            "srtkys": {},
            "sortKeysOrder": []
        }, () => {
            if (type === "all") {
                this.getAllEventList();
            } else
                if (type === "adminuser") {
                    this.getAminUserLogs(this.state.params)
                } else {
                    let updatedparam = { ...this.state.params, ...{ "type": type } };
                    this.getEventData(updatedparam);
                }
        })
    }

    resetOffsetAndGetData(type) {
        let paramsval = this.state.params;
        paramsval["offset"] = paramsval["offset"] + 1000;
        let serachedStateVal = this.state.serachedState;
        serachedStateVal["params"] = this.state.params;
        this.setState({ params: paramsval, serachedState: serachedStateVal, isTableDataLoading: true }, function () {
            if (type === "all") {
                this.getAllEventList();
            } else
                if (type === "adminuser") {
                    this.getAminUserLogs(this.state.params)
                } else {
                    let payload = { "type": type };
                    if (this.state.searchedId !== "" || this.state.isSearchActive) {
                        payload["id"] = this.state.searchedId;
                    }
                    let updatedparam = { ...this.state.params, ...payload };
                    this.getEventData(updatedparam);
                }
        })
    }

    handelSearchInputChange(event) {
        let val = event.target.value;
        if (val === "" || !isNaN(val)) {
            let serachedStateVal = this.state.serachedState;
            serachedStateVal['datachanged'] = false;
            this.setState({ searchedId: val, ifInputChanged: true, serachedState: serachedStateVal })
        }
    }

    onSearchedClicked(event) {
        try {
            let val = this.state.searchedId;
            let serachedStateVal = this.state.serachedState;
            serachedStateVal['pSerachedId'] = val;
            serachedStateVal['datachanged'] = false;
            this.setState({
                isSearchActive: val !== '' ? true : false,
                ifInputChanged: true,
                logsData: [],
                totalDataCount: 0,
                showLoader: true,
                serachedState: serachedStateVal,
                params: { limit: 1000, offset: 0 }
            }, () => {
                let apiParam = { "type": this.state.selectedLogType };
                if (val !== '') {
                    apiParam["id"] = val;
                }
                let updatedparam = { ...this.state.params, ...apiParam };
                if (this.state.selectedLogType === "all") {
                    this.getAllEventList();
                } else
                    if (this.state.selectedLogType === "adminuser" && val === '') {
                        this.getAminUserLogs(updatedparam)
                    } else {
                        this.getEventData(updatedparam);
                    }


            })

        } catch (err) {
            console.log(err)
        }
    }

    checkIfSearchActive() {
        if (this.state.serachedState.pSerachedId.length !== 0) {
            return true;
        } else {
            return false;
        }
    }

    onIdClicked(type, id) {
        if (id) {
            let serachedStateVal = this.state.serachedState;
            serachedStateVal["lastVisitedTab"] = this.state.selectedLogType;
            this.setState({ searchedId: id + '', serachedState: serachedStateVal, selectedLogType: type, isSearchActive: true }, () => {
                this.onSearchedClicked(this);
            })
        }
    }

    onClearSearchInput() {
        this.setState({ searchedId: '' })
    }

    onIdSearchCleared() {
        let serachedStateVal = this.state.serachedState;
        serachedStateVal["pSerachedId"] = '';
        serachedStateVal["datachanged"] = true;
        this.setState({
            searchedId: '',
            isSearchActive: false,
            selectedLogType: serachedStateVal["lastVisitedTab"],
            param: serachedStateVal["lastVisitedTab"]
        }, () => {

            this.setState({
                logsData: [],
                totalDataCount: 0,
                showLoader: true,
                serachedState: serachedStateVal
            }, () => {
                if (serachedStateVal["lastVisitedTab"] === "all") {
                    this.getAllEventList();
                } else
                    if (serachedStateVal["lastVisitedTab"] === "adminuser") {
                        this.getAminUserLogs(this.state.params)
                    } else {
                        let updatedparam = { ...this.state.params, ...{ "type": serachedStateVal["lastVisitedTab"] } };
                        this.getEventData(updatedparam);
                    }
            })
        })
    }

    handelDateChange(dateVal, dateType) {
        let datePayloadsVal = this.state.datePayloads;
        datePayloadsVal[dateType] = dateVal;
        this.setState({ datePayloads: datePayloadsVal })
    }

    componentWillUnmount() {
        this.ismounted = false;
    }

    getIconTypeForId(row) {
        try {
            let type = row.type ? row.type.toLowerCase() : row;
            if (type === "user") {
                return <i className="fa fa-user" aria-hidden="true" style={{ color: this.getIdColorAccToType(row) }}></i>
            } else if (type === "order") {
                return <i className="fa fa-shopping-cart" aria-hidden="true" style={{ color: this.getIdColorAccToType(row) }}></i>
            } else if (type === "payment") {
                return <i className="fa fa-credit-card" aria-hidden="true" style={{ color: this.getIdColorAccToType(row) }}></i>;
            }
        } catch (err) {
            return "";
        }
    }

    getIdColorAccToType(row) {
        try {
            let type = row.type ? row.type.toLowerCase() : row;
            if (type === "user") {
                return "rgb(71, 125, 227)";
            } else if (type === "order") {
                return "rgba(78, 36, 0, 1)"; //"#61cb42"; 
            } else if (type === "payment") {
                return "#02a735";
            }
        } catch (err) {
            return "";
        }
    }

    onSortClicked(prms) {

        let serachedStateVal = this.state.serachedState;
        serachedStateVal["sortParams"] = prms.params;
        serachedStateVal["sortKeysOrder"] = prms.sortKeysOrder;
        serachedStateVal["srtkys"] = prms.srtkys;
        this.setState({ sortParams: prms.params, "srtkys": prms.srtkys, "sortKeysOrder": prms.sortKeysOrder, serachedState: serachedStateVal }, () => {
            this.onSearchedClicked(this);
        })

    }

    getSortIdValues(key) {
        if (!this.checkIfSearchActive()) {
            if (key === "srtkys") {
                return this.state.srtkys;
            }
            if (key === "sortKeysOrder") {
                return this.state.sortKeysOrder;
            }
            if (key === "sortParams") {
                return this.state.sortParams;
            }
        } else {
            if (key === "srtkys") {
                return {};
            }
            if (key === "sortKeysOrder") {
                return [];
            }
            if (key === "sortParams") {
                return {};
            }
        }
    }

    handelRefreshData() {
        this.setState({
            logsData: [],
            showLoader: true,
            params: {
                limit: 1000, // total amount of data 
                offset: 0 // data from which data needs to be get
            },
            totalDataCount: 0,
            isTableDataLoading: false,
            searchedId: "",
            isSearchActive: false,
            serachedState: {
                pSerachedId: '',
                datachanged: false,
                lastVisitedTab: this.state.selectedLogType,
                params: {
                    limit: 1000, // total amount of data 
                    offset: 0 // data from which data needs to be get
                },
                pagenumber: 0,
                sortParams: {}
            },
            datePayloads: {
                startDate: this.getPreviousDate(90),
                endDate: new Date()
            },
            sortParams: {},
            srtkys: {},
            srtKeyOrders: []
        }, () => {
            if (this.state.selectedLogType === "all") {
                this.getAllEventList();
            } else if (this.state.selectedLogType === "adminuser") {
                this.getAminUserLogs(this.state.params)
            } else {
                let updatedparam = { ...this.state.params, ...{ "type": this.state.selectedLogType } };
                this.getEventData(updatedparam);
            }
        });
    }

    render() {
        const { classes } = this.props;
        const { logsData, showLoader, selectedLogType, totalDataCount, isTableDataLoading, datePayloads } = this.state;
        const transactionType = {
            "all": "All",
            "order": "Orders",
            "payment": "Payments",
            "user": "Users",
            "adminuser": "Admin user"
        };
        return (
            <div className={classes.root} >
                {/* <Paper className={classes.card} > */}
                <div style={{ textAlign: "left", padding: "16px 12px", display: "flex", marginTop: "10px" }}>

                    <i onClick={(event) => this.handelRefreshData()} style={{ padding: "18px", fontSize: "18px", color: "#50a1cf", cursor: "pointer" }} data-toggle="tooltip" data-html="true" title="Refresh" className="fa fa-refresh" aria-hidden="true"></i>

                    <TextField
                        select
                        id="select_type"
                        name="select_type"
                        label="Select logs type"
                        type="text"
                        // error={errorFields["transaction_type"] ? true : false}
                        style={{ width: '20%' }}
                        value={selectedLogType}
                        onChange={this.handelLogsTypeChanged.bind(this)}
                    >
                        {Object.keys(transactionType).map((key, i) => (
                            <MenuItem key={i} value={key} selected={true} >
                                &nbsp; {this.getIconTypeForId(key)}&nbsp;{transactionType[key]}
                            </MenuItem>
                        ))}
                    </TextField>
                    {/* <div style={{ textAlign: "right", width: "50%" }}> */}
                    <FormControl className={clsx(classes.margin, classes.textField)}
                        style={{
                            marginTop: "1px",
                            width: "50%",
                            textAlign: "right",
                            display: "block"
                        }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                id="date-picker-dialog"
                                label="Logs start date"
                                format="dd-MMM-yyyy"
                                maxDate={new Date()}
                                value={datePayloads.startDate}
                                onChange={(dateval) => {
                                    this.handelDateChange(dateval, "startDate");
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            &nbsp;
                            &nbsp;
                                    <KeyboardDatePicker
                                id="date-picker-dialog"
                                label="Logs end date"
                                format="dd-MMM-yyyy"
                                value={datePayloads.endDate}
                                maxDate={new Date()}
                                onChange={(dateval) => {
                                    this.handelDateChange(dateval, "endDate");
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                    {/* </div> */}
                    {/* <FormControl variant="outlined" className={classes.formControl}> */}
                    {/* {selectedLogType !== "all" &&
                        <div style={{ height: "44px", borderBottom: "1px solid #949494", marginTop: "18px", marginLeft: "8px" }} >
                            <input
                                type="text"
                                id="searchinput"
                                placeholder="Search by id"
                                value={this.state.searchedId}
                                // onKeyUp={this.handelEnterKeyPress.bind(this)}
                                className={classes.searchInput}
                                onChange={this.handelSearchInputChange.bind(this)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                        // onClick={handleClickShowPassword}
                                        // onMouseDown={handleMouseDownPassword}
                                        >
                                            {<CloseIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </div>} */}

                    {selectedLogType !== "all" &&
                        <FormControl className={clsx(classes.margin, classes.textField)} style={{ marginLeft: "5px" }}>
                            <InputLabel htmlFor="standard-adornment-password">Search by id</InputLabel>
                            <Input
                                id="standard-adornment-password"
                                type={'text'}
                                value={this.state.searchedId}
                                onChange={this.handelSearchInputChange.bind(this)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => this.onClearSearchInput()}
                                        // onMouseDown={handleMouseDownPassword}
                                        >
                                            {this.state.searchedId.length > 0 && <CloseIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>}

                    {/* </FormControl> */}
                    <Button component="span"
                        disabled={showLoader}
                        style={{
                            fontSize: "14px",
                            color: "#fff",
                            width: "90px",
                            // marginTop: "2px",
                            marginLeft: "5px",
                            height: "46px",
                            background: "#e74a52"
                        }}
                        onClick={this.onSearchedClicked.bind(this)}
                    >
                        Search
                                </Button>
                </div>

                {this.checkIfSearchActive() &&
                    <div> <span style={{
                        padding: "5px 5%",
                        color: "#fff",
                        background: "#e74a52",
                        borderRadius: "10px"
                    }}>Showing result of search id : {this.state.serachedState.pSerachedId}
                        <IconButton
                            style={{ color: "#fff" }}
                            aria-label="toggle password visibility"
                            onClick={() => this.onIdSearchCleared()}
                        >
                            {this.state.searchedId.length > 0 && <CloseIcon />}
                        </IconButton> </span> </div>}
                {/* ==============All Data==============  */}
                {logsData && logsData.length > 0 && selectedLogType === "all" &&
                    <AllLogsViewTable
                        tableBodyData={logsData}
                        totalDataCount={totalDataCount}
                        resetOffsetAndGetData={() => this.resetOffsetAndGetData("all")}
                        isTableDataLoading={isTableDataLoading}
                        onIdClicked={(data) => {
                            this.onIdClicked(data.type, data.id)
                        }}
                        showLoader={showLoader}

                        onSortClicked={(prms) => {
                            if (!this.checkIfSearchActive()) {
                                this.onSortClicked(prms);
                            }
                        }}
                        srtkys={this.getSortIdValues("srtkys")}
                        sortKeysOrder={this.getSortIdValues("sortKeysOrder")}
                        sortParams={this.getSortIdValues("sortParams")}
                        ifSearchEnabled={this.checkIfSearchActive()}
                    />}

                {/* ==============order==============  */}
                {logsData && logsData.length > 0 && selectedLogType === "order" &&
                    <OrderLogsViewTable
                        tableBodyData={logsData}
                        totalDataCount={totalDataCount}
                        resetOffsetAndGetData={() => this.resetOffsetAndGetData("order")}
                        isTableDataLoading={isTableDataLoading}
                        onIdClicked={(id) => this.onIdClicked('order', id)}
                        showLoader={showLoader}

                        onSortClicked={(prms) => {
                            if (!this.checkIfSearchActive()) {
                                this.onSortClicked(prms);
                            }
                        }}
                        srtkys={this.getSortIdValues("srtkys")}
                        sortKeysOrder={this.getSortIdValues("sortKeysOrder")}
                        sortParams={this.getSortIdValues("sortParams")}
                        ifSearchEnabled={this.checkIfSearchActive()}
                    />}

                {/* ==============payment==============  */}
                {logsData && logsData.length > 0 && selectedLogType === "payment" &&
                    <PaymentLogsViewTable
                        tableBodyData={logsData}
                        totalDataCount={totalDataCount}
                        resetOffsetAndGetData={() => this.resetOffsetAndGetData("payment")}
                        isTableDataLoading={isTableDataLoading}
                        onIdClicked={(id) => this.onIdClicked('payment', id)}
                        showLoader={showLoader}

                        onSortClicked={(prms) => {
                            if (!this.checkIfSearchActive()) {
                                this.onSortClicked(prms);
                            }
                        }}
                        srtkys={this.getSortIdValues("srtkys")}
                        sortKeysOrder={this.getSortIdValues("sortKeysOrder")}
                        sortParams={this.getSortIdValues("sortParams")}
                        ifSearchEnabled={this.checkIfSearchActive()}
                    />}

                {/* ==============User==============  */}
                {logsData && logsData.length > 0 && selectedLogType === "user" &&
                    <UsersLogsViewTable
                        tableBodyData={logsData}
                        totalDataCount={totalDataCount}
                        resetOffsetAndGetData={() => this.resetOffsetAndGetData("user")}
                        isTableDataLoading={isTableDataLoading}
                        onIdClicked={(id) => this.onIdClicked('user', id)}
                        showLoader={showLoader}

                        onSortClicked={(prms) => {
                            if (!this.checkIfSearchActive()) {
                                this.onSortClicked(prms);
                            }
                        }}
                        srtkys={this.getSortIdValues("srtkys")}
                        sortKeysOrder={this.getSortIdValues("sortKeysOrder")}
                        sortParams={this.getSortIdValues("sortParams")}
                        ifSearchEnabled={this.checkIfSearchActive()}
                    />}
                {/* ==============Admin User==============  */}
                {logsData && logsData.length > 0 && selectedLogType === "adminuser" && !this.checkIfSearchActive() &&
                    <AdminUserLogsTable
                        tableBodyData={logsData}
                        totalDataCount={totalDataCount}
                        resetOffsetAndGetData={() => this.resetOffsetAndGetData("adminuser")}
                        onIdClicked={(id) => this.onIdClicked('adminuser', id)}
                        isTableDataLoading={isTableDataLoading}
                        showLoader={showLoader}
                    />}

                {/* ==============Admin User Logs==============  */}
                {logsData && logsData.length > 0 && selectedLogType === "adminuser" && this.checkIfSearchActive() &&
                    <AllLogsViewTable
                        tableBodyData={logsData}
                        totalDataCount={totalDataCount}
                        resetOffsetAndGetData={() => this.resetOffsetAndGetData("all")}
                        isTableDataLoading={isTableDataLoading}
                        onIdClicked={(data) => {
                            this.onIdClicked(data.type, data.id)
                        }}
                        showLoader={showLoader}

                        onSortClicked={(prms) => {
                            if (!this.checkIfSearchActive()) {
                                this.onSortClicked(prms);
                            }
                        }}
                        srtkys={this.getSortIdValues("srtkys")}
                        sortKeysOrder={this.getSortIdValues("sortKeysOrder")}
                        sortParams={this.getSortIdValues("sortParams")}
                        ifSearchEnabled={this.checkIfSearchActive()}
                    />}

                {showLoader && <Loader height={"40vh"} />}

                {!showLoader && logsData && logsData.length === 0 &&
                    <div className={classes.defaultTemplate}
                        style={{
                            margin: "10%",
                            textAlign: "center",
                            fontSize: "24px"
                        }}>
                        <span style={{ display: "grid" }}>
                            <i style={{ fontSize: "44px" }} className={classes.defaultIcon + " fa fa-frown-o"} aria-hidden="true"></i>
                            {"No data available"}
                        </span>
                    </div>}
            </div>
        );
    }
}
AppLogsContainer.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(AppLogsContainer);