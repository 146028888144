import React from 'react';
// import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
// import Icon from '@material-ui/core/Icon';
import Loader from '../../common/Loader';

const styles = theme => ({
    heading: {
        fontSize: '21px',
        fontWeight: '500',
        marginTop: '0',
        marginBottom: '0',
        fontFamily: 'Montserrat, sans-serif',
    },
    dialogPaper: {
        minWidth: '500px',
        // maxWidth: '700px',
        minHeight: '180px',
        // maxHeight: '500px'
    },

});

class ConfirmDialogWithLoader extends React.Component {
    state = {
        open: this.props.show,
        dialogText: this.props.dialogText,
        dialogTitle: this.props.dialogTitle || "Do you want to update the followings",
    };

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.show,
            dialogText: this.props.dialogText,
            dialogTitle: this.props.dialogTitle || "Do you want to update the followings",
            showConfirmLoader: this.props.showConfirmLoader || false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props !== this.state) {
            this.setState({ open: this.props.show });
        }
        if (nextProps.dialogText !== this.state.dialogText) {
            this.setState({ dialogText: nextProps.dialogText });
        }
        if (nextProps.showConfirmLoader !== this.state.showConfirmLoader) {
            this.setState({ showConfirmLoader: nextProps.showConfirmLoader });
        }
    }

    handleConfirmed = () => {
        this.setState({ open: false });
        this.props.onConfirmed();
    };

    handleClose = () => {
        this.setState({ open: false });
        this.props.onCanceled();
    };

    render() {
        const { classes, showSweetAlertShown } = this.props;

        return (
            <div>
                <Dialog
                    // fullScreen={fullScreen}
                    style={{ minWidth: '250px', display: showSweetAlertShown ? "none": "unset" }}
                    open={this.state.open}
                    classes={{ paper: classes.dialogPaper }}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    {!this.state.showConfirmLoader ? <div>
                        <DialogTitle
                            style={{ background: '#05073a', textAlign: 'center', height: '50px' }}
                            id="form-dialog-title">
                            <p style={{ color: '#fff', marginTop: '-10px', fontFamily: 'Lato', fontSize: '18px' }}>
                                {this.state.dialogTitle}</p>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText style={{ fontFamily: 'Lato', fontSize: '20px', color: '#000000d6' }}>
                                {this.state.dialogText}
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary">
                                Cancel
                        </Button>
                            <Button onClick={this.handleConfirmed} color="primary" autoFocus>
                                Yes !
                        </Button>
                        </DialogActions>
                    </div> :
                        <Loader />}
                </Dialog>
            </div>
        );
    }
}

// ConfirmDialogWithLoader.propTypes = {
//     fullScreen: PropTypes.bool.isRequired,
// };

export default withStyles(styles)(withMobileDialog()(ConfirmDialogWithLoader));
