import Api from '../../config/dev';
import Utils from '../common/utils';


let relationshipManagerService = {

    // http://localhost:3000/bijak/rm
    getRMList: async function (param) {
        return Api({
            method: 'get',
            headers: {
                "Authorization": Utils.getToken()
            },
            url: '/bijak/rm',
            params: param
        }, 6);
    },

    // http://localhost:3000/bijak/update/rm/2
    updateRMUser: async function (id, payload) {
        return Api({
            method: 'post',
            headers: {
                "Authorization": Utils.getToken()
            },
            url: '/bijak/update/rm/'+id,
            data: payload
        }, 6);
    },
    
    // http://localhost:3000/bijak/create/rm
    createRMUser: async function (payload) {
        return Api({
            method: 'post',
            headers: {
                "Authorization": Utils.getToken()
            },
            url: '/bijak/create/rm',
            data: payload
        }, 6);
    }
}


export default relationshipManagerService;