import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

class DateRangeSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedStartDate: this.getPreviousDate(1),
            selectedEndDate: new Date(),
            isAlltimeChecked: false
        }
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
    }

    componentDidMount() {
        this.props.onDefaultDateFromDateRangeShown({ "startDate": this.formateDateForApi(this.state.selectedStartDate), "endDate": this.formateDateForApi(this.state.selectedEndDate) });
    }

    getPreviousDate(PreviousnoOfDays) {
        var date = new Date();
        return (new Date(date.setDate(date.getDate() - PreviousnoOfDays)));
    }

    handleStartDateChange = (date) => {
        this.setState({ selectedStartDate: date }, function () {         
            this.props.onDateChanged({ "startDate": this.formateDateForApi(date), "endDate": this.formateDateForApi(this.state.selectedEndDate) });
        })
    }
    handleEndDateChange = (date) => {
        this.setState({ selectedEndDate: date }, function () {
            this.props.onDateChanged({ "startDate": this.formateDateForApi(this.state.selectedStartDate), "endDate": this.formateDateForApi(date) });
        })
    }

    formateDateForApi(data) {
        if (data && data !== "") {
            var dateVal = new Date(data);
            // console.log(dateVal.getMonth());
            dateVal = dateVal.getFullYear() + "-" + ((dateVal.getMonth() + 1) < 10 ? "0" +( dateVal.getMonth() + 1) : dateVal.getMonth() + 1) + "-" + (dateVal.getDate() < 10 ? "0" + dateVal.getDate() : dateVal.getDate());
            // console.log(dateVal);
            return dateVal;
        } else {
            return "";
        }
    }

    showAllTimeData = (event) => {
        if(event.target.checked) {
            this.setState({isAlltimeChecked: true});
            this.handleStartDateChange(this.formateDateForApi(new Date("01/01/2019")));
        }else {
            this.setState({isAlltimeChecked: false});
            this.handleStartDateChange(this.getPreviousDate(1));
        }
    }

    render() {
        const { selectedStartDate, selectedEndDate, isAlltimeChecked } = this.state;
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="flex-start">

                    <div style={{
                        verticalAlign: "middle",
                        lineHeight: "52px",
                        fontSize: "16px",
                        fontFamily: "lato",
                        fontWeight: 500,
                        marginRight:'50px' 
                    }}>Select Date Range &nbsp; &nbsp;&nbsp;</div>
                    {/* <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="normal"
                    id="date-picker-inline"
                    label="Start date"
                    style={{marginTop: 0}}
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    /> */}
                    <FormControlLabel
                        control={<Checkbox
                            checked={isAlltimeChecked}
                            onChange={(event) => this.showAllTimeData(event)}
                            name="checkedA" />}
                        label="All Time"/>
                    {!this.state.isAlltimeChecked &&    
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Start date"
                        format="dd-MMM-yyyy"
                        style={{ marginTop: 0, width: 150,marginRight:'30px', marginLeft:'20px' }}
                        value={selectedStartDate}
                        onChange={this.handleStartDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }} />
                    } 
                    {!this.state.isAlltimeChecked &&  
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="End date"
                        format="dd-MMM-yyyy"
                        style={{ marginTop: 0, width: 150 ,marginRight:'100px' }}
                        value={selectedEndDate}
                        onChange={this.handleEndDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                    }   
                </Grid>
            </MuiPickersUtilsProvider>);
    }
}


export default (DateRangeSelector);