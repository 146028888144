import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import ConfirmDialog from './../../app/common/ConfirmDialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NoDataAvailable from '../common/NoDataAvailable';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import AddOrderModal from '../orders/common/AddOrderModal';
import orderService from '../../app/orderService/orderService';
import Utils from '../../app/common/utils';
import { getAccessAccordingToRole } from '../../config/appConfig';
import SweetAlertPage from '../../app/common/SweetAlertPage';
import Loader from './Loader';
import OrderInfoDialog from '../orders/components/OrderInfoDialog';
import ViewSupportingInvoiceModal from '../orders/common/ViewSupportingInvoiceModal';
var moment = require('moment');

const styles = theme => ({
    heading: {
        fontSize: '21px',
        fontWeight: '500',
        marginTop: '0',
        marginBottom: '0',
        fontFamily: 'Montserrat, sans-serif',
    },
    dialogPaper: {
        minWidth: '80%',
        // maxWidth: '700px',
        minHeight: '700px',
        // maxHeight: '500px'
    },
    tableCell: {
        paddingLeft: '4px',
        paddingRight: '4px',
        textAlign: 'center',
        maxWidth: '200px'
    },
    formAddBtn: {
        width: '90%',
        borderRadius: '10px',
        fontSize: '20px',
        textTransform: 'uppercase',
        backgroundColor: '#4d9fa0 ',
        color: '#fff',
        height: '45px',
        marginBottom: '15px',
        marginTop: "11px",
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    formRoot: {
        // display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        // marginLeft: '25%',
        border: '1px solid #ccc',
        boxShadow: '2px 2px 6px 0px  rgba(0,0,0,0.3)',
        borderRadius: '4px',
        marginBottom: '20px',
        marginTop: '8%',
        padding: '25px',
        textAlign: 'center'
    },
    info: {
        fontSize: '14px',
        marginLeft: '8px',
        color: '#fd0671',
        cursor: 'pointer'
    }

});

class OrderTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableHeadData: ["order id", "APP ID", "buyer info", "broker name", "Date", "location", "commodity", "Amount  "],
            tableHeadDataKeys: ["id", "app_order_id", "supplier_name/buyer_name", "broker_name", "createdtime", "source_location", "commodity", "bijak_amt"],
            open: this.props.openModal,
            tableBodyData: [],
            rowsPerPage: 50,
            page: 0,
            showAddOrderModal: this.props.showAddOrderModal || false,

            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },
            showOrderInfo: false,
            orderInfo: undefined,

            invoiceModalData: [],
            showSupportingInvoiceModal: false,

            totalDataCount: 0,
            isTableDataLoading: false,
            params: {
                limit: 1000, // total amount ,of data 
                offset: 0 // data from which data needs to be get
            },

            payloadParams: {
            },
            sortKeys: {},
            sortKeysOrder: [],
            sortingEnabled: ["id", "createdtime", "bijak_amt"],
        }
        // console.log(this.props.data)
    }
    componentDidMount() {
        this.getOrderData();
        // console.log(this.props.data);
        let tableHeadData = ["order id", "APP ID", "buyer info", "broker name", "Date", "location", "commodity", "Amount  "];
        let tableHeadDataKeys = ["id", "app_order_id", "buyer_name", "broker_name", "createdtime", "source_location", "commodity", "bijak_amt"];
        if (this.props.userdata.role === "ca") {
            tableHeadData = ["order id", "APP ID", "supplier info", "broker name", "Date", "location", "commodity", "Amount  "];
            tableHeadDataKeys = ["id", "app_order_id", "supplier_name", "broker_name", "createdtime", "source_location", "commodity", "bijak_amt"];
        } else if (this.props.userdata.role === "broker") {
            tableHeadData = ["order id", "APP ID", "supplier info", "buyer info", "Date", "location", "commodity", "Amount  "];
            tableHeadDataKeys = ["id", "app_order_id", "supplier_name", "broker_name", "createdtime", "source_location", "commodity", "bijak_amt"];
        }
        this.setState({ tableHeadData: tableHeadData, tableHeadDataKeys: tableHeadDataKeys });
        // this.formatDataForPagination(this.props.data);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.showAddOrderModal !== this.state.showAddOrderModal) {
            this.setState({ showAddOrderModal: nextProps.showAddOrderModal });
        }
        // if (nextProps.data !== this.state.tableBodyData) {
        //     this.formatDataForPagination(nextProps.data);
        // }
    }

    // formatDataForPagination(rawData) {
    //     this.setState({
    //         tableBodyData: rawData.data ? rawData.data : [],
    //         totalDataCount: rawData.totalCount && rawData.totalCount[0] && rawData.totalCount[0]["count"] ? parseInt(rawData.totalCount[0]["count"], 10) : 0,
    //         isTableDataLoading: false,
    //         showLoader: false
    //     });
    // }

    getTableCellClass(classes, index) {
        return classes.tableCell;
    }

    handleDialogCancel(event) {
        this.props.onEditModalCancel();
    }

    getOrderData() {
        let param = {};
        if (this.props.userdata.role === "ca") {
            param["buyerid"] = this.props.userdata.mobile;
        } else if (this.props.userdata.role === "broker") {
            param["brokerid"] = this.props.userdata.id;
        } else if (this.props.userdata.role === "la") {
            param["supplierid"] = this.props.userdata.mobile;
        } else {
            param["na"] = this.props.userdata.mobile;
        }
        if (Object.keys(param).length) {
            this.setState({ payloadParams: param }, () =>
                this.getListData(param)
            )
        }
    }

    onOrderDataAdded() {
        this.setState({ showAddOrderModal: false }, function () {
            let param = {};
            if (this.props.userdata.role === "ca") {
                param["buyerid"] = this.props.userdata.mobile;
            } else if (this.props.userdata.role === "broker") {
                param["brokerid"] = this.props.userdata.id;
            } else if (this.props.userdata.role === "la") {
                param["supplierid"] = this.props.userdata.mobile;
            } else {
                param["na"] = this.props.userdata.mobile;
            }
            // console.log(param);
            if (Object.keys(param).length) {
                this.setState({
                    payloadParams: param,
                    params: { limit: 1000, offset: 0 },
                    tableBodyData: []
                }, () =>
                    this.getListData(param)
                )
                this.props.onOrderAdded(param);
            }
        });
    }

    async getListData(params) {
        this.setState({ showLoader: true });
        try {
            params["limit"] = this.state.params["limit"];
            params["offset"] = this.state.params["offset"];
            params["userInfo"] = true;
            let resp = await orderService.getOrderListData(params);
            if (resp.data.status === 1 && resp.data.result) {
                let rawData = resp.data.result;
                this.setState({
                    tableBodyData: this.state.tableBodyData.concat(rawData.data),
                    totalDataCount: rawData.totalCount && rawData.totalCount[0] && rawData.totalCount[0]["count"] ? parseInt(rawData.totalCount[0]["count"], 10) : 0,
                    isTableDataLoading: false,
                    showLoader: false
                });
            } else {
                let sweetAlrtData = this.state.sweetAlertData;
                sweetAlrtData["type"] = "error";
                sweetAlrtData["title"] = "Error";
                sweetAlrtData["text"] = resp && resp.data && resp.data.message ? resp.data.message : "Oops an error occured while getting order data";
                this.setState({
                    showSweetAlert: true,
                    sweetAlertData: sweetAlrtData,
                    showLoader: false
                });
            }

        } catch (err) {
            console.error(err);
            if (this.ismounted) {
                // this.setState({ tableBodyData: [],showLoader:false });
            }
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
        if (this.state.tableBodyData.length === (newPage * this.state.rowsPerPage)) {
            this.resetOffsetAndGetData();
        }
    };

    handleChangeRowsPerPage = event => {
        this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
    };



    handleClickOpen() {
        this.setState({ showAddOrderModal: true });
    }

    formatDateAndTime = (dateval) => {
        var fdate = moment.utc(new Date(dateval)).format('DD-MMM-YYYY HH:mm A')
        return <div style={{ width: "95px", display: "inline-block" }}> {fdate.split(" ")[0] + " \n" + fdate.split(" ")[1] + " " + fdate.split(" ")[2]}</div>
        // return <div style={{ width: "95px", display: "inline-block" }}> {fdate.split(" ")[0]}</div>
    }


    handelSweetAlertClosed() {
        this.setState({ showSweetAlert: false }, () => {
            if (this.state.sweetAlertData.type !== "error") {
                // this.handelGetData();
            }
        });
    }

    onInfoClick = (info, event) => {
        this.setState({
            orderInfo: info, showOrderInfo: true
        })
    }

    onOrderInfoModalClose = () => {
        this.setState({
            orderInfo: undefined, showOrderInfo: false
        })
    }

    onShowSupportinInvoiceModal = (info, event) => {
        this.setState({
            invoiceModalData: info["supporting_images"], showSupportingInvoiceModal: true
        })
    }

    resetOffsetAndGetData() {
        let paramsval = this.state.params;
        paramsval["offset"] = paramsval["offset"] + 1000;
        this.setState({ params: paramsval, isTableDataLoading: true }, function () {
            this.getListData(this.state.payloadParams);
        })
    }

    addKeysToSortList = (keys) => {
        if (this.state.sortingEnabled.indexOf(keys) > -1) {
            let srtkys = Object.assign({}, this.state.sortKeys);
            let srtKeyOrders = this.state.sortKeysOrder;
            if (srtKeyOrders.indexOf(keys) === -1) {
                srtKeyOrders.splice(0, 0, keys);
            } else {
                srtKeyOrders.splice(srtKeyOrders.indexOf(keys), 1);
                srtKeyOrders.splice(0, 0, keys);
            }

            if (srtkys.hasOwnProperty(keys)) {
                if (srtkys[keys] === "asc") {
                    srtkys[keys] = "desc";
                } else {
                    srtkys[keys] = "asc";
                }
            } else {
                srtkys[keys] = "asc";
            }
            this.setState({ sortKeys: srtkys, sortKeysOrder: srtKeyOrders });
            // ?sortkey=id,fullname,rating&sortorder=asc,asc,asc&limit=2000&offset=0
            let params = {
                sortkey: [],
                sortorder: []
            }
            for (let i = 0; i < srtKeyOrders.length; i++) {
                params["sortkey"].push(srtKeyOrders[i]);
                params["sortorder"].push(srtkys[srtKeyOrders[i]]);
            }
            params["sortkey"] = params["sortkey"].toString();
            params["sortorder"] = params["sortorder"].toString();
            let payloadParams = this.state.payloadParams;
            payloadParams["sortkey"] = params["sortkey"];
            payloadParams["sortorder"] = params["sortorder"];
                this.setState({
                    payloadParams : payloadParams,
                    tableBodyData : [],
                    page: 0
                },()=>{
                    this.getListData(this.state.payloadParams);
                })
            
        }
    }

        // CHECK app_id_num IS AVAILABLE 
        getAppIdNo(row) {
            console.log( row );
            try {
                if (row) {
                    if (row.hasOwnProperty("app_id_num") && row["app_id_num"]) {
                        return row["app_id_num"];
                    } else {
                        if (row.hasOwnProperty("app_order_id")) {
                            return row["app_order_id"];
                        } else {
                            return "-";
                        }
                    }
                } else {
                    return "-";
                }
            } catch (err) {
                console.log(err);
                return "-";
            }
        }

    render() {
        const { classes } = this.props;
        const { rowsPerPage, page, showSweetAlert, sweetAlertData, showLoader, totalDataCount,
            isTableDataLoading, tableHeadDataKeys, sortKeys, sortingEnabled } = this.state;
        const leftAlignedIndexs = [1, 2, 3];
        const rightAlignedIndexs = [7];
        return (
            <div style={{ marginTop: '50px' }}>
                {this.state.tableBodyData ?
                    <div>
                        <div style={{ maxHeight: "70vh", overflowY: "scroll" }}>
                            <Table className='table-body' stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow style={{ borderBottom: "2px solid #858792" }} >
                                        {this.state.tableHeadData.map((option, i) => (
                                            <TableCell
                                                key={option}
                                                className={this.getTableCellClass(classes, i)}
                                                onClick={() => this.addKeysToSortList(tableHeadDataKeys[i])}
                                                style={{
                                                    textTransform: 'uppercase',
                                                    minWidth: i === 0 || i === 1 ? "85px" : '120px',
                                                    paddingRight: i === 6 ? "10px" : '',
                                                    cursor: sortingEnabled.indexOf(tableHeadDataKeys[i]) > -1 ? "pointer" : "unset",
                                                    textAlign: leftAlignedIndexs.indexOf(i) > -1 ? "left" : rightAlignedIndexs.indexOf(i) > -1 ? "right" : ""
                                                }}>{option}
                                                {sortingEnabled.indexOf(tableHeadDataKeys[i]) > -1 &&
                                                        <i className={Utils.getArrowIconType(sortKeys, tableHeadDataKeys[i])} aria-hidden="true"  style={{ color : sortKeys.hasOwnProperty(tableHeadDataKeys[i]) ? '#e72e89':'unset'}}></i>}
                                            </TableCell>
                                        ))}
                                        {/* <TableCell key="star" className={this.getTableCellClass(classes, 4)} style={{ minWidth: '50px', color: "goldenrod", textAlign: 'left' }}> Quantity </TableCell> */}
                                    </TableRow>
                                </TableHead>
                                {!showLoader &&
                                    <TableBody>
                                        {this.state.tableBodyData &&
                                            (rowsPerPage > 0
                                                ? this.state.tableBodyData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : this.state.tableBodyData
                                            ).map((row, i) => {
                                                return (
                                                    <TableRow key={'table_' + i} id={"order_"+row.id} style={{ background: i % 2 !== 0 ? "#e8e8e8" : "#fff" }}>
                                                        <TableCell component="th" scope="row" className={this.getTableCellClass(classes, 0)} >
                                                            {!row.is_added_by_platform && <i style={{ fontSize: "24px", marginRight: "4px", color: "#50aa35" }} class="fa fa-mobile" aria-hidden="true"></i>}
                                                            <span
                                                                data-toggle="tooltip" data-placement="center" title="info"
                                                                onClick={this.onInfoClick.bind(this, row)}
                                                                style={{ color: "#3f51b5", borderBottom: "2px solid #3f51b5", padding: "0px 2px", cursor: "pointer" }}>
                                                                {row.id}
                                                            </span>
                                                            <i style={{ paddingTop: "11px" }}
                                                                data-toggle="tooltip" data-placement="right" title="Supporting images"
                                                                onClick={this.onShowSupportinInvoiceModal.bind(this, row)}
                                                                className={"fa fa-camera " + classes.info} aria-hidden="true"></i>
                                                            <sup>{row.supporting_images ? row.supporting_images.length : 0}</sup>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" className={this.getTableCellClass(classes, 0)} style={{ textAlign: "left" }} >
                                                            {/* {row.app_order_id ? row.app_order_id : "-"} */}
                                                             {this.getAppIdNo(row)}
                                                        </TableCell>
                                                        {this.props.role !== "la" &&
                                                            <TableCell component="th" scope="row" className={this.getTableCellClass(classes, 0)}>
                                                                <div style={{ display: "grid", textAlign: "left", textTransform: "capitalize" }}>
                                                                    <span>{row.supplier_name ? row.supplier_name : ""} </span>
                                                                    <span style={{ fontSize: "12px" }}>{"( " + Utils.maskMobileNumber(row.supplier_mobile) + " )"}</span>
                                                                </div>
                                                            </TableCell>}

                                                        {(this.props.role === "la" || this.props.role === "broker") && <TableCell component="th" scope="row" className={this.getTableCellClass(classes, 0)}>
                                                            <div style={{ display: "grid", textAlign: "left", textTransform: "capitalize" }}>
                                                                <span>{row.buyer_name ? row.buyer_name : ""} </span>
                                                                <span style={{ fontSize: "12px" }}>{"( " + Utils.maskMobileNumber(row.buyer_mobile) + " )"}</span>
                                                            </div>
                                                        </TableCell>}

                                                        {this.props.role !== "broker" && <TableCell className={this.getTableCellClass(classes, 4)} style={{ textAlign: "left" }}>
                                                            {row.broker_name}
                                                        </TableCell>}
                                                        <TableCell className={this.getTableCellClass(classes, 5)} style={{ padding: "0px", textAlign: 'center', borderBottom: 0 }} >
                                                            {this.formatDateAndTime(row.createdtime)}
                                                        </TableCell>
                                                        <TableCell className={this.getTableCellClass(classes, 4)} >
                                                            {row.source_location ? row.source_location : "-"}
                                                        </TableCell>
                                                        <TableCell className={this.getTableCellClass(classes, 6)}  >
                                                            <span style={{
                                                                color: "white",
                                                                background: "rgb(58, 126, 63)",
                                                                padding: "4px 8px",
                                                                display: "inline-block",
                                                                borderRadius: "13px"
                                                            }}>{row.commodity}</span> </TableCell>
                                                        <TableCell className={this.getTableCellClass(classes, 7)} style={{ textAlign: "right", paddingRight: '10px' }}>
                                                            ₹ {Utils.formatNumberWithComma(row.bijak_amt)}

                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>}

                            </Table>


                        </div>
                        {isTableDataLoading && <Loader />}
                        {this.state.tableBodyData && this.state.tableBodyData.length > 0 && <Table>
                            <TableFooter style={{ borderTop: "2px solid #858792", background: "#fafafa" }}>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[25, 50, 100]}
                                        colSpan={6}
                                        count={totalDataCount}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                        }}
                                        onChangePage={this.handleChangePage.bind(this)}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>}
                        {showLoader && !isTableDataLoading && <Loader />}
                        {!showLoader && !this.state.tableBodyData.length && < NoDataAvailable style={{ height: '25vh' }} />}
                    </div> : <Loader />}
                {/* <Button className={classes.formCancelBtn} onClick={this.handleAddClick.bind(this)} color="primary">Sumbit</Button> */}
                {/* <Button style={{float:'right',marginRight:'28px'}} onClick={this.handleDialogCancel.bind(this)} color="primary">Cancel</Button> */}

                {getAccessAccordingToRole("addOrder") && this.props.userdata && (this.props.userdata.role === "la" || this.props.userdata.role === "ca" || this.props.userdata.role === "broker") &&
                    <div className="updateBtndef" style={{ bottom: "-50px", right: "-50px" }}>
                        <div className="updateBtnFixedModal" style={{ display: 'flex' }} onClick={this.handleClickOpen.bind(this)}>
                            <i className="fa fa-plus-circle add-icon" aria-hidden="true"></i>
                            <p style={{
                                fontSize: "14px",
                                fontFamily: "lato",
                                fontWeight: 600
                            }}>Add Order</p></div>
                    </div>}
                {this.state.showAddOrderModal &&
                    <AddOrderModal
                        open={this.state.showAddOrderModal}
                        userdata={this.props.userdata}
                        onOrderDataAdded={(event) => this.onOrderDataAdded(event)}
                        onAddModalCancel={(event) => {
                            this.setState({ showAddOrderModal: false });
                            this.props.onAddOrderModalClosed()
                        }}
                    />}

                {this.state.showOrderInfo &&
                    <OrderInfoDialog
                        openModal={this.state.showOrderInfo}
                        data={this.state.orderInfo}
                        onEditModalCancel={this.onOrderInfoModalClose.bind(this)} />}

                {this.state.showConfirmDialog ?
                    <ConfirmDialog
                        dialogText={this.state.dialogText}
                        dialogTitle={this.state.dialogTitle}
                        show={this.state.showConfirmDialog}
                        onConfirmed={this.handelConfirmUpdate}
                        onCanceled={this.handelCancelUpdate} /> : ""}

                {showSweetAlert &&
                    <SweetAlertPage
                        show={true}
                        type={sweetAlertData.type}
                        title={sweetAlertData.title}
                        text={sweetAlertData.text}
                        sweetAlertClose={() => this.handelSweetAlertClosed()}
                    />}

                {this.state.showSupportingInvoiceModal &&
                    <ViewSupportingInvoiceModal
                        openModal={this.state.showSupportingInvoiceModal}
                        onInvoiceModalClose={() => { this.setState({ showSupportingInvoiceModal: false, invoiceModalData: [] }) }}
                        invoiceUrlData={this.state.invoiceModalData} />}

            </div>
        );
    }
}

OrderTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrderTable);