import axios from 'axios';
// import cookie from 'react-cookies';
// import { Auth } from "aws-amplify";
import Auth from '@aws-amplify/auth';
import Utils from '../app/common/utils';
import { getEnviornmentType } from './appConfig';

// let currentUrl = window.location.href;

// let baseUrl =  'https://1ueogcah0b.execute-api.ap-south-1.amazonaws.com/dev/';

// var hostName = window.location.hostname;
const envType = getEnviornmentType();
const baseUrl = (envType === "dev" ? `https://1ueogcah0b.execute-api.ap-south-1.amazonaws.com/dev/` :
  (envType === "staging" ? 'https://ne69x2fox6.execute-api.ap-south-1.amazonaws.com/staging/' :
    `https://lwjh5tmr5k.execute-api.ap-south-1.amazonaws.com/prod/`));


const baseUrl1 = (envType === "dev" ? `https://f51qgytp3d.execute-api.ap-south-1.amazonaws.com/dev/` :
  (envType === "staging" ? 'https://u5f42vi2gg.execute-api.ap-south-1.amazonaws.com/staging/' :
    `https://mh53vat5i6.execute-api.ap-south-1.amazonaws.com/prod/`));

const client = axios.create({
  baseURL: baseUrl
});

const client1 = axios.create({
  baseURL: baseUrl1
});

const baseUrl3 = (envType === "dev" ? `https://yh0y6bihj9.execute-api.ap-south-1.amazonaws.com/dev/` :
  (envType === "staging" ? 'https://rwxpzaxw85.execute-api.ap-south-1.amazonaws.com/staging/' :
    `https://9yuezfm6k2.execute-api.ap-south-1.amazonaws.com/prod/`));


const client3 = axios.create({
  baseURL: baseUrl3
});

const baseUrl4 = (envType === "dev" ? `https://f9ol52l7gl.execute-api.ap-south-1.amazonaws.com/dev/` :
  (envType === "staging" ? 'https://niotjh5c2j.execute-api.ap-south-1.amazonaws.com/staging/' :
    `https://z8ez4q59j2.execute-api.ap-south-1.amazonaws.com/prod/`));

const client4 = axios.create({
  baseURL: baseUrl4
});

const baseUrl5 = (envType === "dev" ? `https://7t9k622sm0.execute-api.ap-south-1.amazonaws.com/dev/` :
  (envType === "staging" ? 'https://9ttzwr5py7.execute-api.ap-south-1.amazonaws.com/staging/' :
    `https://hpesenddyj.execute-api.ap-south-1.amazonaws.com/prod/`));

const client5 = axios.create({
  baseURL: baseUrl5
});

const groupUserApiBaseUrl = (
  envType === "dev" ? `https://nvy4z4oj1m.execute-api.ap-south-1.amazonaws.com/dev/` :
    (envType === "staging" ? 'https://f4bllzvde8.execute-api.ap-south-1.amazonaws.com/staging/' :
      `https://59kl5eow34.execute-api.ap-south-1.amazonaws.com/prod/`));

const groupUserApiClient = axios.create({
  baseURL: groupUserApiBaseUrl
});

const dsFeedApiBaseUrl = (
  envType === "dev" ? `https://26a0t4kho4.execute-api.ap-south-1.amazonaws.com/dev/` :
    (envType === "staging" ? 'https://63joc2u1xb.execute-api.ap-south-1.amazonaws.com/staging/' :
      `https://bvyzq9je4b.execute-api.ap-south-1.amazonaws.com/prod/`));

const dsFeedApiClient = axios.create({
  baseURL: dsFeedApiBaseUrl
});



client.defaults.headers.common['Accept'] = 'application/json';


/**
 * Request Wrapper with default success/error actions
 */
const request = function (options, type) {


  const errorHandlingForApiResponse = function (response) {
    var status = "";
    if (response.data.hasOwnProperty('status')) {
      status = response.data.status;
    }

    if (response.data.hasOwnProperty('statusCode')) {
      status = response.data.statusCode;
    }

    // console.log(response);
    // console.log(status);
    if (status === 401) {
      //  Auth.signOut();
      try {

        Auth.signOut()
          .then(data => {

            window.location.href = window.location.origin
            window.sessionStorage.removeItem('bijak_token');
            window.sessionStorage.removeItem('username');

          })
          .catch(err => console.log(err));
      } catch (err) {
        console.log(err)
      }
      return;


    } else if (status === 404) {
      window.location.href = window.location.origin;
      return response;
    }
    else {
      if (response["data"].hasOwnProperty("iscomp") && response["data"]["iscomp"]) {
        let resp = response;
        resp["data"]["result"] = Utils.decryptResponse(response["data"]["result"]);
        return resp;
      } else {
        return response;
      }
    }

  }

  const onErrorOfAuthentication = function (error) {
    try {
      var errorStatus = error.message === "Network Error" ? 401 : error.response.data.statusCode;
      var errorMsg = error.message || error.data.message || "Oops there is an error.";
      if (parseInt(errorStatus, 10) === 401) {
        Auth.signOut()
          .then(data => {
            // userAuth.resetAppOnLogout();
            window.sessionStorage.removeItem('bijak_token')
            window.sessionStorage.removeItem('username');
            window.location.href = window.location.origin;
            return;
          })
          .catch(err => console.log(err));
        // localStorage.setItem('pepToken', null);
        alert("Session expired. Please login agian");

      }
      return { data: { status: 0, result: undefined, message: errorMsg } };
    } catch (er) {
      console.log(er)
      return { data: { status: 0, result: undefined, message: "Oops an error occured" } };
    }
  }

  if (type === 1) {
    return client(options)
      .then(errorHandlingForApiResponse)
      .catch(onErrorOfAuthentication);
  }
  if (type === 2) {
    return client1(options)
      .then(errorHandlingForApiResponse)
      .catch(onErrorOfAuthentication);
  }
  if (type === 3) {
    return client3(options)
      .then(errorHandlingForApiResponse)
      .catch(onErrorOfAuthentication);
  }
  if (type === 4) {
    return client4(options)
      .then(errorHandlingForApiResponse)
      .catch(onErrorOfAuthentication);
  }
  if (type === 5) {
    return client5(options)
      .then(errorHandlingForApiResponse)
      .catch(onErrorOfAuthentication);
  }
  if (type === 6) {
    return groupUserApiClient(options)
      .then(errorHandlingForApiResponse)
    // .catch(onErrorOfAuthentication);
  }

  // ds feed
  if (type === 7) {
    return dsFeedApiClient(options)
      .then(errorHandlingForApiResponse)
    // .catch(onErrorOfAuthentication);
  }
}


export default request;