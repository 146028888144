/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import ConfirmDialog from "../../app/common/ConfirmDialog";
import dsFeedService from "../../app/dsFeedService/dsFeedService";
import SweetAlertPage from "../../app/common/SweetAlertPage";
import ViewMultiImageModal from "../common/ViewMultiImageModal";
import Loader from "../common/Loader";
import CommonTableTemplate from "../common/commonTableTemplate";
import CONSTANTS from "../../utils/constants";

var moment = require("moment");

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      head: {
        color: "#2e3247",
        fontWeight: 600,
        fontSize: "13px !important",
        fontFamily: "lato !important",
        textTransform: "uppercase",
        lineHeight: "1em",
      },
      body: {
        color: "rgba(0, 0, 0, 0.87)",
        fontWeight: 500,
        fontSize: "14px !important",
        fontFamily: "lato !important",
      },
    },
    MuiTablePagination: {
      toolbar: {
        paddingRight: "30%",
      },
    },
  },
});

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    verticalAlign: "middle",
    marginRight: theme.spacing.unit,
    fontSize: 10,
  },
  tableCell: {
    paddingLeft: "4px",
    paddingRight: "4px",
    maxWidth: "200px",
    padding: "8px 12px",
  },
  titleText: {
    width: "50%",
    textAlign: "left",
    paddingLeft: "15px",
    paddingTop: "7px",
    fontFamily: "lato !important",
  },
  defaultTemplate: { height: "30vh", paddingTop: "10vh" },
  defaultSpan: { display: "grid", fontSize: "25px" },
  defaultIcon: { fontSize: "65px", color: "#384952" },
  editIcon: {
    fontSize: "20px",
    color: "#1e459c",
    paddingLeft: 3,
    cursor: "pointer",
    marginRight: "2px",
    float: "left",
  },
  infoIcon: { color: "#d46262", fontSize: "18px", cursor: "pointer" },
  cellDiv: {
    maxWidth: "180px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  root: {
    width: "100%",
    minHeight: "80vh",
  },
  lightTooltip: {
    fontSize: "14px",
    maxWidth: "none",
  },
  toggle: {
    fontSize: "15px",
    color: "#000",
  },
  actionButton: {
    color: "#fff",
    fontSize: "11px",
    height: "28px",
    cursor: "pointer",
    textAlign: "center",
    textTransform: "none",
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
  },
  actionIcon: {
    width: "18px",
    height: "18px",
    marginRight: 2,
  },
});

class SupplyContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableHeadData: [
        "ID",
        "Commodity",
        "Variety",
        "Min Price \u20B9",
        "Max Price \u20B9",
        "Rate Range Unit",
        "Trade Volume Per Week",
        "Location",
        "Contact Info",
        "View Images",
        "Created On",
        "Updated On",
        "Status",
        "Action",
      ],
      tableHeadDataKey: [
        "ID",
        "Commodity",
        "Variety",
        "Min Price",
        "Max Price",
        "Rate Range Unit",
        "Trade Volume Per Week",
        "Location",
        "Contact Info",
        "View Images",
        "Created On",
        "Updated On",
        "Status",
        "Action",
      ],
      tableBodyData: [],
      tableCount: 0,
      searchedText: "",
      showServerDialog: false,
      showIbImageModal: false,
      imageModalData: [],
      deleteId: null,
      showEditDataModal: false,
      showLoader: false,
      action: null,
      approved: CONSTANTS.FILTER_ACTIONS.PENDING,
      rowsPerPage: 10,
      page: 0,
      params: {
        limit: 500, // total amount of data
        offset: 0, // data from which data needs to be get
      },
      filterParams: {},
      sort: {
        column: null,
        direction: "desc",
      },
      sweetAlertData: {
        type: "",
        title: "",
        text: "",
      },
      showConfirmDialog: false,
      actionMesg: null,
      id: null,
      dialogTitle: " Confirmation!",
      dialogText: "",
    };
  }

  async componentDidMount() {
    let param = { limit: 500, offset: 0, action:0, approved:0};
    this.getData(param);
    this.getMasterData();
  }

  async getMasterData() {
    try {
      const params = { lang: CONSTANTS.COMMON_CONSTANTS.EN };
      let resp = await dsFeedService.getMasterData(params);
      if (resp.data.data.quantity_units) {
        const unitsMap = new Map();
        resp.data.data.quantity_units.forEach((unit) => {
          unitsMap.set(unit.id, unit.value);
        });
        this.setState({ unitsMap });
      }
    } catch (err) { }
  }

  async getData(param) {
    try {
      this.setState({ showLoader: true });
      let resp = await dsFeedService.getSupplyList(param);
      let sweetAlrtData = this.state.sweetAlertData;
      if (resp.status === 200 && resp.data.data && resp.data.data.results) {
        this.setState({
          tableBodyData: resp.data.data.results,
          tableCount: resp.data.data.count,
          showLoader: false,
        });
      } else {
        sweetAlrtData["type"] = "error";
        sweetAlrtData["title"] = "Error";
        sweetAlrtData["text"] =
          resp && resp.data && resp.data.message
            ? resp.data.message
            : "Oops there was an error while getting supply list";
        this.setState({
          showSweetAlert: true,
          showLoader: false,
          sweetAlertData: sweetAlrtData,
        });
      }
    } catch (err) { }
  }

  handelCancelUpdate = () => {
    this.setState({ showConfirmDialog: false, alertData: {} });
  };

  handelRefreshData = () => {
    this.setState(
      {
        action: 0,
        approved: CONSTANTS.FILTER_ACTIONS.PENDING,
      },
      () => {
        let param = { limit: 500, offset: 0, action:0, approved:0};
        this.getData(param);
      }
    )
  }

  handleFollowedUpCheckbox = (event) => {
    this.setState(
      {
        approved: event.target.value
      },
      () => {
        let param = {
          limit: 500,
          offset: 0,
          approved: this.state.approved === CONSTANTS.FILTER_ACTIONS.APPROVE ? 1:0,
          action: this.state.approved === CONSTANTS.FILTER_ACTIONS.PENDING ? 0 : 1,
        };
        this.getData(param);
      }
    );
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    if (this.state.tableBodyData.length === newPage * this.state.rowsPerPage) {
      this.resetOffsetAndGetData();
    }
  };

  resetOffsetAndGetData() {
    let paramsval = this.state.params;
    paramsval["offset"] = paramsval["offset"] + 500;
    this.setState({ params: paramsval, isTableDataLoading: true }, function () {
      let param = { limit: 500, offset: 0 };
      this.getData(param);
    });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  onShowSIbImageModal = (info, event) => {
    this.setState({
      imageModalData: info["images"],
      showIbImageModal: true,
    });
  };

  handelSweetAlertClosed = () => {
    this.setState({ showSweetAlert: false }, () => {
      let param = { limit: 500, offset: 0, action:0, approved:0};
      this.getData(param);
    });
  };

  handelConfirmUpdate = async () => {
    const action = this.state.actionMesg;
    const id = this.state.id;
    try {
      let body = { u_id: id, action: action };
      let url = "approve_supply";
      this.setState({ showLoader: true, showConfirmDialog: false });
      let resp = await dsFeedService.putApproveCommon(body, url);
      let sweetAlrtData = this.state.sweetAlertData;
      if (resp && resp.data.response_code == 90 && resp.data.ui_message) {
        this.setState({ showLoader: false });
        sweetAlrtData["type"] = "error";
        sweetAlrtData["title"] = "Error";
        sweetAlrtData["text"] = resp.data.ui_message;
      } else {
        (sweetAlrtData["type"] = "success"),
          (sweetAlrtData["title"] = "Success"),
          (sweetAlrtData["text"] = resp.data.ui_message);
        this.setState({
          showSweetAlert: true,
          showLoader: false,
          sweetAlertData: sweetAlrtData,
        });
      }
    } catch (err) {
      this.setState({ showConfirmDialog: false });
    }
  };

  handleApproveReject = (actionMesg, id) => {
    this.setState({
      actionMesg: actionMesg,
      id: id,
      showConfirmDialog: true,
      dialogText: `Do you want to ${actionMesg}?`,
    });
  };

  getTableCellClass(classes, index) {
    return classes.tableCell;
  }

  render() {
    const { classes } = this.props;
    const {
      unitsMap,
      action,
      approved,
      tableBodyData,
      tableHeadData,
      rowsPerPage,
      page,
      showSweetAlert,
      tableHeadDataKey,
      sweetAlertData,
      tableCount,
      showLoader,
    } = this.state;
    return (
      <MuiThemeProvider theme={theme}>
        <Paper className={classes.card}>
          <CommonTableTemplate
            //common checkbox filter functionallty
            handelRefreshData={this.handelRefreshData}
            handleActionCheckbox={this.handleActionCheckbox}
            handleFollowedUpCheckbox={this.handleFollowedUpCheckbox}
            action={action}
            approved={approved}
            // common Table functionallty
            showLoader={showLoader}
            unitsMap={unitsMap}
            rowsPerPage={rowsPerPage}
            page={page}
            getTableCellClass={this.getTableCellClass}
            classes={classes}
            tableHeadData={tableHeadData}
            tableCount={tableCount}
            tableBodyData={tableBodyData}
            tableHeadDataKey={tableHeadDataKey}
            onShowSIbImageModal={this.onShowSIbImageModal}
            handleChangePage={this.handleChangePage}
            handleApproveReject={this.handleApproveReject}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            type={CONSTANTS.DSFEED_CONSTANTS.SUPPLY}
          />

          {showLoader && <Loader />}

          {this.state.tableBodyData.length > 0 ? (
            ""
          ) : (
              <div className={classes.defaultTemplate}>
                {this.state.searchedText.length > 0 ? (
                  <span className={classes.defaultSpan}>
                    <i
                      className={classes.defaultIcon + " fa fa-frown-o"}
                      aria-hidden="true"
                    ></i>
                    {"Your search does not match any list"}{" "}
                  </span>
                ) : !showLoader ? (
                  <span className={classes.defaultSpan}>
                    <i
                      className={classes.defaultIcon + " fa fa-frown-o"}
                      aria-hidden="true"
                    ></i>
                    {"No data available"}
                  </span>
                ) : (
                      ""
                    )}
              </div>
            )}

          {this.state.showConfirmDialog ? (
            <ConfirmDialog
              dialogText={this.state.dialogText}
              dialogTitle={this.state.dialogTitle}
              show={this.state.showConfirmDialog}
              onConfirmed={this.handelConfirmUpdate}
              onCanceled={this.handelCancelUpdate}
            />
          ) : (
              ""
            )}

          {this.state.showIbImageModal && (
            <ViewMultiImageModal
              openModal={this.state.showIbImageModal}
              onInvoiceModalClose={() => {
                this.setState({ showIbImageModal: false, imageModalData: [] });
              }}
              imageUrlData={this.state.imageModalData}
            />
          )}

          {showSweetAlert && (
            <SweetAlertPage
              show={true}
              type={sweetAlertData.type}
              title={sweetAlertData.title}
              text={sweetAlertData.text}
              sweetAlertClose={() => this.handelSweetAlertClosed()}
            />
          )}
        </Paper>
      </MuiThemeProvider>
    );
  }
}

SupplyContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SupplyContainer);
